import {
  Driver_Read,
  Driver_Read_Nested,
  Driver_Read_Typeahead,
} from '@treadinc/horizon-api-spec';
import parsePhoneNumber from 'libphonenumber-js';

import { CompanyBasic } from '~hooks/useCompany/models';
import { BasicEquipment } from '~hooks/useEquipment/models';
import { Nullable } from '~types/Nullable';

import { CompanyShareBasic } from '../useCompanyShares';

class DriverTypeahead {
  constructor(
    private _id: string,
    private _first_name: string,
    private _last_name: string,
    private _company_share: Nullable<CompanyShareBasic>,
    private _equipment: Nullable<BasicEquipment>,
  ) {}

  public get id(): string {
    return this._id;
  }

  public get firstName(): string {
    return this._first_name;
  }

  public get lastName(): string {
    return this._last_name;
  }

  public get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  public get companyShare(): Nullable<CompanyShareBasic> {
    return this._company_share;
  }

  public get equipment(): Nullable<BasicEquipment> {
    return this._equipment;
  }

  public set equipment(equipment: Nullable<BasicEquipment>) {
    this._equipment = equipment;
  }

  public static parse(proto: Driver_Read_Typeahead) {
    return new DriverTypeahead(
      proto.id,
      proto.first_name,
      proto.last_name,
      proto.company_share ? CompanyShareBasic.parse(proto.company_share) : null,
      proto.equipment ? BasicEquipment.parse(proto.equipment) : null,
    );
  }
}

export class DriverBasic extends DriverTypeahead {
  constructor(
    _id: string,
    _first_name: string,
    _last_name: string,
    _company_share: Nullable<CompanyShareBasic>,
    _equipment: Nullable<BasicEquipment>,
    private _email: Nullable<string>,
    private _phone: Nullable<string>,
  ) {
    super(_id, _first_name, _last_name, _company_share, _equipment);
  }

  public get email(): Nullable<string> {
    return this._email;
  }

  public get phone(): Nullable<string> {
    if (this._phone) {
      const phone = this._phone.replace(/^\+/g, '');
      return parsePhoneNumber(`+${phone}`)?.formatInternational() as string;
    }
  }

  public static parse(proto: Driver_Read_Nested) {
    return new DriverBasic(
      proto.id,
      proto.first_name,
      proto.last_name,
      proto.company_share ? CompanyShareBasic.parse(proto.company_share) : null,
      proto.equipment ? BasicEquipment.parse(proto.equipment) : null,
      proto.email,
      proto.phone,
    );
  }
}

export class Driver extends DriverBasic {
  constructor(
    _id: string,
    _first_name: string,
    _last_name: string,
    _company_share: Nullable<CompanyShareBasic>,
    _equipment: Nullable<BasicEquipment>,
    _email: Nullable<string>,
    _phone: Nullable<string>,
    private _company: CompanyBasic,
    private _additional_equipment: BasicEquipment[],
    private _managed: boolean,
  ) {
    super(_id, _first_name, _last_name, _company_share, _equipment, _email, _phone);
  }

  public get company(): CompanyBasic {
    return this._company;
  }

  public get additionalEquipment(): BasicEquipment[] {
    return this._additional_equipment;
  }

  public get managed(): boolean {
    return this._managed;
  }

  public static parse(proto: Driver_Read) {
    return new Driver(
      proto.id,
      proto.first_name,
      proto.last_name,
      proto.company_share ? CompanyShareBasic.parse(proto.company_share) : null,
      proto.equipment ? BasicEquipment.parse(proto.equipment) : null,
      proto.email,
      proto.phone,
      CompanyBasic.parse(proto.company),
      proto.additional_equipment.map((equipment) => BasicEquipment.parse(equipment)),
      proto.managed,
    );
  }
}
