import {
  deleteV1ResourceScopesId,
  getV1UsersUserIdResourceScopes,
  GetV1UsersUserIdResourceScopesData,
  postV1UsersUserIdResourceScopes,
  PostV1UsersUserIdResourceScopesData,
  ResourceType,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';

import { SitesDiff } from '~components/UserForm/UserForm';
import connection from '~services/connectionModule';
import { extractPagination } from '~services/pagination';

import { ResourceScope } from './models';

interface CreateResourceScopeParams {
  userId: string;
  resourceId: string;
  resourceType: ResourceType;
}

interface DeleteResourceScopeParams {
  resourceId: string;
}

interface GetAllResourceScopeParams {
  userId: string;
  resourceType: ResourceType;
}

export const useResourceScopes = () => {
  const createResourceScope = async ({
    userId,
    resourceId,
    resourceType,
  }: CreateResourceScopeParams) => {
    const body: PostV1UsersUserIdResourceScopesData['body'] = {
      resource_id: resourceId,
      resource_type: resourceType,
    };
    try {
      const response = await postV1UsersUserIdResourceScopes({
        path: { 'user-id': userId },
        body,
      });
      return response;
    } catch (e) {
      connection.handleRequestError(
        e,
        $t('error_messages.resource_scopes.failed_to_create') as string,
      );
    }
  };

  const deleteResourceScope = async ({ resourceId }: DeleteResourceScopeParams) => {
    try {
      await deleteV1ResourceScopesId({ path: { id: resourceId } });
    } catch (e) {
      connection.handleRequestError(
        e,
        $t('error_messages.resource_scopes.failed_to_delete') as string,
      );
    }
  };

  const getAllResourceScopes = async ({
    userId,
    resourceType,
  }: GetAllResourceScopeParams) => {
    const getPage = async (query: GetV1UsersUserIdResourceScopesData) => {
      try {
        const response = await getV1UsersUserIdResourceScopes(query);
        const resourceScopes = response.data.data.map((resourceScope) => {
          return ResourceScope.parse(resourceScope);
        });
        const pagination = extractPagination(response);

        return { data: resourceScopes, pagination };
      } catch (e) {
        connection.handleRequestError(
          e,
          $t('error_messages.resource_scopes.failed_to_fetch') as string,
        );
      }
    };

    let shouldFetchNextPage = true;
    let allResourceScopes: ResourceScope[] = [];

    const query: GetV1UsersUserIdResourceScopesData = {
      path: { 'user-id': userId },
      query: { 'filter[resource_type]': resourceType, 'page[limit]': 100 },
    };

    while (shouldFetchNextPage) {
      const response = await getPage(query);

      allResourceScopes = allResourceScopes.concat(response?.data ?? []);
      shouldFetchNextPage = Boolean(response?.pagination.after);

      if (shouldFetchNextPage) {
        query.query = {
          ...query.query,
          'page[after]': String(response?.pagination.after),
        };
      }
    }

    return allResourceScopes;
  };

  const saveUserSitesDiff = async (userId: string, sitesDiff: SitesDiff) => {
    const { siteIdsToAdd, resourceIdsToDelete } = sitesDiff;

    const createSiteRequests = siteIdsToAdd.map((siteId) => {
      return createResourceScope({
        userId,
        resourceId: siteId,
        resourceType: ResourceType.SITE,
      });
    });

    const deleteSiteRequests = resourceIdsToDelete.map((resourceId) => {
      return deleteResourceScope({ resourceId });
    });

    await Promise.all([...createSiteRequests, ...deleteSiteRequests]);
  };

  return {
    createResourceScope,
    deleteResourceScope,
    getAllResourceScopes,
    saveUserSitesDiff,
  };
};
