import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Control, Controller } from 'react-hook-form';

import { FormFieldLabel } from './FormFieldLabel';

interface SliderFormFieldProps {
  control: Control;
  isRequired?: boolean;
  label?: string;
  max: number;
  min: number;
  name: string;
  step: number;
}

export default function SliderFormField({
  control,
  isRequired,
  label,
  max,
  min,
  name,
  step,
}: SliderFormFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const handleChange = (_event: Event, newValue: number | number[]) => {
          field.onChange(newValue);
        };

        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {label && <FormFieldLabel label={`${label}${isRequired ? ' *' : ''}`} />}

            <Slider
              max={max}
              min={min}
              onChange={handleChange}
              step={step}
              value={field.value}
            />
          </Box>
        );
      }}
    />
  );
}
