import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { reduce, sortedIndex } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { NextBillionAssetLocation } from '~hooks/useNextBillionAssetLocationHistories/models';

import { CycleTimeline } from './components/CycleTimeline';
import { useIndicatorValues } from './components/hooks/useIndicatorValues';
import { SpeedTimeline } from './components/SpeedTimeline';

const TimelineTypes = {
  CYCLE: 'CYCLE',
  SPEED: 'SPEED',
};

const timelineTypeOptions = [
  {
    label: 'Cycle Times',
    value: TimelineTypes.CYCLE,
  },
  {
    label: 'Driver Speed',
    value: TimelineTypes.SPEED,
  },
];

interface TimelineProps {
  pings?: NextBillionAssetLocation[];
  onHover?: (type: number) => void;
}

interface TimelineComponentProps extends TimelineProps {
  pings: Required<TimelineProps>['pings'];
}

// This is the amount of time, in milliseconds, within which a marker is considered
// Hovered over based on the user's mouse position in the timeline graph
const HOVERED_THRESHOLD = 1000;
const getTimeKey = (time: number) => Math.round(time / HOVERED_THRESHOLD);

export const Timeline = ({ pings, onHover }: TimelineProps) => {
  if (!pings?.length) {
    return null;
  }

  return <TimelineComponent pings={pings} onHover={onHover} />;
};

const TimelineComponent = ({ pings, onHover }: TimelineComponentProps) => {
  const [timelineType, setTimelineType] = useState(TimelineTypes.CYCLE);

  const minTime = pings[0].createdAt.toISOString();
  const maxTime = pings[pings.length - 1].createdAt.toISOString();

  const { indicatorValues } = useIndicatorValues(minTime, maxTime, []);

  const timePingMap = useMemo(() => {
    return reduce(
      pings,
      (pingMap, p, index) => {
        const createdAtThreshold = getTimeKey(p.createdAt.valueOf());
        pingMap[createdAtThreshold] = index;
        return pingMap;
      },
      {} as Record<number, number>,
    );
  }, [pings.length]);

  const sortedKeys = useMemo(
    () =>
      Object.keys(timePingMap)
        .map(Number)
        .sort((a, b) => a - b),
    [timePingMap],
  );

  const findClosestTimeKey = (time: number): number | null => {
    const timeKey = getTimeKey(time);

    // Find the insertion point
    const index = sortedIndex(sortedKeys, timeKey);

    // If the exact value exists, return it
    if (sortedKeys[index] === timeKey) {
      return timeKey;
    }

    // Otherwise, get the largest value that's less than our target
    const closest = sortedKeys[index - 1];

    // If no valid value was found (i.e., all values are greater than timeKey, or the array is empty)
    if (closest === undefined) {
      return null;
    }

    return closest;
  };

  useEffect(() => {
    if (indicatorValues.indicatorTime && indicatorValues.indicatorVisible) {
      const closestTimeKey = findClosestTimeKey(indicatorValues.indicatorTime);
      const idxPing = closestTimeKey ? timePingMap[closestTimeKey] : null;

      if (idxPing) {
        onHover?.(idxPing);
      }
    }
  }, [indicatorValues.indicatorTime, indicatorValues.indicatorVisible]);

  return (
    <>
      <Box sx={{ mt: 2, mb: 2 }} mx={4}>
        {timelineType === TimelineTypes.CYCLE ? (
          <CycleTimeline pings={pings} />
        ) : (
          <SpeedTimeline pings={pings} />
        )}
      </Box>
      <Box
        p={1}
        display={'flex'}
        justifyContent={'flex-start'}
        zIndex={3}
        height={'fit-content'}
      >
        <Select
          value={timelineType}
          onChange={(event) => setTimelineType(event.target.value)}
          margin={'dense'}
          size={'small'}
        >
          {timelineTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </>
  );
};
