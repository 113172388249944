import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AuthMethod, ModelError_Item, ResourceType } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { capitalize } from 'lodash';
import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { ControllerProps, FieldErrors, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { CheckBoxFormField } from '~components/FormFields/CheckBoxFormField';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { PhoneCodeFlagInput } from '~components/FormFields/PhoneCodeFlagInput';
import { TextFormField } from '~components/FormFields/TextFormField';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { data as enums } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { FormStateChangeProps } from '~formsShared';
import { AdminRoleItem, AdminUser } from '~hooks/admin/useUsersAdmin/models';
import { CompanyBasic, useCompany } from '~hooks/useCompany';
import { CompanyShare } from '~hooks/useCompanyShares';
import { Driver } from '~hooks/useDrivers';
import { ResourceScope } from '~hooks/useResourceScopes/models';
import { useResourceScopes } from '~hooks/useResourceScopes/useResourceScopes';
import { Site, useSites } from '~hooks/useSites';
import { RoleItem, User } from '~hooks/useUsers';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';
import { allowedTimezones } from '~utils/dateTime';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

import { LoadingSpinner } from '../Order/ordersDispatchStyledComponents';
import {
  driverFormValidationSchema,
  userEmailSchema,
  userPhoneSchema,
  userWithEmailAndOmitedPhoneValidationSchema,
  userWithEmailOrPhoneValidationSchema,
  userWithRequiredEmailAndOmitedPhoneValidationSchema,
  userWithRequiredEmailValidationSchema,
} from './userFormValidationSchema';

export interface UserFormHandler {
  submit: (callback: (data: UserValidationDTOWithSitesDiff) => void) => void;
  resetForm: (callback?: () => void) => void;
}

type UserValidationSchema =
  | typeof userWithRequiredEmailValidationSchema
  | typeof userWithRequiredEmailAndOmitedPhoneValidationSchema
  | typeof userWithEmailOrPhoneValidationSchema
  | typeof userWithEmailAndOmitedPhoneValidationSchema;

type UserValidationDTO =
  | yup.InferType<typeof userWithRequiredEmailValidationSchema>
  | yup.InferType<typeof userWithRequiredEmailAndOmitedPhoneValidationSchema>
  | yup.InferType<typeof userWithEmailOrPhoneValidationSchema>
  | yup.InferType<typeof userWithEmailAndOmitedPhoneValidationSchema>;

export type SitesDiff = { resourceIdsToDelete: string[]; siteIdsToAdd: string[] };

export type UserValidationDTOWithSitesDiff = UserValidationDTO & { sitesDiff: SitesDiff };

export interface UserFormStateChangeProps extends FormStateChangeProps {
  hasValidEmail: boolean;
  hasValidPhone: boolean;
}

// Where this form is being rendered from
enum UserFormSource {
  ADMIN_USERS = 'admin_users',
}

interface UserProfileFormProviderProps {
  availableAuthMethods: AuthMethod[];
  defaultUser: Nullable<User | AdminUser>;
  defaultDriver?: Driver;
  editable: boolean;
  disableUserRolesField?: boolean;
  onFormDirty?: (isDirty: boolean) => void;
  onFormStateChange: ({
    isValid,
    isDirty,
    hasValidEmail,
    hasValidPhone,
  }: UserFormStateChangeProps) => void;
  userRolesOptions: Array<RoleItem | AdminRoleItem>;
  allowSharing?: boolean;
  companyShares?: Array<CompanyShare>;
  source?: `${UserFormSource}`;
  errors?: ModelError_Item[];
  isManagedUser?: boolean;
}

interface UserProfileFormProps
  extends Omit<UserProfileFormProviderProps, 'availableAuthMethods'> {
  validationSchema: UserValidationSchema;
  sites: ResourceScope[];
}

// Once entered, phone number cannot be edited, but we have registered users with invalid numbers.
// This is blocking admins from editing user's fields different from phone number, so for those
// Cases we skip the validation.
const shouldSkipPhoneNumberValidation = (phoneNumber: Nullable<string>) => {
  return Boolean(phoneNumber);
};

const validateEmail = (email: Nullable<string>) => {
  let isValid = true;

  try {
    const input = email || '';

    if (input.length) {
      userEmailSchema.validateSync(input);
    } else {
      isValid = false;
    }
  } catch {
    isValid = false;
  }

  return isValid;
};

const validatePhone = (phone: Nullable<string>) => {
  let isValid = true;

  try {
    const input = phone || '';

    if (input.length) {
      userPhoneSchema.validateSync(input);
    } else {
      isValid = false;
    }
  } catch {
    isValid = false;
  }

  return isValid;
};

type ResourceScopesState = {
  isLoading: boolean;
  sites: ResourceScope[];
};

const UserFormProvider = forwardRef(function UserFormProvider(
  {
    availableAuthMethods,
    defaultUser,
    editable,
    source,
    ...rest
  }: UserProfileFormProviderProps,
  ref: Ref<UserFormHandler>,
) {
  const isEditing = Boolean(defaultUser?.id);

  const { getAllResourceScopes } = useResourceScopes();
  const [resourceScopes, setResourceScopes] = useState<ResourceScopesState>({
    isLoading: isEditing,
    sites: [],
  });

  useEffect(() => {
    if (isEditing) {
      setResourceScopes((state) => ({ ...state, isLoading: true }));

      getAllResourceScopes({
        userId: String(defaultUser?.id),
        resourceType: ResourceType.SITE,
      }).then((response) => {
        setResourceScopes({
          isLoading: false,
          sites: response.reduce((acc, resourceScope) => {
            if (resourceScope.resource?.type === ResourceType.SITE) {
              acc.push(resourceScope);
            }

            return acc;
          }, [] as ResourceScope[]),
        });
      });
    }
  }, [isEditing]);

  const validationSchema = useMemo(() => {
    const skipPhoneNumberValidation = shouldSkipPhoneNumberValidation(defaultUser?.phone);

    if (availableAuthMethods.includes(AuthMethod.OTP_SMS)) {
      return skipPhoneNumberValidation
        ? userWithEmailAndOmitedPhoneValidationSchema
        : userWithEmailOrPhoneValidationSchema;
    }

    return skipPhoneNumberValidation
      ? userWithRequiredEmailAndOmitedPhoneValidationSchema
      : userWithRequiredEmailValidationSchema;
  }, [availableAuthMethods, defaultUser?.phone]);

  if (resourceScopes.isLoading) {
    return <LoadingSpinner isVisible />;
  }

  return (
    <UserForm
      ref={ref}
      editable={editable}
      validationSchema={validationSchema}
      defaultUser={defaultUser}
      source={source}
      sites={resourceScopes.sites}
      {...rest}
    />
  );
});

const UserForm = forwardRef(function UserForm(
  {
    allowSharing = false,
    companyShares,
    defaultDriver,
    defaultUser,
    disableUserRolesField,
    editable,
    errors: errorsProp,
    onFormStateChange,
    sites,
    source,
    userRolesOptions = [],
    validationSchema,
    isManagedUser = false,
  }: UserProfileFormProps,
  ref: Ref<UserFormHandler>,
) {
  const managedCompaniesEnabledFeatureFlagEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.managedCompaniesEnabled,
  });

  const { userStore } = useStores();
  const { getAllConnectedCompaniesByCompanyIdTypeahead } = useCompany();
  const { getCompanySitesTypeahead } = useSites();

  const isSharedDriver = Boolean(defaultDriver?.companyShare?.id);

  const isManagedDriver =
    managedCompaniesEnabledFeatureFlagEnabled && defaultDriver?.managed;
  const defaultSites = sites.map((site) => ({
    id: String(site.resource?.id),
    name: String(site.resource?.name),
    resourceId: String(site.id),
  }));

  const defaultUserValues = {
    firstName: defaultUser?.firstName || '',
    lastName: defaultUser?.lastName || '',
    userRoles: [...(defaultUser?.userRoles || [])],
    phone: defaultUser?.phone,
    email: defaultUser?.email,
    company: defaultUser?.company || userStore.currentCompanies[0] || null,
    timeZone: defaultUser?.timeZone || enums.time_zone.default,
    textToAcceptFieldEnabled: source === UserFormSource.ADMIN_USERS,
    textToAcceptFieldValue: defaultUser?.textToAcceptEnabled,
    externalId: defaultUser?.externalId,
    equipment: defaultUser?.equipment,
  };

  const defaultDriverValues = {
    firstName: defaultDriver?.firstName || '',
    lastName: defaultDriver?.lastName || '',
    phone: defaultDriver?.phone,
    email: defaultDriver?.email,
    company: defaultDriver?.company || null,
  };

  const defaultValues = defaultDriver ? defaultDriverValues : defaultUserValues;
  const {
    control,
    formState: { errors, isValid, isDirty },
    getValues,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(defaultDriver ? driverFormValidationSchema : validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...defaultValues,
      companyShares: companyShares?.length
        ? companyShares.map((companyShare) => {
            return {
              id: companyShare.receiverCompany.id,
              legalName: companyShare.receiverCompany.legalName,
            };
          })
        : [],
      sites: defaultSites,
    },
  });

  const watchedCompany = watch('company');
  const defaultCompanyShares = watch('companyShares');
  const watchedUserRoles = watch('userRoles');
  const watchedEmail = watch('email');
  const watchedPhone = watch('phone');
  const watchedSites = watch('sites') as unknown as Site[] | null;

  const [selectedShareToCompanies, setSelectedShareToCompanies] = useState<
    CompanyBasic[]
  >(defaultCompanyShares as CompanyBasic[]);

  const sitesDiff: SitesDiff = useMemo(() => {
    const resourceIdsToDelete = defaultSites.reduce((acc, defaultSite) => {
      const site = watchedSites?.find((site) => site.id === defaultSite.id);

      if (!site) {
        acc.push(defaultSite.resourceId);
      }

      return acc;
    }, [] as string[]);

    const siteIdsToAdd = (watchedSites ?? []).reduce((acc, watchedSite) => {
      const site = defaultSites.find((site) => site.id === watchedSite.id);

      if (!site) {
        acc.push(watchedSite.id);
      }

      return acc;
    }, [] as string[]);

    return { resourceIdsToDelete, siteIdsToAdd };
  }, [defaultSites, watchedSites]);

  // Update default values once async companyShares are loaded
  useEffect(() => {
    if (companyShares?.length) {
      const updatedDefaultValues = {
        ...defaultValues,
        companyShares: companyShares.map((companyShare) => ({
          id: companyShare.receiverCompany.id,
          legalName: companyShare.receiverCompany.legalName,
        })),
      };
      reset(updatedDefaultValues);
    }
  }, [companyShares, reset, JSON.stringify(defaultValues)]);

  useEffect(() => {
    const newDefaultCompanyShares = getValues('companyShares');
    setSelectedShareToCompanies(newDefaultCompanyShares as CompanyBasic[]);
  }, [defaultCompanyShares]);

  const hasValidEmail = useMemo(() => {
    return validateEmail(watchedEmail);
  }, [watchedEmail]);

  const hasValidPhone = useMemo(() => {
    return validatePhone(watchedPhone);
  }, [watchedPhone]);

  const hasDriverRole = useMemo(() => {
    return (
      watchedUserRoles &&
      watchedUserRoles.some((role) => role.name.toLowerCase() === 'driver')
    );
  }, [watchedUserRoles]);

  const hasViewerRole = useMemo(() => {
    return (
      watchedUserRoles &&
      watchedUserRoles.some((role) => role.name.toLowerCase() === 'viewer')
    );
  }, [watchedUserRoles]);

  const { isEmailFieldOptional, isPhoneFieldOptional } = useMemo(() => {
    const emailDesc = validationSchema.describe().fields.email as yup.SchemaDescription;
    const phoneDesc = validationSchema.describe().fields.phone as yup.SchemaDescription;

    return {
      isEmailFieldOptional: emailDesc ? emailDesc.nullable || emailDesc.optional : true,
      isPhoneFieldOptional: phoneDesc ? phoneDesc.nullable || phoneDesc.optional : true,
    };
  }, [validationSchema]);

  useEffect(() => {
    if (!hasViewerRole) {
      setValue('sites', []);
    }
  }, [hasViewerRole]);

  useImperativeHandle(ref, () => ({
    submit(callback) {
      handleSubmit((data) =>
        callback({ ...data, sitesDiff } as UserValidationDTOWithSitesDiff),
      )();
    },
    resetForm(callback) {
      reset();
      callback?.();
    },
  }));

  useEffect(() => {
    onFormStateChange({ isValid, isDirty, hasValidEmail, hasValidPhone });
  }, [isValid, isDirty, hasValidEmail, hasValidPhone]);

  useEffect(() => {
    if (errorsProp) {
      for (const error of errorsProp) {
        const newErrorField = getErrorMapping(error.field, error.message);
        if (newErrorField.field && newErrorField.message) {
          setError(newErrorField.field as keyof ReturnType<typeof setError>, {
            type: 'manual',
            message: capitalize(newErrorField.message as string),
          });
        }
      }
    }
  }, [errorsProp]);

  return (
    <form>
      <Grid container spacing={2}>
        {!isManagedUser && (
          <Grid item xs={12}>
            <CompanySelectorFormField
              sx={{ width: '100%', minWidth: '100%' }}
              name={'company'}
              isRequired={true}
              disabled={!!defaultUser?.company?.id || !!defaultDriver?.company?.id}
              errors={errors}
              control={control}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <TextFormField
            control={control}
            errors={errors}
            name="firstName"
            label={`${$t('form_fields.first_name')}`}
            isRequired={true}
            disabled={!editable || (isSharedDriver && !isManagedDriver)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            control={control}
            errors={errors}
            name="lastName"
            label={`${$t('form_fields.last_name')}`}
            isRequired={true}
            disabled={!editable || (isSharedDriver && !isManagedDriver)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            control={control}
            errors={errors}
            name="email"
            type="email"
            label={`${$t('form_fields.email')}`}
            isRequired={!isEmailFieldOptional}
            disabled={!editable || (isSharedDriver && !isManagedDriver)}
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneCodeFlagInput
            control={control as unknown as ControllerProps['control']}
            errors={errors as unknown as FieldErrors}
            name="phone"
            label={`${$t('form_fields.phone')}`}
            disabled={!editable || (isSharedDriver && !isManagedDriver)}
            isRequired={!isPhoneFieldOptional}
          />
        </Grid>
        {defaultDriver && (
          <Grid item xs={6}>
            <TextFormField
              control={control}
              errors={errors}
              name="externalId"
              type="text"
              label={`${$t('form_fields.external_id')}`}
              disabled={!editable || isSharedDriver}
            />
          </Grid>
        )}

        {!defaultDriver && (
          <>
            <Grid item xs={6}>
              <AutocompleteFormField
                control={control}
                name="userRoles"
                errors={errors}
                list={userRolesOptions}
                label={`${$t('form_fields.role')}`}
                isRequired={true}
                clearable={false}
                multiple={true}
                disabled={disableUserRolesField || !editable || isSharedDriver}
                getValue={(item) => item?.id}
                getLabel={(item) => item.name}
                limitTags={2}
              />
            </Grid>
            <Grid item xs={6}>
              <AutocompleteFormField
                control={control}
                name="timeZone"
                errors={errors}
                list={allowedTimezones}
                label={`${$t('form_fields.time_zone')}`}
                isRequired={true}
                clearable={false}
                getValue={(item) => item}
                getLabel={(item) => item}
                disabled={!editable || isSharedDriver}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFormField
                control={control}
                errors={errors}
                name="externalId"
                type="text"
                label={`${$t('form_fields.external_id')}`}
                disabled={!editable || isSharedDriver}
              />
            </Grid>

            {getValues('textToAcceptFieldEnabled') && (
              <Grid item xs={12}>
                <CheckBoxFormField
                  control={control}
                  disabled={!editable || isSharedDriver}
                  label={`${$t('form_fields.text_to_accept_enabled')}`}
                  name="textToAcceptFieldValue"
                  value={Boolean(getValues('textToAcceptFieldValue'))}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>

      {/* Viewer site selection */}
      {hasViewerRole && (
        <Grid container sx={{ mt: 2 }}>
          <FormSectionTitle
            sx={{ mb: 1, fontSize: '14px' }}
            title={$t('user.form.site_permissions')}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AutocompleteAsyncFormField
                asyncCallback={getCompanySitesTypeahead}
                control={control}
                disabled={!editable || isSharedDriver}
                errors={errors}
                extraRequestOptions={{ companyId: watchedCompany?.id || '' }}
                filterSelectedOptions={false}
                getLabel={(item) => item.name}
                getValue={(item) => item.id}
                hideChipOverflow
                hideLabel
                limitTags={1}
                multiple
                name="sites"
              />
            </Grid>
            <Grid item xs={6} sx={{ mt: -1 }}>
              <Typography variant={'caption'} fontWeight={700} color={'secondary'}>
                {$t('user.form.site_access_list')}
              </Typography>
              <Typography variant={'body2'}>
                {watchedSites && watchedSites.length > 0
                  ? watchedSites.map((site) => site.name).join(', ')
                  : $t('common.none_selected')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* Shared driver configuration */}
      {(hasDriverRole || !!defaultDriver) && allowSharing && (
        <Grid container sx={{ mt: 2 }}>
          <FormSectionTitle
            sx={{ mb: 1 }}
            title={$t('user.form.shared_driver_configuration')}
          />
          <Typography variant={'body2'} sx={{ mb: 2 }}>
            {$t('user.form.shared_driver_configuration_hint')}
          </Typography>

          {isSharedDriver && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              sx={{ width: '100%', border: '1px solid #ccc', padding: '5px' }}
              mb={2}
            >
              <Typography variant={'body2'}>
                {$t('user.form.shared_from_company')}{' '}
                {defaultDriver?.companyShare?.senderCompany?.legalName}
              </Typography>
            </Box>
          )}

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AutocompleteAsyncFormField
                control={control}
                errors={errors}
                name="companyShares"
                getValue={(item) => item.id}
                getLabel={(item) => item.legalName}
                asyncCallback={getAllConnectedCompaniesByCompanyIdTypeahead}
                extraRequestOptions={{
                  companyId: isSharedDriver
                    ? userStore.currentCompanies[0].id
                    : watchedCompany?.id || '',
                }}
                filterSelectedOptions={false}
                label={`${$t('user.form.select_share_to_companies')}`}
                multiple
                limitTags={1}
                onMultipleOptionsChange={(values) => {
                  setSelectedShareToCompanies(values as CompanyBasic[]);
                }}
                hideChipOverflow
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'caption'} fontWeight={700} color={'secondary'}>
                {$t('user.form.shared_list')}
              </Typography>
              <Typography variant={'body2'}>
                {selectedShareToCompanies.length
                  ? selectedShareToCompanies
                      .map((company) => company.legalName)
                      .join(', ')
                  : $t('common.none_selected')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </form>
  );
});

const getErrorMapping = (fieldName: string, defaultMessage: string) => {
  switch (fieldName) {
    case 'base':
      return {
        field: 'firstName',
        message: defaultMessage,
      };
    default:
      return {
        field: fieldName,
        message: defaultMessage,
      };
  }
};

export { UserFormProvider as UserForm };
