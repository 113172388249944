import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import {
  AuthMethod,
  ModelError_Item,
  // this is a @typescript-eslint bug - we're using this type in a specialization and the
  // linter doesn't catch it :/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ModelError_Response,
} from '@treadinc/horizon-api-spec';
import type { AxiosError } from 'axios';
import { t as $t, t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import { DriverForm, DriverFormValues } from '~components/DriverForm/DriverForm';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { StatusTabFilters } from '~components/StatusTabFilters/StatusTabFilters';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { PageTitle } from '~components/typographyTitles/PageTitle';
import { UserForm, UserValidationDTOWithSitesDiff } from '~components/UserForm/UserForm';
import { AccountTypesFilter, data as enums } from '~constants/enums';
import { companyConnectAccountByTreadId, FeatureFlags } from '~constants/featureFlags';
import { FormStateChangeProps } from '~formsShared';
import { Account, useAccount } from '~hooks/useAccount';
import { AddressItem } from '~hooks/useAddress';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { useDriverForm } from '~hooks/useDriverForm/useDriverForm';
import { useDrivers } from '~hooks/useDrivers';
import { useEquipment } from '~hooks/useEquipment';
import { useEquipmentForm } from '~hooks/useEquipmentForm/useEquipmentForm';
import { useUserForm } from '~hooks/useUserForm/useUserForm';
import { User, useUserRoles, useUsers } from '~hooks/useUsers';
import { ConnectAccountDialog } from '~pages/Settings/Accounts/ConnectAccountDialog';
import { DeleteAccountErrorComponent } from '~pages/Settings/Accounts/DeleteAccountErrorComponent';
import { useAccountFilters } from '~pages/Settings/Accounts/useAccountFilters';
import {
  EquipmentForm,
  EquipmentFormValues,
} from '~pages/Settings/Administration/EquipmentManagement/EquipmentForm';
import { InvitesToSendState } from '~pages/Settings/UserManagement/UserManagementDataGrid';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';
import { dateFormat } from '~utils/dateTime';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';
import { getEffectiveUserCompanyId } from '~utils/user/user-utils';

import { AccountsDialog } from './AccountsDialog';

export interface AccountFormStateChangeProps extends FormStateChangeProps {
  hasValidEmail: boolean;
  hasValidPhone: boolean;
  inConnectedView: boolean;
}

const AccountTypes = enums.account_type.values.reduce(
  (obj: Record<string, unknown>, value: string) => {
    obj[value] = value;
    return obj;
  },
  {},
);

const ActionsWrapper = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1.5),
}));

const ConnectAccountDialogCTA = () => {
  const { userStore } = useStores();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);
  const { treatments, isReady } = useSplitTreatments({
    names: [companyConnectAccountByTreadId],
  });
  const featureFlag = treatments[companyConnectAccountByTreadId];
  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);

  return (
    <>
      {isReady && featureFlag.treatment === 'on' && userPermissions.canConnectCompany && (
        <>
          <Button
            data-test-id={'create-company-btn'}
            color={'primary'}
            onClick={openDialog}
            startIcon={<Add />}
          >
            {t('actions.connect')}
          </Button>

          <ConnectAccountDialog isDialogOpen={isDialogOpen} onClose={closeDialog} />
        </>
      )}
    </>
  );
};

interface GetCompanyAccountsParams {
  accountTypes: (AccountTypesFilter.CUSTOMER | AccountTypesFilter.VENDOR)[] | undefined;
  searchQuery: string;
  companyId?: string;
}

const AccountsDataGrid = observer(() => {
  const theme = useTheme();
  const { treatments, isReady } = useSplitTreatments({
    names: [FeatureFlags.simplifiedSettingsTabs],
  });
  const simplifiedSettingsTabsFeatureFlag =
    treatments[FeatureFlags.simplifiedSettingsTabs];
  const managedCompaniesEnabledFeatureFlagEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.managedCompaniesEnabled,
  });
  const driverEquipmentEnhancementsFeatureFlagEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.driverEquipmentEnhancements,
  });
  const location = useLocation();
  const isCustomerPage = location.pathname.includes('customers');

  const queriedAccountType = isCustomerPage
    ? AccountTypesFilter.CUSTOMER
    : AccountTypesFilter.VENDOR;

  const { accountTypeParam, accountTypeFilter, setAccountTypeFilter } =
    useAccountFilters();

  const getAccountType = useCallback(() => {
    return simplifiedSettingsTabsFeatureFlag.treatment === 'on'
      ? [queriedAccountType as AccountTypesFilter.CUSTOMER | AccountTypesFilter.VENDOR]
      : accountTypeParam;
  }, [simplifiedSettingsTabsFeatureFlag.treatment, queriedAccountType, accountTypeParam]);

  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState<boolean>(false);
  const [selectedAccountId, setSelectedAccountId] = useState<Nullable<string>>(null);
  const [selectedAccountCompanyId, setSelectedAccountCompanyId] =
    useState<Nullable<string>>(null);
  const [selectedAccountAddress, setSelectedAccountAddress] =
    useState<Nullable<AddressItem>>(null);
  const [asyncErrors, setAsyncErrors] = useState<ModelError_Item[]>([]);

  const modalDialogRef = useRef<ModalDialogHandler>(null);
  const {
    getAllCompanyAccounts,
    deleteAccount,
    isLoading: isAccountLoading,
  } = useAccount();
  const { setSearchQueryValue, searchValue } = useDataGridSearch();
  const { toasterStore, userStore, companyAssetsStore } = useStores();
  const isLoading = isAccountLoading;
  const companyId = getEffectiveUserCompanyId(userStore);
  const userRolesOptions = userStore.userRoles;
  const {
    driverDialogRef,
    driverFormIsDirty,
    isDriverDialogOpen,
    setIsDriverDialogOpen,
    driverInvitesToSend,
    onDriverFormStateChange,
    onChangeDriverInvite,
    createNewDriver,
  } = useDriverForm();
  const { createDriverByCompanyId, isLoading: isLoadingDrivers } = useDrivers();
  const {
    userDialogRef,
    onUserFormStateChange,
    isUserDialogOpen,
    setIsUserDialogOpen,
    userInvitesToSend,
    onChangeUserInvite,
    userFormIsDirty,
    createNewUser,
  } = useUserForm();
  const { isUpdating: isUpdatingUsers, createUser } = useUsers();
  const { getUserRoles } = useUserRoles();
  const {
    equipmentFormRef,
    isEquipmentFormOpen,
    setIsEquipmentFormOpen,
    isEquipmentFormDirty,
    createNewEquipment,
    onEquipmentFormStateChange,
  } = useEquipmentForm();
  const { isLoading: isUpdatingEquipment, createEquipmentByCompanyId } = useEquipment();

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    getUserRoles();
  }, []);

  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);

  const rows = useMemo(() => {
    return companyAssetsStore.userCompanyAccounts || [];
  }, [companyAssetsStore.userCompanyAccounts]);
  const selectedAccount = useMemo(() => {
    return (
      companyAssetsStore.userCompanyAccounts.find(
        (account) => account.id === selectedAccountId,
      ) || undefined
    );
  }, [selectedAccountId, rows]);
  const createNew = () => {
    setSelectedAccountId(null);
    setIsAccountDialogOpen(true);
  };
  const addUser = (account: Account) => {
    setAsyncErrors([]);
    setSelectedAccountId(account.id);
    setSelectedAccountCompanyId(account.connectedCompany?.id);
    createNewUser();
  };
  const addEquipment = (account: Account) => {
    setAsyncErrors([]);
    setSelectedAccountId(account.id);
    setSelectedAccountCompanyId(account.connectedCompany?.id);
    createNewEquipment();
  };
  const addDriver = (account: Account) => {
    setAsyncErrors([]);
    setSelectedAccountId(account.id);
    setSelectedAccountCompanyId(account.connectedCompany?.id);
    setSelectedAccountAddress(account.address);
    createNewDriver();
  };
  const deleteRow = (id: string) => {
    setSelectedAccountId(id);
    modalDialogRef.current?.open();
  };
  const editRow = (id: string) => {
    setSelectedAccountId(id);
    setIsAccountDialogOpen(true);
  };
  const handleCloseAccountDialog = () => {
    setIsAccountDialogOpen(false);
    setSelectedAccountId(null);
  };
  const handleCloseUserDialog = () => {
    setIsUserDialogOpen(false);
    setSelectedAccountId(null);
    setSelectedAccountCompanyId(null);
  };
  const handleCloseDriverDialog = () => {
    setIsDriverDialogOpen(false);
    setSelectedAccountId(null);
    setSelectedAccountCompanyId(null);
    setSelectedAccountAddress(null);
  };
  const handleCloseEquipmentDialog = () => {
    setIsEquipmentFormOpen(false);
    setIsEquipmentFormOpen(false);
    setSelectedAccountId(null);
  };
  const onChangeQuickFilter = (searchQuery: string) => {
    setSearchQueryValue(searchQuery);
  };

  const onSubmitUserForm = async () => {
    const userData = await new Promise<UserValidationDTOWithSitesDiff | undefined>(
      (resolve) => {
        userDialogRef.current?.submit((data) => resolve(data));
      },
    );

    if (userData) {
      await onUserSubmitCallback(userData, userInvitesToSend);
    }
  };

  const onUserSubmitCallback = async (
    data: UserValidationDTOWithSitesDiff,
    userNotificationsPreferenceData?: InvitesToSendState,
  ) => {
    const shouldSendEmailInvite = Boolean(
      !userNotificationsPreferenceData?.email.isDisabled &&
        userNotificationsPreferenceData?.email.shouldSend,
    );
    const shouldSendSmsInvite = Boolean(
      !userNotificationsPreferenceData?.sms.isDisabled &&
        userNotificationsPreferenceData?.sms.shouldSend,
    );
    const invitesToSend = { email: shouldSendEmailInvite, sms: shouldSendSmsInvite };

    try {
      await createUser(data as unknown as User, invitesToSend, selectedAccountCompanyId);
      setIsUserDialogOpen(false);
      const name = `${data?.firstName} ${data?.lastName}`;
      toasterStore.push(alert($t('user.user_created', { name }), AlertTypes.success));
    } catch (error) {
      const e = error as AxiosError<ModelError_Response>;

      setAsyncErrors(e.response?.data.error.errors || []);
      throw new Error('Failed to create user');
    }
  };

  const onSubmitEquipmentForm = async () => {
    const equipmentData = await new Promise<EquipmentFormValues | undefined>(
      (resolve) => {
        equipmentFormRef.current?.submit((data) => resolve(data));
      },
    );

    if (equipmentData) {
      await onEquipmentSubmitCallback(equipmentData);
    }
  };

  const onEquipmentSubmitCallback = async (data: EquipmentFormValues) => {
    try {
      const equipment = await createEquipmentByCompanyId({
        equipment: data as EquipmentFormValues,
        managedCompanyId: selectedAccountCompanyId as string,
      });
      setIsEquipmentFormOpen(false);
      toasterStore.push(
        alert(
          t('administration.equipment.equipment_created', {
            name: equipment?.name,
          }),
          AlertTypes.success,
        ),
      );
    } catch (error) {
      const e = error as AxiosError<ModelError_Response>;

      setAsyncErrors(e.response?.data.error.errors || []);
      throw new Error('Failed to create equipment');
    }
  };

  const onSubmitDriverForm = async () => {
    const driverData = await new Promise<DriverFormValues | undefined>((resolve) => {
      driverDialogRef.current?.submit((data) => resolve(data));
    });

    if (driverData) {
      await onDriverSubmitCallback(driverData, driverInvitesToSend);
    }
  };

  const onDriverSubmitCallback = async (
    data: DriverFormValues,
    userNotificationsPreferenceData?: InvitesToSendState,
  ) => {
    const shouldSendEmailInvite = Boolean(
      !userNotificationsPreferenceData?.email.isDisabled &&
        userNotificationsPreferenceData?.email.shouldSend,
    );
    const shouldSendSmsInvite = Boolean(
      !userNotificationsPreferenceData?.sms.isDisabled &&
        userNotificationsPreferenceData?.sms.shouldSend,
    );
    const invitesToSend = { email: shouldSendEmailInvite, sms: shouldSendSmsInvite };

    try {
      await createDriverByCompanyId(
        data,
        invitesToSend,
        selectedAccountCompanyId,
        selectedAccountAddress,
      );
      setIsDriverDialogOpen(false);
      const name = `${data?.firstName} ${data?.lastName}`;
      toasterStore.push(alert($t('user.user_created', { name }), AlertTypes.success));
    } catch (error) {
      const e = error as AxiosError<ModelError_Response>;

      setAsyncErrors(e.response?.data.error.errors || []);
      throw new Error(`${$t('error_messages.drivers_failed_to_create_driver')}`);
    }
  };

  const deleteAccountCallBack = async () => {
    if (selectedAccountId) {
      await deleteAccount({
        id: selectedAccountId,
        callBack: () => {
          modalDialogRef.current?.close();
          toasterStore.push(alert($t('account.account_removed'), AlertTypes.success));
        },
        errorCallback: (error: AxiosError) => {
          modalDialogRef.current?.setError({
            code: error.response?.status || null,
            message: error.message || 'An unexpected error occurred',
          });
        },
      });
    }
  };

  useEffect(() => {
    const params: GetCompanyAccountsParams = {
      accountTypes: getAccountType(),
      searchQuery: searchValue,
    };

    if (!userStore.isCurrentCompanyActive) {
      params.companyId = companyId;
    }

    getAllCompanyAccounts(params);
  }, [getAccountType, searchValue, companyId, userStore.isCurrentCompanyActive]);

  const accountsTypesFilters = [
    {
      value: AccountTypesFilter.CUSTOMER,
      label: 'common.customers',
    },
    { value: AccountTypesFilter.VENDOR, label: 'common.vendors' },
    { value: AccountTypesFilter.ALL, label: 'common.all' },
  ];

  const accountsColumns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: $t('form_fields.account_name'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return params.value;
          },
        },
        {
          field: 'company',
          headerName: $t('form_fields.company'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row.company?.legalName))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value?.legalName;
          },
          renderCell: (params: GridValueGetterParams) => {
            return params.value;
          },
        },
        {
          field: 'company.connected',
          headerName: $t('form_fields.connected'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            const { connectedCompany, id } = params.row;
            const isConnectedLabel = connectedCompany ? 'Yes' : 'No';

            return (
              <BasicTooltip
                title={isConnectedLabel}
                key={`tooltip-account-type-key-${id}`}
              >
                <Chip
                  key={id}
                  label={isConnectedLabel}
                  color={connectedCompany ? 'success' : 'info'}
                  variant={'outlined'}
                />
              </BasicTooltip>
            );
          },
        },
        {
          field: 'accountTypes',
          headerName: $t('form_fields.type'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                {params.value?.sort().map((type: string, index: number) => (
                  <BasicTooltip title={type} key={`tooltip-account-type-key-${type}`}>
                    <Chip
                      sx={{ ml: index !== 0 ? 1 : 0 }}
                      key={type}
                      label={type}
                      color={type === AccountTypes.vendor ? 'info' : 'success'}
                      variant={'outlined'}
                    />
                  </BasicTooltip>
                ))}
              </>
            );
          },
        },

        {
          field: 'address.fullAddress',
          headerName: $t('form_fields.address'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.address) return '';
            const {
              address: { streetAddress, premise, locality, country, administrativeArea },
            } = params.row;

            return `${streetAddress}${premise ? ` ${premise},` : ','} ${locality}, ${administrativeArea}, ${country.name}`;
          },
        },

        // {
        //   Field: 'address.administrativeArea',
        //   HeaderName: 'State',
        //   Flex: 1,
        //   ValueGetter: (params: GridValueGetterParams) => {
        //     Return get(params.row, 'address.administrativeArea');
        //   },
        // },
        //
        // {
        //   Field: 'address.country',
        //   HeaderName: 'Country',
        //   Flex: 1,
        //   ValueGetter: (params: GridValueGetterParams) => {
        //     Return get(params.row, 'address.country.name');
        //   },
        // },
        {
          field: 'createdAt',
          headerName: $t('common.created_on'),
          type: 'date',
          valueGetter: (params: GridValueGetterParams) => {
            return new Date(params.value);
          },
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                title={`${$t('common.last_update')}: ${dateFormat(params.row.updatedAt)}`}
              >
                <Typography component="span">
                  {dateFormat(params.row.createdAt)}
                </Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'notes',
          headerName: $t('form_fields.notes'),
          flex: 1,
        },

        // {
        //   Field: 'email',
        //   HeaderName: $t('form_fields.email'),
        //   Flex: 1,
        //   ValueGetter: (params: GridValueGetterParams) => {
        //     Const { primaryContact, billingContact } = params.row;
        //     Return [primaryContact, billingContact].map((item) => item.email).join(', ');
        //   },
        // },
        {
          field: 'actions',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={companyAssetsStore.userCompanyAccounts?.length || 0}
              loading={loadingRef.current}
              pagination={companyAssetsStore.accountsPagination}
              callback={(link, searchQuery) =>
                getAllCompanyAccounts({
                  link,
                  searchQuery,
                  accountTypes: getAccountType(),
                  ...{
                    companyId: !userStore.isCurrentCompanyActive ? companyId : undefined,
                  },
                })
              }
              altText={`${$t('actions.actions')}`}
              searchQuery={searchValue}
            />
          ),
          renderCell: (params) => {
            const options = [];
            const { managed } = params.row;
            const showManagedCompanyActions =
              managedCompaniesEnabledFeatureFlagEnabled && managed;

            if (showManagedCompanyActions) {
              options.push({
                title: t('actions.add_user'),
                callBack: () => addUser(params.row),
              });

              options.push({
                title: t('actions.add_equipment'),
                callBack: () => addEquipment(params.row),
              });

              options.push({
                title: t('actions.add_driver'),
                callBack: () => addDriver(params.row),
              });
            }

            if (userPermissions.canDeleteAccount) {
              options.push({
                title: t('actions.delete'),
                icon: <Delete />,
                callBack: () => deleteRow(params.row.id),
              });
            }

            return (
              <>
                {userPermissions.canEditAccount && (
                  <IconButton size="small" onClick={() => editRow(params.row.id)}>
                    <Edit />
                  </IconButton>
                )}
                <SimpleMenu options={options}>
                  <MoreHoriz />
                </SimpleMenu>
              </>
            );
          },
        },
      ] as GridColDef[],
    [rows, userPermissions, managedCompaniesEnabledFeatureFlagEnabled],
  );

  const initialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          // Hide columns status and traderName, the other columns will remain visible
          notes: false,
        },
      },
    }),
    [],
  );

  return (
    <>
      <Box sx={{ mr: -3 }}>
        {isReady && simplifiedSettingsTabsFeatureFlag.treatment !== 'on' ? (
          <Box sx={{ mb: 2 }}>
            <StatusTabFilters
              value={accountTypeFilter as AccountTypesFilter}
              onChange={(tab) => setAccountTypeFilter(tab as AccountTypesFilter)}
              filters={accountsTypesFilters}
            />
          </Box>
        ) : (
          <PageTitle
            text={
              isCustomerPage
                ? `${t('administration.customers.title')}`
                : `${t('administration.vendors.title')}`
            }
            sx={{ mb: 2 }}
          />
        )}
        <DataGrid
          id="admin-accounts-grid"
          sx={{ maxWidth: `calc(100% - ${theme.spacing(3)})` }}
          columns={accountsColumns}
          // HiddenColumns={['id', 'address.country', 'address.zip']}
          rows={rows as unknown as Record<string, any>[]}
          loading={isLoading}
          initialState={initialState}
          onChangeFilter={onChangeQuickFilter}
          headerActionsComponent={
            <>
              {userPermissions.canCreateAccount && (
                <ActionsWrapper display={'flex'}>
                  <ConnectAccountDialogCTA />

                  <Button
                    data-test-id={'create-company-btn'}
                    color={'primary'}
                    onClick={createNew}
                    startIcon={<Add />}
                  >
                    {t('account.create_account')}
                  </Button>
                </ActionsWrapper>
              )}
            </>
          }
        />
      </Box>
      <AccountsDialog
        open={isAccountDialogOpen}
        defaultAccount={selectedAccount}
        onClose={handleCloseAccountDialog}
      />

      <ModalDialog
        ref={modalDialogRef}
        loading={isLoading}
        title={$t('account.delete_account_title')}
        content={$t('account.delete_account_text')}
        confirmButtonText={`${$t('actions.delete')}`}
        callBack={deleteAccountCallBack}
        handleErrorsInline={true}
        errorComponent={DeleteAccountErrorComponent}
      />

      <Dialog open={isUserDialogOpen} onClose={handleCloseUserDialog}>
        <DialogHeader
          closeCallBack={handleCloseUserDialog}
          title={t('user.create_user')}
        />
        <DialogContent sx={{ my: 4 }}>
          <UserForm
            ref={userDialogRef}
            editable={true}
            defaultUser={null}
            onFormStateChange={onUserFormStateChange}
            userRolesOptions={userRolesOptions}
            availableAuthMethods={[AuthMethod.OTP_SMS, AuthMethod.PASSWORD]} // Hardcoding the default auth methods since we cannot get them from the API
            allowSharing={true}
            errors={asyncErrors}
            isManagedUser={true}
          />
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          {userInvitesToSend && (
            <Box display="flex" gap={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: 0.5, marginTop: '-1px' }}
                      size="small"
                      name="email"
                      onChange={onChangeUserInvite}
                      checked={userInvitesToSend.email.shouldSend}
                      color={'primary'}
                      disabled={isUpdatingUsers || userInvitesToSend.email.isDisabled}
                    />
                  }
                  label={
                    <Typography fontSize="12px">
                      {$t('user.form.send_email_invitation')}
                    </Typography>
                  }
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: 0.5, marginTop: '-1px' }}
                      size="small"
                      name="sms"
                      onChange={onChangeUserInvite}
                      checked={userInvitesToSend.sms.shouldSend}
                      color={'primary'}
                      disabled={isUpdatingUsers || userInvitesToSend.sms.isDisabled}
                    />
                  }
                  label={
                    <Typography fontSize="12px">
                      {$t('user.form.send_sms_invitation')}
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={!userFormIsDirty || isUpdatingUsers}
              loading={isUpdatingUsers}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitUserForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isUpdatingUsers ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t('actions.create')}
            </LoadingButton>
            <Button
              onClick={handleCloseUserDialog}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdatingUsers}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={isEquipmentFormOpen} onClose={handleCloseEquipmentDialog}>
        <DialogHeader
          closeCallBack={handleCloseEquipmentDialog}
          title={t(
            driverEquipmentEnhancementsFeatureFlagEnabled
              ? 'administration.equipment.create_internal_truck'
              : 'administration.equipment.create_equipment',
          )}
        />
        <DialogContent sx={{ my: 4 }}>
          <EquipmentForm
            ref={equipmentFormRef}
            defaultEquipment={null}
            companyShares={[]}
            onFormStateChange={onEquipmentFormStateChange}
            selectedCompanyId={null}
            errors={asyncErrors}
            isManagedEquipment={true}
          />
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={isUpdatingEquipment || !isEquipmentFormDirty}
              loading={isUpdatingEquipment}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitEquipmentForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isUpdatingEquipment ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t('actions.create')}
            </LoadingButton>
            <Button
              onClick={handleCloseEquipmentDialog}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdatingEquipment}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={isDriverDialogOpen} onClose={handleCloseDriverDialog}>
        <DialogHeader
          closeCallBack={handleCloseDriverDialog}
          title={t(`user.create_driver`)}
        />
        <DialogContent sx={{ my: 4 }}>
          <DriverForm
            ref={driverDialogRef}
            onFormStateChange={onDriverFormStateChange}
            errors={asyncErrors}
            isManagedDriver={true}
          />
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          {driverInvitesToSend && (
            <Box display="flex" gap={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: 0.5, marginTop: '-1px' }}
                      size="small"
                      name="email"
                      onChange={onChangeDriverInvite}
                      checked={driverInvitesToSend.email.shouldSend}
                      color={'primary'}
                      disabled={isLoadingDrivers || driverInvitesToSend.email.isDisabled}
                    />
                  }
                  label={
                    <Typography fontSize="12px">
                      {$t('user.form.send_email_invitation')}
                    </Typography>
                  }
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: 0.5, marginTop: '-1px' }}
                      size="small"
                      name="sms"
                      onChange={onChangeDriverInvite}
                      checked={driverInvitesToSend.sms.shouldSend}
                      color={'primary'}
                      disabled={isLoadingDrivers || driverInvitesToSend.sms.isDisabled}
                    />
                  }
                  label={
                    <Typography fontSize="12px">
                      {$t('user.form.send_sms_invitation')}
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={!driverFormIsDirty || isLoadingDrivers}
              loading={isLoadingDrivers}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitDriverForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isLoadingDrivers ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t(`actions.create`)}
            </LoadingButton>
            <Button
              onClick={handleCloseDriverDialog}
              sx={{ mr: 2, px: 2 }}
              disabled={isLoadingDrivers}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
});
export { AccountsDataGrid };
