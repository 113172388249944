import { UnitOfDistance } from '@treadinc/horizon-api-spec';
import _ from 'lodash';
import { get } from 'lodash';
import { matchIsValidTel } from 'mui-tel-input';

// Function to compare objects by values (including nested objects)
const compareValues = (obj1: unknown, obj2: unknown, excludedProps: string[] = []) => {
  // https://lodash.com/docs/4.17.15#isEqual doing the same thing

  if (obj1 === obj2) {
    return true; // If both objects are the same reference, they are equal
  }
  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return false; // If one of them is not an object (including null), they are not equal
  }

  // Check if both objects belong to the same class
  if (obj1.constructor !== obj2.constructor) {
    return false;
  }

  // Get the property names of both objects
  const props1 = Object.getOwnPropertyNames(obj1);
  const props2 = Object.getOwnPropertyNames(obj2);
  if (props1.length !== props2.length) {
    return false; // If they have a different number of properties, they are not equal
  }

  // Compare each property recursively
  for (const prop of props1) {
    if (excludedProps.includes(prop as string)) {
      continue; // Skip the comparison for excluded properties
    }

    // @ts-ignore
    const value1 = obj1[prop];
    // @ts-ignore
    const value2 = obj2[prop];

    if (!compareValues(value1, value2, excludedProps)) {
      return false;
    }
  }

  return true; // If all checks pass, the objects are equal
};

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

const stringAvatar = (name: string) => {
  const split = name.split(' ');
  const first = get(split, '[0][0]', '');
  const second = get(split, '[1][0]', '');
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${first}${second}`,
  };
};

const splitStringBySentenceChange = (str: string): string => {
  return str ? str.replace(/([a-z])([A-Z])/g, '$1 $2') : '';
};

const snakeCaseToHuman = (str: string): string => {
  if (!str) return '';

  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const humanToSnakeCase = (str: string): string => {
  if (!str) return '';

  return str
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('_');
};
const isActionClicked = (reason: 'backdropClick' | 'escapeKeyDown') => {
  return reason !== 'backdropClick';
};
interface LatLon {
  latitude: number | string;
  longitude: number | string;
}
const validateLatLon = ({ latitude, longitude }: LatLon) => {
  // Regular expression for valid latitude and longitude format
  const latLonRegex =
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)([eE][-+]?\d{1,2})?[,]?\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)?([eE][-+]?\d{1,2})?$/;

  if (!latLonRegex.test(`${latitude},${longitude}`)) {
    // Invalid format
    return false;
  }

  // Extract numeric values
  const lat = parseFloat(latitude as string);
  const lon = parseFloat(longitude as string);

  // Check valid range for latitude (-90 to 90) and longitude (-180 to 180)
  if (isNaN(lat) || lat < -90 || lat > 90 || isNaN(lon) || lon < -180 || lon > 180) {
    // Values are out of valid range
    return false;
  }

  // Valid latitude and longitude
  return true;
};

const matchIsValidTelOrTestTel = (value: string) => {
  const allowedAreaCodes = ['111', '000'];
  const areaCode = value.replace('+1', '').slice(0, 3);
  if (allowedAreaCodes.includes(areaCode)) {
    return value.length === 12;
  }

  return matchIsValidTel(value);
};

const hexToRgbaArray = (hex: string, alpha: number): [number, number, number, number] => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b, alpha];
};

const hexToRgba = (hex: string, alpha: number) => {
  return `rgba(${hexToRgbaArray(hex, alpha).join(',')})`;
};

const nullableStringOrNumberIsValidNumber = (input: string | number | null) => {
  if (_.isNil(input) || String(input).length === 0) {
    return false;
  }

  const inputAsNumber = parseFloat(String(input));

  return Number.isFinite(inputAsNumber);
};

const convertMeters = (meters: number, to: UnitOfDistance) => {
  const divisor = to === UnitOfDistance.KILOMETER ? 1000 : 1000 * 1.6;

  return meters / divisor;
};

export {
  compareValues,
  convertMeters,
  hexToRgba,
  hexToRgbaArray,
  humanToSnakeCase,
  isActionClicked,
  matchIsValidTelOrTestTel,
  nullableStringOrNumberIsValidNumber,
  snakeCaseToHuman,
  splitStringBySentenceChange,
  stringAvatar,
  validateLatLon,
};
