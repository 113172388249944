import { Subscription } from '@rails/actioncable';
import { LiveSiteChannel_Read, Site_Read_Nested } from '@treadinc/horizon-api-spec';
import { useState } from 'react';

import connection from '~services/connectionModule';
import { realTimeChannels } from '~services/realTimeChannels';
import { useStores } from '~store';

import { Site } from '../useSites/models';
import { LiveSiteData } from './models';

interface SubscribeToLiveSiteUpdatesProps {
  onMessageCallBack: (data: LiveSiteData) => void;
}

export const useLiveSiteLocations = () => {
  const [subscription, setSubscription] = useState<Subscription>();
  const { userStore } = useStores();

  const subscribeToLiveSiteUpdates = ({
    onMessageCallBack,
  }: SubscribeToLiveSiteUpdatesProps) => {
    const consumer = connection.realTimeConnection;
    const channel = realTimeChannels.LiveSiteChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = consumer?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = consumer?.subscriptions?.create?.(
        {
          channel: channel,
          company_id: userStore.userCompany?.id,
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          connected: () => () => {},
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          disconnected: () => () => {},
          received: ({ data }: { data: LiveSiteChannel_Read }) => {
            const locationData = LiveSiteData.parse(data);
            onMessageCallBack(locationData);
          },
        },
      );
    }

    setSubscription(subscription);
  };

  return { subscription, subscribeToLiveSiteUpdates };
};
