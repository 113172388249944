import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import InvoiceState from '~components/Invoice/InvoiceState';
import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import { Invoice, useInvoices } from '~hooks/useInvoices';
import theme from '~theme/AppTheme';

const JobPayable = () => {
  const { id } = useParams();
  const { isLoading, getJobPayable } = useInvoices();
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const { t } = useTranslation();

  const fetchInvoice = async (id: string) => {
    const invoice = await getJobPayable(id);
    if (invoice) {
      setInvoice(invoice);
    }
  };

  useEffect(() => {
    if (id) {
      fetchInvoice(id);
    }
  }, [id]);

  const tableData = useMemo(() => {
    if (!invoice) return [];

    return [
      { column: t('jobPayableColumns.invoiceId'), value: invoice.invoiceId },
      {
        column: t('jobPayableColumns.state'),
        value: <InvoiceState state={invoice.state} />,
      },
      {
        column: t('jobPayableColumns.totalQuantity'),
        value: invoice.invoiceLineItemsTotalQuantity,
      },
      { column: t('jobPayableColumns.orderId'), value: invoice.orderOrderIds.join(', ') },
      { column: t('jobPayableColumns.project'), value: invoice.projectNames.join(', ') },
      { column: t('jobPayableColumns.customer'), value: invoice.customerAccount?.name },
      { column: t('jobPayableColumns.vendor'), value: invoice.vendorAccount?.name },
      {
        column: t('jobPayableColumns.equipmentType'),
        value: invoice.equipmentTypeNames.join(', '),
      },
      {
        column: t('jobPayableColumns.truckId'),
        value: invoice.equipmentExternalIds.join(', '),
      },
      {
        column: t('jobPayableColumns.material'),
        value: invoice.materialNames.join(', '),
      },
      { column: t('jobPayableColumns.driver'), value: invoice.driver?.fullName },
      {
        column: t('jobPayableColumns.pickupSite'),
        value: invoice.pickupSiteNames.join(', '),
      },
      {
        column: t('jobPayableColumns.dropoffSite'),
        value: invoice.dropoffSiteNames.join(', '),
      },
      { column: t('jobPayableColumns.driverRate'), value: invoice.rate?.name },
      { column: t('jobPayableColumns.rateValue'), value: invoice.rateValue },
      { column: t('jobPayableColumns.rateType'), value: invoice.rateType },
      { column: t('jobPayableColumns.jobId'), value: invoice.jobJobIds.join(', ') },
      {
        column: t('jobPayableColumns.loggingStartedAt'),
        value: invoice.jobSummary?.loggingStartedAt?.format('YYYY-MM-DD HH:mm A'),
      },
      {
        column: t('jobPayableColumns.loggingEndedAt'),
        value: invoice.jobSummary?.loggingEndedAt?.format('YYYY-MM-DD HH:mm A'),
      },
      {
        column: t('jobPayableColumns.breakTime'),
        value: invoice.jobSummary?.breakTimeMinutes
          ? `${invoice.jobSummary?.breakTimeMinutes} minutes`
          : '',
      },
      {
        column: t('jobPayableColumns.workTime'),
        value: invoice.jobSummary?.workTimeMinutes
          ? `${invoice.jobSummary?.workTimeMinutes} minutes`
          : '',
      },
      {
        column: t('jobPayableColumns.firstGeofenceEntryAt'),
        value: invoice.jobSummary?.firstGeofenceEntryAt?.format('YYYY-MM-DD HH:mm A'),
      },
      {
        column: t('jobPayableColumns.lastGeofenceExitAt'),
        value: invoice.jobSummary?.lastGeofenceExitAt?.format('YYYY-MM-DD HH:mm A'),
      },

      {
        column: t('jobPayableColumns.payStartTime'),
        value: invoice.payStartTime?.format('YYYY-MM-DD HH:mm A'),
      },
      {
        column: t('jobPayableColumns.payEndTime'),
        value: invoice.payEndTime?.format('YYYY-MM-DD HH:mm A'),
      },
      { column: t('jobPayableColumns.totalLoads'), value: invoice.totalLoadsCount },
      {
        column: t('jobPayableColumns.completedLoads'),
        value: invoice.jobSummary?.completedLoadsCount,
      },
      {
        column: t('jobPayableColumns.deliveredQuantities'),
        value: invoice.jobSummary?.deliveredQuantities
          .map(
            (dq) =>
              `${dq.materialName}: ${dq.delivered} / ${dq.total} ${dq.unitOfMeasure}`,
          )
          .join(', '),
      },
      {
        column: t('jobPayableColumns.jobPhase'),
        value: invoice.jobSummary?.jobPhaseName,
      },
      {
        column: t('jobPayableColumns.jobPhaseCode'),
        value: invoice.jobSummary?.jobPhaseCode,
      },
      {
        column: t('jobPayableColumns.foremanExternalId'),
        value: invoice.jobSummary?.foremanExternalId,
      },
    ];
  }, [invoice]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: '400px' }}>
      <LoadingSpinner loadingIndicatorSize={16} isVisible={isLoading} />
      {invoice && (
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {tableData.map(({ column, value }) => (
                <TableRow
                  key={column}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '& td, & th': {
                      borderBottom: '1px solid',
                      borderColor: theme.brandV2.colors.treadGray7,
                    },
                  }}
                >
                  <TableCell sx={{ width: '200px' }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {column}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row" sx={{ width: '200px' }}>
                    <Typography variant="body2">{value || '-'}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default JobPayable;
