import { LayersList } from '@deck.gl/core';
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Circle, GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createRoot } from 'react-dom/client';

import { TORONTO_OFFICE_COORDINATES } from '~constants/mapConsts';

import { TrafficLayerToggle } from '../Controls/TrafficLayerToggle';
import { GeofenceType, GOOGLE_MAPS_LOADER_OPTIONS, MarkerType } from './constants';
import { TreadMarker } from './TreadMarker';
import { TreadMarkerProps } from './types';

type LngLat = {
  lat: number;
  lng: number;
};
type MapTypeId = 'hybrid' | 'satellite' | 'terrain' | 'roadmap';
type FillColor = 'black' | 'blue';

export interface MapControlProps {
  map?: google.maps.Map;
}

type MapV2Props = {
  center?: LngLat;
  drawingMode?: boolean;
  drawingCoordinates?: LngLat[];
  emptyText?: string;
  markers?: TreadMarkerProps[];
  maxZoom?: number;
  // Callback to publish the new coordinates as a polygon is edited
  onPolygonEdit?: (coordinates: LngLat[]) => void;
  overlayLayers?: LayersList;
  children?: ReactElement<MapControlProps> | ReactElement<MapControlProps>[];
};

export function MapV2({
  center,
  drawingMode,
  drawingCoordinates = [],
  emptyText,
  markers = [],
  // For reference, 17 is well suited for site creation, while 14 is more in line with live map
  maxZoom = 17,
  onPolygonEdit,
  overlayLayers,
  children,
}: MapV2Props) {
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [mapTypeId, setMapTypeId] = useState<MapTypeId>('roadmap');
  const [activeMarkerId, setActiveMarkerId] = useState<string | null>(null);
  const drawableRef = useRef<google.maps.Polygon | null>(null);
  const listenersRef = useRef([]);
  const overlayRef = useRef<GoogleMapsOverlay | null>(null);

  const { isLoaded } = useJsApiLoader(GOOGLE_MAPS_LOADER_OPTIONS);

  const fitBounds = useCallback(
    (bounds: google.maps.LatLngBounds) => {
      if (mapInstance) {
        mapInstance.fitBounds(bounds);
        const fittedZoom = mapInstance.getZoom() ?? Infinity;
        if (Number.isInteger(fittedZoom) && fittedZoom > maxZoom) {
          mapInstance.setZoom(maxZoom);
        }
      }
    },
    [mapInstance],
  );

  // Changing to drawing mode triggers coordinate hydration and map fit
  useEffect(() => {
    if (mapInstance && drawingMode) {
      // This is the case where we are drawing a new polygon. First draw centering.
      if (drawingCoordinates.length === 0) {
        const initialCoordinates = [
          {
            lat:
              (markers[0]?.lat ?? center?.lat ?? TORONTO_OFFICE_COORDINATES.lat) + 0.001,
            lng: markers[0]?.lng ?? center?.lng ?? TORONTO_OFFICE_COORDINATES.lng,
          },
          {
            lat:
              (markers[0]?.lat ?? center?.lat ?? TORONTO_OFFICE_COORDINATES.lat) - 0.001,
            lng:
              (markers[0]?.lng ?? center?.lng ?? TORONTO_OFFICE_COORDINATES.lng) + 0.001,
          },
          {
            lat:
              (markers[0]?.lat ?? center?.lat ?? TORONTO_OFFICE_COORDINATES.lat) - 0.001,
            lng:
              (markers[0]?.lng ?? center?.lng ?? TORONTO_OFFICE_COORDINATES.lng) - 0.001,
          },
        ];
        const bounds = new window.google.maps.LatLngBounds();
        initialCoordinates.forEach((coordinate) => bounds.extend(coordinate));
        fitBounds(bounds);
        onPolygonEdit?.(initialCoordinates);
      } else {
        // This is the case where we are editing an existing polygon. First draw centering.
        const bounds = new window.google.maps.LatLngBounds();
        drawingCoordinates.forEach((coordinate) => bounds.extend(coordinate));
        const formattedMarkers = markers.map(({ lat, lng }) => ({
          lat,
          lng,
        }));
        formattedMarkers.forEach((marker) => bounds.extend(marker));
        fitBounds(bounds);
      }
    }
  }, [drawingMode, mapInstance]);

  // We use this dependency to be less aggressive with the fitBounds calls
  // Updating a marker should not trigger a fitBounds call anymore
  const sortedMarkerIdString = useMemo(
    () =>
      Array.from(new Set(markers.map((marker) => marker.id)))
        .sort()
        .toString(),
    [markers],
  );

  // Fit map to markers
  useEffect(() => {
    if (mapInstance && markers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      drawingCoordinates.forEach((coordinate) => bounds.extend(coordinate));
      const formattedMarkers = markers.map(({ lat, lng }) => ({
        lat,
        lng,
      }));
      formattedMarkers.forEach((marker) => bounds.extend(marker));
      markers.forEach((marker) => {
        if (
          (marker.type === MarkerType.site ||
            marker.type === MarkerType.pickup_site ||
            marker.type === MarkerType.dropoff_site ||
            marker.type === MarkerType.both_site) &&
          marker.geofenceType === GeofenceType.polygon
        ) {
          marker.coordinates.forEach((polygon) => {
            polygon.forEach(([lng, lat]) => bounds.extend({ lat, lng }));
          });
        }
      });
      markers.forEach((marker) => {
        if (
          (marker.type === MarkerType.site ||
            marker.type === MarkerType.pickup_site ||
            marker.type === MarkerType.dropoff_site ||
            marker.type === MarkerType.both_site) &&
          marker.geofenceType === GeofenceType.circle
        ) {
          const circle = new google.maps.Circle({
            center: {
              lat: marker.lat,
              lng: marker.lng,
            },
            radius: Math.max(...marker.radii),
          });
          const circleBounds = circle.getBounds();
          if (circleBounds) {
            bounds.extend(circleBounds.getNorthEast());
            bounds.extend(circleBounds.getSouthWest());
          }
        }
      });
      fitBounds(bounds);
    }
  }, [mapInstance, sortedMarkerIdString]);

  const onMapLoad = useCallback(
    (map: google.maps.Map) => {
      setMapInstance(map);

      // Adding custom controls
      const trafficToggleDiv = document.createElement('div');
      const root = createRoot(trafficToggleDiv);
      root.render(
        <TrafficLayerToggle
          map={map}
          // Margin 10px aligns with Google Maps default map type spacing
          sx={{ marginTop: '10px' }}
        />,
      );
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(trafficToggleDiv);

      // Initialize the Deck.gl Google Maps Overlay
      const overlay = new GoogleMapsOverlay({});
      overlay.setMap(map);
      overlayRef.current = overlay;
    },
    [setMapInstance],
  );

  const onMapUnmount = useCallback(() => {
    setMapInstance(null);
    overlayRef.current = null;
  }, [setMapInstance]);

  const onMapTypeIdChanged = useCallback(() => {
    const newMapTypeId = mapInstance?.getMapTypeId() as MapTypeId;
    if (newMapTypeId) {
      setMapTypeId(newMapTypeId);
    }
  }, [mapInstance, setMapTypeId]);

  const onEdit = useCallback(() => {
    if (drawableRef.current) {
      const nextPath = drawableRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      onPolygonEdit?.(nextPath);
    }
  }, [onPolygonEdit]);

  const onPolygonLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      drawableRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        // @ts-ignore - types are wrong. These are valid events and needed
        path?.addListener('set_at', onEdit),
        path?.addListener('insert_at', onEdit),
        path?.addListener('remove_at', onEdit),
      );
    },
    [onEdit],
  );

  const cleanupEditablePolygon = useCallback(() => {
    // Please see here for more: https://codesandbox.io/p/sandbox/reactgooglemapsapi-editing-a-polygon-popr2
    // @ts-ignore - types are wrong. This is found in examples and needed
    listenersRef.current.forEach((lis) => lis?.remove());
    drawableRef.current = null;
  }, []);

  const circles = markers.reduce(
    (acc, marker) => {
      if (marker.type === MarkerType.moving_site) {
        const newCircles = marker.radii.map((radius) => ({
          center: {
            lng: marker.lng,
            lat: marker.lat,
          },
          radius,
          fillColor: 'black' as FillColor,
        }));
        return [...acc, ...newCircles];
      }
      if (
        (marker.type === MarkerType.site ||
          marker.type === MarkerType.pickup_site ||
          marker.type === MarkerType.dropoff_site ||
          marker.type === MarkerType.both_site) &&
        marker.geofenceType === GeofenceType.circle
      ) {
        const newCircles = marker.radii.map((radius) => ({
          center: {
            lng: marker.lng,
            lat: marker.lat,
          },
          radius,
          fillColor: 'blue' as FillColor,
        }));
        return [...acc, ...newCircles];
      }
      return acc;
    },
    [] as {
      center: { lng: number; lat: number };
      radius: number;
      fillColor: FillColor;
    }[],
  );

  const polygons = markers.reduce((acc, marker) => {
    if (
      (marker.type === MarkerType.site ||
        marker.type === MarkerType.pickup_site ||
        marker.type === MarkerType.dropoff_site ||
        marker.type === MarkerType.both_site) &&
      marker.geofenceType === GeofenceType.polygon
    ) {
      const formattedPolygons = marker.coordinates.map((polygon) =>
        polygon.map(([lng, lat]) => ({
          lat,
          lng,
        })),
      );
      return [...acc, ...formattedPolygons];
    }
    return acc;
  }, [] as LngLat[][]);

  // update the deck.gl overlay with the new data
  useEffect(() => {
    if (!overlayRef.current) return;
    overlayRef.current.setProps({
      layers: overlayLayers,
    });
  }, [overlayLayers, overlayRef.current]);

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ height: '100%', width: '100%' }}
          center={
            markers.length > 0 || drawingCoordinates.length > 0
              ? undefined
              : {
                  lng: center?.lng ?? TORONTO_OFFICE_COORDINATES.lng,
                  lat: center?.lat ?? TORONTO_OFFICE_COORDINATES.lat,
                }
          }
          zoom={14}
          onLoad={onMapLoad}
          onUnmount={onMapUnmount}
          onMapTypeIdChanged={onMapTypeIdChanged}
          options={{
            mapTypeId,
            zoomControl: true,
            streetViewControl: false,
            rotateControl: true,
            scaleControl: true,
            styles: [
              {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
              },
            ],
          }}
        >
          {markers.map((marker) => {
            return (
              <TreadMarker
                key={marker.id}
                isActive={marker.id === activeMarkerId}
                {...marker}
                {...(marker.type === MarkerType.site || marker.type === MarkerType.truck
                  ? {
                      onMouseDown: () => setActiveMarkerId(marker.id),
                      onMouseUp: () => setActiveMarkerId(null),
                      onMouseOver: () => setActiveMarkerId(marker.id),
                      onMouseOut: () => setActiveMarkerId(null),
                    }
                  : {})}
              />
            );
          })}
          {circles.map((circle, index) => (
            <Circle
              key={index}
              center={circle.center}
              radius={circle.radius}
              options={{
                fillOpacity: 0.25 - index * 0.05,
                fillColor: circle.fillColor,
                strokeOpacity: 0.5,
                strokeWeight: 0,
              }}
            />
          ))}
          {polygons?.[0]?.length &&
            polygons.map((polygon, index) => (
              <Polygon
                key={`${index}-${polygon[0].lat}${polygon[0].lng}`}
                paths={polygon}
                options={{
                  fillColor: 'blue',
                  fillOpacity: 0.35,
                  strokeColor: 'blue',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                }}
              />
            ))}
          {emptyText && markers.length === 0 && drawingCoordinates.length === 0 && (
            <Box
              sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                background: 'rgba(255,255,255,0.8)',
                display: 'grid',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <Typography variant="h5">{emptyText}</Typography>
            </Box>
          )}
          {drawingMode && (
            <Polygon
              editable
              draggable
              key={`drawing-polygon`}
              paths={drawingCoordinates}
              onLoad={onPolygonLoad}
              onUnmount={cleanupEditablePolygon}
              onMouseUp={onEdit}
              onDragEnd={onEdit}
              options={{
                fillColor: 'orange',
                fillOpacity: 0.35,
                strokeColor: 'orange',
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
            />
          )}

          {mapInstance &&
            Children.map(children, (child) => {
              if (isValidElement(child)) {
                return cloneElement(child, { map: mapInstance });
              }
              return null;
            })}
        </GoogleMap>
      )}
    </>
  );
}
