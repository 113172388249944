import axios from 'axios';
import { t } from 'i18next';
import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';

import { OrderSchemaVersion } from '../newOrderFormSchema';
import useCycleEstimate from './useCycleEstimate';
import useDeliveredQuantitiesEstimate from './useDeliveredQuantitiesEstimate';
import useOrderEstimate from './useOrderEstimate';
import useRouteEstimate from './useRouteEstimate';

export default function OrderEstimateEffects() {
  const form = useFormContext();

  const { estimateOrder } = useOrderEstimate();
  const { estimateRoute } = useRouteEstimate();
  const { estimateCycle } = useCycleEstimate();
  const { estimateDeliveredQuantity, estimateDeliveredPerTruck } =
    useDeliveredQuantitiesEstimate();

  const isSchemaV2 = form.getValues('_schemaVersion') === OrderSchemaVersion.V2;
  const isEditing = Boolean(form.watch('id'));
  const isCalcsRefreshEnabled = isSchemaV2 && !isEditing;

  const debouncedEstimateOrder = useCallback(
    _.debounce(() => {
      form.clearErrors('jobTime');

      estimateOrder()
        .then((estimate) => {
          if (!_.isNil(estimate)) {
            form.setValue('loadsPerTruck', `${estimate.loadsPerTruck ?? ''}`);
            form.setValue('totalLoads', `${estimate.totalLoads ?? ''}`);
            form.setValue(
              'unitsPerHour',
              `${_.isNil(estimate.unitsPerHour) ? '' : Math.round(estimate.unitsPerHour)}`,
            );
            form.setValue('truckCount', `${estimate.truckCount ?? ''}`);
          }
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            const field = error.response?.data?.error?.errors?.[0]?.field;

            if (field === 'job_duration_minutes') {
              const message = error.response?.data?.error?.errors?.[0]?.message;
              const { groups } = /(?<duration>[0-9]+)/.exec(message) ?? {};

              if (groups?.duration) {
                const updatedMessage = t(
                  'dispatch.order.cycle.job_duration_minutes_greater_than_error_message',
                  { value: (Number(groups.duration) / 60).toFixed(2) },
                );
                form.setError('jobTime', { message: updatedMessage, type: 'manual' });
              }
            }
          }
        });
    }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS),
    [estimateOrder],
  );

  useEffect(() => {
    if (!isCalcsRefreshEnabled) {
      return;
    }

    debouncedEstimateOrder();

    return () => {
      debouncedEstimateOrder.cancel();
    };
  }, [isCalcsRefreshEnabled, debouncedEstimateOrder]);

  useEffect(() => {
    if (!isCalcsRefreshEnabled) {
      return;
    }

    estimateRoute().then((estimate) => {
      if (!_.isNil(estimate)) {
        form.setValue('cycleDistance', estimate.cycleDistance.toFixed(2));
        form.setValue('pickupToDropoff', `${estimate.pickupToDropoff}`);
      }
    });
  }, [isCalcsRefreshEnabled, estimateRoute]);

  useEffect(() => {
    if (!isCalcsRefreshEnabled) {
      return;
    }

    const cycleTime = estimateCycle();

    if (!_.isNil(cycleTime)) {
      form.setValue('cycleTime', `${cycleTime}`);
    }
  }, [isCalcsRefreshEnabled, estimateCycle]);

  useEffect(() => {
    const estimatedDeliveredQuantity = estimateDeliveredQuantity();

    if (!_.isNil(estimatedDeliveredQuantity)) {
      form.setValue('estimatedDeliveredQuantity', `${estimatedDeliveredQuantity}`);
    }
  }, [estimateDeliveredQuantity]);

  useEffect(() => {
    if (!isCalcsRefreshEnabled) {
      return;
    }

    const jobQuantity = estimateDeliveredPerTruck();

    if (!_.isNil(jobQuantity)) {
      form.setValue('jobQuantity', `${jobQuantity}`);
    }
  }, [isCalcsRefreshEnabled, estimateDeliveredPerTruck]);

  return null;
}
