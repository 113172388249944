import {
  patchV1DriverDaysDriverDayIdResourceUsageLogs,
  patchV1JobsJobIdResourceUsageLogs,
  ResourceUsageLog_Read,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { ResourceUsageLogDTO } from '~pages/Approvals/DriverPay/helpers';
import connection from '~services/connectionModule';
import { useStores } from '~store';

import { ResourceUsageLog } from './models';

type GetResourceUsageLogsParams = { 'page[after]'?: string };

const useResourceUsageLogs = () => {
  const { addOnsStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getResourceUsageLogsByDriverDay = async (driverDayId: string) => {
    setIsLoading(true);

    const getPage = (params: GetResourceUsageLogsParams) => {
      return connection.getPaginated<ResourceUsageLog_Read>(
        `${API_VERSION}/driver_days/${driverDayId}/resource_usage_logs`,
        { params: { ...params, 'page[limit]': 25 } },
        $t('error_messages.add_ons.falied_to_fetch_resource_usage_logs') as string,
      );
    };

    let resourceUsageLogs: ResourceUsageLog[] = [];
    let shouldFetchNextPage = true;
    const params: GetResourceUsageLogsParams = {};

    try {
      while (shouldFetchNextPage) {
        const response = await getPage(params);

        const parsedResourceUsageLogs = response.data.map((resouce) => {
          return ResourceUsageLog.parse(resouce);
        });
        resourceUsageLogs = resourceUsageLogs.concat(parsedResourceUsageLogs);

        shouldFetchNextPage = Boolean(response.pagination.after);

        if (shouldFetchNextPage) {
          params['page[after]'] = response.pagination.after;
        }
      }
      addOnsStore.setResourceUsageLogsByDriverDayId(driverDayId, resourceUsageLogs);
    } finally {
      setIsLoading(false);
    }
  };

  const getResourceUsageLogsByJob = async (jobId: string) => {
    setIsLoading(true);

    const getPage = (params: GetResourceUsageLogsParams) => {
      return connection.getPaginated<ResourceUsageLog_Read>(
        `${API_VERSION}/jobs/${jobId}/resource_usage_logs`,
        { params: { ...params, 'page[limit]': 25 } },
        $t('error_messages.add_ons.falied_to_fetch_resource_usage_logs') as string,
      );
    };

    let resourceUsageLogs: ResourceUsageLog[] = [];
    let shouldFetchNextPage = true;
    const params: GetResourceUsageLogsParams = {};

    try {
      while (shouldFetchNextPage) {
        const response = await getPage(params);

        const parsedResourceUsageLogs = response.data.map((resouce) => {
          return ResourceUsageLog.parse(resouce);
        });
        resourceUsageLogs = resourceUsageLogs.concat(parsedResourceUsageLogs);

        shouldFetchNextPage = Boolean(response.pagination.after);

        if (shouldFetchNextPage) {
          params['page[after]'] = response.pagination.after;
        }
      }

      addOnsStore.setResourceUsageLogsByJobId(jobId, resourceUsageLogs);
    } finally {
      setIsLoading(false);
    }
  };

  const batchUpdateResourceUsageLogsByDriverDay = async (
    driverDayId: string,
    logs: ResourceUsageLogDTO[],
    dateOfUse: string,
  ) => {
    setIsUpdating(true);
    try {
      const response = await patchV1DriverDaysDriverDayIdResourceUsageLogs({
        path: { 'driver-day-id': driverDayId },
        body: {
          resource_usage_logs: logs.map((log) => {
            return ResourceUsageLog.deparse(log, dateOfUse);
          }),
        },
      });

      const updatedResourceUsageLogs = response.data.data?.map((log) => {
        return ResourceUsageLog.parse(log);
      });
      addOnsStore.setResourceUsageLogsByDriverDayId(
        driverDayId,
        updatedResourceUsageLogs,
      );
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.add_ons.failed_to_update') as string,
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const batchUpdateResourceUsageLogsByJob = async (
    jobId: string,
    logs: ResourceUsageLogDTO[],
    dateOfUse: string,
  ) => {
    setIsUpdating(true);
    try {
      const response = await patchV1JobsJobIdResourceUsageLogs({
        path: { 'job-id': jobId },
        body: {
          resource_usage_logs: logs.map((log) => {
            return ResourceUsageLog.deparse(log, dateOfUse);
          }),
        },
      });

      const updatedResourceUsageLogs = response.data.data?.map((log) => {
        return ResourceUsageLog.parse(log);
      });

      addOnsStore.setResourceUsageLogsByJobId(jobId, updatedResourceUsageLogs);
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.add_ons.failed_to_update') as string,
      );
    } finally {
      setIsUpdating(false);
    }
  };
  return {
    batchUpdateResourceUsageLogsByDriverDay,
    batchUpdateResourceUsageLogsByJob,
    getResourceUsageLogsByDriverDay,
    getResourceUsageLogsByJob,
    isLoading,
  };
};

export default useResourceUsageLogs;
