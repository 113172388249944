import { ChipOwnProps } from '@mui/material/Chip';
import { OrderState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';

export const OrderStateLabelMapping: Partial<
  Record<OrderState, Pick<ChipOwnProps, 'color' | 'label'>>
> = {
  [OrderState.ACCEPTED]: { color: 'secondary', label: t('status.created') },
  [OrderState.CANCELED]: { color: 'secondary', label: t('status.canceled') },
  [OrderState.COMPLETED]: { color: 'success', label: t('status.completed') },
  [OrderState.CREATED]: { color: 'info', label: t('status.created') },
  [OrderState.HOLD]: { color: 'info', label: t('status.hold') },
  [OrderState.WEATHER_HOLD]: { color: 'info', label: t('status.weather_hold') },
  [OrderState.IN_PROGRESS]: { color: 'info', label: t('status.in_progress') },
  [OrderState.PENDING_REQUEST]: { color: 'info', label: t('status.pending') },
  [OrderState.REJECTED]: { color: 'error', label: t('status.rejected') },
};
