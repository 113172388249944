import Add from '@mui/icons-material/Add';
import FilterList from '@mui/icons-material/FilterList';
import { buttonClasses, paperClasses } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {
  ModelError_Item,
  ModelError_Response,
  OrderUnitOfMeasure,
} from '@treadinc/horizon-api-spec';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useRef, useState } from 'react';

import { DialogHeader } from '~components/Dialog/DialogHeader';
import {
  SmallButton,
  SmallLoadingButton,
} from '~components/Order/ordersDispatchStyledComponents';
import { PageTitle } from '~components/typographyTitles/PageTitle';
import { AccountBasic } from '~hooks/useAccount';
import { MaterialRate } from '~hooks/useMaterialRates/models';
import useMaterialRates from '~hooks/useMaterialRates/useMaterialRates';
import connection from '~services/connectionModule';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isActionClicked } from '~utils/utilFunctions';

import MaterialRateForm, { MaterialRateFormHandler } from './components/MaterialRateForm';
import MaterialRatesDataGrid, {
  MaterialRatesDataGridHandler,
} from './components/MaterialRatesDataGrid';

interface MaterialRateDialogState {
  customer?: AccountBasic;
  errors?: ModelError_Item[];
  isOpen: boolean;
  isSaving: boolean;
  materialRate?: MaterialRate;
}

const MaterialRates = observer(function MaterialRates() {
  const { toasterStore, userStore } = useStores();
  const { createMaterialRate, deleteMaterialRate, updateMaterialRate } =
    useMaterialRates();

  const [materialRateDialogState, setMaterialRateDialogState] =
    useState<MaterialRateDialogState>({ isOpen: false, isSaving: false });

  const materialRatesDataGridRef = useRef<MaterialRatesDataGridHandler>(null);
  const materialRateFormRef = useRef<MaterialRateFormHandler>(null);

  const companyId = userStore.userCompany?.id;
  const isCustomerSelected = Boolean(materialRateDialogState.customer?.id);
  const isEditingMaterialRate = Boolean(materialRateDialogState.materialRate?.id);

  const dialogTitle = useMemo(() => {
    if (isCustomerSelected) {
      if (isEditingMaterialRate) {
        return t('administration.material_rates.edit_rate_form_title');
      }

      return t('administration.material_rates.add_rate_form_title');
    }

    return t('administration.material_rates.add_customer_schedule_form_title');
  }, [isCustomerSelected, isEditingMaterialRate]);

  const openDialog = useCallback((materialRate?: MaterialRate) => {
    setMaterialRateDialogState({ isOpen: true, isSaving: false, materialRate });
  }, []);

  const closeDialog = useCallback(() => {
    setMaterialRateDialogState((state) => ({ ...state, isOpen: false }));
  }, []);

  const handleEditMaterialRate = useCallback(
    (materialRate: MaterialRate) => {
      openDialog(materialRate);
    },
    [openDialog],
  );

  const handleDeleteMaterialRate = useCallback(async (materialRate: MaterialRate) => {
    try {
      await deleteMaterialRate(materialRate.id);

      toasterStore.push(
        alert(
          t('administration.material_rates.material_rate_deleted_message'),
          AlertTypes.success,
        ),
      );

      materialRatesDataGridRef.current?.refetch();
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.material_rates.failed_to_delete_material_rate') as string,
      );
    }
  }, []);

  const handleMaterialRateFormSubmit = useCallback(async () => {
    const formData = await materialRateFormRef.current?.submit();

    if (!formData) {
      return;
    }

    try {
      setMaterialRateDialogState((state) => ({ ...state, isSaving: true }));

      if (isEditingMaterialRate) {
        await updateMaterialRate(String(materialRateDialogState.materialRate?.id), {
          material_id: formData.material.id,
          project_id: formData.project?.id || undefined,
          site_id: formData.site?.id || undefined,
          customer_account_id: formData.customer?.id || undefined,
          buy_price: Number(formData.buyPrice),
          sell_price: Number(formData.sellPrice),
          unit_of_measure: formData.unitOfMeasure.id as OrderUnitOfMeasure,
        });
      } else {
        await createMaterialRate({
          material_id: formData.material.id,
          project_id: formData.project?.id || undefined,
          site_id: formData.site?.id || undefined,
          customer_account_id: formData.customer?.id || undefined,
          buy_price: Number(formData.buyPrice),
          sell_price: Number(formData.sellPrice),
          unit_of_measure: formData.unitOfMeasure.id as OrderUnitOfMeasure,
        });
      }

      toasterStore.push(
        alert(
          isEditingMaterialRate
            ? t('administration.material_rates.material_rate_updated_message')
            : t('administration.material_rates.material_rate_created_message'),
          AlertTypes.success,
        ),
      );

      setMaterialRateDialogState((state) => ({
        ...state,
        isOpen: false,
        isSaving: false,
      }));

      materialRatesDataGridRef.current?.refetch();
    } catch (error) {
      const e = error as AxiosError<ModelError_Response>;
      let badRequestErrors: ModelError_Item[] | undefined;

      if (e.response?.status === 422) {
        badRequestErrors = e.response?.data.error.errors;
      }

      setMaterialRateDialogState((state) => ({
        ...state,
        isSaving: false,
        errors: badRequestErrors,
      }));

      connection.handleRequestError(
        error,
        (isEditingMaterialRate
          ? t('error_messages.material_rates.failed_to_update_material_rate')
          : t('error_messages.material_rates.failed_to_create_material_rate')) as string,
        [422],
      );
    }
  }, [isEditingMaterialRate, materialRateDialogState.materialRate?.id]);

  return (
    <>
      <PageTitle text={`${t('administration.material_rates.title')}`} />

      <Box
        sx={{
          border: `solid 1px ${theme.palette.divider}`,
          display: 'flex',
          height: 'calc(100vh - 153px)',
          mb: -4,
          mt: 2,
        }}
      >
        <Box
          sx={{
            borderRight: `solid 1px ${theme.palette.divider}`,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            height: '100%',
            maxWidth: '240px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1.5, flex: 0 }}>
            <SmallButton startIcon={<Add />} onClick={() => openDialog()}>
              {t('administration.material_rates.add_customer_schedule_cta')}
            </SmallButton>
          </Box>

          <Box
            sx={{
              borderTop: `solid 1px ${theme.palette.divider}`,
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              overflow: 'auto',
            }}
          >
            <Box>
              <ListItemButton
                selected
                sx={{
                  borderBottom: `solid 1px ${theme.palette.divider}`,
                  borderRight: `solid 2px ${theme.palette.primary.main}`,
                }}
              >
                <ListItemText
                  primary={t('administration.rates.default_rate')}
                  sx={{ fontWeight: 600 }}
                />
              </ListItemButton>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, p: 1.5 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', flex: 0 }}>
            <SmallButton
              size="small"
              startIcon={<FilterList />}
              sx={{
                [`&.${buttonClasses.root}`]: {
                  backgroundColor: 'white',
                  border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                  borderRadius: theme.brandV2.borderRadius,
                },
              }}
            >
              {t('common.filters')}
            </SmallButton>

            {isCustomerSelected && (
              <SmallButton startIcon={<Add />} onClick={() => {}}>
                {t('administration.material_rates.add_material_rate_cta')}
              </SmallButton>
            )}
          </Box>

          <Box sx={{ flex: 1, mt: 1.5 }}>
            <MaterialRatesDataGrid
              ref={materialRatesDataGridRef}
              companyId={companyId}
              onDelete={handleDeleteMaterialRate}
              onEdit={handleEditMaterialRate}
            />
          </Box>
        </Box>
      </Box>

      <Dialog
        sx={{ [`& .${paperClasses.root}`]: { maxWidth: '500px', overflowY: 'visible' } }}
        open={materialRateDialogState.isOpen}
        onClose={(__: never, reason: DialogCloseReasonType) => {
          if (!materialRateDialogState.isSaving && isActionClicked(reason)) {
            closeDialog();
          }
        }}
      >
        <DialogHeader
          closeCallBack={() => {
            if (!materialRateDialogState.isSaving) {
              closeDialog();
            }
          }}
          title={
            <Typography component="span" variant="h5">
              {dialogTitle}
            </Typography>
          }
        />

        <DialogContent>
          <MaterialRateForm
            ref={materialRateFormRef}
            errors={materialRateDialogState.errors}
            materialRate={materialRateDialogState.materialRate}
          />
        </DialogContent>

        <DialogActions
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            justifyContent: 'flex-end',
            m: 0,
            p: 2,
          }}
        >
          <SmallButton
            color="secondary"
            disabled={materialRateDialogState.isSaving}
            onClick={closeDialog}
            variant="outlined"
          >
            {t('actions.cancel')}
          </SmallButton>

          <SmallLoadingButton
            color="brandV2Yellow"
            disabled={materialRateDialogState.isSaving}
            loading={materialRateDialogState.isSaving}
            onClick={handleMaterialRateFormSubmit}
          >
            {isEditingMaterialRate ? t('actions.update') : t('actions.create')}
          </SmallLoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default MaterialRates;
