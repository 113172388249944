import SubtitlesOff from '@mui/icons-material/SubtitlesOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { InvoiceCategoryFilter } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';

import InvoicesTabContent from '~components/Invoice/InvoicesTabContent';
import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import { SmallTabs } from '~components/Tabs/SmallTabs';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { Invoice, useInvoices } from '~hooks/useInvoices';
import { Job, useJob } from '~hooks/useJob';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

import { JobDetails } from './JobDetails';
import { LoadDetails } from './LoadDetails';

interface Props {
  details: Job;
}

const JobInvoices = observer(
  ({ job, currentCategory }: { job: Job; currentCategory: InvoiceCategoryFilter }) => {
    const { approveInvoice, getJobPayable, getJobReceivable } = useInvoices();
    const { currencyFormatter } = useCompanyCurrency();
    const { userStore } = useStores();
    const { subscribeToInvoiceRTU } = useInvoices();
    const [invoice, setInvoice] = useState<Invoice | null | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      let subscription: ReturnType<typeof subscribeToInvoiceRTU> | null = null;

      if (userStore.userCompany.id) {
        subscription = subscribeToInvoiceRTU(userStore.userCompany.id);
      }

      return () => {
        subscription?.unsubscribe?.();
      };
    }, [userStore.userCompany.id]);

    const getInvoice = async (jobId: string) => {
      let invoice;
      setIsLoading(true);

      const func =
        currentCategory === InvoiceCategoryFilter.PAYABLES
          ? getJobPayable
          : getJobReceivable;
      try {
        invoice = await func(jobId);
      } finally {
        setInvoice(invoice ?? null);
        setIsLoading(false);
      }
    };

    const reload = async () => {
      await getInvoice(job.id);
    };

    useEffect(() => {
      getInvoice(job.id);
    }, [currentCategory, job.id]);

    if (isLoading) {
      return <LoadingSpinner isVisible={isLoading} />;
    }

    if (invoice === null) {
      return (
        <Box display="flex" gap={2} key={job.id}>
          {' '}
          <Box
            key={job.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
              mb: 2,
              width: '100%',
            }}
          >
            <SubtitlesOff />
            <Typography
              color={theme.brandV2.colors.treadBlack}
              variant="body1"
              sx={{ textAlign: 'center', fontWeight: 600 }}
            >
              {$t('approvals.driver_day.no_invoices')}
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      invoice && (
        <InvoicesTabContent
          invoice={invoice}
          job={job}
          utils={{
            currencyFormatter,
            approveInvoice: async (invoice: Invoice) => {
              await approveInvoice(invoice);
            },
          }}
          isDayDetailsView={true}
          key={invoice.id}
          customCategory={currentCategory}
          reload={reload}
        />
      )
    );
  },
);
const JobDetailsContainer = ({ details }: Props) => {
  const { userStore } = useStores();
  const { isViewOnlyUser } = userStore;
  const { getJobById } = useJob();
  const [detailedJob, setDetailedJob] = useState<Job>(details);
  const [currentCategory, setCurrentCategory] = useState<InvoiceCategoryFilter>(
    InvoiceCategoryFilter.PAYABLES,
  );

  // Set detailedJob if the details passed in changes
  // We shouldn't need to store the state at this level, but see note below
  useEffect(() => {
    setDetailedJob(details);
  }, [details]);

  // Not sure what case we would have to reload the job details given that the parentshould
  // Be responsible for providing the updated details
  // However, will keep this here for now until there is more time to investigate
  useEffect(() => {
    if (!details.id) return;
    getJobById(details.id).then((job) => {
      setDetailedJob(job);
    });
  }, [details.id]);

  const tabs = useMemo(() => {
    const allTabs = [
      {
        label: $t('order.form.loads'),
        id: `loads_${details.id}`,
        content: (
          <LoadDetails
            details={detailedJob}
            reload={() => {
              getJobById(details.id).then((job) => {
                setDetailedJob(job);
              });
            }}
          />
        ),
        isVisible: true,
      },
      {
        label: $t('dispatch.job.details'),
        id: `details_${details.id}`,
        content: <JobDetails details={detailedJob} />,
        isVisible: true,
      },
      {
        label: $t('dispatch.job.payables'),
        id: `payables_${details.id}`,
        content: <JobInvoices job={details} currentCategory={currentCategory} />,
        onSelected: () => {
          setCurrentCategory(InvoiceCategoryFilter.PAYABLES);
        },
        isVisible: !isViewOnlyUser,
      },
      {
        label: $t('dispatch.job.receivables'),
        id: `receivables_${details.id}`,
        content: <JobInvoices job={details} currentCategory={currentCategory} />,
        onSelected: () => {
          setCurrentCategory(InvoiceCategoryFilter.RECEIVABLES);
        },
        isVisible: !isViewOnlyUser,
      },
    ];

    return allTabs.filter((tab) => tab.isVisible);
  }, [details.id, detailedJob, currentCategory, isViewOnlyUser]);

  return <SmallTabs sx={{ p: 2 }} tabs={tabs} />;
};

export { JobDetailsContainer };
