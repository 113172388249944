import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';

import { CheckBoxFormField } from '~components/FormFields/CheckBoxFormField';
import { SingleDateTimeFormField } from '~components/FormFields/SingleDateTimeFormField';
import { TextFormField } from '~components/FormFields/TextFormField';

export default function DateAndTime() {
  const form = useFormContext();

  const watchedJobStartSameAsOrderStart = form.watch('jobStartSameAsOrderStart');

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SingleDateTimeFormField
          control={form.control}
          dateLabel={`${t('order.form.start_date')}`}
          errors={form.formState.errors}
          isRequired
          name="loadAt"
          timeLabel={`${t('order.form.start_time')}`}
        />
      </Grid>

      <Grid item xs={6}>
        <CheckBoxFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('dispatch.order.order_details_v2.same_start_time')}`}
          name="jobStartSameAsOrderStart"
          value={true}
          sx={{ pt: '18px' }}
        />
      </Grid>

      <Grid item xs={6}>
        <SingleDateTimeFormField
          control={form.control}
          dateLabel={`${t('order.form.job_start_date')}`}
          disabled={watchedJobStartSameAsOrderStart}
          errors={form.formState.errors}
          isRequired={!watchedJobStartSameAsOrderStart}
          name="jobStartAt"
          timeLabel={`${t('order.form.job_start_time')}`}
        />
      </Grid>

      <Grid item xs={3}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('form_fields.truck_stagger')}`}
          name="truckDelay"
          type="number"
        />
      </Grid>

      <Grid item xs={3}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('form_fields.trucks_per_stagger')}`}
          name="trucksPerDelay"
          type="number"
        />
      </Grid>
    </Grid>
  );
}
