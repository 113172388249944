import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import { useState } from 'react';
import { ControllerProps, FieldErrors, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { PhoneCodeFlagInput } from '~components/FormFields/PhoneCodeFlagInput';
import { useLogin } from '~hooks/useLogin/useLogin';

import { signInWithPhoneNumberValidationSchema } from './validationSchemas';

interface SignInPhoneNumberFormProps {
  onOtpSent: (args: { phone: string; phoneId: string }) => void;
  phone: string;
}

interface SignInWithPhoneNumberDataProps
  extends yup.InferType<typeof signInWithPhoneNumberValidationSchema> {}

const SignInPhoneNumberForm = ({ onOtpSent, phone }: SignInPhoneNumberFormProps) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { sendOtpSms, isLoading } = useLogin();

  const onSubmit = (data: SignInWithPhoneNumberDataProps) => {
    sendOtpSms({ phone: data.phoneNumber }).then((response) => {
      onOtpSent({ phone: data.phoneNumber, phoneId: response.phone_id });
    });
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignInWithPhoneNumberDataProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(signInWithPhoneNumberValidationSchema),
    defaultValues: { phoneNumber: phone ?? '' },
  });

  return (
    <Box
      width="100%"
      component="form"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={1}
      onSubmit={handleSubmit(onSubmit)}
    >
      <PhoneCodeFlagInput
        control={control as unknown as ControllerProps['control']}
        errors={errors as unknown as FieldErrors}
        name="phoneNumber"
        label={`${t('form_fields.phone')}`}
      />

      <LoadingButton
        fullWidth
        color={isInputFocused ? 'primary' : 'inherit'}
        sx={isInputFocused ? undefined : { backgroundColor: '#E1E1E1' }}
        variant="contained"
        type="submit"
        loading={isLoading}
      >
        {t('actions.send_sms_login_code')}
      </LoadingButton>
    </Box>
  );
};

export default SignInPhoneNumberForm;
