import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { MapControlProps } from '../v2/MapV2';

interface MapConfigurationControlProps extends MapControlProps {
  position: google.maps.ControlPosition;
  onOptionsChange: (options: Record<string, boolean>) => void;
}

export enum MapConfigurationOptionKey {
  VENDOR_NAME = 'vendor_name',
}

const MARKER_OPTIONS = [
  {
    label: 'Show vendor name',
    key: MapConfigurationOptionKey.VENDOR_NAME,
    default: false,
  },
];
const MAP_CONFIGURATIONS_STORE_KEY = 'map_configurations';

const MapConfigurationControl = ({
  map,
  position,
  onOptionsChange,
}: MapConfigurationControlProps) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (map) {
      const controlContainer = document.createElement('div');
      setContainer(controlContainer);

      map.controls[position].push(controlContainer);

      return () => {
        const controls = map.controls[position];
        for (let i = 0; i < controls.getLength(); i++) {
          if (controls.getAt(i) === controlContainer) {
            controls.removeAt(i);
            break;
          }
        }
      };
    }
  }, [map, position]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openPopover: boolean = Boolean(anchorEl);
  const [selectedOptions, setSelectedOptions] = useState<Record<string, boolean>>({});

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleOptionChange = (option: string): void => {
    setSelectedOptions((prevSelected) => ({
      ...prevSelected,
      [option]: !prevSelected[option],
    }));
  };

  // Load the selected options from local storage
  useEffect(() => {
    const savedOptions = localStorage.getItem(MAP_CONFIGURATIONS_STORE_KEY);
    const parsedSavedOptions = savedOptions ? JSON.parse(savedOptions) : {};

    const missingOptions = MARKER_OPTIONS.filter(
      (option) => !Object.keys(parsedSavedOptions).includes(option.key),
    );

    setSelectedOptions({
      ...Object.fromEntries(missingOptions.map((opt) => [opt.key, opt.default])),
      ...parsedSavedOptions,
    });
  }, []);

  // Save the selected options to local storage
  useEffect(() => {
    localStorage.setItem(MAP_CONFIGURATIONS_STORE_KEY, JSON.stringify(selectedOptions));
    onOptionsChange(selectedOptions);
  }, [selectedOptions]);

  // Render the inline HTML into the container
  return container
    ? createPortal(
        <>
          {/* Button to Toggle Dropdown */}
          <Button
            onClick={handleToggle}
            sx={{
              margin: '10px',
              minWidth: 0,
              p: 1,
              height: '40px',
              width: '40px',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'white',
              },
              boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
              borderRadius: '0',
            }}
            color="inherit"
          >
            <SettingsIcon />
          </Button>

          {/* Dropdown Menu */}
          <Popover
            anchorEl={anchorEl}
            open={openPopover}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <Box sx={{ p: 1.5 }}>
              <Typography variant="body1" fontWeight="bold">
                {t('live_map.map_configuration')}
              </Typography>
              {MARKER_OPTIONS.map((option) => (
                <FormControlLabel
                  key={option.key}
                  control={
                    <Radio
                      checked={selectedOptions[option.key]}
                      onClick={() => handleOptionChange(option.key)}
                      color="default"
                    />
                  }
                  label={option.label}
                />
              ))}
            </Box>
          </Popover>
        </>,
        container,
      )
    : null;
};

export default MapConfigurationControl;
