import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { nullableStringOrNumberIsValidNumber } from '~utils/utilFunctions';

type CycleEstimateArgs = {
  dropoffOnsite: number;
  pickupOnsite: number;
  pickupToDropoff: number;
};

export default function useCycleEstimate() {
  const form = useFormContext();

  const watchedDropoffOnsite = form.watch('dropoffOnsite') as string | number | null;
  const watchedPickupOnsite = form.watch('pickupOnsite') as string | number | null;
  const watchedPickupToDropoff = form.watch('pickupToDropoff') as string | number | null;

  const cycleEstimateArgs: CycleEstimateArgs | null = useMemo(() => {
    const isValidPickupOnsite =
      nullableStringOrNumberIsValidNumber(watchedPickupOnsite) &&
      Number(watchedPickupOnsite) > 0;

    const isValidDropoffOnsite =
      nullableStringOrNumberIsValidNumber(watchedDropoffOnsite) &&
      Number(watchedDropoffOnsite) > 0;

    const isValidPickupToDropoff =
      nullableStringOrNumberIsValidNumber(watchedPickupToDropoff) &&
      Number(watchedPickupToDropoff) > 0;

    const canEstimate =
      isValidPickupOnsite && isValidDropoffOnsite && isValidPickupToDropoff;

    if (!canEstimate) {
      return null;
    }

    return {
      dropoffOnsite: Number(watchedPickupOnsite),
      pickupOnsite: Number(watchedDropoffOnsite),
      pickupToDropoff: Number(watchedPickupToDropoff),
    };
  }, [watchedPickupOnsite, watchedDropoffOnsite, watchedPickupToDropoff]);

  const estimateCycle = useCallback(() => {
    if (!cycleEstimateArgs) {
      return;
    }

    const { pickupOnsite, dropoffOnsite, pickupToDropoff } = cycleEstimateArgs;

    return pickupOnsite + dropoffOnsite + pickupToDropoff * 2;
  }, [JSON.stringify(cycleEstimateArgs)]);

  return { estimateCycle };
}
