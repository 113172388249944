import Box from '@mui/material/Box';
import { t } from 'i18next';
import { ReactNode, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_TAB, StatusTabValue } from '~components/Filters/OrdersStatusFilter';
import { CommonLoader } from '~components/Loaders/CommonLoader';
import Tabs, { TabProps } from '~components/Tabs/Tabs';
import { DispatchURLParams, OrdersDispatchStorageKeys } from '~constants/dispatch';
import DriversView from '~pages/Dispatch/components/drivers/DriversView';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

import CalendarView from './components/calendar/CalendarView';
import OrdersView from './components/OrdersView';

export enum DispatchView {
  ORDERS = 'orders',
  DRIVERS = 'drivers',
  CALENDAR = 'calendar',
}

const AVAILABLE_VIEWS: TabProps<DispatchView>[] = [
  { label: t('dispatch.dispatch_v2.orders'), value: DispatchView.ORDERS },
  { label: t('dispatch.dispatch_v2.calendar_tab'), value: DispatchView.CALENDAR },
  { label: t('dispatch.dispatch_v2.drivers'), value: DispatchView.DRIVERS },
];
const DEFAULT_VIEW = DispatchView.ORDERS;

const getInitialView = (searchParams: URLSearchParams) => {
  const viewParam = searchParams.get(DispatchURLParams.VIEW) as DispatchView;
  const storedView = localStorage.getItem(
    OrdersDispatchStorageKeys.VIEW_PARAM,
  ) as DispatchView;
  const view = viewParam || storedView;

  return Object.values(DispatchView).includes(view) ? view : DEFAULT_VIEW;
};

const getInitialStatusTab = (searchParams: URLSearchParams) => {
  const tabParam = searchParams.get(DispatchURLParams.TAB) as StatusTabValue;
  const storedTab = localStorage.getItem(
    OrdersDispatchStorageKeys.TAB_PARAM,
  ) as StatusTabValue;
  const tab = tabParam || storedTab;

  return Object.values(StatusTabValue).includes(tab) ? tab : DEFAULT_TAB;
};

function DispatchProvider() {
  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get(DispatchURLParams.VIEW) as DispatchView;
  const isValidView = Object.values(DispatchView).includes(view);

  const isLoading = !isValidView;

  const handleSelectedViewChange = useCallback(
    (view: DispatchView) => {
      setSearchParams((params) => {
        params.set(DispatchURLParams.VIEW, view);

        return params;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (isValidView) {
      localStorage.setItem(OrdersDispatchStorageKeys.VIEW_PARAM, view);
    }
  }, [isValidView, view]);

  useEffect(() => {
    setSearchParams((params) => {
      params.set(DispatchURLParams.VIEW, getInitialView(params));
      params.set(DispatchURLParams.TAB, getInitialStatusTab(params));

      return params;
    });
  }, [setSearchParams]);

  if (isLoading) {
    return <CommonLoader />;
  }

  return <Dispatch onSelectedViewChange={handleSelectedViewChange} view={view} />;
}

export interface IDispatchViewComponent {
  viewSelector: ReactNode;
}

interface DispatchProps {
  onSelectedViewChange: (view: DispatchView) => void;
  view: DispatchView;
}

const viewComponentByDispatchView: Record<
  DispatchView,
  (props: IDispatchViewComponent) => JSX.Element
> = {
  [DispatchView.ORDERS]: OrdersView,
  [DispatchView.DRIVERS]: DriversView,
  [DispatchView.CALENDAR]: CalendarView,
};

function Dispatch({ onSelectedViewChange, view }: DispatchProps) {
  const { userStore } = useStores();
  const View = viewComponentByDispatchView[view];
  const isViewOnlyUserViewingOrders =
    userStore.isViewOnlyUser && view === DispatchView.ORDERS;

  return (
    <Box
      sx={{
        bgcolor: theme.brandV2.colors.treadGray8,
        display: 'grid',
        gridAutoRows: 'auto auto 1fr',
        gridTemplateColumns: isViewOnlyUserViewingOrders ? '1fr' : 'auto 1fr',
        height: '100%',
        mx: -5,
        width: `calc(100% + ${theme.spacing(5 * 2)})`,
      }}
    >
      <View
        viewSelector={
          <Tabs onChange={onSelectedViewChange} selected={view} tabs={AVAILABLE_VIEWS} />
        }
      />
    </Box>
  );
}

export default DispatchProvider;
