import _ from 'lodash';
import * as yup from 'yup';

import { nameIdNonRequiredSchema, nameIdSchemaRequired } from '~constants/regexConst';
import { MaterialRate } from '~hooks/useMaterialRates/models';

export const materialRateSchema = yup.object().shape({
  customer: nameIdNonRequiredSchema.notRequired(),
  project: nameIdNonRequiredSchema.notRequired(),
  site: nameIdNonRequiredSchema.notRequired(),
  material: nameIdSchemaRequired.required(),
  unitOfMeasure: nameIdSchemaRequired.required(),
  buyPrice: yup
    .number()
    .transform((_, val) => (String(val).trim() !== '' ? Number(val) : undefined))
    .min(0)
    .typeError('Numbers only.')
    .notRequired(),
  sellPrice: yup.number().min(0.01).typeError('Numbers only.').required(),
});

export type MaterialRateDTO = yup.InferType<typeof materialRateSchema>;

export const setMaterialRatesSchemaDefaultValues = (materialRate?: MaterialRate) => {
  const defaultValues: MaterialRateDTO = {
    customer: {
      id: materialRate?.customerAccount?.id ?? '',
      name: materialRate?.customerAccount?.name ?? '',
    },
    project: {
      id: materialRate?.project?.id ?? '',
      name: materialRate?.project?.name ?? '',
    },
    site: { id: materialRate?.site?.id ?? '', name: materialRate?.site?.name ?? '' },
    material: {
      id: materialRate?.material.id ?? '',
      name: materialRate?.material.name ?? '',
    },
    unitOfMeasure: {
      id: materialRate?.unitOfMeasure ?? '',
      name: materialRate?.unitOfMeasure ?? '',
    },
    buyPrice: _.isNil(materialRate?.buyPrice)
      ? null
      : (materialRate?.buyPrice.toFixed(2) as unknown as number),
    sellPrice: _.isNil(materialRate?.sellPrice)
      ? ('' as unknown as number)
      : (materialRate?.sellPrice.toFixed(2) as unknown as number),
  };

  return defaultValues;
};
