import dayjs from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import * as yup from 'yup';

import {
  nameIdNonRequiredSchema,
  nameIdSchemaRequired,
  nameIdTypeSchema,
  notesSchema,
  personNameSchema,
  phoneSchema,
  waypointSchema,
  waypointSchemaOptionalSites,
} from '~constants/regexConst';
import { CompanyBasic } from '~hooks/useCompany';
import { Order } from '~hooks/useOrders';
import { Service } from '~hooks/useServices';
import { EmptyContactItem } from '~pages/Sales/Projects/projectFormSchema';
import { Nullable } from '~types/Nullable';

import { fileAttachmentSchema } from '../../../formsShared/components/FileAttachments';

const DEFAULT_JOB_DURATION_IN_HOURS = 10.5;
const DEFAULT_CYCLE_ONSITE_DURATION_IN_MINUTES = 20;
const MAX_TRUCK_COUNT = 200;

export const serviceIsStandingHaul = (service: Service | null) => {
  return service?.name === 'Standing Haul';
};
export const serviceIsHiredTruck = (service: Service | null) => {
  return service?.name === 'Hired Truck';
};
export const serviceIsMaterialDelivery = (service: Service | null) => {
  return service?.name === 'Material Delivery';
};

export const findHiredTruckServiceInServicesList = (list: Service[]) => {
  return list.find((service) => serviceIsHiredTruck(service));
};

export const getMaxTruckCount = (
  quantity: string | number | null | undefined,
  grossCapacity: string | number | null | undefined,
) => {
  const isEmptyQuantity = _.isNil(quantity) || !String(quantity).length;
  const isEmptyGrossCapacity = _.isNil(grossCapacity) || !String(grossCapacity).length;

  if (!isEmptyQuantity && !isEmptyGrossCapacity) {
    const result = Math.ceil(Number(quantity) / Number(grossCapacity));

    if (result > MAX_TRUCK_COUNT) {
      return MAX_TRUCK_COUNT;
    }

    if (result < 1) {
      return 1;
    }

    return result;
  }

  return MAX_TRUCK_COUNT;
};

export enum OrderSchemaVersion {
  V1 = 'v1',
  V2 = 'v2',
}

const emptyStringOrNumberNotRequiredSchema = yup.lazy((val) => {
  return String(val).trim() === ''
    ? yup.string().notRequired()
    : yup.number().notRequired();
});

export const newOrderFormSchema = yup.object().shape({
  _schemaVersion: yup
    .mixed<OrderSchemaVersion>()
    .oneOf(Object.values(OrderSchemaVersion)),
  id: yup.string(),
  company: yup.object().when('id', {
    // @ts-ignore
    is: '',
    then: () => {
      yup.object().shape({
        legalName: yup.string().required(),
        id: yup.string().required(),
      });
    },
    otherwise: () => {
      yup.object().notRequired();
    },
  }),
  jobStartAt: yup.string().required(),
  loadAt: yup.string().required(),
  loadAtTime: yup.string().nullable(),
  loadAtDate: yup.string().nullable(),
  orderId: yup.string(),
  notes: notesSchema,
  jobNotes: notesSchema,
  projectNotes: notesSchema,
  orderNotes: notesSchema,
  internalNotes: notesSchema,
  jobTime: yup.number().when('_schemaVersion', {
    is: OrderSchemaVersion.V2,
    then: () => {
      return yup.lazy((val) => {
        return String(val).trim() === ''
          ? yup.string().notRequired()
          : yup.number().min(1).max(16).notRequired();
      });
    },
    otherwise: () => emptyStringOrNumberNotRequiredSchema,
  }),
  truckCount: yup.number().when('id', {
    is: (id: string) => id,
    then: (schema) => {
      return schema.notRequired();
    },
    otherwise: (schema) => {
      return schema.when(
        ['_schemaVersion', 'quantity', 'grossCapacity'],
        ([_schemaVersion, quantity, grossCapacity], schema) => {
          if (_schemaVersion === OrderSchemaVersion.V2) {
            const max = getMaxTruckCount(quantity, grossCapacity);

            return schema.min(1).max(max).typeError('Numbers only.').required();
          }

          return schema.min(1).max(MAX_TRUCK_COUNT).typeError('Numbers only.').required();
        },
      );
    },
  }),
  truckDelay: yup.number().nullable(),
  trucksPerDelay: yup
    .number()
    .min(1, () => t('form_fields.trucks_per_delay_min'))
    .nullable()
    .test(
      'is-not-greater-than-truckCount',
      `${t('form_validation_errors.trucks_per_delay_greater_than_truck_count')}`,
      (value, context) => {
        if (_.isNil(value)) return true;
        if (!context.parent.truckCount) return true;
        return value <= context.parent.truckCount;
      },
    ),
  loadsPerTruck: yup
    .number()
    .nullable()
    .min(0)
    .transform((_, val) => (String(val).trim() !== '' ? Number(val) : null)),
  unitOfMeasure: yup.object().when('service', {
    is: (service: Service | null) => serviceIsHiredTruck(service),
    then: () => nameIdNonRequiredSchema.notRequired(),
    otherwise: () => nameIdSchemaRequired.required(),
  }),
  account: nameIdSchemaRequired.required(),
  project: nameIdNonRequiredSchema.nullable(),
  material: yup.object().when('service', {
    is: (service: Service | null) => serviceIsHiredTruck(service),
    then: () => nameIdNonRequiredSchema.notRequired(),
    otherwise: () => nameIdSchemaRequired.required(),
  }),
  equipmentType: yup.object().notRequired(),
  service: nameIdTypeSchema.required(),
  quantity: yup.number().when('service', {
    is: (service: Service | null) => {
      return serviceIsHiredTruck(service) || serviceIsStandingHaul(service);
    },
    then: (schema) => {
      return schema
        .nullable()
        .min(0)
        .transform((_, val) => (String(val).trim() !== '' ? Number(val) : null));
    },
    otherwise: (schema) => schema.min(0).required(),
  }),
  state: yup.string(),
  pickUpWayPoint: yup.object().when('service', {
    is: (service: Service | null) => serviceIsHiredTruck(service),
    then: () => waypointSchemaOptionalSites.notRequired(),
    otherwise: () => waypointSchema.required(),
  }),
  dropOffWayPoint: yup.object().when('service', {
    is: (service: Service | null) => serviceIsHiredTruck(service),
    then: () => waypointSchemaOptionalSites.notRequired(),
    otherwise: () => waypointSchema.required(),
  }),
  phase: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
      code: yup.string(),
    })
    .notRequired(),
  phaseName: yup.string().notRequired(),
  foremen: yup.array().of(yup.object().shape({ id: yup.string().required() }).required()),
  allContacts: yup.array().of(
    yup.object().shape({
      name: personNameSchema(`${t('form_fields.name')}`).notRequired(),
      phone: phoneSchema,
      type: nameIdNonRequiredSchema.when('name', {
        is: (name: string | null) => Boolean(name),
        then: () => nameIdSchemaRequired.required(),
      }),
      isDeleted: yup.boolean().notRequired(),
    }),
  ),
  projectMaterialTotals: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          material: nameIdNonRequiredSchema,
          unitOfMeasure: nameIdNonRequiredSchema,
          totalQuantity: yup.string().notRequired(),
          id: yup.string(),
        })
        .notRequired(),
    )
    .notRequired(),
  allPhaseCodes: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          id: yup.string(),
          name: yup.string(),
          code: yup.string(),
        })
        .notRequired(),
    )
    .notRequired(),
  department: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string(),
    })
    .notRequired(),
  name: yup.string().notRequired(),
  poJobNumber: yup.string().notRequired(),
  zone: yup.string().notRequired(),
  externalId: yup.string().notRequired(),
  serviceClass: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string(),
    })
    .notRequired(),
  grossCapacity: yup.string().notRequired(),
  unitsPerHour: yup.number().nullable(),
  jobQuantity: yup.number().when('service', {
    is: (service: Service | null) =>
      serviceIsStandingHaul(service) || serviceIsHiredTruck(service),
    then: (schema) => {
      return schema
        .nullable()
        .min(0)
        .transform((_, val) => (String(val).trim() !== '' ? Number(val) : null));
    },
    otherwise: (schema) => {
      return yup.lazy((val) => {
        const isEmpty = String(val).trim() === '' || _.isNil(val);

        if (isEmpty) {
          return yup
            .string()
            .notRequired()
            .transform(() => null);
        }

        return schema.min(0).required();
      });
    },
  }),
  dispatchNumber: yup.string().notRequired(),
  fileAttachments: fileAttachmentSchema,
  requester: yup.object().shape({ id: yup.string() }).notRequired(),
  estimatedDeliveredQuantity: yup.string().notRequired(),
  cycleTime: emptyStringOrNumberNotRequiredSchema,
  cycleDistance: emptyStringOrNumberNotRequiredSchema,
  pickupOnsite: emptyStringOrNumberNotRequiredSchema,
  dropoffOnsite: emptyStringOrNumberNotRequiredSchema,
  pickupToDropoff: emptyStringOrNumberNotRequiredSchema,
  totalLoads: emptyStringOrNumberNotRequiredSchema,
  recurringLoads: yup.boolean().notRequired(),
});

export const setDefaultOrderValues = (
  defaultOrder: Order,
  company?: Nullable<CompanyBasic>,
  defaultDate?: string,
  schemaVersion?: OrderSchemaVersion,
  orderFormStartTimeDefaultTo7amFeatureFlagEnabled?: boolean,
) => {
  const pickUpWayPoint = defaultOrder?.pickUpWayPoints?.[0];
  const dropOffWayPoint = defaultOrder?.dropOffWayPoints?.[0];
  const isSchemaV2 = schemaVersion === OrderSchemaVersion.V2;

  let loadAt =
    isSchemaV2 && !defaultOrder && defaultDate
      ? dayjs.tz(defaultDate).set('hours', dayjs().hour()).add(1, 'hours').startOf('hour')
      : defaultOrder?.loadAt || ('' as const);

  if (!defaultOrder && orderFormStartTimeDefaultTo7amFeatureFlagEnabled) {
    loadAt = (loadAt || dayjs.tz()).set('hours', 7).startOf('hour');
  }

  return {
    _schemaVersion: schemaVersion ?? OrderSchemaVersion.V1,
    id: defaultOrder?.id || '',
    company: defaultOrder?.company || company || null,
    jobStartAt: defaultOrder?.jobStartAt || '',
    loadAt: loadAt,
    loadAtDate: loadAt,
    loadAtTime: loadAt,
    notes: defaultOrder?.notes || '',
    jobNotes: defaultOrder?.jobNotes || '',
    projectNotes: '',
    orderNotes: defaultOrder?.notes || '',
    internalNotes: defaultOrder?.internalNotes || '',
    jobTime: isSchemaV2
      ? defaultOrder
        ? _.isNil(defaultOrder?.jobTime)
          ? null
          : Number(defaultOrder.jobTime / 60)
        : DEFAULT_JOB_DURATION_IN_HOURS
      : defaultOrder?.jobTime || null,
    jobQuantity: defaultOrder?.jobQuantity || '',
    state: defaultOrder?.state || '',
    truckCount: defaultOrder?.orderId
      ? defaultOrder?.orderSummary?.jobsCount ?? defaultOrder?.truckCount
      : 1,
    truckDelay: defaultOrder?.truckDelay || 0,
    trucksPerDelay: defaultOrder?.trucksPerDelay || 1,
    loadsPerTruck: isSchemaV2
      ? defaultOrder?.loadsPerTruck ?? null
      : defaultOrder?.loadsPerTruck || 1,
    unitOfMeasure: {
      name: defaultOrder?.unitOfMeasure?.name || '',
      id: defaultOrder?.unitOfMeasure?.id || '',
    },
    account: {
      name: defaultOrder?.account?.name || '',
      id: defaultOrder?.account?.id || '',
    },
    orderId: defaultOrder?.orderId || '',
    project: {
      name: defaultOrder?.project?.name || '',
      id: defaultOrder?.project?.id || '',
      externalId: defaultOrder?.project?.externalId || '',
    },
    projectExternalId: defaultOrder?.project?.externalId || null,
    material: defaultOrder?.material || null,
    equipmentType: defaultOrder?.equipmentType || null,
    service: defaultOrder?.service || null,
    quantity: defaultOrder?.quantity,
    pickUpWayPoint: pickUpWayPoint?.id
      ? {
          id: pickUpWayPoint?.id || '',
          type: pickUpWayPoint?.type || '',
          ordinality: pickUpWayPoint?.ordinality ?? 10,
          contact: {
            name: pickUpWayPoint?.contact?.name || '',
            phone: pickUpWayPoint?.contact?.phone || '',
          },
          site: {
            id: pickUpWayPoint?.site?.id || '',
            name: pickUpWayPoint?.site?.name || '',
          },
        }
      : null,
    dropOffWayPoint: dropOffWayPoint?.id
      ? {
          id: dropOffWayPoint?.id || '',
          type: dropOffWayPoint?.type || '',
          ordinality: dropOffWayPoint?.ordinality ?? 10,
          contact: {
            name: dropOffWayPoint?.contact?.name || '',
            phone: dropOffWayPoint?.contact?.phone || '',
          },
          site: {
            id: dropOffWayPoint?.site?.id || '',
            name: dropOffWayPoint?.site?.name || '',
          },
        }
      : null,
    allContacts: defaultOrder?.allContacts?.length
      ? defaultOrder.allContacts.map((single) => {
          return {
            id: single.id || undefined,
            name: single.name || '',
            phone: single.phone || '',
            type: single.type || undefined,
            isDeleted: false,
          };
        })
      : [EmptyContactItem],
    phase: {
      id: defaultOrder?.phase?.id || '',
      name: defaultOrder?.phase?.name || '',
      code: defaultOrder?.phase?.code || '',
    },
    phaseCode: defaultOrder?.phase?.code || '',
    phases: defaultOrder?.phases || [],
    department: defaultOrder?.department || {},
    foremen: defaultOrder?.foremen || [],
    name: defaultOrder?.name || '',
    poJobNumber: defaultOrder?.poJobNumber || '',
    zone: defaultOrder?.zone || '',
    externalId: defaultOrder?.externalId || '',
    serviceClass: defaultOrder?.serviceClass || {},
    grossCapacity: defaultOrder?.equipmentType?.grossCapacity || '',
    unitsPerHour: defaultOrder?.unitsPerHour || null,
    dispatchNumber: defaultOrder?.dispatchNumber || '',
    requester: defaultOrder?.requester || {},
    cycleTime: defaultOrder?.cycleTimeMinutes ?? null,
    cycleDistance: defaultOrder?.pickupToDropoffDistanceMeters ?? null,
    pickupOnsite:
      defaultOrder?.pickupOnsiteMinutes ?? DEFAULT_CYCLE_ONSITE_DURATION_IN_MINUTES,
    dropoffOnsite:
      defaultOrder?.dropoffOnsiteMinutes ?? DEFAULT_CYCLE_ONSITE_DURATION_IN_MINUTES,
    pickupToDropoff: defaultOrder?.pickupToDropoffTimeMinutes ?? null,
    totalLoads: defaultOrder?.expectedTotalLoads ?? null,
    estimatedDeliveredQuantity: null,
    recurringLoads: _.isNil(defaultOrder?.recurringLoads)
      ? false
      : defaultOrder.recurringLoads,
  };
};
export type NewOrderFormSchemaInterface = yup.InferType<typeof newOrderFormSchema>;

export function makeCustomerSpecificOrderFormSchema(
  tomlinsonOrderFormProjectFirstFeatureFlagEnabled: boolean,
) {
  let schema = newOrderFormSchema.clone();

  if (tomlinsonOrderFormProjectFirstFeatureFlagEnabled) {
    schema = schema.shape({ project: nameIdSchemaRequired.required('') });
  }

  return schema;
}
