import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import Send from '@mui/icons-material/Send';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { nonEditableJobStates } from '~pages/Dispatch/constants/jobStates';
import { useDriverSchedulerFetch } from '~pages/Dispatch/hooks/useDriverSchedulerFetch';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

import CreateOrderButton from '../CreateOrderButton';

const searchBarMaxWidthInPx = 180;

interface DriverSchedulerFiltersBarProps {
  isOrderFormDrawerMinimized?: boolean;
  onNewOrderClick: () => void;
}

export const DriverSchedulerFiltersBar = observer(
  ({ isOrderFormDrawerMinimized, onNewOrderClick }: DriverSchedulerFiltersBarProps) => {
    const { bulkSendJobs } = useDriverSchedulerFetch();
    const { driverSchedulerStore, userStore } = useStores();
    const handleBulkSendJobs = async () => {
      const sendableJobs = driverSchedulerStore.assignedJobs.filter(
        (job) =>
          job.driver &&
          job.driver.id &&
          job.status &&
          !nonEditableJobStates.includes(job.status),
      );

      await bulkSendJobs(sendableJobs.map((job) => job.id));
    };

    return (
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
        <Box display="flex" gap={1}>
          <SendAllJobsButton
            onClick={handleBulkSendJobs}
            disabled={userStore.isViewOnlyUser}
          />

          <CreateOrderButton
            isForResumeOrderCreation={isOrderFormDrawerMinimized}
            disabled={userStore.isViewOnlyUser}
            onClick={onNewOrderClick}
          />
        </Box>
      </Box>
    );
  },
);

interface SearchBarProps {
  isAlwaysExpanded?: boolean;
  sx?: SxProps;
}

export const SearchBar = observer(({ isAlwaysExpanded, sx }: SearchBarProps) => {
  const { driverSchedulerStore } = useStores();
  const inputRef = useRef<HTMLInputElement>();
  const [isExpanded, setIsExpanded] = useState(Boolean(isAlwaysExpanded));
  const [inputValue, setInputValue] = useState('');

  const inputHasValue = inputValue.length > 0;
  const applyFilter = _.debounce(() => {
    driverSchedulerStore.setAssignedJobsFilters({ search: inputValue }, true);
  }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  useEffect(() => {
    if (!isExpanded) {
      setInputValue('');
    }
  }, [isExpanded]);

  useEffect(() => {
    applyFilter();

    return () => {
      applyFilter.cancel();
    };
  }, [inputValue]);

  return (
    <Box sx={sx}>
      <Collapse
        collapsedSize={`calc(18px + (${theme.spacing(1)} * 2)`}
        in={isExpanded}
        orientation="horizontal"
        sx={{ '& .MuiCollapse-wrapperInner': { width: '100%' } }}
      >
        <Box
          border={`solid 1px ${theme.brandV2.colors.treadGray7}`}
          borderRadius={theme.brandV2.borderRadius}
          maxWidth={searchBarMaxWidthInPx}
          width="100%"
          onClick={() => {
            if (!isAlwaysExpanded) {
              setIsExpanded(true);
              inputRef.current?.focus();
            }
          }}
        >
          <OutlinedInput
            inputRef={inputRef}
            fullWidth
            onBlur={() => {
              if (!isAlwaysExpanded && !inputHasValue) {
                setIsExpanded(false);
              }
            }}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={`${t('dispatch.drivers.search')}`}
            size="small"
            value={inputValue}
            startAdornment={
              <InputAdornment position="start">
                <Search
                  sx={{
                    color: theme.brandV2.colors.treadBlack,
                    cursor: 'pointer',
                    fontSize: '16px',
                  }}
                />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end" onClick={() => setInputValue('')}>
                <Close
                  sx={{
                    color: theme.brandV2.colors.treadBlack,
                    cursor: 'pointer',
                    fontSize: '16px',
                    visibility: inputHasValue ? 'visible' : 'hidden',
                  }}
                />
              </InputAdornment>
            }
            sx={{
              '&.MuiInputBase-root': {
                height: `calc(${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px - 2px)`,
                px: 1,
                py: 0.5,
                '.MuiInputBase-input': {
                  fontSize: '12px',
                  p: 0,
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
              },
            }}
          />
        </Box>
      </Collapse>
    </Box>
  );
});

interface SendAllJobsButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

function SendAllJobsButton({ onClick, disabled = false }: SendAllJobsButtonProps) {
  return (
    <Box>
      <SmallButton
        onClick={onClick}
        disabled={disabled}
        startIcon={<Send />}
        sx={{
          '&.MuiButtonBase-root': {
            p: `0 ${theme.spacing(1)}`,
            backgroundColor: 'white',
            border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
            borderRadius: theme.brandV2.borderRadius,
            height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
          },
        }}
      >
        {t('dispatch.dispatch_v2.send_all_jobs')}
      </SmallButton>
    </Box>
  );
}
