import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { JobState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

import { Status as JobStatus } from '~components/Job/Status';
import { NextBillionAssetLocation } from '~hooks/useNextBillionAssetLocationHistories/models';

const emDash = '-';

function KeyValuePair({
  keyName,
  value,
}: {
  keyName: string;
  value: string | null | undefined;
}) {
  return (
    <>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        {keyName}
      </Typography>
      <Typography variant="body2">{value ?? emDash}</Typography>
    </>
  );
}

export const TruckHoverPopoverContent = observer(
  ({ truckLocation }: { truckLocation: NextBillionAssetLocation }) => {
    return (
      <Box
        sx={{
          p: 1,
          borderRadius: 1,
          display: 'grid',
          gridTemplateColumns: '2fr 3fr',
          gap: 0.5,
          maxWidth: '240px',
        }}
      >
        <Box sx={{ gridColumn: 'span 2' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
            <Typography
              noWrap
              variant="h6"
              sx={{ fontSize: '14px' }}
            >{`${truckLocation.equipment?.company_share?.external_id ?? truckLocation.equipment?.name}${truckLocation.driverName ? ` - ${truckLocation.driverName}` : ''}`}</Typography>
            {truckLocation.jobState && (
              <JobStatus status={truckLocation.jobState as JobState} />
            )}
          </Box>
          {truckLocation.vendorName && (
            <Typography
              sx={{ fontSize: '12px' }}
            >{`${truckLocation.vendorName}`}</Typography>
          )}
        </Box>
        <KeyValuePair
          keyName={t('live_map.truck_popover.license_plate')}
          value={truckLocation.equipment?.license_number}
        />
        <KeyValuePair
          keyName={t('live_map.truck_popover.phone')}
          value={truckLocation.driverPhoneNumber}
        />
        <KeyValuePair
          keyName={t('live_map.truck_popover.dispatch_number')}
          value={truckLocation.dispatchNumber}
        />
        <KeyValuePair
          keyName={t('live_map.truck_popover.gps_data')}
          value={truckLocation.createdAt.format('ddd DD [at] hh:mm A')}
        />
      </Box>
    );
  },
);
