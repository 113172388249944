import { DndContext, DragOverlay } from '@dnd-kit/core';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import PersonAddOutlined from '@mui/icons-material/PersonAddOutlined';
import SendOutlined from '@mui/icons-material/SendOutlined';
import SmsOutlined from '@mui/icons-material/SmsOutlined';
import { SxProps, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  OrderState,
  UserBulkAssignJobsChannel_Read,
  UserBulkCopyAssignmentsChannel_Read,
  UserBulkSendJobsChannel_Read,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';

import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import OrdersStatusFilter from '~components/Filters/OrdersStatusFilter';
import BulkEditJobDialog from '~components/Job/BulkEditJobDialog';
import {
  BulkAssignJobsDialog,
  BulkSendJobsDialog,
} from '~components/Job/JobsDataGridBulkActions';
import SendTextDialog from '~components/Job/SendTextDialog';
import { CommonLoader } from '~components/Loaders/CommonLoader';
import {
  LoadingSpinner,
  OrdersViewBulkActionsBarButton,
} from '~components/Order/ordersDispatchStyledComponents';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { FetchReason } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { Order, useOrdersDispatch } from '~hooks/useOrders';
import { useStores } from '~store';
import {
  Filters as OrdersDispatchStoreFilters,
  ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE,
} from '~store/OrdersDispatchStore';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import { useAwaitableFeatureFlag } from '~utils/hooks/useFeatureFlag';
import useInfiniteScroll from '~utils/hooks/useInfiniteScroll';
import { usePrevious } from '~utils/hooks/usePrevious';

import { IDispatchViewComponent } from '../Dispatch';
import BulkAssignMultipleJobsDialog from './BulkAssignMultipleJobsDialog';
import CopyVendorAssignmentsDialog from './CopyVendorAssignmentsDialog';
import CreateOrderButton from './CreateOrderButton';
import DriverPanelAssigneeCard from './DriverPanelAssigneeCard';
import { HEADER_HEIGHT, JOB_COLUMN_WIDTH } from './drivers/constants';
import OrdersDispatchFilters from './filters/OrdersDispatchFilters';
import OrderDispatchCard from './OrderDispatchCard';
import OrderForm from './OrderForm';
import { ExpandedStatesMenu, SearchBar } from './OrdersDispatchFiltersBar';
import OrdersViewBulkActionsBar, {
  useOrdersViewBulkActionsBar,
} from './OrdersViewBulkActionsBar';
import OrdersViewDriverPanel from './OrdersViewDriverPanel';
import RefreshOrdersListButton from './RefreshOrdersListButton';
import SharedDatesFilter from './SharedDatesFilter';

const infiniteScrollOptions: IntersectionObserverInit = {
  threshold: 0.1,
  rootMargin: '500px',
} as const;

const MODAL_DIALOG_ANIMATION_DURATION = 300;

enum OrderActionDialog {
  BULK_ASSIGN_MULTIPLE = 'bulk_assign_multiple',
  CANCEL_ORDER = 'cancel_order',
  CLONE_ORDER = 'clone_order',
  TEXT_ALL_DRIVERS = 'text_all_drivers',
}

type OrderActionDialogState = {
  dialog?: OrderActionDialog;
  isOpen: boolean;
  order?: Order;
};

type OrderFormState = {
  drawer: {
    isMinimized: boolean;
    isOpen: boolean;
    order?: Order;
  };
  confirmationDialog: {
    isOpen: boolean;
    order?: Order;
  };
};

const ordersFetchInitialState = {
  initialFetchComplete: false,
  reason: FetchReason.INITIAL_FETCH,
  shouldFetch: false,
};

export const ORDERS_DISPATCH_HEADER_ROW_HEIGHT_IN_PX = 35;
export const ORDER_DISPATCH_CARDS_CONTAINER_GUTTER_SIZE = 1.5;
const ORDER_DISPATCH_V3_GRID_ROW_HEIGHT_IN_PX = 47;
const LEFT_COLUMN_COLLAPSED_WIDTH_IN_PX = 42;
const FETCH_ORDERS_REQUIRED_FILTERS: Array<keyof OrdersDispatchStoreFilters> = [
  'orderStates',
  'search',
];

const areAllRequiredFiltersInPlace = (currentFilters: OrdersDispatchStoreFilters) => {
  const isSearching = Boolean(currentFilters.search?.length);

  if (isSearching) {
    return true;
  }

  return FETCH_ORDERS_REQUIRED_FILTERS.every((filter) => filter in currentFilters);
};

interface OrdersViewProviderProps extends IDispatchViewComponent {}

function OrdersViewProvider(props: OrdersViewProviderProps) {
  const nonRoutableAlertsFeatureFlag = useAwaitableFeatureFlag({
    featureFlagKey: FeatureFlags.nonRoutableAlerts,
  });
  const bulkAssignMultipleFeatureFlag = useAwaitableFeatureFlag({
    featureFlagKey: FeatureFlags.bulkAssignMultiple,
  });

  const isLoadingFeatureFlags =
    !nonRoutableAlertsFeatureFlag.isReady || !bulkAssignMultipleFeatureFlag.isReady;

  if (isLoadingFeatureFlags) {
    return <CommonLoader />;
  }

  return (
    <OrdersView
      {...props}
      nonRoutableAlertsFeatureFlagEnabled={
        nonRoutableAlertsFeatureFlag.isFeatureFlagEnabled
      }
      bulkAssignMultipleFeatureFlagEnabled={
        bulkAssignMultipleFeatureFlag.isFeatureFlagEnabled
      }
    />
  );
}

interface OrdersViewProps extends OrdersViewProviderProps {
  bulkAssignMultipleFeatureFlagEnabled?: boolean;
  nonRoutableAlertsFeatureFlagEnabled?: boolean;
}

const OrdersView = observer(
  ({
    bulkAssignMultipleFeatureFlagEnabled,
    nonRoutableAlertsFeatureFlagEnabled,
    viewSelector,
  }: OrdersViewProps) => {
    const infiniteScroll = useInfiniteScroll(infiniteScrollOptions);
    const { ordersDispatchStore, userStore, toasterStore } = useStores();
    const {
      acceptOrder,
      bulkAssignJobs,
      bulkCopyAssignments,
      bulkSendJobs,
      cancelOrder,
      cloneOrder,
      completeOrder,
      createJobFromOrder,
      getCompanyOrders,
      getCompanyOrdersByCompanyId,
      handleAssigneeDragCancel,
      handleAssigneeDragEnd,
      handleAssigneeDragStart,
      rejectOrder,
      subscribeToBulkAssignJobsRTU,
      subscribeToBulkCopyAssignmentsRTU,
      subscribeToBulkSendJobsRTU,
      subscribeToJobsRTU,
      subscribeToJobUnassignmentRTU,
      subscribeToOrdersRTU,
    } = useOrdersDispatch();
    const { isViewOnlyUser } = userStore;
    const [ordersFetchState, setOrdersFetchState] = useState({
      ...ordersFetchInitialState,
    });
    const [orderActionDialog, setOrderActionDialog] = useState<OrderActionDialogState>({
      isOpen: false,
    });

    const cloneOrderDialogRef = useRef<ModalDialogHandler>(null);
    const cancelOrderDialogRef = useRef<ModalDialogHandler>(null);
    const dismissCurrentOrderDialogRef = useRef<ModalDialogHandler>(null);

    const [orderFormState, setOrderFormState] = useState<OrderFormState>({
      drawer: { isMinimized: false, isOpen: false },
      confirmationDialog: { isOpen: false },
    });

    const bulkActions = useOrdersViewBulkActionsBar(ordersDispatchStore.orderJobs);
    const isBulkActionsBarVisible = bulkActions.jobsCount > 0;
    const hasChildCompanies = userStore.userCompanies.length > 1;

    const companyId = userStore.currentCompanies[0].id || userStore.userCompany?.id;
    const isReadyToFetchOrders = areAllRequiredFiltersInPlace(
      ordersDispatchStore.filters,
    );

    const currentFilters = JSON.stringify(
      ordersDispatchStore.filters,
      // this is to presever end & start dates when "All Dates" filters is selected, as
      // we want to keep track of the whole set of filters applied
      (_, value) => value ?? null,
    );
    const previousFilters = usePrevious(currentFilters);

    const openOrderActionDialog = useCallback(
      (dialog: OrderActionDialog, order?: Order) => {
        setOrderActionDialog((state) => ({ ...state, isOpen: true, dialog, order }));

        if (dialog === OrderActionDialog.CLONE_ORDER) {
          cloneOrderDialogRef.current?.open();
        } else if (dialog === OrderActionDialog.CANCEL_ORDER) {
          cancelOrderDialogRef.current?.open();
        }
      },
      [],
    );

    const closeOrderActionDialog = useCallback(() => {
      setOrderActionDialog((state) => ({ ...state, isOpen: false }));
      cloneOrderDialogRef.current?.close();
      cancelOrderDialogRef.current?.close();
      bulkActions.closeDialog('textAllDrivers');
    }, []);

    const dismissCurrentModalDialogAndOpenDrawerForOrder = useCallback((order: Order) => {
      setOrderFormState((state) => ({
        ...state,
        drawer: { isMinimized: false, isOpen: false },
      }));

      setTimeout(() => {
        setOrderFormState((state) => ({
          ...state,
          drawer: { isMinimized: false, isOpen: true, order },
        }));
      }, MODAL_DIALOG_ANIMATION_DURATION);
    }, []);

    const handleEditOrder = useCallback(
      (order: Order) => {
        if (orderFormState.drawer.isMinimized) {
          // drawer is for editing an order
          if (orderFormState.drawer.order?.id) {
            // same order -> open drawer right away
            if (orderFormState.drawer.order.id === order.id) {
              setOrderFormState((state) => ({
                ...state,
                drawer: {
                  ...state.drawer,
                  isMinimized: false,
                },
              }));
            }

            // different order -> ask for confirmation
            else {
              setOrderFormState((state) => ({
                ...state,
                confirmationDialog: { isOpen: true, order },
              }));
              dismissCurrentOrderDialogRef.current?.open();
            }
          }

          // drawer is for creating an order -> ask for confirmation
          else {
            setOrderFormState((state) => ({
              ...state,
              confirmationDialog: { isOpen: true, order },
            }));
            dismissCurrentOrderDialogRef.current?.open();
          }
        } else {
          setOrderFormState((state) => ({
            ...state,
            drawer: { isMinimized: false, isOpen: true, order },
          }));
        }
      },
      [orderFormState],
    );

    const handleAcceptOrder = useCallback(async (orderId: string) => {
      const acceptedOrder = await acceptOrder(orderId);

      toasterStore.push(
        alert(
          t('dispatch.order.updated', { name: acceptedOrder.orderId }),
          AlertTypes.success,
        ),
      );
    }, []);

    const handleAllCardsExpandedStateChange = useCallback((expandedState: string) => {
      ordersDispatchStore.setOrderCardsExpandedState({
        allCards: expandedState,
        cardsByOrder: new Map(),
      });
    }, []);

    const handleBulkAssignJobsUpdateReceived = useCallback(
      (data: UserBulkAssignJobsChannel_Read) => {
        const hasSuccessfulUpdates = (data.assigned_job_friendly_ids?.length ?? 0) > 0;
        const hasFailedUpdates = (data.failed_job_friendly_ids?.length ?? 0) > 0;
        const failedJobIds = data.failed_job_friendly_ids
          ?.map((jobId) => `TRD-${jobId}`)
          .join(', ');

        if (hasSuccessfulUpdates && hasFailedUpdates) {
          toasterStore.push(
            alert(
              t('dispatch.order.bulk_jobs_sent', {
                success_num: data.assigned_job_friendly_ids?.length,
                failed_num: data.failed_job_friendly_ids?.length,
                failed_ids: `${failedJobIds}`,
              }),
              AlertTypes.error,
            ),
          );
        } else if (hasFailedUpdates) {
          toasterStore.push(
            alert(
              t('dispatch.order.bulk_jobs_sent_all_updates_failed', {
                failed_num: data.failed_job_friendly_ids?.length,
                failed_ids: `${failedJobIds}`,
              }),
              AlertTypes.error,
            ),
          );
        } else {
          toasterStore.push(
            alert(
              t('dispatch.order.bulk_jobs_sent_all_updates_successful', {
                success_num: data.assigned_job_friendly_ids?.length,
              }),
              AlertTypes.success,
            ),
          );
        }
      },
      [],
    );

    const handleBulkCopyAssignmentsUpdateReceived = useCallback(
      (data: UserBulkCopyAssignmentsChannel_Read) => {
        toasterStore.push(
          alert(
            t('dispatch.order.bulk_jobs_sent', {
              success_num: data.copied_job_friendly_ids?.length,
              failed_num: data.failed_job_friendly_ids?.length,
              failed_ids: ` ${data.failed_job_friendly_ids?.map((v) => `TRD-${v}`).join(', ')}`,
            }),
            data.failed_job_friendly_ids?.length || 0 > 0
              ? AlertTypes.error
              : AlertTypes.success,
          ),
        );
      },
      [],
    );

    const handleBulkSendJobsUpdateReceived = useCallback(
      (data: UserBulkSendJobsChannel_Read) => {
        toasterStore.push(
          alert(
            t('dispatch.order.bulk_jobs_sent', {
              success_num: data.sent_job_friendly_ids?.length,
              failed_num: data.failed_job_friendly_ids?.length,
              failed_ids: ` ${data.failed_job_friendly_ids?.map((v) => `TRD-${v}`).join(', ')}`,
            }),
            data.failed_job_friendly_ids?.length || 0 > 0
              ? AlertTypes.error
              : AlertTypes.success,
          ),
        );
      },
      [],
    );

    const handleCancelOrder = useCallback(
      async (orderId: string) => {
        try {
          const cancelledOrder = await cancelOrder(orderId);

          if (orderFormState.drawer.order?.id === orderId) {
            setOrderFormState((state) => ({
              ...state,
              drawer: { isMinimized: false, isOpen: false },
            }));
          }

          toasterStore.push(
            alert(
              t('dispatch.order.canceled', { name: cancelledOrder.orderId }),
              AlertTypes.success,
            ),
          );
        } finally {
          closeOrderActionDialog();
        }
      },
      [closeOrderActionDialog, orderFormState.drawer.order?.id],
    );

    const handleCloneOrder = useCallback(
      async (orderId: string, includeAssignees: boolean) => {
        let clonedOrder: Order | null = null;

        try {
          clonedOrder = await cloneOrder(orderId, includeAssignees);

          toasterStore.push(
            alert(
              t('dispatch.order.copied', { name: clonedOrder.orderId }),
              AlertTypes.success,
            ),
          );
        } finally {
          closeOrderActionDialog();

          if (clonedOrder?.id) {
            dismissCurrentModalDialogAndOpenDrawerForOrder(clonedOrder);
          }
        }
      },
      [
        closeOrderActionDialog,
        openOrderActionDialog,
        dismissCurrentModalDialogAndOpenDrawerForOrder,
      ],
    );

    const handleCompleteOrder = useCallback(async (orderId: string) => {
      const completedOrder = await completeOrder(orderId);

      toasterStore.push(
        alert(
          t('dispatch.order.completed', { name: completedOrder.orderId }),
          AlertTypes.success,
        ),
      );
    }, []);

    const handleCreateJobFromOrder = useCallback(async (orderId: string) => {
      const createdJob = await createJobFromOrder(orderId);

      toasterStore.push(
        alert(
          t('dispatch.order.added_job', { name: createdJob.jobId }),
          AlertTypes.success,
        ),
      );
    }, []);

    const handleRefreshOrdersClick = useCallback(() => {
      setOrdersFetchState((state) => ({
        ...state,
        shouldFetch: true,
        reason: FetchReason.REFRESH,
      }));
    }, []);

    const handleRejectOrder = useCallback(async (orderId: string) => {
      const rejectedOrder = await rejectOrder(orderId);

      toasterStore.push(
        alert(
          t('dispatch.order.updated', { name: rejectedOrder.orderId }),
          AlertTypes.success,
        ),
      );
    }, []);

    const fetchOrders = () => {
      if (ordersFetchState.reason === FetchReason.FILTERS_CHANGED) {
        handleAllCardsExpandedStateChange(ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE);
      }

      if (ordersDispatchStore.deepLinkedOrder) {
        return;
      }

      if (userStore.isCurrentCompanyActive) {
        getCompanyOrders().then(() => {
          infiniteScroll.notify();

          setOrdersFetchState((state) => ({
            ...state,
            shouldFetch: false,
            initialFetchComplete: true,
          }));
        });
      } else {
        getCompanyOrdersByCompanyId({ companyId }).then(() => {
          infiniteScroll.notify();

          setOrdersFetchState((state) => ({
            ...state,
            shouldFetch: false,
            initialFetchComplete: true,
          }));
        });
      }
    };

    useEffect(() => {
      let ordersRTUSubscription: ReturnType<typeof subscribeToOrdersRTU> | null = null;
      let jobsRTUSubscription: ReturnType<typeof subscribeToJobsRTU> | null = null;
      let bulkSendJobsRTUSubscription: ReturnType<
        typeof subscribeToBulkSendJobsRTU
      > | null = null;
      let bulkAssignJobsRTUSubscription: ReturnType<
        typeof subscribeToBulkAssignJobsRTU
      > | null = null;
      let bulkCopyAssignmentsRTUSubscription: ReturnType<
        typeof subscribeToBulkCopyAssignmentsRTU
      > | null = null;
      let jobUnassignmentRTUSubscription: ReturnType<
        typeof subscribeToJobUnassignmentRTU
      > | null = null;

      if (companyId) {
        ordersRTUSubscription = subscribeToOrdersRTU(companyId);
        jobsRTUSubscription = subscribeToJobsRTU(companyId);
        bulkSendJobsRTUSubscription = subscribeToBulkSendJobsRTU(
          companyId,
          handleBulkSendJobsUpdateReceived,
        );
        bulkAssignJobsRTUSubscription = subscribeToBulkAssignJobsRTU(
          companyId,
          handleBulkAssignJobsUpdateReceived,
        );
        bulkCopyAssignmentsRTUSubscription = subscribeToBulkCopyAssignmentsRTU(
          companyId,
          handleBulkCopyAssignmentsUpdateReceived,
        );
        jobUnassignmentRTUSubscription = subscribeToJobUnassignmentRTU(companyId);
      }

      return () => {
        ordersRTUSubscription?.unsubscribe?.();
        jobsRTUSubscription?.unsubscribe?.();
        bulkSendJobsRTUSubscription?.unsubscribe?.();
        bulkAssignJobsRTUSubscription?.unsubscribe?.();
        bulkCopyAssignmentsRTUSubscription?.unsubscribe?.();
        jobUnassignmentRTUSubscription?.unsubscribe?.();
      };
    }, [companyId]);

    useEffect(() => {
      if (companyId) {
        setOrdersFetchState({ ...ordersFetchInitialState, shouldFetch: true });
      }
    }, [companyId]);

    useEffect(() => {
      if (ordersFetchState.shouldFetch) {
        if (
          [FetchReason.FILTERS_CHANGED, FetchReason.REFRESH].includes(
            ordersFetchState.reason,
          )
        ) {
          bulkActions.clearAllCheckedOrdersAndJobs();
        }

        fetchOrders();
      }
    }, [ordersFetchState.shouldFetch, ordersFetchState.reason]);

    useEffect(() => {
      if (!isReadyToFetchOrders) {
        return;
      }

      setOrdersFetchState((state) => {
        if (!state.initialFetchComplete) {
          return { ...state, shouldFetch: true, reason: FetchReason.INITIAL_FETCH };
        }

        if (infiniteScroll.endReached && ordersDispatchStore.hasMore) {
          return { ...state, shouldFetch: true, reason: FetchReason.INFINITE_SCROLL };
        }

        if (currentFilters !== previousFilters) {
          return { ...state, shouldFetch: true, reason: FetchReason.FILTERS_CHANGED };
        }

        return { ...state, shouldFetch: false };
      });
    }, [
      isReadyToFetchOrders,
      currentFilters,
      infiniteScroll.endReached,
      ordersDispatchStore.hasMore,
      previousFilters,
    ]);

    useEffect(() => {
      return () => {
        ordersDispatchStore.setDeepLinkedOrder();
        ordersDispatchStore.setFilters({ search: '' }, true);
      };
    }, []);

    const isSidebarCollapsed = ordersDispatchStore.isSidebarCollapsed;
    const isSearching = Boolean(ordersDispatchStore.filters.search?.length);

    const driversColumnSx: SxProps = {
      '&.MuiBox-root': {
        borderRight: `solid 1px ${theme.brandV2.colors.treadGray7}`,
        width: `${isSidebarCollapsed ? LEFT_COLUMN_COLLAPSED_WIDTH_IN_PX : JOB_COLUMN_WIDTH}px`,
      },
    };

    const isLargeViewport = useMediaQuery(theme.breakpoints.up('md'));

    // derive sidebar collapsed state from viewport size on first render
    useEffect(() => {
      if (_.isNil(isSidebarCollapsed)) {
        ordersDispatchStore.toggleSidebar(!isLargeViewport);
      }
    }, [isSidebarCollapsed, isLargeViewport]);

    return (
      <>
        <DndContext
          onDragCancel={handleAssigneeDragCancel}
          onDragEnd={handleAssigneeDragEnd}
          onDragStart={handleAssigneeDragStart}
        >
          {!isViewOnlyUser && (
            <Box
              sx={{
                gridRow: 'span 3',
                ...driversColumnSx,
              }}
            >
              <OrdersViewDriverPanel />
            </Box>
          )}

          <Box
            sx={{
              alignItems: 'center',
              bgcolor: 'white',
              borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
              display: 'flex',
              justifyContent: 'space-between',
              px: 2,
              py: 1,
            }}
          >
            {viewSelector}

            <Box display="flex" gap={1} ml="auto">
              <ExpandedStatesMenu
                expandedState={ordersDispatchStore.orderCardsExpandedState.allCards}
                onExpandedStateChange={handleAllCardsExpandedStateChange}
              />

              <CreateOrderButton
                isForResumeOrderCreation={orderFormState.drawer.isMinimized}
                disabled={userStore.isViewOnlyUser}
                onClick={() => {
                  if (orderFormState.drawer.isMinimized) {
                    setOrderFormState((state) => ({
                      ...state,
                      drawer: {
                        ...state.drawer,
                        isMinimized: false,
                      },
                    }));
                  } else {
                    setOrderFormState((state) => ({
                      ...state,
                      drawer: { isMinimized: false, isOpen: true },
                    }));
                  }
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              bgcolor: 'white',
              borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
              display: 'flex',
              gap: 2,
              px: 2,
              py: 1,
            }}
          >
            <SharedDatesFilter
              dateRangePickerMode="icon"
              dateRangeUseTodayFormat
              view="orders"
            />

            <RefreshOrdersListButton
              disabled={ordersDispatchStore.isLoadingOrders}
              onClick={handleRefreshOrdersClick}
              sx={{ ml: 'auto' }}
            />
          </Box>

          <Box>
            <Box
              sx={{
                alignItems: 'center',
                bgcolor: 'white',
                borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                display: 'flex',
                gap: 1,
                px: 2,
                py: 1,
              }}
            >
              {isSearching ? null : <OrdersStatusFilter />}

              <SearchBar isAlwaysExpanded />

              {isSearching ? null : <OrdersDispatchFilters />}
            </Box>

            <Box
              sx={{
                height: `calc(100vh - ${HEADER_HEIGHT}px - ${ORDER_DISPATCH_V3_GRID_ROW_HEIGHT_IN_PX}px * 2 - 2px)`,
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  columnGap: ORDER_DISPATCH_CARDS_CONTAINER_GUTTER_SIZE,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  overflow: 'auto',
                  p: ORDER_DISPATCH_CARDS_CONTAINER_GUTTER_SIZE,
                  rowGap: 2,
                }}
              >
                <LoadingSpinner
                  isVisible={
                    ordersDispatchStore.orders.length === 0 &&
                    ordersDispatchStore.isLoadingOrders
                  }
                />

                {ordersDispatchStore.orders.map((order) => (
                  <OrderDispatchCard
                    key={order.id}
                    order={order}
                    companyHasChildCompanies={hasChildCompanies}
                    expandedState={
                      ordersDispatchStore.orderCardsExpandedState.cardsByOrder.get(
                        order.id,
                      ) ?? ordersDispatchStore.orderCardsExpandedState.allCards
                    }
                    checkedJobs={bulkActions.checkedOrdersAndJobs[order.id]}
                    isChecked={order.id in bulkActions.checkedOrdersAndJobs}
                    nonRoutableAlertsFeatureFlagEnabled={
                      nonRoutableAlertsFeatureFlagEnabled
                    }
                    onBulkAssignMultipleClick={
                      bulkAssignMultipleFeatureFlagEnabled
                        ? () => {
                            openOrderActionDialog(
                              OrderActionDialog.BULK_ASSIGN_MULTIPLE,
                              order,
                            );
                          }
                        : undefined
                    }
                    onAcceptOrder={() => handleAcceptOrder(order.id)}
                    onCheckedStateChange={bulkActions.updateCheckedOrdersAndJobs}
                    onCloneOrderClick={() => {
                      openOrderActionDialog(OrderActionDialog.CLONE_ORDER, order);
                    }}
                    onCreateJobFromOrderClick={() => handleCreateJobFromOrder(order.id)}
                    onEditClick={() => handleEditOrder(order)}
                    onExpandedStateChange={
                      ordersDispatchStore.toggleOrderCardExpandedState
                    }
                    onOrderStateChange={(nextOrderState) => {
                      if (nextOrderState === OrderState.CANCELED) {
                        openOrderActionDialog(OrderActionDialog.CANCEL_ORDER, order);
                      } else if (nextOrderState === OrderState.COMPLETED) {
                        handleCompleteOrder(order.id);
                      }

                      return Promise.resolve();
                    }}
                    onRejectOrder={() => handleRejectOrder(order.id)}
                    onTextAllDriversClick={() => {
                      openOrderActionDialog(OrderActionDialog.TEXT_ALL_DRIVERS, order);
                    }}
                  />
                ))}

                {ordersDispatchStore.hasMore ? (
                  <Box ref={infiniteScroll.nodeRef}>
                    <LoadingSpinner isVisible={ordersDispatchStore.isLoadingOrders} />
                  </Box>
                ) : ordersDispatchStore.isLoadingOrders ? null : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    position="relative"
                    sx={{
                      '&::before': {
                        backgroundColor: theme.brandV2.colors.treadGray6,
                        content: "''",
                        display: 'block',
                        height: '1px',
                        left: 0,
                        position: 'absolute',
                        right: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                      },
                    }}
                  >
                    <Typography
                      bgcolor={theme.brandV2.colors.treadGray8}
                      color={theme.brandV2.colors.treadGray6}
                      fontSize="12px"
                      fontWeight="700"
                      position="relative"
                      px={1}
                      textAlign="center"
                      textTransform="uppercase"
                    >
                      {ordersDispatchStore.orders.length === 0
                        ? t('common.no_data')
                        : t('dispatch.dispatch_v2.all_orders_loaded')}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <DragOverlay>
            {ordersDispatchStore.draggedAssignee ? (
              <DriverPanelAssigneeCard
                assignee={ordersDispatchStore.draggedAssignee}
                placement="dragging"
              />
            ) : null}
          </DragOverlay>
        </DndContext>

        {isBulkActionsBarVisible && (
          <OrdersViewBulkActionsBar
            jobsCount={bulkActions.jobsCount}
            onClearAll={bulkActions.clearAllCheckedOrdersAndJobs}
          >
            <OrdersViewBulkActionsBarButton
              color="inherit"
              onClick={() => bulkActions.openDialog('copyVendorAssignments')}
              startIcon={<ContentCopyOutlined />}
            >
              {t('actions.copy_to')}
            </OrdersViewBulkActionsBarButton>

            <OrdersViewBulkActionsBarButton
              color="inherit"
              onClick={() => bulkActions.openDialog('editJobNotes')}
              startIcon={<EditOutlined />}
            >
              {t('dispatch.order.edit')}
            </OrdersViewBulkActionsBarButton>

            <OrdersViewBulkActionsBarButton
              color="inherit"
              disabled={bulkActions.jobsWithTextableDrivers.length === 0}
              onClick={() => bulkActions.openDialog('textAllDrivers')}
              startIcon={<SmsOutlined />}
            >
              {t('actions.text')}
            </OrdersViewBulkActionsBarButton>

            <BasicTooltip title={t('dispatch.order.assign_to_vendor_tooltip')}>
              <Box sx={{ display: 'inline-block' }}>
                <OrdersViewBulkActionsBarButton
                  color="inherit"
                  disabled={bulkActions.assignableJobs.length === 0}
                  onClick={() => bulkActions.openDialog('assign')}
                  startIcon={<PersonAddOutlined />}
                >
                  {t('dispatch.order.assign_to_vendor')}
                </OrdersViewBulkActionsBarButton>
              </Box>
            </BasicTooltip>

            <OrdersViewBulkActionsBarButton
              color="brandV2Green"
              disabled={bulkActions.sendableJobs.length === 0}
              onClick={() => bulkActions.openDialog('send')}
              startIcon={<SendOutlined />}
            >
              {t('actions.send')}
            </OrdersViewBulkActionsBarButton>
          </OrdersViewBulkActionsBar>
        )}

        <OrderForm
          defaultDate={ordersDispatchStore.filters.startDate}
          isMinimized={orderFormState.drawer.isMinimized}
          isOpen={orderFormState.drawer.isOpen}
          onClose={() => {
            setOrderFormState(() => ({
              drawer: { isMinimized: false, isOpen: false },
              confirmationDialog: { isOpen: false },
            }));
          }}
          onMinimize={() => {
            setOrderFormState((state) => ({
              ...state,
              drawer: {
                ...state.drawer,
                isMinimized: true,
              },
            }));
          }}
          order={orderFormState.drawer.order}
        />

        <ModalDialog
          callBack={() => {
            dismissCurrentOrderDialogRef.current?.close();

            if (orderFormState.confirmationDialog.order) {
              dismissCurrentModalDialogAndOpenDrawerForOrder(
                orderFormState.confirmationDialog.order,
              );
            }
          }}
          confirmButtonColor="primary"
          confirmButtonText={`${t('actions.confirm')}`}
          content={t('dispatch.dispatch_v2.edit_confirmation_dialog_text', {
            action: orderFormState.drawer.order?.id
              ? t('common.edited').toLowerCase()
              : t('common.created').toLowerCase(),
            orderId: orderFormState.confirmationDialog.order?.orderId,
          })}
          ref={dismissCurrentOrderDialogRef}
          title={t('dispatch.order.edit_order')}
          onClose={() => {
            setOrderFormState((state) => ({
              ...state,
              confirmationDialog: {
                ...state.confirmationDialog,
                isOpen: false,
              },
            }));
          }}
        />

        <BulkSendJobsDialog
          isOpen={bulkActions.dialogsState.send.isOpen}
          onClose={() => bulkActions.closeDialog('send')}
          onConfirm={() => {
            if (bulkActions.sendableJobs.length) {
              bulkSendJobs(bulkActions.sendableJobs, []);
            }

            bulkActions.closeDialog('send');
          }}
        />

        <BulkAssignJobsDialog
          accountId={bulkActions.dialogsState.assign.selectedVendorAccountId ?? null}
          companyId={companyId}
          isOpen={bulkActions.dialogsState.assign.isOpen}
          onClose={() => bulkActions.closeDialog('assign')}
          onConfirm={() => {
            const { assignableJobs, dialogsState } = bulkActions;
            const { selectedVendorAccountId } = dialogsState.assign;

            if (selectedVendorAccountId && assignableJobs.length) {
              bulkAssignJobs(assignableJobs, selectedVendorAccountId);
            }

            bulkActions.closeDialog('assign');
          }}
          onSelectedAccountChange={bulkActions.updateBulkAssignJobsSelectedAccount}
        />

        <BulkAssignMultipleJobsDialog
          isOpen={
            orderActionDialog.isOpen &&
            orderActionDialog.dialog === OrderActionDialog.BULK_ASSIGN_MULTIPLE
          }
          onClose={closeOrderActionDialog}
          onConfirm={closeOrderActionDialog}
          order={orderActionDialog.order}
        />

        <BulkEditJobDialog
          isOpen={bulkActions.dialogsState.editJobNotes.isOpen}
          jobIds={bulkActions.jobsWithEditableNotes.jobIds}
          onClose={() => bulkActions.closeDialog('editJobNotes')}
          defaultValues={{
            notes: bulkActions.jobsWithEditableNotes.defaultNotes ?? undefined,
          }}
        />

        <CopyVendorAssignmentsDialog
          companyId={companyId}
          isOpen={bulkActions.dialogsState.copyVendorAssignments.isOpen}
          orderId={bulkActions.dialogsState.copyVendorAssignments.selectedOrderId}
          onClose={() => bulkActions.closeDialog('copyVendorAssignments')}
          onConfirm={() => {
            const { copyableJobs, dialogsState } = bulkActions;
            const { selectedOrderId } = dialogsState.copyVendorAssignments;

            if (selectedOrderId && copyableJobs.length) {
              bulkCopyAssignments(copyableJobs, selectedOrderId);
            }

            bulkActions.closeDialog('copyVendorAssignments');
          }}
          onSelectedOrderChange={bulkActions.updateBulkCopyAssignmentsSelectedOrder}
        />

        <ModalDialog
          callBack={() => handleCloneOrder(`${orderActionDialog.order?.id}`, true)}
          cancelButtonText={`${t('dispatch.order.copy_without_assignments')}`}
          confirmButtonColor="primary"
          confirmButtonText={`${t('dispatch.order.copy_with_assignments')}`}
          loading={ordersDispatchStore.isCloningOrder}
          onCancel={() => handleCloneOrder(`${orderActionDialog.order?.id}`, false)}
          ref={cloneOrderDialogRef}
          content={t('dispatch.order.clone_description')}
          title={t('dispatch.order.clone_order')}
        />

        <SendTextDialog
          isOpen={
            bulkActions.dialogsState.textAllDrivers.isOpen ||
            (orderActionDialog.isOpen &&
              orderActionDialog.dialog === OrderActionDialog.TEXT_ALL_DRIVERS)
          }
          onClose={closeOrderActionDialog}
          mode={
            bulkActions.dialogsState.textAllDrivers.isOpen
              ? 'bulk_drivers'
              : 'order_drivers'
          }
          source={
            bulkActions.dialogsState.textAllDrivers.isOpen
              ? bulkActions.jobsWithTextableDrivers
              : orderActionDialog.order
          }
        />

        <ModalDialog
          callBack={() => handleCancelOrder(`${orderActionDialog.order?.id}`)}
          confirmButtonColor="error"
          confirmButtonText={`${t('actions.confirm')}`}
          loading={ordersDispatchStore.isCancellingOrder}
          ref={cancelOrderDialogRef}
          content={t('dispatch.order.cancel_description')}
          title={t('dispatch.order.cancel_order')}
        />
      </>
    );
  },
);

export default OrdersViewProvider;
