import SubtitlesOff from '@mui/icons-material/SubtitlesOff';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { InvoiceCategoryFilter } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';

import InvoicesTabContent from '~components/Invoice/InvoicesTabContent';
import { SmallTabs } from '~components/Tabs/SmallTabs';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';
import { Invoice, useInvoices } from '~hooks/useInvoices';
import { Job } from '~hooks/useJob/models';
import { useStores } from '~store/RootStore';
import theme from '~theme/AppTheme';

import ApprovedBySection from './components/ApprovedBySection';
import JobDetails, { JobDetailsTabs, JobHeader } from './components/JobDetails';
import { ShiftDetails } from './components/ShiftDetails';

export const DriverDayDetails = observer(({ driverDayId }: { driverDayId: string }) => {
  const [toggledJob, setToggledJob] = useState<Job | null>(null);
  const { driverDayStore } = useStores();
  const { fetchJobsForDriverDay, isLoadingDriverDayJobs } = useDriverDays();
  const { approveInvoice, getDriverDayPayables, getDriverDayReceivables } = useInvoices();
  const [currentCategory, setCurrentCategory] = useState<InvoiceCategoryFilter>(
    InvoiceCategoryFilter.PAYABLES,
  );
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const getInvoices = async () => {
    const fn =
      currentCategory === InvoiceCategoryFilter.PAYABLES
        ? getDriverDayPayables
        : getDriverDayReceivables;
    const invoices = await fn(driverDayId);
    if (invoices) {
      setInvoices(invoices);
    }
  };

  const jobIdsForDay = driverDayStore.jobIdsByDriverDayId[driverDayId];
  const jobsForDay = useMemo(
    () => jobIdsForDay?.map((jobId) => driverDayStore.jobByJobId[jobId]),
    [jobIdsForDay, driverDayStore.jobByJobId],
  );

  const isLoading =
    isLoadingDriverDayJobs || !jobsForDay || driverDayStore.isLoadingAddOnChargesByJob;

  const driverDay = driverDayStore.driverDays.find((dDay) => dDay.id === driverDayId);
  const { currencyFormatter } = useCompanyCurrency();

  const getInvoicesForJob = useCallback(
    (jobId: string) => {
      return invoices.filter((invoice) => invoice.job?.id === jobId);
    },
    [invoices],
  );
  useEffect(() => {
    getInvoices();
  }, [currentCategory]);

  const JobsTabContent = () => {
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '3fr 2fr',
          gap: 2,
        }}
      >
        <Box
          aria-label={`job list for ${driverDayId}`}
          component="ul"
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          {driverDay && (
            <ShiftDetails
              driverDay={driverDay}
              firstJobStartAt={jobsForDay[0].jobStartAt}
            />
          )}

          {jobsForDay.map((job) => (
            <JobDetails
              key={job.id}
              driverDayId={driverDayId}
              job={job}
              toggledJob={toggledJob}
              onToggleJob={setToggledJob}
              editable={driverDay?.editable || false}
            />
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <ApprovedBySection driverDayId={driverDayId} />

          {toggledJob && <JobDetailsTabs toggledJob={toggledJob} />}
        </Box>
      </Box>
    );
  };

  const DriverInvoices = () => {
    return jobsForDay.map((job, ind) => {
      const filteredInvoices = getInvoicesForJob(job.id);
      if (!filteredInvoices.length) {
        return (
          <Box
            display="flex"
            gap={2}
            key={job.id}
            sx={{
              width: '100%',
            }}
          >
            {' '}
            <Box
              key={job.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 2,
                width: '100%',

                gap: 1.5,

                backgroundColor: 'white',
                border: `1px solid ${theme.brandV2.colors.treadGray7}`,
                borderRadius: 2,
                pb: 1,
              }}
            >
              <JobHeader
                job={job}
                hideMapToggle
                toggledJob={toggledJob}
                onToggleJob={setToggledJob}
              />
              <SubtitlesOff style={{ marginInline: 'auto' }} />
              <Typography
                color={theme.brandV2.colors.treadBlack}
                variant="body1"
                sx={{ textAlign: 'center', fontWeight: 600 }}
              >
                {t('approvals.driver_day.no_invoices', {
                  category: currentCategory,
                })}
              </Typography>
            </Box>
          </Box>
        );
      }
      return filteredInvoices.map((invoice) => {
        return (
          <InvoicesTabContent
            invoice={invoice}
            job={job}
            utils={{
              currencyFormatter,
              approveInvoice: async (invoice: Invoice) => {
                await approveInvoice(invoice);
              },
            }}
            isDayDetailsView={true}
            customHeader={
              <JobHeader job={job} toggledJob={toggledJob} onToggleJob={setToggledJob} />
            }
            key={invoice.id}
            customCategory={currentCategory}
            reload={getInvoices}
          />
        );
      });
    });
  };
  const tabs = useMemo(() => {
    return [
      {
        id: 'jobs',
        headerClassName: 'jobs-tab',
        label: t('page_headings.driver_day_shift'),
        content: <JobsTabContent />,
      },
      {
        id: 'payables',
        headerClassName: 'jobs-tab',
        label: t('page_headings.driver_day_payables'),
        content: <DriverInvoices />,
        onSelected: () => {
          setCurrentCategory(InvoiceCategoryFilter.PAYABLES);
        },
      },
      {
        id: 'recievables',
        headerClassName: 'jobs-tab',
        label: t('page_headings.driver_day_receivables'),
        content: <DriverInvoices />,
        onSelected: () => {
          setCurrentCategory(InvoiceCategoryFilter.RECEIVABLES);
        },
      },
    ];
  }, [jobsForDay, toggledJob, driverDay, invoices]);

  useEffect(() => {
    fetchJobsForDriverDay(driverDayId);
  }, [driverDayId]);

  useEffect(() => {
    if (!toggledJob && jobsForDay && jobsForDay.length > 0) {
      setToggledJob(jobsForDay?.[0]);
    }
  }, [toggledJob, jobsForDay]);

  if (isLoading) {
    return <Skeleton height={300} />;
  }

  return (
    <Box sx={{ p: 1.5, pb: 2, borderTopWidth: '0px' }}>
      {jobIdsForDay.length ? (
        <SmallTabs
          tabs={tabs}
          sx={{
            background: 'transparent',
          }}
          tabPanelStyle={{
            backgroundColor: theme.brandV2.colors.treadGray8,
            p: 1.5,
            pb: 2,
          }}
          tabStyle={{
            '&.MuiTab-root': {
              borderRadius: '8px 8px 0 0',
              px: 1.5,
              width: 'fit-content',
              fontSize: '12px',
            },

            background: theme.brandV2.colors.treadGray7,
            border: `1px solid ${theme.brandV2.colors.treadGray7}`,
            color: theme.brandV2.colors.treadGray2,
            '&.Mui-selected': {
              color: theme.palette.primary.contrastText,
              background: theme.brandV2.colors.treadGray8,
              borderColor: theme.brandV2.colors.treadGray7,
            },
          }}
        />
      ) : null}
    </Box>
  );
});
