import { yupResolver } from '@hookform/resolvers/yup';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { GridColDef } from '@mui/x-data-grid-premium';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { Company, CompanyBasic } from '~hooks/useCompany';
import useManagedCompanies, {
  QUERY_PAGINATION_LIMIT,
} from '~hooks/useManagedCompanies/useManagedCompanies';
import { Pagination } from '~services/pagination';

import ManageAccountsForm from './ManageAccountsForm';

type ManagedCompaniesState = {
  companies: Company[];
  isFormOpen: boolean;
  isLoading: boolean;
  pagination?: Pagination;
};

const selectedCompanySchema = yup.object().shape({
  company: yup.mixed<CompanyBasic>().nullable(),
});
type SelectedCompanyDTO = yup.InferType<typeof selectedCompanySchema>;

export default function ManagedCompanies() {
  const { getManagedCompaniesByCompanyId, deleteManagedCompany } = useManagedCompanies();

  const [managedCompaniesState, setManagedCompaniesState] =
    useState<ManagedCompaniesState>({
      companies: [],
      isFormOpen: false,
      isLoading: false,
    });
  const [dataGridPagination, setDataGridPagination] = useState<Pagination>({
    limit: QUERY_PAGINATION_LIMIT,
    page: 1,
  });

  const loadingRef = useRef(managedCompaniesState.isLoading);
  loadingRef.current = managedCompaniesState.isLoading;

  const form = useForm<SelectedCompanyDTO>({
    resolver: yupResolver(selectedCompanySchema),
    mode: 'onSubmit',
    defaultValues: { company: null },
  });

  const companyId = form.watch('company')?.id ?? null;

  const fetchManagedCompanies = useCallback(async () => {
    if (!companyId) {
      return;
    }

    try {
      setManagedCompaniesState((state) => ({ ...state, isLoading: true }));

      const response = await getManagedCompaniesByCompanyId({
        companyId,
        pagination: dataGridPagination,
      });

      if (response) {
        setManagedCompaniesState({
          companies: response.data,
          isFormOpen: false,
          isLoading: false,
          pagination: response.pagination,
        });
      } else {
        throw new Error('Failed to fetch managed companies');
      }
    } catch {
      setManagedCompaniesState({
        companies: [],
        isFormOpen: false,
        isLoading: false,
      });
    }
  }, [companyId, dataGridPagination]);

  const handleManageAccountsClick = useCallback(() => {
    setManagedCompaniesState((state) => ({ ...state, isFormOpen: true }));
  }, []);

  const handleCloseManageAccountsForm = useCallback(
    (shouldRefetch?: true) => {
      setManagedCompaniesState((state) => ({ ...state, isFormOpen: false }));

      if (shouldRefetch) {
        fetchManagedCompanies();
      }
    },
    [fetchManagedCompanies],
  );

  const handleDeleteManagedCompanyClick = useCallback(
    (managedCompanyId: string) => {
      if (!companyId) {
        return;
      }

      deleteManagedCompany({ companyId, managedCompanyId }).then(() => {
        fetchManagedCompanies();
      });
    },
    [companyId, fetchManagedCompanies],
  );

  const columns = useMemo(() => {
    const columns: Array<GridColDef<{ id: string; data: Company }>> = [
      {
        flex: 1,
        field: 'companyName',
        headerName: `${t('administration.managed_companies.grid_columns.company_name')}`,
        valueGetter: (params) => params.row.data.legalName,
      },
      {
        width: 96,
        field: 'actions',
        disableColumnMenu: true,
        filterable: false,
        hideable: false,
        sortable: false,
        type: 'actions',
        renderHeader: () => {
          return (
            <HeaderNavigation
              count={managedCompaniesState.companies.length}
              loading={loadingRef.current}
              pagination={{
                ...dataGridPagination,
                before: managedCompaniesState.pagination?.before,
                after: managedCompaniesState.pagination?.after,
              }}
              callback={(link) => {
                if (!link) {
                  return;
                }

                if (link === 'after') {
                  setDataGridPagination((state) => ({
                    page: state.page + 1,
                    limit: QUERY_PAGINATION_LIMIT,
                    after: managedCompaniesState.pagination?.after,
                  }));
                } else {
                  setDataGridPagination((state) => ({
                    page: state.page - 1,
                    limit: QUERY_PAGINATION_LIMIT,
                    before: managedCompaniesState.pagination?.before,
                  }));
                }
              }}
              altText={`${t('actions.actions')}`}
              searchQuery={''}
            />
          );
        },
        renderCell: (params) => {
          return (
            <BasicTooltip
              title={t('administration.managed_companies.grid_actions.delete')}
            >
              <IconButton
                onClick={() => handleDeleteManagedCompanyClick(params.row.data.id)}
                size="small"
              >
                <Close />
              </IconButton>
            </BasicTooltip>
          );
        },
      },
    ];

    return columns;
  }, [
    companyId,
    dataGridPagination,
    handleDeleteManagedCompanyClick,
    managedCompaniesState.companies,
    managedCompaniesState.pagination,
  ]);

  const rows = useMemo(() => {
    return managedCompaniesState.companies.map((company) => ({
      id: company.id,
      data: company,
    }));
  }, [companyId, managedCompaniesState.companies]);

  useEffect(() => {
    fetchManagedCompanies();
  }, [companyId, fetchManagedCompanies]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <CompanySelectorFormField
          clearable
          control={form.control}
          errors={form.formState.errors}
          hideLabel
          inputProps={{ placeholder: `${t('form_fields.company_selector_hint')}` }}
          name="company"
          sx={{ width: '540px' }}
        />

        <DataGrid
          id="managed-companies-grid"
          key={`${managedCompaniesState.isLoading}-${companyId}`}
          columns={columns}
          disableColumnFilter
          headerActionsComponent={
            <>
              <Box display={'flex'}>
                <Button onClick={handleManageAccountsClick} disabled={!companyId}>
                  {t(
                    'administration.managed_companies.manage_accounts_form.open_form_cta',
                  )}
                </Button>
              </Box>
            </>
          }
          hideExport
          loading={managedCompaniesState.isLoading}
          rows={rows}
          sx={{
            "& .MuiDataGrid-cell[data-field='actions']": { px: '5px' },
            '& .MuiDataGrid-columnHeaderTitle': { fontSize: '10px' },
          }}
        />
      </Box>

      <ManageAccountsForm
        companyId={companyId ?? undefined}
        isOpen={managedCompaniesState.isFormOpen}
        managedCompanies={managedCompaniesState.companies}
        onClose={handleCloseManageAccountsForm}
      />
    </>
  );
}
