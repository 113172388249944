import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import Mail from '@mui/icons-material/Mail';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import Sms from '@mui/icons-material/Sms';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import {
  AuthMethod,
  ModelError_Item,
  ModelError_Response,
} from '@treadinc/horizon-api-spec';
import { AxiosError } from 'axios';
import { t as $t, t } from 'i18next';
import _ from 'lodash';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog } from '~components/Dialog/ModalDialog';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import {
  UserForm,
  UserFormHandler,
  UserFormStateChangeProps,
  UserValidationDTOWithSitesDiff,
} from '~components/UserForm/UserForm';
import UserNotificationsPreferenceForm, {
  UserNotificationsPreferenceDTO,
  UserNotificationsPreferenceHandler,
} from '~components/UserForm/UserNotificationsPreferenceForm';
import { FormStateChangeProps } from '~formsShared';
import { useCompaniesAdmin } from '~hooks/admin/useCompaniesAdmin/useCompaniesAdmin';
import { AdminUser } from '~hooks/admin/useUsersAdmin/models';
import { useUserRolesAdmin } from '~hooks/admin/useUsersAdmin/useUserRolesAdmin';
import { useUsersAdmin } from '~hooks/admin/useUsersAdmin/useUsersAdmin';
import { CompanyBasic } from '~hooks/useCompany';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { useResourceScopes } from '~hooks/useResourceScopes/useResourceScopes';
import { User, useUsers } from '~hooks/useUsers';
import { PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { Nullable } from '~types/Nullable';
import { dateFormat } from '~utils/dateTime';
import { isActionClicked } from '~utils/utilFunctions';

interface InvitesToSendState {
  email: { isDisabled: boolean; shouldSend: boolean };
  sms: { isDisabled: boolean; shouldSend: boolean };
}

interface DialogState {
  isOpen: boolean;
  availableAuthMethods: AuthMethod[];
}

const AdminUsersDataGrid = () => {
  const theme = useTheme();
  const { userStore } = useStores();
  const {
    control,
    formState: { errors },
    watch,
  } = useForm();

  const [asyncErrors, setAsyncErrors] = useState<ModelError_Item[]>([]);
  const [dialogState, setDialogState] = useState<DialogState>({
    isOpen: false,
    availableAuthMethods: [],
  });
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [userFormIsDirty, setUserFormIsDirty] = useState(false);
  const [
    userNotificationsPreferenceFormIsDirty,
    setUserNotificationsPreferenceFormIsDirty,
  ] = useState(false);

  const formIsDirty = userFormIsDirty || userNotificationsPreferenceFormIsDirty;

  const selectedCompany: Nullable<CompanyBasic> =
    watch('users-companies-selector') || userStore.userCompany;
  const [invitesToSend, setInvitesToSend] = useState<InvitesToSendState>();

  const { companyAdminStore, userAdminStore, toasterStore } = useStores();
  const {
    getCompanyUsers,
    deleteUser,
    impersonateUser,
    updateUser,
    createUser,
    revokeAllUserSessions,
    isLoadingAllUsers,
    isUpdating,
  } = useUsersAdmin();
  const { getCompanyById } = useCompaniesAdmin();
  const { getUserRoles } = useUserRolesAdmin();
  const { sendInvitation } = useUsers();
  const { saveUserSitesDiff } = useResourceScopes();
  //Move outside, as it might be some different company list, (aka pernmisssions based
  // @VENI bewtter pass it from above as prop move to parent
  const { searchValue, setSearchQueryValue } = useDataGridSearch();

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoadingAllUsers);
  useEffect(() => {
    loadingRef.current = isLoadingAllUsers;
  }, [isLoadingAllUsers]);

  const userRolesOptions = userAdminStore.userRoles;
  const getUsers = (link: PaginationLink, searchQuery: string | undefined) => {
    if (selectedCompany) {
      getCompanyUsers(selectedCompany?.id, link, searchQuery);
    }
  };

  useEffect(() => {
    getUserRoles();
  }, []);

  useEffect(() => {
    getUsers({} as PaginationLink, searchValue);
  }, [selectedCompany?.id, searchValue]);

  const selectedCompanyAuthMethods = useMemo(async () => {
    if (selectedCompany?.id) {
      const company = await getCompanyById(selectedCompany.id);
      return company?.authMethods ?? [];
    }
    return [];
  }, [selectedCompany?.id, companyAdminStore.companies]);

  const onChangeInvite = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as 'email' | 'sms';
    const checked = event.target.checked;

    setInvitesToSend((state) => {
      const newState = _.cloneDeep(state);

      if (newState) {
        newState[name] = {
          ...newState[name],
          shouldSend: checked,
        };
      }

      return newState;
    });
  }, []);

  const modalDialogRef = useRef<any>(null);
  const updateDialogRef = useRef<UserFormHandler>(null);
  const userNotificationsPreferenceRef = useRef<UserNotificationsPreferenceHandler>(null);

  const getAdminUserFromStore = useCallback(
    (userId: string) => {
      return userAdminStore.users.find((user) => user.id === userId) ?? null;
    },
    [userAdminStore.users],
  );

  const handleClose = useCallback(() => {
    setDialogState((state) => ({ ...state, isOpen: false }));
    setAsyncErrors([]);
  }, []);

  const editRow = useCallback(
    async (id: string) => {
      setSelectedId(id);

      const adminUser = getAdminUserFromStore(id);

      if (selectedCompany) {
        setDialogState({
          isOpen: true,
          availableAuthMethods: await selectedCompanyAuthMethods,
        });

        if (adminUser?.isActiveMember && adminUser?.isActiveUser) {
          setInvitesToSend(undefined);
        } else {
          setInvitesToSend({
            email: { isDisabled: true, shouldSend: false },
            sms: { isDisabled: true, shouldSend: false },
          });
        }
      }
    },
    [selectedCompany],
  );

  const createNew = useCallback(async () => {
    setSelectedId(null);

    if (selectedCompany) {
      setDialogState({
        isOpen: true,
        availableAuthMethods: await selectedCompanyAuthMethods,
      });
      setInvitesToSend({
        email: { isDisabled: true, shouldSend: false },
        sms: { isDisabled: true, shouldSend: false },
      });
    }
  }, [selectedCompany]);

  const deleteUserCallback = () => {
    if (selectedId) {
      deleteUser(selectedId, () => {
        modalDialogRef.current?.close();
        toasterStore.push(alert($t('user.user_deleted'), AlertTypes.success));
      });
    }
  };

  const resendInvite = (rowId: string, method: 'email' | 'sms') => {
    if (rowId) {
      sendInvitation(rowId, method).then(() => {
        toasterStore.push(alert($t('user.invitation_sent'), AlertTypes.success));
      });
    }
  };

  const handleErrors = (error: AxiosError<ModelError_Response>) => {
    setAsyncErrors(error.response?.data.error.errors || []);
  };

  const onSuccess = async (
    user?: AdminUser,
    formData?: UserValidationDTOWithSitesDiff,
  ) => {
    await saveUserSitesDiff(
      String(user?.id),
      formData?.sitesDiff ?? { siteIdsToAdd: [], resourceIdsToDelete: [] },
    );

    handleClose();
    const name = `${user?.firstName} ${user?.lastName}`;
    toasterStore.push(
      alert(
        selectedId
          ? $t('user.user_updated', { name })
          : $t('user.user_created', { name }),
        AlertTypes.success,
      ),
    );
  };

  const onSubmitCallback = (
    data: UserValidationDTOWithSitesDiff,
    userNotificationsPreferenceData?: UserNotificationsPreferenceDTO,
  ) => {
    const shouldSendEmailInvite = Boolean(
      !invitesToSend?.email.isDisabled && invitesToSend?.email.shouldSend,
    );
    const shouldSendSmsInvite = Boolean(
      !invitesToSend?.sms.isDisabled && invitesToSend?.sms.shouldSend,
    );

    if (selectedId) {
      updateUser(
        { id: selectedId, ...data } as unknown as AdminUser,
        userNotificationsPreferenceData,
      )
        .then((adminUser) => {
          return onSuccess(adminUser, data);
        })
        .catch(handleErrors);

      if (shouldSendEmailInvite) {
        sendInvitation(selectedId, 'email');
      }

      if (shouldSendSmsInvite) {
        sendInvitation(selectedId, 'sms');
      }
    } else {
      createUser(data as unknown as AdminUser, {
        email: shouldSendEmailInvite,
        sms: shouldSendSmsInvite,
      })
        .then((adminUser) => {
          return onSuccess(adminUser, data);
        })
        .catch(handleErrors);
    }
  };

  const onSubmitForm = async () => {
    const userData = await new Promise<UserValidationDTOWithSitesDiff | undefined>(
      (resolve) => {
        updateDialogRef.current?.submit((data) => resolve(data));
      },
    );

    const userNotificationsPreferenceData = await new Promise<
      UserNotificationsPreferenceDTO | undefined
    >((resolve) => {
      if (selectedUser?.id && userNotificationsPreferenceRef.current) {
        userNotificationsPreferenceRef.current.onSubmit?.((data) => resolve(data));
      } else {
        resolve(undefined);
      }
    });

    if (userData) {
      onSubmitCallback(userData, userNotificationsPreferenceData);
    }
  };

  const onFormStateChange = ({
    isDirty,
    hasValidEmail,
    hasValidPhone,
  }: UserFormStateChangeProps) => {
    setUserFormIsDirty(isDirty);

    if (invitesToSend) {
      setInvitesToSend((state) => {
        const newState = _.cloneDeep(state);

        if (newState) {
          newState.email = {
            ...newState.email,
            isDisabled: !hasValidEmail,
          };

          newState.sms = {
            ...newState.sms,
            isDisabled: !hasValidPhone,
          };
        }

        return newState;
      });
    }
  };

  const onUserNotificationsPreferenceFormStateChange = useCallback(
    ({ isDirty }: FormStateChangeProps) => {
      setUserNotificationsPreferenceFormIsDirty(isDirty);
    },
    [],
  );

  const rows = useMemo(() => {
    const users = userAdminStore.users || [];
    return users.length
      ? users.filter(
          (user) =>
            !selectedCompany?.id || get(user, 'company.id') === selectedCompany?.id,
        )
      : [];
  }, [userAdminStore.users, selectedCompany?.id]);

  const selectedUser = useMemo(() => {
    return (
      userAdminStore.users.find((user) => user.id === selectedId) ||
      ({
        company: selectedCompany,
      } as AdminUser)
    );
  }, [selectedId, rows]);

  const totalUserCount = useMemo(
    () => userAdminStore.users.length,
    [userAdminStore.users],
  );
  const onChangeQuickFilter = (searchQuery: string) => {
    setSearchQueryValue(searchQuery);
  };

  const permissions = useMemo(() => userStore.getPermissions(), []);

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'lastName',
          headerName: $t('form_fields.last_name'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => (
            <div
              title={params.value}
              className={'MuiDataGrid-cellContent'}
              role={'presentation'}
              data-sentry-mask
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'firstName',
          headerName: $t('form_fields.first_name'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            const user = params.row as AdminUser;
            return `${user.firstName}`;
          },
          renderCell: (params: GridValueGetterParams) => (
            <div
              title={params.value}
              className={'MuiDataGrid-cellContent'}
              role={'presentation'}
              data-sentry-mask
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'company',
          headerName: $t('form_fields.company'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((item) => item.company?.legalName))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return (params.row as AdminUser).company?.legalName || '';
          },
        },
        {
          field: 'roles',
          headerName: $t('form_fields.role'),
          flex: 1,
          height: 'auto',
          valueGetter: (params: GridValueGetterParams) => {
            return (params.row as AdminUser).userRoles?.map((role) => role.name);
          },
          renderCell: (params: GridValueGetterParams) => {
            return params.value.join(', ');
          },
        },
        {
          field: 'email',
          headerName: 'Email',
          flex: 1,
          renderCell: (params: GridValueGetterParams) => (
            <div
              title={params.value}
              className={'MuiDataGrid-cellContent'}
              role={'presentation'}
              data-sentry-mask
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'phone',
          headerName: $t('form_fields.phone'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => (
            <div
              title={params.value}
              className={'MuiDataGrid-cellContent'}
              role={'presentation'}
              data-sentry-mask
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'createdAt',
          headerName: $t('common.created_on'),
          type: 'date',
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return new Date(params.value);
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                key={`created__${params.row.id}`}
                title={`${$t('common.last_update')}: ${dateFormat(params.row.updatedAt)}`}
              >
                <span>{dateFormat(params.row.createdAt)}</span>
              </BasicTooltip>
            );
          },
        },
        { field: 'id', headerName: $t('form_fields.id'), flex: 1 },
        {
          field: 'status',
          headerName: $t('form_fields.status'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            const user = params.row as AdminUser;
            return `${user.status.charAt(0).toUpperCase()}${user.status.slice(1)}`;
          },
        },
        {
          field: 'currentLocation',
          headerName: $t('user.current_location'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            const coords = params.row.currentLocation;
            return _.isNil(coords?.lat) || _.isNil(coords?.lon)
              ? '-'
              : `${Number(coords.lat).toFixed(6)}, ${Number(coords.lon).toFixed(6)}`;
          },
          renderCell: (params: GridValueGetterParams) => (
            <div
              title={params.value}
              className={'MuiDataGrid-cellContent'}
              role={'presentation'}
              data-sentry-mask
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'another.action.navigation',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={totalUserCount}
              loading={loadingRef.current}
              pagination={userAdminStore.pagination}
              callback={getUsers}
              altText={`${$t('actions.actions')}`}
              searchQuery={searchValue}
            />
          ),
          renderCell: (params: GridValueGetterParams) => {
            const options = [];

            if (!params.row.isActiveMember && params.row.email) {
              options.push({
                title: t('user.resend_email_invitation'),
                icon: <Mail />,
                callBack: () => resendInvite(params.row.id, 'email'),
              });
            }

            if (params.row.phone && !params.row.isActiveUser) {
              options.push({
                title: t('user.resend_sms_invitation'),
                icon: <Sms />,
                callBack: () => resendInvite(params.row.id, 'sms'),
              });
            }

            options.push({
              title: 'Revoke Sessions',
              icon: <RemoveCircle />,
              callBack: () => {
                revokeAllUserSessions(params.row.id);
              },
            });

            return (
              <>
                {permissions.canImpersonate && (
                  <IconButton size="small" onClick={() => impersonateUser(params.row.id)}>
                    <FingerprintIcon />
                  </IconButton>
                )}
                <IconButton size="small" onClick={() => editRow(params.row.id)}>
                  <Edit />
                </IconButton>
                <SimpleMenu options={options}>
                  <MoreHoriz />
                </SimpleMenu>
              </>
            );
          },
        },
      ] as GridColDef[],
    [rows],
  );

  const initialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {},
      },
    }),
    [],
  );

  return (
    <>
      <Box display={'grid'} width={'30%'} data-test-id={'company-selector'}>
        <CompanySelectorFormField
          name="users-companies-selector"
          errors={errors}
          control={control}
          hideLabel={true}
          inputProps={{
            placeholder: `${$t('form_fields.company_selector_hint')}`,
          }}
          sx={{
            flexGrow: 1,
            flexShrink: 0,
          }}
          clearable
        />
      </Box>
      <Box sx={{ mt: 3 }} data-test-id={'users-data-grid'}>
        <DataGrid
          id="admin-users-grid"
          columns={columns}
          rows={rows as unknown as Record<string, any>[]}
          loading={isLoadingAllUsers}
          initialState={initialState}
          onChangeFilter={onChangeQuickFilter}
          headerActionsComponent={
            <>
              <Box display={'flex'}>
                <Button
                  size="medium"
                  color={'primary'}
                  onClick={createNew}
                  startIcon={<Add />}
                  disabled={!selectedCompany?.id}
                >
                  {selectedCompany?.id ? (
                    t('user.create_user')
                  ) : (
                    <BasicTooltip title={t('user.create_user')}>
                      <span>{t('user.create_user')}</span>
                    </BasicTooltip>
                  )}
                </Button>
              </Box>
            </>
          }
        />
      </Box>

      <Dialog
        open={dialogState.isOpen}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
      >
        <DialogHeader
          closeCallBack={handleClose}
          title={t(`user.${selectedId ? 'update_user' : 'create_user'}`)}
        />
        <DialogContent sx={{ my: 4 }}>
          <UserForm
            ref={updateDialogRef}
            availableAuthMethods={dialogState.availableAuthMethods}
            defaultUser={selectedUser}
            editable={true}
            onFormStateChange={onFormStateChange}
            source="admin_users"
            errors={asyncErrors}
            userRolesOptions={userRolesOptions}
          />

          {selectedUser?.id && (
            <UserNotificationsPreferenceForm
              key={selectedUser?.id}
              ref={userNotificationsPreferenceRef}
              editable
              onFormStateChange={onUserNotificationsPreferenceFormStateChange}
              user={selectedUser as unknown as User}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          {invitesToSend && (
            <Box display="flex" gap={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: 0.5, marginTop: '-1px' }}
                      size="small"
                      name="email"
                      onChange={onChangeInvite}
                      checked={invitesToSend.email.shouldSend}
                      color={'primary'}
                      disabled={isUpdating || invitesToSend.email.isDisabled}
                    />
                  }
                  label={
                    <Typography fontSize="12px">
                      {$t('user.form.send_email_invitation')}
                    </Typography>
                  }
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: 0.5, marginTop: '-1px' }}
                      size="small"
                      name="sms"
                      onChange={onChangeInvite}
                      checked={invitesToSend.sms.shouldSend}
                      color={'primary'}
                      disabled={isUpdating || invitesToSend.sms.isDisabled}
                    />
                  }
                  label={
                    <Typography fontSize="12px">
                      {$t('user.form.send_sms_invitation')}
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={!formIsDirty || isUpdating}
              loading={isUpdating}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t(`actions.${selectedId ? 'update' : 'create'}`)}
            </LoadingButton>
            <Button
              onClick={handleClose}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdating}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <ModalDialog
        ref={modalDialogRef}
        title={$t('user.delete_user_title')}
        content={$t('user.delete_user_text')}
        confirmButtonText={`${$t('actions.confirm')}`}
        callBack={deleteUserCallback}
        loading={isUpdating}
      />
    </>
  );
};

export default observer(AdminUsersDataGrid);
