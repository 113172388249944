import * as Sentry from '@sentry/react';
import {
  AccountType,
  Driver_Read,
  Driver_Read_Typeahead,
  getV1CompaniesCompanyIdDrivers,
  GetV1CompaniesCompanyIdDriversData,
  postV1CompaniesCompanyIdAccountsInvite,
  User_Create,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import _ from 'lodash';
import { useState } from 'react';

import { DriverFormValues } from '~components/DriverForm/DriverForm';
import { API_VERSION } from '~constants/consts';
import { Account } from '~hooks/useAccount';
import { AddressItem } from '~hooks/useAddress';
import { CompanyBasic, useCompany } from '~hooks/useCompany';
import { Driver, DriverBasic } from '~hooks/useDrivers/models';
import connection from '~services/connectionModule';
import { extractPagination, PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';

interface GetDriversByCompanyIdProps {
  companyId: string;
  link?: PaginationLink;
  limit?: number;
  dispatchable?: boolean;
  shared?: boolean;
  searchQuery?: string;
  callback?: (drivers: Driver[]) => void;
}

interface FetchDriversByCompanyIdProps {
  companyId: string;
  dispatchable?: boolean;
  shared?: boolean;
  searchQuery?: string;
  pagination: { link?: NonNullable<PaginationLink>; cursor?: string; limit: number };
}

interface GetDriversByCompanyIdParams {
  'page[limit]'?: number;
  'page[after]'?: string;
  'page[before]'?: string;
  'filter[dispatchable]'?: boolean;
  'filter[shared]'?: boolean;
  'search[datagrid]'?: string;
}

export interface GetDriversTypeaheadParams {
  companyId: string;
  shared?: boolean;
  dispatchable?: boolean;
  searchParams?: {
    limit?: number;
    query?: string;
    link?: {
      type: PaginationLink;
      cursor: string;
    };
  };
}

interface GetDriversTypeaheadQueryProps {
  'page[limit]'?: number;
  'page[before]'?: string;
  'page[after]'?: string;
  'search[query]'?: string;
  'filter[dispatchable]'?: boolean;
  'filter[shared]'?: boolean;
}

export const useDrivers = () => {
  const { companyAssetsStore } = useStores();
  const { getCompanyById } = useCompany();
  const [isLoading, setIsLoading] = useState(false);

  const createDriverByCompanyId = async (
    driver: DriverFormValues,
    inviteTypes: User_Create['send_invite'] = {},
    accountId?: Nullable<string>,
    defaultAccountAddress?: Nullable<AddressItem>,
  ) => {
    const companyId = accountId || (driver.company as CompanyBasic).id;
    let driverAddress = driver.address;

    if (!driverAddress) {
      if (accountId && defaultAccountAddress) {
        driverAddress = defaultAccountAddress;
      } else {
        const company = await getCompanyById({ id: companyId });
        driverAddress = company?.address;
      }
    }
    const fullName = `${driver.firstName} ${driver.lastName}`;
    const invitesToSend = {
      email: inviteTypes?.email || false,
      sms: inviteTypes?.sms || false,
    };
    const updatedAccount = {
      ...driver,
      name: fullName,
      address: driverAddress,
      primaryContact: {
        name: fullName,
        email: driver.email ?? undefined,
        phone: driver.phone ?? undefined,
      },
      invitesToSend,
      accountTypes: [AccountType.VENDOR],
      companyType: 'owner_operator',
      autoAcceptOrders: false,
    };

    setIsLoading(true);
    try {
      const response = await postV1CompaniesCompanyIdAccountsInvite({
        path: { 'company-id': companyId },
        body: Account.deparseConnectedAccount(updatedAccount),
      });

      const accountSaved = Account.parse(response);
      if (!accountId) {
        companyAssetsStore.addAccount(accountSaved);
      }
      return accountSaved;
    } catch (error) {
      console.error(error);
      connection.handleRequestError(
        error,
        $t('error_messages.account.failed_to_create_connected') as string,
        [422],
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getDriverById = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await connection.get<Driver_Read>(
        `${API_VERSION}/drivers/${id}`,
        {},
        $t('error_messages.drivers.failed_to_fetch_driver') as string,
      );
      return Driver.parse(response);
    } catch (e) {
      console.error(e);
      throw new Error($t('error_messages.drivers.failed_to_fetch_driver') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const getDriversByCompanyId = async ({
    companyId,
    link,
    shared,
    limit,
    dispatchable,
    searchQuery,
    callback,
  }: GetDriversByCompanyIdProps) => {
    const query: GetV1CompaniesCompanyIdDriversData['query'] = {
      'page[limit]': limit || companyAssetsStore.driversPagination.limit,
    };
    if (searchQuery) {
      query['search[datagrid]'] = searchQuery;
    }
    if (link && companyAssetsStore.driversPagination[link]) {
      query[`page[${link}]`] = companyAssetsStore.driversPagination[link];
    }
    if (!_.isNil(shared)) {
      query['filter[shared]'] = shared;
    }
    if (!_.isNil(dispatchable)) {
      query['filter[dispatchable]'] = dispatchable;
    }

    try {
      setIsLoading(true);
      const response = await getV1CompaniesCompanyIdDrivers({
        path: { 'company-id': companyId },
        query,
      });
      const parsedDrivers = response.data.data.map(Driver.parse);
      const pagination = extractPagination(response);
      companyAssetsStore.setDrivers(parsedDrivers);
      companyAssetsStore.setDriversPagination(pagination);
      companyAssetsStore.updateDriversPageNumber(link);
      callback?.(parsedDrivers);
      return parsedDrivers;
    } catch (e) {
      connection.handleRequestError(
        e,
        $t('error_messages.drivers.failed_to_fetch_drivers') as string,
      );
    } finally {
      setIsLoading(false);
    }
  };

  // same as "getDriversByCompanyId", but store agnostic
  const fetchDriversByCompanyId = async ({
    companyId,
    dispatchable,
    shared,
    searchQuery,
    pagination,
  }: FetchDriversByCompanyIdProps) => {
    const params: GetDriversByCompanyIdParams = {
      'page[limit]': pagination.limit,
    };

    if (pagination.link && pagination.cursor) {
      params[`page[${pagination.link}]`] = pagination.cursor;
    }

    if (!_.isNil(shared)) {
      params['filter[shared]'] = shared;
    }

    if (!_.isNil(dispatchable)) {
      params['filter[dispatchable]'] = dispatchable;
    }

    if (!_.isNil(searchQuery)) {
      params['search[datagrid]'] = searchQuery;
    }

    try {
      setIsLoading(true);

      const response = await connection.getPaginated<Driver_Read>(
        `${API_VERSION}/companies/${companyId}/drivers`,
        { params },
        $t('error_messages.drivers.failed_to_fetch_drivers') as string,
      );
      const { data, pagination } = response;
      const parsedDrivers = data.map((driver) => Driver.parse(driver));

      return { pagination, data: parsedDrivers };
    } catch (e) {
      console.error(e);
      throw new Error($t('error_messages.drivers.failed_to_fetch_drivers') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const getDriversByCompanyIdTypeahead = async (options: GetDriversTypeaheadParams) => {
    const params: GetDriversTypeaheadQueryProps = {
      'page[limit]':
        options.searchParams?.limit || companyAssetsStore.driversPagination.limit,
    };
    if (options.searchParams?.query) {
      params['search[query]'] = options.searchParams.query.trim();
    }
    if (!_.isNil(options.shared)) {
      params['filter[shared]'] = options.shared;
    }
    if (!_.isNil(options.dispatchable)) {
      params['filter[dispatchable]'] = options.dispatchable;
    }
    if (
      options?.searchParams?.link &&
      options?.searchParams?.link.type &&
      options?.searchParams?.link.cursor
    ) {
      params[`page[${options?.searchParams?.link.type}]`] =
        options?.searchParams?.link.cursor;
    }

    try {
      setIsLoading(true);
      const response = await connection.getPaginated<Driver_Read_Typeahead>(
        `${API_VERSION}/companies/${options.companyId}/drivers/typeahead`,
        { params },
        $t('error_messages.drivers.failed_to_fetch_drivers') as string,
      );
      const { data, pagination } = response;
      return { data: (data as Driver_Read[]).map(DriverBasic.parse), pagination };
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      throw new Error($t('error_messages.drivers.failed_to_fetch_drivers') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const getDriverByID = async (driverId: string) => {
    setIsLoading(true);
    try {
      const resp = await connection.get<Driver_Read>(
        `${API_VERSION}/drivers/${driverId}`,
        {},
        $t('error_messages.drivers.failed_to_fetch_driver') as string,
      );
      const formatted = Driver.parse(resp);
      return formatted;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createDriverByCompanyId,
    fetchDriversByCompanyId,
    getDriverByID,
    getDriverById,
    getDriversByCompanyId,
    getDriversByCompanyIdTypeahead,
    isLoading,
  };
};
