import { LayerSpecification, SourceSpecification } from '@nbai/nbmap-gl';
import { LiveSiteChannel_Read, WaypointType } from '@treadinc/horizon-api-spec';
import { Polygon } from '@turf/helpers';
import dayjs from 'dayjs';

import { Job } from '~hooks/useJob';
import { AssetLocationType } from '~pages/LiveMap/types';
import { Nullable } from '~types/Nullable';
import { dateFormat } from '~utils/dateTime';

import { Site, SiteBasic } from '../useSites/models';

const EQUIPMENT_MAX_LENGTH = 15;

export interface JobFeatureProperties {
  description: string;
  state: string;
  id: string;
  jobId: string;
  companyId: string;
  updatedAt: string;
  material: string;
  equipment: string;
  equipmentTrimmed: string;
  bearing: number;
  startedAt: string;
  driverName: string;
  type: AssetLocationType;
  opacity?: number;
}
export interface SiteFeatureProperties {
  externalId: string;
  name: string;
  address: string;
  type: string;
  geofenceType: string;
}

export interface LiveMapFeature {
  type: string;
  properties: JobFeatureProperties | SiteFeatureProperties;
  layer?: LayerSpecification;
  source?: SourceSpecification;
  geometry:
    | {
        type: string;
        coordinates: (number | undefined | null)[];
      }
    | Polygon;
}
export interface LiveMapRealTimeEventData {
  latest_location: {
    meta_data: {
      job_id: string;
    };
    location: {
      lat: number | null;
      lon: number | null;
    };
  };
}

const trimEquipmentName = (name: string) => {
  if (name.length <= EQUIPMENT_MAX_LENGTH) {
    return name;
  }

  return `${name.slice(0, EQUIPMENT_MAX_LENGTH)}…`;
};

class LiveMapJobEventData {
  public static parse(
    lat: string | null | number,
    lng: string | null | number,
    job: Job,
  ): LiveMapJobEventData {
    return new LiveMapJobEventData(lat, lng, job);
  }
  public get geometry(): { coordinates: (number | undefined | null)[]; type: string } {
    return {
      type: 'Point',
      coordinates: [Number(this.lng), Number(this.lat)],
    };
  }
  public get job(): Job {
    return this._job;
  }
  public get geoFeature(): LiveMapFeature {
    const equipment = this.job.equipment?.name || '';

    return {
      type: 'Feature',
      properties: {
        description: '',
        state: this.job.status || '',
        id: this.job.id || '',
        jobId: this.job.jobId || '',
        companyId: this?.job?.company?.legalName || '',
        updatedAt: dateFormat(this.job.updatedAt, 'HH:mm A'),
        material: this.job.material?.name || '',
        equipment,
        equipmentTrimmed: trimEquipmentName(equipment),
        bearing: 0, // No bearing data saved in the job, only on the NextBillionAssetLocation
        startedAt: dateFormat(this.job.jobStartAt || dayjs(), 'HH:mm A'),
        driverName:
          `${this.job.driver?.firstName || ''} ${this.job.driver?.lastName || ''}` || '',
        type: AssetLocationType.TRUCK,
      },
      geometry: {
        type: 'Point',
        coordinates: [Number(this.lng), Number(this.lat)],
      },
    };
  }
  public get properties(): any {
    const equipment = this.job.equipment?.name || '';

    return {
      description: '',
      state: this.job.status || '',
      id: this.job.id || '',
      jobId: this.job.jobId || '',
      companyId: this?.job?.company?.legalName || '',
      updatedAt: dateFormat(this.job.updatedAt, 'HH:mm A'),
      material: this.job.material?.name || '',
      equipment,
      equipmentTrimmed: trimEquipmentName(equipment),
      started_at: dateFormat(this.job.jobStartAt || dayjs(), 'HH:mm A'),
      orderId: this.job.orderId || '',
      vendorName: this.job.customerJobAssignment?.customerAccount?.name || '',
      customerName: this.job.customerJobAssignment?.customerAccount?.name || '',
      driverName:
        `${this.job.driver?.firstName || ''} ${this.job.driver?.lastName || ''}` || '',
      type: AssetLocationType.TRUCK,
    };
  }

  public get lat(): Nullable<number | string> {
    return this._lat;
  }
  public get lng(): Nullable<number | string> {
    return this._lng;
  }

  constructor(
    private _lat: Nullable<number | string>,
    private _lng: Nullable<number | string>,
    private _job: Job,
  ) {}
}

class LiveSiteData {
  public static parse(proto: LiveSiteChannel_Read) {
    return new LiveSiteData(SiteBasic.parse(proto.site), proto.waypoint_type);
  }

  public get site(): SiteBasic {
    return this._site;
  }

  public get waypointType() {
    return this._waypointType;
  }

  public set waypointType(value) {
    this._waypointType = value;
  }

  constructor(
    private _site: SiteBasic,
    private _waypointType: WaypointType | 'both',
  ) {}
}

export { LiveMapJobEventData, LiveSiteData };
