import { MaterialRate_Read, OrderUnitOfMeasure } from '@treadinc/horizon-api-spec';
import _ from 'lodash';

import { AccountBasic } from '../useAccount';
import { CompanyBasic } from '../useCompany';
import { BasicMaterial } from '../useMaterials';
import { ProjectBasic } from '../useProjects/models';
import { SiteBasic } from '../useSites';

export class MaterialRate {
  constructor(
    private _id: string,
    private _buy_price: number | null,
    private _sell_price: number | null,
    private _unit_of_measure: OrderUnitOfMeasure,
    private _material: BasicMaterial,
    private _project: ProjectBasic | null,
    private _site: SiteBasic | null,
    private _customer_account: AccountBasic | null,
    private _company: CompanyBasic,
  ) {}

  public get id() {
    return this._id;
  }

  public get buyPrice() {
    return this._buy_price;
  }

  public get sellPrice() {
    return this._sell_price;
  }

  public get unitOfMeasure() {
    return this._unit_of_measure;
  }

  public get material() {
    return this._material;
  }

  public get project() {
    return this._project;
  }

  public get site() {
    return this._site;
  }

  public get customerAccount() {
    return this._customer_account;
  }

  public get company() {
    return this._company;
  }

  public static parse(proto: MaterialRate_Read) {
    return new MaterialRate(
      proto.id,
      _.isNil(proto.buy_price) ? null : Number(proto.buy_price),
      _.isNil(proto.sell_price) ? null : Number(proto.sell_price),
      proto.unit_of_measure,
      BasicMaterial.parse(proto.material),
      _.isNil(proto.project) ? null : ProjectBasic.parse(proto.project),
      _.isNil(proto.site) ? null : SiteBasic.parse(proto.site),
      _.isNil(proto.customer_account) ? null : AccountBasic.parse(proto.customer_account),
      CompanyBasic.parse(proto.company),
    );
  }
}
