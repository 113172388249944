import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { CompanyType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { DialogHeader } from '~components/Dialog/DialogHeader';
import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { Company, CompanyBasic } from '~hooks/useCompany';
import useManagedCompanies, {
  GetConnectedAccountsByCompanyIdParams,
} from '~hooks/useManagedCompanies/useManagedCompanies';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isActionClicked } from '~utils/utilFunctions';

interface ManageAccountsFormProps {
  companyId?: string;
  isOpen: boolean;
  managedCompanies?: Company[];
  onClose: (shouldRefetch?: true) => void;
}

export default function ManageAccountsForm({
  companyId,
  isOpen,
  managedCompanies,
  onClose,
}: ManageAccountsFormProps) {
  const formRef = useRef<FormHandler>(null);

  const { toasterStore } = useStores();
  const { createManagedCompany, deleteManagedCompany } = useManagedCompanies();

  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const companies = useMemo(() => {
    return (managedCompanies ?? []).map((company) => {
      return new CompanyBasic(
        company.id,
        company.legalName,
        company.companyType as CompanyType,
      );
    });
  }, [managedCompanies]);

  const handleSelectedCompaniesChange = useCallback(
    (selectedCompaniesDidChange: boolean) => {
      setIsSubmitButtonDisabled(!selectedCompaniesDidChange);
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    if (!companyId) {
      return;
    }

    formRef.current?.onSubmit(async (dto) => {
      try {
        setIsSaving(true);

        const toDelete = _.differenceBy(companies, dto.companies, (item) => item.id);
        const toAdd = _.differenceBy(dto.companies, companies, (item) => item.id);

        const createRequests = toAdd.map((company) => {
          return createManagedCompany({ companyId, managedCompanyId: company.id });
        });
        const deleteRequests = toDelete.map((company) => {
          return deleteManagedCompany({ companyId, managedCompanyId: company.id });
        });

        await Promise.all([...createRequests, ...deleteRequests]);

        toasterStore.push(
          alert(
            t('administration.managed_companies.manage_accounts_form.success_message'),
            AlertTypes.success,
          ),
        );
        onClose(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsSaving(false);
      }
    });
  }, [companyId, companies, onClose]);

  const handleClose = useCallback(() => {
    if (!isSaving) {
      onClose();
    }
  }, [onClose, isSaving]);

  return (
    <Dialog
      open={isOpen}
      onClose={(_: never, reason: DialogCloseReasonType) => {
        if (isActionClicked(reason) && !isSaving) {
          handleClose();
        }
      }}
      sx={{ [`& .${dialogClasses.paper}`]: { overflowY: 'visible' } }}
    >
      <DialogHeader
        closeCallBack={handleClose}
        title={t('administration.managed_companies.manage_accounts_form.title')}
      />

      <DialogContent>
        {isOpen && companyId ? (
          <Form
            key={companyId}
            ref={formRef}
            companyId={companyId}
            managedCompanies={companies}
            onSelectedCompaniesChange={handleSelectedCompaniesChange}
          />
        ) : null}
      </DialogContent>

      <DialogActions
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          m: 0,
          p: 2,
        }}
      >
        <Button
          color="secondary"
          disabled={isSaving}
          onClick={handleClose}
          variant="outlined"
        >
          {t('actions.cancel')}
        </Button>

        <LoadingButton
          disabled={isSaving || isSubmitButtonDisabled}
          loading={isSaving}
          loadingPosition="start"
          onClick={handleSubmit}
          startIcon={<></>}
          variant="contained"
          sx={isSaving ? { pl: 4 } : {}}
        >
          {t('actions.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

interface FormProps {
  companyId: string;
  managedCompanies: CompanyBasic[];
  onSelectedCompaniesChange: (selectedCompaniesDidChange: boolean) => void;
}

interface FormHandler {
  onSubmit: (callback: (args: FormDTO) => void) => void;
}

const formSchema = yup.object().shape({
  companies: yup.array().of(yup.mixed<CompanyBasic>().required()).required(),
});
type FormDTO = yup.InferType<typeof formSchema>;

const Form = forwardRef<FormHandler, FormProps>(function Form(
  { companyId, managedCompanies, onSelectedCompaniesChange },
  ref,
) {
  const form = useForm<FormDTO>({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { companies: managedCompanies },
  });

  const { getConnectedAccountsByCompanyId } = useManagedCompanies();

  const watchedCompanies = form.watch('companies');
  const selectedCompaniesDidChange = useMemo(() => {
    const initialSelections = managedCompanies.map((company) => company.id).sort();
    const currentSelections = watchedCompanies.map((company) => company.id).sort();

    return JSON.stringify(initialSelections) !== JSON.stringify(currentSelections);
  }, [managedCompanies, watchedCompanies]);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit(callback) {
        form.handleSubmit(callback)();
      },
    }),
    [form.handleSubmit],
  );

  useEffect(() => {
    onSelectedCompaniesChange(selectedCompaniesDidChange);
  }, [onSelectedCompaniesChange, selectedCompaniesDidChange]);

  return (
    <Box>
      <AutocompleteAsyncFormField
        asyncCallback={(args: GetConnectedAccountsByCompanyIdParams) => {
          return getConnectedAccountsByCompanyId(args).then((response) => {
            return {
              pagination: response?.pagination,
              data: (response?.data ?? []).map((account) => {
                return account.connectedCompany;
              }),
            };
          });
        }}
        clearable={false}
        control={form.control}
        errors={form.formState.errors}
        extraRequestOptions={{ companyId }}
        filterSelectedOptions={false}
        getLabel={(item: CompanyBasic | null) => item?.legalName ?? ''}
        getValue={(item: CompanyBasic | null) => item?.id ?? ''}
        hideLabel
        isRequired
        limitTags={2}
        multiple
        name="companies"
      />
    </Box>
  );
});
