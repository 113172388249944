import { Consumer, Subscription } from '@rails/actioncable';
import { Dispatch } from 'react';

import connection from '~services/connectionModule';

/**
 * Subscribes to a channel using ActionCable and creates a new subscription if one doesn't exist.
 * subscription state will be set to the new subscription or the existing one.
 *
 * @param channel - The name of the channel to subscribe to.
 * @param companyId - The company ID to use for the subscription.
 * @param receivedHandler - The callback function to handle received data from the channel.
 * @param createOptions - The options to create the subscription with. Only applicable for certain channels.
 */
export const subscribeToChannel = <T>(
  channel: string,
  companyId: string | null,
  setSubscription: Dispatch<Subscription<Consumer>>, //(subscription: unknown) => void,
  receivedHandler: (resp: T) => void,
  createOptions?: Record<string, unknown>,
) => {
  const consumer = connection.realTimeConnection;

  let subscription = consumer?.subscriptions.subscriptions.find((sub) =>
    sub.identifier.includes(channel),
  );

  // If there is no existing subscription, create a new one
  if (!subscription) {
    subscription = consumer?.subscriptions?.create?.(
      {
        channel,
        ...(companyId ? { company_id: companyId } : {}),
        ...createOptions,
      },
      {
        connected: () => () => {},
        disconnected: () => () => {},
        received: receivedHandler,
      },
    );
  }

  setSubscription(subscription);
};
