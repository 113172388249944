import CheckCircleOutlineOutlined from '@mui/icons-material/CheckCircleOutlineOutlined';
import Clear from '@mui/icons-material/Clear';
import ControlPointOutlined from '@mui/icons-material/ControlPointOutlined';
import FlagOutlined from '@mui/icons-material/FlagOutlined';
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined';
import WarningAmber from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BoxOwnProps, styled } from '@mui/system';
import { t } from 'i18next';
import _ from 'lodash';
import React, { createElement, useCallback, useMemo } from 'react';

import { Status as LoadStatus } from '~components/Job/LoadStatus';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { JobLoadSummary, useJob } from '~hooks/useJob';
import { Ticket } from '~hooks/useTickets/models';
import ApprovalActionsMenu from '~pages/Approvals/DriverDay/components/ApprovalActionsMenu';
import { RemoveItemButton } from '~pages/Sales/sharedSales/RemoveItemButton';
import theme from '~theme/AppTheme';
import { Nullable } from '~types/Nullable';
import { dateFormat, formatDuration, minutesToHoursMins } from '~utils/dateTime';

import { OverflowAwareText } from '../Order/ordersDispatchStyledComponents';

interface Props {
  cycleTime: number;
  index: number;
  isDeletable: boolean;
  isLast?: boolean;
  load: JobLoadSummary;
  onClick: () => void;
  onRemoveLoad: (id: string) => void;
  reload: () => void;
  sx?: SxProps<Theme>;
  ticket: Nullable<Ticket>;
  isEditing?: boolean;
  readonly?: boolean;
}
const LoadCard = ({
  cycleTime,
  index,
  isDeletable,
  isLast,
  load,
  onClick,
  onRemoveLoad,
  reload,
  sx,
  ticket,
  isEditing = false,
  readonly = false,
}: Props) => {
  const { approveLoad, unapproveLoad } = useJob();
  const hasCycleTime = cycleTime > 0;
  const hasTicket = !!ticket;
  const hasAttachment = !!ticket?.imageUrl;
  const ticketDate = ticket?.serviceDate || ticket?.ocrServiceDate || ticket?.createdAt;
  const ticketIsMissingData =
    !ticket?.ticketNumber ||
    !ticket?.material?.name ||
    !ticket?.quantity ||
    !ticket?.unitOfMeasure?.name ||
    !ticket?.imageUrl;
  const formattedTicketTime = ticketDate
    ? dateFormat(new Date(ticketDate), 'h:mm A')
    : '-';
  const formattedTicketQuantity =
    ticket?.quantity && ticket?.unitOfMeasure?.name
      ? `${Number(ticket.quantity).toFixed(2)} ${ticket.unitOfMeasure.name}`
      : '-';
  const ticketIsFlagged = ticket?.flagged || false;
  const loadIsApproved = load.approved;
  const loadIsApprovable = load.approvable;

  const ApproveRowCTA = () => {
    if (loadIsApproved) {
      return (
        <ApprovalActionsMenu
          onMenuItemClick={() => {
            unapproveLoad(load.id);
            reload();
          }}
          approved={loadIsApproved}
        />
      );
    }

    if (loadIsApprovable) {
      return (
        <Button
          variant="outlined"
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            approveLoad(load.id);
            reload();
          }}
          sx={{ fontSize: '12px', minWidth: 0, px: '4px', py: 0, height: '24px' }}
          disabled={isEditing}
        >
          {t('approvals.approve')}
        </Button>
      );
    }

    return null;
  };

  const attachmentsAndAlerts = useMemo(() => {
    const items = [];

    if (hasAttachment) {
      items.push({
        tooltip: t('loads.ticket_attached'),
        ariaLabel: t('aria_label.ticket.has_attachment'),
        icon: InsertDriveFileOutlined,
        color: theme.brandV2.colors.treadGray2,
      });
    }

    if (loadIsApproved) {
      items.push({
        tooltip: t('loads.ticket_approved'),
        ariaLabel: t('aria_label.ticket.approved'),
        icon: CheckCircleOutlineOutlined,
        color: theme.brandV2.colors.treadGreen,
      });
    }

    if (hasTicket && ticketIsMissingData) {
      items.push({
        tooltip: t('loads.missing_info'),
        ariaLabel: t('aria_label.ticket.missing_info'),
        icon: WarningAmber,
        color: theme.brandV2.colors.treadOrangeDark,
      });
    }

    return items;
  }, [hasAttachment, loadIsApproved, hasTicket, ticketIsMissingData]);

  const handleRemoveLoad = useCallback(
    (event?: React.MouseEvent) => {
      event?.stopPropagation();
      onRemoveLoad(load.id);
    },
    [onRemoveLoad, load.id],
  );

  return (
    <Box
      aria-label={`load job ${load.id}`}
      onClick={onClick}
      sx={{
        borderBottom: isLast ? '0px' : `1px solid ${theme.brandV2.colors.treadGray6}`,
        cursor: readonly ? 'default' : 'pointer',
        ...sx,
      }}
    >
      {/* Load number */}
      <CardInfoContainer isColumn sx={{ alignSelf: 'center', alignItems: 'center' }}>
        <Typography variant="body2">{index + 1}</Typography>

        {ticketIsFlagged && (
          <BasicTooltip title={t('loads.ticket_flagged')}>
            <FlagOutlined
              aria-label={t('aria_label.ticket.flagged')}
              sx={{
                width: '16px',
                height: '16px',
                color: theme.brandV2.colors.treadGray2,
              }}
            />
          </BasicTooltip>
        )}
      </CardInfoContainer>

      {/* Status and cycle */}
      <CardInfoContainer isColumn>
        <Box>
          <LoadStatus status={load.state} />
        </Box>

        <Typography variant="body2">
          {hasCycleTime ? minutesToHoursMins(cycleTime) : '-'}
        </Typography>
      </CardInfoContainer>

      {/* Attachments and alerts */}
      <CardInfoContainer sx={{ mt: '4px', justifySelf: 'center' }}>
        {attachmentsAndAlerts.map((item) => (
          <BasicTooltip key={item.tooltip} title={item.tooltip}>
            {createElement(item.icon, {
              'aria-label': item.ariaLabel,
              sx: { width: '16px', height: '16px', color: item.color },
            })}
          </BasicTooltip>
        ))}
      </CardInfoContainer>

      {/* Ticket Qty / Add ticket CTA */}
      <CardInfoContainer isColumn sx={{ mt: '4px' }}>
        {ticket ? (
          <OverflowAwareText variant="body2">
            {ticket.ticketNumber || '-'}
          </OverflowAwareText>
        ) : (
          <>
            {!readonly && (
              <Typography
                variant="body2"
                sx={{ color: theme.brandV2.colors.treadOrangeDark }}
              >
                <ControlPointOutlined sx={{ mr: 0.5, width: '16px', height: '16px;' }} />
                {t('loads.add_ticket')}
              </Typography>
            )}
          </>
        )}

        <OverflowAwareText variant="body2">{formattedTicketQuantity}</OverflowAwareText>
      </CardInfoContainer>

      {/* Ticket Time */}
      <CardInfoContainer isColumn sx={{ mt: '4px' }}>
        <Typography variant="body2">{formattedTicketTime}</Typography>
      </CardInfoContainer>

      {/* Pickup Entry */}
      <CardInfoContainer isColumn sx={{ mt: '4px' }}>
        {load.loadSummary?.arrivedPickupAt && (
          <>
            <Typography variant="body2">
              {load.loadSummary.arrivedPickupAt.format('hh:mm A')}
            </Typography>

            <Typography variant="body2" color={theme.brandV2.colors.treadGray2}>
              {load.loadSummary.pickupTimeMinutes
                ? formatDuration(load.loadSummary.pickupTimeMinutes * 60)
                : '0m'}
            </Typography>
          </>
        )}
      </CardInfoContainer>

      {/* Drop Off Entry */}
      <CardInfoContainer isColumn sx={{ mt: '4px' }}>
        {load.loadSummary?.arrivedDropoffAt && (
          <>
            <Typography variant="body2">
              {load.loadSummary.arrivedDropoffAt.format('hh:mm A')}
            </Typography>

            <Typography variant="body2" color={theme.brandV2.colors.treadGray2}>
              {load.loadSummary.dropoffTimeMinutes
                ? formatDuration(load.loadSummary.dropoffTimeMinutes * 60)
                : '0m'}
            </Typography>
          </>
        )}
      </CardInfoContainer>

      {/* Approve / Unapprove CTAs */}
      <CardInfoContainer>
        <ApproveRowCTA />
      </CardInfoContainer>

      {/* Remove row */}
      <CardInfoContainer sx={{ alignSelf: 'center' }}>
        {!readonly && isDeletable && (
          <RemoveItemButton
            action={handleRemoveLoad}
            sx={{
              cursor: 'pointer',
              backgroundColor: 'transparent',
              width: '16px',
              height: '16px',
              ml: 'auto',
            }}
            icon={Clear}
            iconStyles={{
              color: theme.brandV2.colors.treadGray3,
              width: '16px',
              height: '16px',
            }}
          />
        )}
      </CardInfoContainer>
    </Box>
  );
};

type CardInfoContainerProps = BoxOwnProps & {
  isColumn?: boolean;
};

const CardInfoContainer = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'isColumn',
})<CardInfoContainerProps>(({ isColumn }) => ({
  display: 'flex',
  flexDirection: isColumn ? 'column' : 'row',
  gap: '2px',
  overflow: 'hidden',
  padding: theme.spacing(1, 0),
}));

export default LoadCard;
