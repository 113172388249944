import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useCallback } from 'react';
import { ControllerProps, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { OtpFormField } from '~components/FormFields/OtpFormField';
import { useLogin } from '~hooks/useLogin/useLogin';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

import FormWrapperBox from './FormWrapperBox';
import { SIGN_IN_OTP_LENGTH, signInWithOtpValidationSchema } from './validationSchemas';

interface SignInWithOtpFormProps {
  onBack: () => void;
  phone: string;
  phoneId: string;
}

interface SignInWithOtpDataProps
  extends yup.InferType<typeof signInWithOtpValidationSchema> {}

const SignInWithOtpForm = ({ onBack, phone, phoneId }: SignInWithOtpFormProps) => {
  const { toasterStore } = useStores();
  const { loginWithOtp, sendOtpSms, isLoading } = useLogin();

  const handleResendButtonClick = useCallback(() => {
    sendOtpSms({ phone }).then(() => {
      toasterStore.push(alert(t('actions.new_otp_sms_sent'), AlertTypes.success));
    });
  }, []);

  const onSubmit = (data: SignInWithOtpDataProps) => {
    loginWithOtp({ phone_id: phoneId, code: data.otp });
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignInWithOtpDataProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(signInWithOtpValidationSchema),
  });

  return (
    <FormWrapperBox hideLogo onBack={onBack} sx={{ pt: 10 }}>
      <Typography variant="body1" textTransform="uppercase" fontWeight={600}>
        {t('actions.enter_verification_code')}
      </Typography>

      <Typography variant="body1" mt={1} textAlign="center">
        {t('actions.input_code_sent')}
      </Typography>

      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        gap={5}
        mt={5}
      >
        <OtpFormField
          length={SIGN_IN_OTP_LENGTH}
          otpType="numeric"
          control={control as unknown as ControllerProps['control']}
          errors={errors}
          name="otp"
        />

        <Box width="100%">
          <LoadingButton
            fullWidth
            color="primary"
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            {t('actions.signIn')}
          </LoadingButton>

          <LoadingButton
            sx={{ mt: 1 }}
            fullWidth
            size="large"
            color="inherit"
            variant="text"
            onClick={handleResendButtonClick}
            loading={isLoading}
            type="button"
          >
            {t('actions.resend_code')}
          </LoadingButton>
        </Box>
      </Box>
    </FormWrapperBox>
  );
};

export default SignInWithOtpForm;
