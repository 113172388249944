import {
  deleteV1MaterialRatesId,
  getV1CompaniesCompanyIdMaterialRates,
  MaterialRate_Create,
  MaterialRate_Update,
  patchV1MaterialRatesId,
  postV1MaterialRates,
} from '@treadinc/horizon-api-spec';
import { useCallback } from 'react';

import { extractPagination, Pagination } from '~services/pagination';

import { MaterialRate } from './models';

const QUERY_PAGINATION_LIMIT = 100;

export default function useMaterialRates() {
  const createMaterialRate = useCallback(async (body: MaterialRate_Create) => {
    return await postV1MaterialRates({ body });
  }, []);

  const deleteMaterialRate = useCallback(async (id: string) => {
    return await deleteV1MaterialRatesId({ path: { id } });
  }, []);

  const getCompanyMaterialRates = useCallback(
    async (companyId: string, pagination: Pagination) => {
      const response = await getV1CompaniesCompanyIdMaterialRates({
        path: { 'company-id': companyId },
        query: {
          'page[limit]': QUERY_PAGINATION_LIMIT,
          'page[after]': pagination.after,
          'page[before]': pagination.before,
        },
      });

      const data = response.data.data.map((materialRate) => {
        return MaterialRate.parse(materialRate);
      });

      return { data, pagination: extractPagination(response) };
    },
    [],
  );

  const updateMaterialRate = useCallback(
    async (id: string, body: MaterialRate_Update) => {
      return await patchV1MaterialRatesId({ path: { id }, body });
    },
    [],
  );

  return {
    createMaterialRate,
    deleteMaterialRate,
    getCompanyMaterialRates,
    updateMaterialRate,
  };
}
