import Refresh from '@mui/icons-material/Refresh';
import { buttonBaseClasses, buttonClasses, svgIconClasses } from '@mui/material';
import { SxProps } from '@mui/system';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

interface RefreshOrdersListButtonProps {
  disabled?: boolean;
  onClick: () => void;
  sx?: SxProps;
}

const RefreshOrdersListButton = observer(
  ({ disabled, onClick, sx }: RefreshOrdersListButtonProps) => {
    const { ordersDispatchStore } = useStores();

    if (ordersDispatchStore.deepLinkedOrder) {
      return null;
    }

    return (
      <SmallButton
        color="inherit"
        disabled={disabled}
        onClick={onClick}
        startIcon={<Refresh />}
        variant="outlined"
        sx={{
          [`&.${buttonBaseClasses.root}`]: {
            border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
            height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
            pl: 0.5,
            pr: 1,
            py: 0,
            [`.${buttonClasses.startIcon}`]: { ml: 0, mr: 0.5 },
            [`.${svgIconClasses.root}`]: { fontSize: '24px' },
          },
          ...sx,
        }}
      >
        {t('order.new_orders_available')}
      </SmallButton>
    );
  },
);

export default RefreshOrdersListButton;
