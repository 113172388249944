import _ from 'lodash';
import { ChangeEvent, useCallback, useRef, useState } from 'react';

import { UserFormHandler, UserFormStateChangeProps } from '~components/UserForm/UserForm';
import { InvitesToSendState } from '~pages/Settings/UserManagement/UserManagementDataGrid';

export const useUserForm = () => {
  const userDialogRef = useRef<UserFormHandler>(null);
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const [userInvitesToSend, setUserInvitesToSend] = useState<InvitesToSendState>();
  const [userFormIsDirty, setUserFormIsDirty] = useState(false);

  const onChangeUserInvite = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as 'email' | 'sms';
    const checked = event.target.checked;

    setUserInvitesToSend((state) => {
      const newState = _.cloneDeep(state);

      if (newState) {
        newState[name] = {
          ...newState[name],
          shouldSend: checked,
        };
      }

      return newState;
    });
  }, []);

  const onUserFormStateChange = ({
    isDirty,
    hasValidEmail,
    hasValidPhone,
  }: UserFormStateChangeProps) => {
    setUserFormIsDirty(isDirty);

    if (userInvitesToSend) {
      setUserInvitesToSend((state) => {
        const newState = _.cloneDeep(state);

        if (newState) {
          newState.email = {
            ...newState.email,
            isDisabled: !hasValidEmail,
          };

          newState.sms = {
            ...newState.sms,
            isDisabled: !hasValidPhone,
          };
        }

        return newState;
      });
    }
  };

  const createNewUser = () => {
    setIsUserDialogOpen(true);
    setUserInvitesToSend({
      email: { shouldSend: false, isDisabled: false },
      sms: { shouldSend: false, isDisabled: false },
    });
  };

  return {
    userDialogRef,
    isUserDialogOpen,
    userInvitesToSend,
    setUserInvitesToSend,
    userFormIsDirty,
    onChangeUserInvite,
    onUserFormStateChange,
    createNewUser,
    setIsUserDialogOpen,
  };
};
