import {
  deleteV1CompaniesCompanyIdManagedCompaniesId,
  DeleteV1CompaniesCompanyIdManagedCompaniesIdData,
  getV1CompaniesCompanyIdAccountsConnected,
  GetV1CompaniesCompanyIdAccountsConnectedData,
  getV1CompaniesCompanyIdManagedCompanies,
  GetV1CompaniesCompanyIdManagedCompaniesData,
  putV1CompaniesCompanyIdManagedCompaniesId,
  PutV1CompaniesCompanyIdManagedCompaniesIdData,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';

import connection from '~services/connectionModule';
import { extractPagination, Pagination } from '~services/pagination';

import { Account } from '../useAccount';
import { Company } from '../useCompany';

export const QUERY_PAGINATION_LIMIT = 100;

type CreateManagedCompanyParams = {
  companyId: string;
  managedCompanyId: string;
};

type DeleteManagedCompanyParams = {
  companyId: string;
  managedCompanyId: string;
};

export type GetConnectedAccountsByCompanyIdParams = {
  companyId: string;
  cursor?: string;
  query?: string;
};

type GetManagedCompaniesByCompanyIdParams = {
  companyId: string;
  pagination?: Pagination;
};

export default function useManagedCompanies() {
  async function createManagedCompany(params: CreateManagedCompanyParams) {
    try {
      const args: PutV1CompaniesCompanyIdManagedCompaniesIdData = {
        path: { 'company-id': params.companyId, id: params.managedCompanyId },
      };

      const response = await putV1CompaniesCompanyIdManagedCompaniesId(args);

      return Company.parse(response.data.data);
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.managed_companies.failed_to_create_managed_account') as string,
      );

      return null;
    }
  }

  async function deleteManagedCompany(params: DeleteManagedCompanyParams) {
    try {
      const args: DeleteV1CompaniesCompanyIdManagedCompaniesIdData = {
        path: { 'company-id': params.companyId, id: params.managedCompanyId },
      };

      await deleteV1CompaniesCompanyIdManagedCompaniesId(args);

      return null;
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.managed_companies.failed_to_create_managed_account') as string,
      );

      return null;
    }
  }

  async function getConnectedAccountsByCompanyId(
    params: GetConnectedAccountsByCompanyIdParams,
  ) {
    try {
      const args: GetV1CompaniesCompanyIdAccountsConnectedData = {
        path: { 'company-id': params.companyId },
        query: {
          'page[after]': params.cursor || undefined,
          'page[limit]': QUERY_PAGINATION_LIMIT,
          'search[datagrid]': params.query || undefined,
        },
      };

      const response = await getV1CompaniesCompanyIdAccountsConnected(args);
      const accounts = response.data.data.map((account) => Account.parse(account));
      const pagination = extractPagination(response);

      return { data: accounts, pagination };
    } catch (error) {
      connection.handleRequestError(
        error,
        t(
          'error_messages.managed_companies.failed_to_fetch_connected_accounts',
        ) as string,
      );

      return null;
    }
  }

  async function getManagedCompaniesByCompanyId(
    params: GetManagedCompaniesByCompanyIdParams,
  ) {
    try {
      const args: GetV1CompaniesCompanyIdManagedCompaniesData = {
        path: { 'company-id': params.companyId },
        query: {
          'page[limit]': QUERY_PAGINATION_LIMIT,
          'page[after]': params.pagination?.after,
          'page[before]': params.pagination?.before,
        },
      };

      const response = await getV1CompaniesCompanyIdManagedCompanies(args);
      const companies = response.data.data.map((company) => Company.parse(company));
      const pagination = extractPagination(response);

      return { data: companies, pagination };
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.managed_companies.failed_to_fetch_managed_companies') as string,
      );

      return null;
    }
  }

  return {
    createManagedCompany,
    deleteManagedCompany,
    getConnectedAccountsByCompanyId,
    getManagedCompaniesByCompanyId,
  };
}
