import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  InvoiceCategoryFilter,
  RateOwnerType,
  RateType,
} from '@treadinc/horizon-api-spec';
import { t, use } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { Invoice } from '~hooks/useInvoices';
import { useRates } from '~hooks/useRates';
import * as colsDef from '~pages/Approvals/DriverPay/driversPayDataGridColumnsDefinition';
import {
  RateDetailsDTO,
  rateDetailsSchema,
} from '~pages/Sales/Orders/NewOrderForm/schema';
import {
  getRateTypeLabel,
  getRateTypeOptions,
} from '~pages/Settings/RatesManagement/rateUtils';
import theme from '~theme/AppTheme';

interface VendorRatePanelProps {
  invoice: Invoice;
  utils: colsDef.DriverPayRowDef['row']['utils'];
  category: InvoiceCategoryFilter;
  isEditing: boolean;
  accountId?: string;
  ref: React.RefObject<VendorRatePanelHandler>;
}
export type VendorRatePanelHandler = {
  onSubmit?: () => Promise<{
    targetRateValue?: number | null | undefined;
    targetRate: {
      id: string;
      name: string;
      type: string;
      rate: number;
    } | null;
    targetRateType: {
      label: string;
      value: string;
    } | null;
  }>;
};

const VendorRatePanel = observer(
  forwardRef<VendorRatePanelHandler, VendorRatePanelProps>(function VendorRatePanel(
    { invoice, utils, category, isEditing, accountId },
    ref,
  ) {
    const isReceivable = category === InvoiceCategoryFilter.RECEIVABLES;
    const { getAccountRatesUsingOwnerType } = useRates();
    const fetchRates = async (search: string) => {
      return await getAccountRatesUsingOwnerType({
        driverId: invoice.driver?.id,
        ownerType: isReceivable ? RateOwnerType.CUSTOMER : RateOwnerType.VENDOR,
        query: search,
        accountId: accountId ?? undefined,
      });
    };

    const rateTypeOptions = getRateTypeOptions();
    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
      watch,
    } = useForm<RateDetailsDTO>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      resolver: yupResolver(rateDetailsSchema),
      defaultValues: {
        targetRate: invoice.rate ?? null,
        targetRateType: invoice.rateType
          ? { label: getRateTypeLabel(invoice.rateType), value: invoice.rateType }
          : null,
        targetRateValue: invoice.rateValue ?? 0,
      },
    });

    const { targetRateType, targetRateValue, targetRate } = watch();

    useEffect(() => {
      if (targetRateValue !== targetRate?.rate) {
        setValue('targetRate', null);
      }
    }, [targetRateValue]);

    useEffect(() => {
      if (targetRateType?.value !== targetRate?.type) {
        setValue('targetRate', null);
      }
    }, [targetRateType]);

    const handleClearTargetRate = useCallback(() => {
      setValue('targetRate', null);
      setValue('targetRateType', null);
      setValue('targetRateValue', 0);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        onSubmit: () => {
          return new Promise((resolve) => {
            handleSubmit((data) => {
              resolve(data);
            })();
          });
        },
      }),
      [],
    );

    return (
      <Box display="flex" alignItems="center" gap={3} width={'75%'}>
        {isEditing ? (
          <AutocompleteAsyncFormField
            name="targetRate"
            control={control}
            label={
              isReceivable
                ? (t('approvals.driver_pay.tabs.driver_pay.customer_rate_card') as string)
                : (t('approvals.driver_pay.tabs.driver_pay.vendor_rate_card') as string)
            }
            getLabel={(rate) => rate.name}
            getValue={(rate) => rate.id}
            asyncCallback={fetchRates}
            errors={errors}
            clearable
            onClear={handleClearTargetRate}
            onSelect={(rate) => {
              setValue('targetRateType', {
                label: getRateTypeLabel(rate.type.value as RateType),
                value: rate.type.value,
              });
              setValue('targetRateValue', rate.timeRate);
              return {
                id: rate.id,
                name: rate.name,
                type: rate.type.value,
                rate: rate.timeRate,
              };
            }}
          />
        ) : (
          <PanelField
            label={
              isReceivable
                ? t('approvals.driver_pay.tabs.driver_pay.customer_rate_card')
                : t('approvals.driver_pay.tabs.driver_pay.vendor_rate_card')
            }
            content={invoice.rate?.name ?? ''}
          />
        )}

        {isEditing ? (
          <AutocompleteFormField
            name="targetRateType"
            control={control}
            label={
              isReceivable
                ? (t('approvals.driver_pay.tabs.driver_pay.rate_type') as string)
                : (t('approvals.driver_pay.tabs.driver_pay.vendor_rate_type') as string)
            }
            list={rateTypeOptions}
            errors={errors}
            isRequired={true}
            getLabel={(item) => item?.label ?? getRateTypeLabel(item)}
            getValue={(item) => item?.value ?? item}
            clearable
            onSelect={(rateType) => {
              setValue('targetRate', null);
              return rateType;
            }}
          />
        ) : (
          <PanelField
            label={
              isReceivable
                ? t('approvals.driver_pay.tabs.driver_pay.rate_type')
                : t('approvals.driver_pay.tabs.driver_pay.vendor_rate_type')
            }
            content={
              invoice.rateType
                ? t(`administration.rates.${_.snakeCase(invoice.rateType)}`)
                : ''
            }
          />
        )}

        {isEditing ? (
          <TextFormField
            name="targetRateValue"
            control={control}
            label={
              isReceivable
                ? (t('approvals.driver_pay.tabs.driver_pay.rate') as string)
                : (t('approvals.driver_pay.tabs.driver_pay.vendor_rate') as string)
            }
            errors={errors}
            type="number"
          />
        ) : (
          <PanelField
            label={
              isReceivable
                ? t('approvals.driver_pay.tabs.driver_pay.rate')
                : t('approvals.driver_pay.tabs.driver_pay.vendor_rate')
            }
            content={
              _.isNil(invoice.rateValue) ? '' : utils.currencyFormatter(invoice.rateValue)
            }
          />
        )}
      </Box>
    );
  }),
);

interface PanelFieldProps {
  content: string;
  label: string;
}

const PanelField = ({ content, label }: PanelFieldProps) => (
  <Box display="flex" flexDirection="column" gap={0.5} width="250px">
    <Typography>{label}</Typography>
    <Typography
      bgcolor={theme.palette.grey[100]}
      borderRadius={1}
      fontWeight={700}
      px={2}
      py={1}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      minHeight="37px"
    >
      {content}
    </Typography>
  </Box>
);

export default VendorRatePanel;
