const realTimeChannels = {
  CompanyJobUpdateChannel: 'CompanyJobUpdateChannel',
  CompanyOrderUpdateChannel: 'CompanyOrderUpdateChannel',
  DeploymentChannel: 'DeploymentChannel',
  DriverDayUpdateChannel: 'DriverDayUpdateChannel',
  InaccessibleJobChannel: 'InaccessibleJobChannel',
  InvoiceUpdateChannel: 'InvoiceUpdateChannel',
  NextBillionLastLocationChannel: 'NextBillionLastLocationChannel',
  SettlementUpdateChannel: 'SettlementUpdateChannel',
  UserBulkAssignJobsChannel: 'UserBulkAssignJobsChannel',
  UserBulkCopyAssignmentsChannel: 'UserBulkCopyAssignmentsChannel',
  UserBulkSendJobsChannel: 'UserBulkSendJobsChannel',
  TicketUpdateChannel: 'TicketUpdateChannel',
  AgaveLinkedAccountChannel: 'AgaveLinkedAccountChannel',
  AgaveCustomerChannel: 'AgaveCustomerChannel',
  AgaveVendorChannel: 'AgaveVendorChannel',
  AgaveItemChannel: 'AgaveItemChannel',
  AgaveLedgerAccountChannel: 'AgaveLedgerAccountChannel',
  LiveSiteChannel: 'LiveSiteChannel',
};
export { realTimeChannels };
