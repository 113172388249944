import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BoxOwnProps } from '@mui/system/Box';
import {
  InvoiceCategoryFilter,
  InvoiceState as InvoiceStateEnums,
  RateType,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { GridCardWrapper } from '~components/Cards/GridCardWrapper';
import { SimpleCard } from '~components/Cards/SimpleCard';
import InvoiceState from '~components/Invoice/InvoiceState';
import { RouteMap } from '~components/Job/RouteMap';
import { SmallTabs } from '~components/Tabs/SmallTabs';
import { useAddOns } from '~hooks/useAddOns';
import { InvoiceLineItem, useInvoiceLineItems } from '~hooks/useInvoiceLineItems';
import { Invoice, useInvoices } from '~hooks/useInvoices';
import { Job } from '~hooks/useJob';
import { InfoIcon } from '~icons/InfoIcon';
import * as colsDef from '~pages/Approvals/DriverPay/driversPayDataGridColumnsDefinition';
import LineItemsTable, {
  LineItemsFormHandler,
} from '~pages/Approvals/DriverPay/LineItemsTable';
import VendorRatePanel, {
  VendorRatePanelHandler,
} from '~pages/Approvals/DriverPay/VendorRatePanel';
import { useStores } from '~store';
import { InvoiceSearchParamName } from '~store/InvoicesStore';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';

import { CommonLoader } from '../Loaders/CommonLoader';

interface InvoicesTabContentProps {
  invoice: Invoice;
  job: Job;
  utils: colsDef.DriverPayRowDef['row']['utils'];
  isDayDetailsView?: boolean;
  customHeader?: React.ReactNode;
  customCategory?: InvoiceCategoryFilter;
  reload: () => void;
}

export default function InvoicesTabContent({
  invoice,
  job,
  utils,
  isDayDetailsView = false,
  customHeader,
  customCategory,
  reload,
}: InvoicesTabContentProps) {
  const { toasterStore, invoicesStore } = useStores();
  const [isEditing, setIsEditing] = useState(false);
  const currentCategory =
    invoicesStore.searchParams[InvoiceSearchParamName.INVOICE_CATEGORY];

  const canEditLineItems = useMemo(() => {
    return [InvoiceStateEnums.PENDING, InvoiceStateEnums.CUSTOMER_PENDING].includes(
      invoice.state,
    );
  }, [invoice.state]);

  const lineItemsFormRef = useRef<LineItemsFormHandler>({});
  const vendorRatePanelRef = useRef<VendorRatePanelHandler>(null);
  const { batchUpdateInvoiceLineItems } = useInvoiceLineItems();
  const { batchUpdateAddOnCharges } = useAddOns();
  const { updateInvoiceRate, isLoading, isUpdating } = useInvoices();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const data = await lineItemsFormRef.current.onSubmit?.();
    const rateData = await vendorRatePanelRef.current?.onSubmit?.();

    let updatesMade = false;

    const promises = [];

    if (rateData) {
      promises.push(
        updateInvoiceRate({
          invoiceId: String(invoice.id),
          rate_id: rateData.targetRate?.id ?? null,
          rate_type: rateData.targetRateType?.value as RateType,
          rate_value: rateData.targetRateValue ?? null,
        }),
      );
    }
    if (data?.lineItems.length) {
      promises.push(
        batchUpdateInvoiceLineItems(
          String(invoice.id),
          data.lineItems as Array<Partial<InvoiceLineItem>>,
        ),
      );
    }

    if (data?.addOnCharges.length) {
      promises.push(batchUpdateAddOnCharges(String(invoice.id), data.addOnCharges));
    }

    Promise.all(promises)
      .then(() => {
        updatesMade = true;
      })
      .finally(() => {
        setIsEditing(false);
        if (updatesMade) {
          toasterStore.push(
            alert(
              t('approvals.driver_pay.invoice_updated_successfully'),
              AlertTypes.success,
            ),
          );
        } else {
          setIsEditing(false);
        }
        reload();
      });
  };

  const onApproveInvoice = () => {
    utils.approveInvoice(invoice);
  };

  const DayDetailsViewCTA = () => {
    if (isEditing) {
      return (
        <Box display="flex" gap={1} alignItems="center">
          <Button
            color="inherit"
            onClick={() => setIsEditing(false)}
            startIcon={<Close />}
          >
            {t('actions.discard')}
          </Button>

          <Button type="submit" color="success" startIcon={<Check />}>
            {t('actions.save')}
          </Button>
        </Box>
      );
    } else if (canEditLineItems) {
      return (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => setIsEditing(true)}
            startIcon={<Edit />}
            variant="outlined"
            color="secondary"
          >
            {t('actions.edit')}
          </Button>
          <Button onClick={() => onApproveInvoice()} color="success">
            {t('actions.approve')}
          </Button>
        </Box>
      );
    } else if (invoice.state === InvoiceStateEnums.APPROVED) {
      return (
        <Box
          sx={{
            mt: 2,
          }}
        >
          <InvoiceState state={invoice.state} />
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            display: 'flex',
            gap: 0.5,
            alignItems: 'center',
            flex: 1,
            mt: 2,
          }}
        >
          <InfoIcon />
          <Typography
            color={theme.brandV2.colors.treadBlack}
            fontWeight={400}
            className="MuiTypography-body1"
          >
            {t('approvals.driver_day.approval_and_editing_not_allowed')}
          </Typography>
        </Box>
      );
    }
  };

  const DayDetailsView = () => {
    return (
      <Box display="flex" gap={2}>
        <Box
          {...(isEditing
            ? {
                component: 'form',
                noValidate: true,
                autoComplete: 'off',
                onSubmit: handleSubmit,
              }
            : {})}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: 2,

            gap: 1.5,

            backgroundColor: 'white',
            border: `1px solid ${theme.brandV2.colors.treadGray7}`,
            borderRadius: 2,
            pb: 1,
          }}
        >
          {customHeader}
          <CardPanel
            hasBorderBottom
            display={'flex'}
            sx={{
              justifyContent: 'space-between',
              alignItems: isEditing || canEditLineItems ? 'end' : 'center',
            }}
          >
            <VendorRatePanel
              invoice={invoice}
              utils={utils}
              category={customCategory || InvoiceCategoryFilter.PAYABLES}
              isEditing={isEditing}
              accountId={job.order?.account?.id}
              ref={vendorRatePanelRef}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <DayDetailsViewCTA />
            </Box>
          </CardPanel>

          <CardPanel>
            <LineItemsTable
              invoice={invoice}
              isEditing={isEditing}
              job={job}
              ref={lineItemsFormRef}
              utils={utils}
            />
          </CardPanel>
        </Box>

        <Box minWidth="500px" flex={0}>
          <SmallTabs
            tabs={[
              {
                label: 'Map',
                id: `map_${job.id}`,
                content: (
                  <Box sx={{ m: -2 }}>
                    <RouteMap job={job} />
                  </Box>
                ),
              },
            ]}
          />
        </Box>
      </Box>
    );
  };

  return isLoading || isUpdating ? (
    <CommonLoader />
  ) : isDayDetailsView ? (
    <DayDetailsView />
  ) : (
    <Box display="flex" gap={2}>
      <Box flex={1} overflow="auto">
        <GridCardWrapper>
          <SimpleCard>
            <Box
              m={-2}
              {...(isEditing
                ? {
                    component: 'form',
                    noValidate: true,
                    autoComplete: 'off',
                    onSubmit: handleSubmit,
                  }
                : {})}
            >
              <CardPanel hasBorderBottom>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography color={theme.palette.text.secondary} fontWeight={700}>
                    {currentCategory === InvoiceCategoryFilter.RECEIVABLES
                      ? t('page_headings.driver_day_receivables')
                      : t('page_headings.driver_pay')}
                  </Typography>

                  {isEditing ? (
                    <Box display="flex" gap={1} alignItems="center">
                      <Button
                        color="inherit"
                        onClick={() => setIsEditing(false)}
                        startIcon={<Close />}
                      >
                        {t('actions.discard')}
                      </Button>

                      <Button type="submit" color="success" startIcon={<Check />}>
                        {t('actions.save')}
                      </Button>
                    </Box>
                  ) : canEditLineItems ? (
                    <Button onClick={() => setIsEditing(true)} startIcon={<Edit />}>
                      {t('actions.edit')}
                    </Button>
                  ) : null}
                </Box>
              </CardPanel>

              <CardPanel hasBorderBottom>
                <VendorRatePanel
                  invoice={invoice}
                  utils={utils}
                  category={currentCategory || InvoiceCategoryFilter.PAYABLES}
                  isEditing={isEditing}
                  ref={vendorRatePanelRef}
                />
              </CardPanel>

              <CardPanel>
                <LineItemsTable
                  invoice={invoice}
                  isEditing={isEditing}
                  job={job}
                  ref={lineItemsFormRef}
                  utils={utils}
                />
              </CardPanel>
            </Box>
          </SimpleCard>
        </GridCardWrapper>
      </Box>

      <Box minWidth="500px" flex={0}>
        <SmallTabs
          tabs={[
            {
              label: 'Map',
              id: `map_${job.id}`,
              content: (
                <Box sx={{ m: -2 }}>
                  <RouteMap job={job} />
                </Box>
              ),
            },
          ]}
        />
      </Box>
    </Box>
  );
}

interface CardPanelProps extends BoxOwnProps {
  hasBorderBottom?: boolean;
}

const CardPanel = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'hasBorderBottom',
})<CardPanelProps>(({ theme, hasBorderBottom }) => ({
  '&.MuiBox-root': {
    padding: theme.spacing(2),
    ...(hasBorderBottom ? { borderBottom: `solid 1px ${theme.palette.divider}` } : {}),
  },
}));

interface ButtonProps extends MuiButtonProps {}

const Button = ({ color = 'primary', ...rest }: ButtonProps) => (
  <MuiButton size="small" sx={{ height: '33px' }} color={color} {...rest} />
);
