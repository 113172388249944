import { Invoice_Read, InvoiceState, RateType } from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';

import { AccountBasic } from '~hooks/useAccount/models';
import { DriverBasic } from '~hooks/useDrivers/models';
import { BasicEquipment } from '~hooks/useEquipment/models';
import { JobBasic, JobSummary } from '~hooks/useJob/models';
import { BasicMaterial } from '~hooks/useMaterials/models';
import { OrderBasic } from '~hooks/useOrders/models';
import { ProjectBasic } from '~hooks/useProjects/models';
import { RateBasicWithValue } from '~hooks/useRates/models';
import { SiteBasic } from '~hooks/useSites/models';
import { Nullable } from '~types/Nullable';

export class Invoice {
  constructor(
    private _id: Nullable<string>,
    private _state: InvoiceState,
    private _total_amount: number,
    private _order: Nullable<OrderBasic>,
    private _project: Nullable<ProjectBasic>,
    private _customer_account: Nullable<AccountBasic>,
    private _vendor_account: Nullable<AccountBasic>,
    private _equipment: Nullable<BasicEquipment>,
    private _material: Nullable<BasicMaterial>,
    private _driver: Nullable<DriverBasic>,
    private _pickup_site: Nullable<SiteBasic>,
    private _dropoff_site: Nullable<SiteBasic>,
    private _job: Nullable<JobBasic>,
    private _invoice_id: string,
    private _rate: Nullable<RateBasicWithValue>,
    private _job_summary: Nullable<JobSummary>,
    private _invoice_line_items_total_quantity: number,
    private _rate_value: Nullable<number>,
    private _rate_type: Nullable<RateType>,
    private _truck_id: Nullable<string>,
    private _editable: boolean,
    private _order_order_ids: string[],
    private _project_names: string[],
    private _equipment_type_names: string[],
    private _material_names: string[],
    private _pickup_site_names: string[],
    private _dropoff_site_names: string[],
    private _job_job_ids: string[],
    private _equipment_external_ids: string[],
    private _total_loads_count: number,
    private _pay_start_time: Nullable<Dayjs>,
    private _pay_end_time: Nullable<Dayjs>,
  ) {}

  public get id() {
    return this._id;
  }

  public get state() {
    return this._state;
  }

  public get totalAmount() {
    return this._total_amount;
  }

  public get order() {
    return this._order;
  }

  public get project() {
    return this._project;
  }

  public get customerAccount() {
    return this._customer_account;
  }

  public get vendorAccount() {
    return this._vendor_account;
  }

  public get equipment() {
    return this._equipment;
  }

  public get material() {
    return this._material;
  }

  public get driver() {
    return this._driver;
  }

  public get pickupSite() {
    return this._pickup_site;
  }

  public get dropoffSite() {
    return this._dropoff_site;
  }

  public get job() {
    return this._job;
  }

  public get invoiceId() {
    return this._invoice_id;
  }

  public get rate() {
    return this._rate;
  }

  public get jobSummary() {
    return this._job_summary;
  }

  public get invoiceLineItemsTotalQuantity() {
    return this._invoice_line_items_total_quantity;
  }

  public get rateValue() {
    return this._rate_value;
  }

  public get rateType() {
    return this._rate_type;
  }
  public get truckId() {
    return this._truck_id;
  }

  public get editable() {
    return this._editable;
  }

  public get orderOrderIds() {
    return this._order_order_ids;
  }

  public get projectNames() {
    return this._project_names;
  }

  public get equipmentTypeNames() {
    return this._equipment_type_names;
  }

  public get materialNames() {
    return this._material_names;
  }

  public get pickupSiteNames() {
    return this._pickup_site_names;
  }

  public get dropoffSiteNames() {
    return this._dropoff_site_names;
  }

  public get jobJobIds() {
    return this._job_job_ids;
  }

  public get equipmentExternalIds() {
    return this._equipment_external_ids;
  }

  public get totalLoadsCount() {
    return this._total_loads_count;
  }

  public get payStartTime() {
    return this._pay_start_time;
  }

  public get payEndTime() {
    return this._pay_end_time;
  }

  public static parse(proto: Invoice_Read) {
    return new Invoice(
      proto.id,
      proto.state,
      Number(proto.total_amount),
      proto.order ? OrderBasic.parse(proto.order) : null,
      proto.project ? ProjectBasic.parse(proto.project) : null,
      proto.customer_account ? AccountBasic.parse(proto.customer_account) : null,
      proto.vendor_account ? AccountBasic.parse(proto.vendor_account) : null,
      proto.equipment ? BasicEquipment.parse(proto.equipment) : null,
      proto.material ? BasicMaterial.parse(proto.material) : null,
      proto.driver ? DriverBasic.parse(proto.driver) : null,
      proto.pickup_site ? SiteBasic.parse(proto.pickup_site) : null,
      proto.dropoff_site ? SiteBasic.parse(proto.dropoff_site) : null,
      proto.job ? JobBasic.parse(proto.job) : null,
      proto.invoice_id,
      proto.rate ? RateBasicWithValue.parse(proto.rate) : null,
      proto.job_summary ? JobSummary.parse(proto.job_summary) : null,
      Number(proto.invoice_line_items_total_quantity),
      _.isNil(proto.rate_value) ? null : Number(proto.rate_value),
      proto.rate_type ?? null,
      proto.truck_id ?? null,
      proto.editable,
      proto.order_order_ids,
      proto.project_names,
      proto.equipment_type_names,
      proto.material_names,
      proto.pickup_site_names,
      proto.dropoff_site_names,
      proto.job_job_ids,
      proto.equipment_external_ids,
      Number(proto.total_loads_count),
      proto.pay_start_time ? dayjs(proto.pay_start_time) : null,
      proto.pay_end_time ? dayjs(proto.pay_end_time) : null,
    );
  }
}
