import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-premium';
import { t } from 'i18next';
import _ from 'lodash';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { DataGridTableAPI } from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { MaterialRate } from '~hooks/useMaterialRates/models';
import useMaterialRates from '~hooks/useMaterialRates/useMaterialRates';
import connection from '~services/connectionModule';
import { Pagination } from '~services/pagination';

const QUERY_PAGINATION_LIMIT = 100;

interface MaterialRateGridState {
  isLoading: boolean;
  materialRates: MaterialRate[];
  pagination?: Pagination;
}

interface MaterialRatesDataGridProps {
  companyId: string;
  onDelete: (materialRate: MaterialRate) => void;
  onEdit: (materialRate: MaterialRate) => void;
}

export type MaterialRatesDataGridHandler = {
  refetch: () => void;
};

const MaterialRatesDataGrid = forwardRef<
  MaterialRatesDataGridHandler,
  MaterialRatesDataGridProps
>(function MaterialRatesDataGrid({ companyId, onDelete, onEdit }, ref) {
  const { currencyFormatter } = useCompanyCurrency();

  const { getCompanyMaterialRates } = useMaterialRates();

  const [materialRatesGridState, setMaterialRatesGridState] =
    useState<MaterialRateGridState>({
      isLoading: true,
      materialRates: [],
    });

  const [dataGridPagination, setDataGridPagination] = useState<Pagination>({
    limit: QUERY_PAGINATION_LIMIT,
    page: 1,
  });

  const dataGridRef = useRef<DataGridTableAPI>(null);

  const loadingRef = useRef(materialRatesGridState.isLoading);
  loadingRef.current = materialRatesGridState.isLoading;

  const columns = useMemo(() => {
    const columns: Array<GridColDef<{ id: string; data: MaterialRate }>> = [
      {
        flex: 1,
        field: 'project',
        headerName: `${t('form_fields.project')}`,
        valueGetter: ({ row }) => row.data.project?.name,
      },
      {
        flex: 1,
        field: 'material',
        headerName: `${t('form_fields.material')}`,
        valueGetter: ({ row }) => row.data.material.name,
      },
      {
        flex: 1,
        field: 'unit',
        headerName: `${t('form_fields.unit')}`,
        valueGetter: ({ row }) => row.data.unitOfMeasure,
      },
      {
        flex: 1,
        field: 'site',
        headerName: `${t('form_fields.site')}`,
        valueGetter: ({ row }) => row.data.site?.name,
      },
      {
        flex: 1,
        field: 'buyPrice',
        headerName: `${t('administration.material_rates.buy_price')}`,
        valueGetter: ({ row }) => row.data.buyPrice,
        renderCell: ({ row }) => (
          <Typography sx={{ fontSize: '12px' }}>
            {_.isNil(row.data.buyPrice) ? '' : currencyFormatter(row.data.buyPrice)}
          </Typography>
        ),
      },
      {
        flex: 1,
        field: 'sellPrice',
        headerName: `${t('administration.material_rates.sell_price')}`,
        valueGetter: ({ row }) => row.data.sellPrice,
        renderCell: ({ row }) => (
          <Typography sx={{ fontSize: '12px' }}>
            {_.isNil(row.data.sellPrice) ? '' : currencyFormatter(row.data.sellPrice)}
          </Typography>
        ),
      },
      {
        width: 96,
        field: 'actions',
        disableColumnMenu: true,
        filterable: false,
        hideable: false,
        sortable: false,
        type: 'actions',
        renderHeader: () => {
          return (
            <HeaderNavigation
              count={materialRatesGridState.materialRates.length}
              loading={loadingRef.current}
              pagination={{
                ...dataGridPagination,
                before: materialRatesGridState.pagination?.before,
                after: materialRatesGridState.pagination?.after,
              }}
              callback={(link) => {
                if (!link) {
                  return;
                }

                if (link === 'after') {
                  setDataGridPagination((state) => ({
                    page: state.page + 1,
                    limit: QUERY_PAGINATION_LIMIT,
                    after: materialRatesGridState.pagination?.after,
                  }));
                } else {
                  setDataGridPagination((state) => ({
                    page: state.page - 1,
                    limit: QUERY_PAGINATION_LIMIT,
                    before: materialRatesGridState.pagination?.before,
                  }));
                }
              }}
              altText={`${t('actions.actions')}`}
              searchQuery=""
            />
          );
        },
        renderCell: (params) => {
          const options = [
            {
              callBack: () => onDelete(params.row.data),
              icon: <Delete />,
              title: `${t('actions.delete')}`,
            },
          ];

          return (
            <>
              <BasicTooltip
                title={t('administration.material_rates.edit_material_rate_cta')}
              >
                <IconButton onClick={() => onEdit(params.row.data)} size="small">
                  <Edit />
                </IconButton>
              </BasicTooltip>

              <SimpleMenu options={options} size="small">
                <MoreHoriz />
              </SimpleMenu>
            </>
          );
        },
      },
    ];

    return columns;
  }, [
    dataGridPagination,
    materialRatesGridState.materialRates,
    materialRatesGridState.pagination,
  ]);

  const rows = useMemo(() => {
    return materialRatesGridState.materialRates.map((materialRate) => ({
      id: materialRate.id,
      data: materialRate,
    }));
  }, [materialRatesGridState.materialRates]);

  const fetchMaterialRates = useCallback(async () => {
    try {
      const { data, pagination } = await getCompanyMaterialRates(
        companyId,
        dataGridPagination,
      );

      setMaterialRatesGridState({ isLoading: false, materialRates: data, pagination });
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.material_rates.failed_to_fetch_material_rates') as string,
      );
    }
  }, [companyId, dataGridPagination]);

  useEffect(() => {
    fetchMaterialRates();
  }, [fetchMaterialRates]);

  useEffect(() => {
    dataGridRef.current?.updateColumns(columns);
  }, [columns]);

  useImperativeHandle(
    ref,
    () => ({
      refetch() {
        fetchMaterialRates();
      },
    }),
    [fetchMaterialRates],
  );

  return (
    <DataGrid
      id="material-rates-grid"
      ref={dataGridRef}
      columns={columns}
      disableColumnFilter
      hideExport
      hideToolbar
      loading={materialRatesGridState.isLoading}
      rows={rows}
      sx={{
        '& .MuiDataGrid-root': { p: 0, borderBottom: 0 },
        "& .MuiDataGrid-cell[data-field='actions']": { px: '5px' },
      }}
    />
  );
});

export default MaterialRatesDataGrid;
