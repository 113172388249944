import { useRef, useState } from 'react';

import { FormStateChangeProps } from '~formsShared';
import { EquipmentFormHandlers } from '~pages/Settings/Administration/EquipmentManagement/EquipmentForm';

export const useEquipmentForm = () => {
  const equipmentFormRef = useRef<EquipmentFormHandlers>(null);
  const [isEquipmentFormOpen, setIsEquipmentFormOpen] = useState(false);
  const [isEquipmentFormDirty, setIsEquipmentFormDirty] = useState(false);

  const createNewEquipment = () => {
    setIsEquipmentFormOpen(true);
  };

  const onEquipmentFormStateChange = ({ isDirty }: FormStateChangeProps) => {
    setIsEquipmentFormDirty(isDirty);
  };

  return {
    equipmentFormRef,
    isEquipmentFormOpen,
    setIsEquipmentFormOpen,
    isEquipmentFormDirty,
    setIsEquipmentFormDirty,
    createNewEquipment,
    onEquipmentFormStateChange,
  };
};
