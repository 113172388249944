import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import {
  AccountType,
  getV1CompaniesAccountsTypeahead,
  getV1CompaniesCompanyIdMaterialTypeahead,
  getV1CompaniesCompanyIdProjectsTypeahead,
  getV1CompaniesCompanyIdSiteTypeahead,
  ModelError_Item,
  ProjectState,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { useForm } from 'react-hook-form';

import {
  AutocompleteAsyncFormField,
  AutocompleteAsyncFormFieldRef,
} from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { unitOfMeasureOptions } from '~constants/enums';
import { NameIdSchemaRequired } from '~constants/regexConst';
import { AccountTypeahead } from '~hooks/useAccount';
import { MaterialRate } from '~hooks/useMaterialRates/models';
import { MaterialTypeahead } from '~hooks/useMaterials';
import { ProjectTypeahead } from '~hooks/useProjects/models';
import { SiteTypeahead } from '~hooks/useSites';
import { extractPagination } from '~services/pagination';
import { useStores } from '~store';

import {
  MaterialRateDTO,
  materialRateSchema,
  setMaterialRatesSchemaDefaultValues,
} from './materialRateSchema';

interface MaterialRateFormProps {
  errors?: ModelError_Item[];
  materialRate?: MaterialRate;
}

export type MaterialRateFormHandler = {
  submit: () => Promise<MaterialRateDTO>;
};

const MaterialRateForm = forwardRef<MaterialRateFormHandler, MaterialRateFormProps>(
  function MaterialRateForm({ errors, materialRate }, ref) {
    const { userStore } = useStores();

    const form = useForm<MaterialRateDTO>({
      resolver: yupResolver(materialRateSchema),
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: setMaterialRatesSchemaDefaultValues(materialRate),
    });

    const projectAutocompleteRef = useRef<AutocompleteAsyncFormFieldRef>(null);

    const companyId = userStore.userCompany?.id;
    const account = form.watch('customer') as AccountTypeahead | null;

    const fetchCustomers = useCallback(
      async (additionalProps: { query?: string; cursor?: string } = {}) => {
        const response = await getV1CompaniesAccountsTypeahead({
          path: { 'company-id': companyId },
          query: {
            'filter[account_types]': [AccountType.CUSTOMER],
            'search[query]': additionalProps.query || undefined,
            'page[limit]': 25,
            ...(additionalProps.cursor ? { 'page[after]': additionalProps.cursor } : {}),
          },
        });

        const customers = response.data.data.map((customer) => {
          const parsed = AccountTypeahead.parse(customer);

          return { id: parsed.id, name: parsed.name };
        });

        return { data: customers, pagination: extractPagination(response) };
      },
      [companyId],
    );

    const fetchProjects = useCallback(
      async (additionalProps: { query?: string; cursor?: string } = {}) => {
        if (!account?.id) {
          return { data: [] };
        }

        const response = await getV1CompaniesCompanyIdProjectsTypeahead({
          path: { 'company-id': companyId },
          query: {
            'filter[states]': [ProjectState.ACTIVE],
            'filter[account_id]': account.id,
            'page[limit]': 25,
            ...(additionalProps.cursor ? { 'page[after]': additionalProps.cursor } : {}),
          },
        });

        const projects = response.data.data.map((project) => {
          const parsed = ProjectTypeahead.parse(project);

          return { id: parsed.id, name: parsed.name };
        });

        return { data: projects, pagination: extractPagination(response) };
      },
      [companyId, account?.id],
    );

    const fetchSites = useCallback(
      async (additionalProps: { query?: string; cursor?: string } = {}) => {
        const response = await getV1CompaniesCompanyIdSiteTypeahead({
          path: { 'company-id': companyId },
          query: {
            'page[limit]': 25,
            ...(additionalProps.cursor ? { 'page[after]': additionalProps.cursor } : {}),
          },
        });

        const sites = response.data.data.map((site) => {
          const parsed = SiteTypeahead.parse(site);

          return { id: parsed.id, name: parsed.name };
        });

        return { data: sites, pagination: extractPagination(response) };
      },
      [companyId],
    );

    const fetchMaterials = useCallback(
      async (additionalProps: { query?: string; cursor?: string } = {}) => {
        const response = await getV1CompaniesCompanyIdMaterialTypeahead({
          path: { 'company-id': companyId },
          query: {
            'page[limit]': 25,
            ...(additionalProps.cursor ? { 'page[after]': additionalProps.cursor } : {}),
          },
        });

        const materials = response.data.data.map((material) => {
          const parsed = MaterialTypeahead.parse(material);
          const name = `${parsed.externalId ? `${parsed.externalId} - ` : ''}${parsed.name}`;

          return { id: parsed.id, name };
        });

        return { data: materials, pagination: extractPagination(response) };
      },
      [companyId],
    );

    useImperativeHandle(
      ref,
      () => ({
        submit() {
          return new Promise((resolve) => {
            form.handleSubmit((data) => resolve(data))();
          });
        },
      }),
      [],
    );

    useEffect(() => {
      if (errors) {
        for (const error of errors) {
          if (error.field === 'sell_price') {
            form.setError('sellPrice', { type: 'manual', message: error.message });
          }
        }
      }
    }, [errors]);

    return (
      <Box mt={2.5}>
        <Box display="grid" gridTemplateColumns="repeat(6, 1fr)" gap={2}>
          <AutocompleteAsyncFormField
            asyncCallback={fetchCustomers}
            control={form.control}
            debounceTime={500}
            errors={form.formState.errors}
            getLabel={(item: NameIdSchemaRequired | null) => item?.name ?? ''}
            getValue={(item: NameIdSchemaRequired | null) => item?.id ?? ''}
            label={`${t('form_fields.customer')}`}
            name="customer"
            sx={{ gridColumn: 'span 6' }}
            onSelect={(option) => {
              form.setValue('project', { id: '', name: '' });
              projectAutocompleteRef.current?.reset();

              return option;
            }}
          />

          <Divider sx={{ gridColumn: 'span 6' }} />

          <FormSectionTitle
            title={`${t('administration.material_rates.add_rate_form_section_title')}`}
            sx={{ gridColumn: 'span 6' }}
          />

          <AutocompleteAsyncFormField
            ref={projectAutocompleteRef}
            asyncCallback={fetchProjects}
            control={form.control}
            disabled={!account?.id}
            errors={form.formState.errors}
            getLabel={(item: NameIdSchemaRequired | null) => item?.name ?? ''}
            getValue={(item: NameIdSchemaRequired | null) => item?.id ?? ''}
            label={`${t('form_fields.project')}`}
            name="project"
            sx={{ gridColumn: 'span 6' }}
          />

          <AutocompleteAsyncFormField
            asyncCallback={fetchSites}
            control={form.control}
            errors={form.formState.errors}
            getLabel={(item: NameIdSchemaRequired | null) => item?.name ?? ''}
            getValue={(item: NameIdSchemaRequired | null) => item?.id ?? ''}
            label={`${t('administration.material_rates.material_source')}`}
            name="site"
            sx={{ gridColumn: 'span 6' }}
          />

          <AutocompleteAsyncFormField
            asyncCallback={fetchMaterials}
            control={form.control}
            errors={form.formState.errors}
            getLabel={(item: NameIdSchemaRequired | null) => item?.name ?? ''}
            getValue={(item: NameIdSchemaRequired | null) => item?.id ?? ''}
            isRequired
            label={`${t('form_fields.material')}`}
            name="material"
            sx={{ gridColumn: 'span 4' }}
          />

          <AutocompleteFormField
            control={form.control}
            errors={form.formState.errors}
            getLabel={(item: NameIdSchemaRequired | null) => item?.name ?? ''}
            getValue={(item: NameIdSchemaRequired | null) => item?.id ?? ''}
            isRequired
            label={`${t('form_fields.unit')}`}
            list={unitOfMeasureOptions}
            name="unitOfMeasure"
            sx={{ gridColumn: 'span 2' }}
          />

          <TextFormField
            control={form.control}
            errors={form.formState.errors}
            label={`${t('administration.material_rates.buy_price')}`}
            name="buyPrice"
            type="number"
            sx={{ gridColumn: 'span 3' }}
          />

          <TextFormField
            control={form.control}
            errors={form.formState.errors}
            isRequired
            label={`${t('administration.material_rates.sell_price')}`}
            name="sellPrice"
            type="number"
            sx={{ gridColumn: 'span 3' }}
          />
        </Box>
      </Box>
    );
  },
);

export default observer(MaterialRateForm);
