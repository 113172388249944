import { OrderState, SiteType } from '@treadinc/horizon-api-spec';
import _ from 'lodash';
import { makeObservable, observable, runInAction } from 'mobx';

import { CalendarDispatchStorageKeys } from '~constants/dispatch';
import { Job } from '~hooks/useJob';
import { Order } from '~hooks/useOrders';
import { Pagination } from '~services/pagination';

const defaultPaginationState: Pagination = { limit: 20, page: 1 };

export type Filters = {
  customerAccountIds?: string[];
  dispatchNumbers?: string[];
  driverIds?: string[];
  dropOffSiteIds?: string[];
  endDate?: string;
  jobStates?: string[];
  orderStates?: OrderState[];
  pickUpSiteIds?: string[];
  projectIds?: string[];
  projectsExternalIds?: string[];
  requesterIds?: string[];
  searchName?: string;
  searchOrder?: string;
  siteTypes?: SiteType[];
  startDate?: string;
  vendorAccountIds?: string[];
};

export type OrderJobsFilters = {
  jobStates?: string[];
};

class CalendarDispatchStore {
  copyVendorAssignmentsFilters: Filters = {};
  copyVendorAssignmentsOrders: Order[] = [];
  copyVendorAssignmentsPagination: Pagination = { ...defaultPaginationState };
  filters: Filters = {};
  hasMore: boolean = false;
  hasMoreCopyVendorAssignmentsOrders: boolean = true;
  isLoadingCopyVendorAssignmentsOrders: boolean = false;
  isLoadingOrderJobs: Map<string, boolean> = new Map();
  isLoadingOrders: boolean = false;
  isSavingOrder: boolean = false;
  orderJobs: Map<string, Job[]> = new Map();
  orders: Order[] = [];
  pagination: Pagination = { ...defaultPaginationState };

  constructor() {
    makeObservable(this, {
      copyVendorAssignmentsFilters: observable,
      copyVendorAssignmentsOrders: observable,
      copyVendorAssignmentsPagination: observable,
      filters: observable,
      hasMore: observable,
      hasMoreCopyVendorAssignmentsOrders: observable,
      isLoadingCopyVendorAssignmentsOrders: observable,
      isLoadingOrderJobs: observable,
      isLoadingOrders: observable,
      isSavingOrder: observable,
      orderJobs: observable,
      orders: observable,
      pagination: observable,
    });
  }

  copyVendorAssignmentsOrdersFetchStart() {
    runInAction(() => {
      this.isLoadingCopyVendorAssignmentsOrders = true;
    });
  }

  copyVendorAssignmentsOrdersFetchEnd(orders: Order[], pagination?: Pagination) {
    runInAction(() => {
      this.isLoadingCopyVendorAssignmentsOrders = false;
      this.copyVendorAssignmentsOrders = _.uniqBy(
        this.copyVendorAssignmentsOrders.concat(orders),
        (order) => order.id,
      );
      this.copyVendorAssignmentsPagination = {
        limit: 8,
        after: pagination?.after ?? '',
        page: defaultPaginationState.page,
      };
      this.hasMoreCopyVendorAssignmentsOrders = Boolean(pagination?.after);
    });
  }

  ordersFetchStart() {
    runInAction(() => {
      this.isLoadingOrders = true;
    });
  }

  ordersFetchEnd(orders: Order[], pagination?: Pagination) {
    runInAction(() => {
      this.isLoadingOrders = false;
      this.orders = _.uniqBy(this.orders.concat(orders), (order) => order.id);
      this.pagination = {
        limit: defaultPaginationState.limit,
        after: pagination?.after ?? '',
        page: defaultPaginationState.page,
      };
      this.hasMore = Boolean(pagination?.after);
    });
  }

  orderCreateStart() {
    runInAction(() => {
      this.isSavingOrder = true;
    });
  }

  orderCreateEnd() {
    runInAction(() => {
      this.isSavingOrder = false;
    });
  }

  orderUpdateStart() {
    runInAction(() => {
      this.isSavingOrder = true;
    });
  }

  orderUpdateEnd(order?: Order) {
    if (!order) {
      runInAction(() => {
        this.isSavingOrder = false;
      });

      return;
    }

    const index = this.orders.findIndex(({ id }) => id === order.id);

    runInAction(() => {
      this.isSavingOrder = false;

      if (index > -1) {
        this.orders.splice(index, 1, order);
      }
    });
  }

  orderJobsFetchStart(orderId: string) {
    runInAction(() => {
      this.isLoadingOrderJobs.set(orderId, true);
      this.orderJobs.set(orderId, []);
    });
  }

  orderJobsFetchEnd(orderId: string, jobs: Job[]) {
    runInAction(() => {
      this.isLoadingOrderJobs.set(orderId, false);
      this.orderJobs.set(orderId, jobs);
    });
  }

  resetCopyVendorAssignmentsOrders(filters: Filters = {}) {
    runInAction(() => {
      this.copyVendorAssignmentsFilters = filters;
      this.copyVendorAssignmentsOrders = [];
      this.copyVendorAssignmentsPagination = { ...defaultPaginationState };
      this.hasMoreCopyVendorAssignmentsOrders = true;
      this.isLoadingCopyVendorAssignmentsOrders = false;
    });
  }

  resetOrders(filters: Filters = {}) {
    runInAction(() => {
      this.filters = filters;
      this.hasMore = false;
      this.isLoadingOrderJobs = new Map();
      this.isLoadingOrders = false;
      this.orderJobs = new Map();
      this.orders = [];
      this.pagination = { ...defaultPaginationState };
    });
  }

  setCopyVendorAssignmentsFilters(filters: Filters, merge?: boolean) {
    this.resetCopyVendorAssignmentsOrders(
      merge ? { ...this.copyVendorAssignmentsFilters, ...filters } : filters,
    );
  }

  retrieveStoredFilters() {
    const storedFilters = localStorage.getItem(
      CalendarDispatchStorageKeys.APPLIED_FILTERS,
    );
    const parsedFilters = (storedFilters ? JSON.parse(storedFilters) : {}) as Filters;

    return parsedFilters;
  }

  restoreFilters() {
    runInAction(() => {
      this.filters = { ...this.filters, ...this.retrieveStoredFilters() };
    });
  }

  persistFilters(filters: Filters) {
    runInAction(() => {
      const toPersist: Array<keyof Filters> = [
        'customerAccountIds',
        'startDate',
        'endDate',
        'dispatchNumbers',
        'dropOffSiteIds',
        'pickUpSiteIds',
        'projectIds',
        'projectsExternalIds',
        'driverIds',
        'vendorAccountIds',
        'jobStates',
      ];

      const shouldPersistFilters = Object.keys(filters).some((key) => {
        return toPersist.includes(key as keyof Filters);
      });

      if (!shouldPersistFilters) {
        return;
      }

      const values = _.pick(filters, toPersist);
      localStorage.setItem(
        CalendarDispatchStorageKeys.APPLIED_FILTERS,
        JSON.stringify(values),
      );
    });
  }

  setFilters(filters: Filters, merge?: boolean) {
    const newFilters = _.omit(
      merge ? { ...this.filters, ...filters } : filters,
      'searchOrder',
    );

    if (filters.searchOrder) {
      this.resetOrders({ searchOrder: filters.searchOrder });
    } else {
      const previousFilters = this.retrieveStoredFilters();

      this.resetOrders({ ...previousFilters, ...newFilters, searchOrder: '' });
    }

    this.persistFilters(newFilters);
  }

  updateJob(job: Job) {
    if (!job.order?.id) {
      return;
    }

    const orderJobs = this.orderJobs.get(job.order.id);

    if (orderJobs) {
      const index = orderJobs.findIndex(({ id }) => id === job.id);

      runInAction(() => {
        if (index > -1) {
          orderJobs.splice(index, 1, job);
        } else {
          orderJobs.push(job);
        }
      });
    }
  }
}

export default CalendarDispatchStore;
