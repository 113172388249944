import {
  Resource_Read_Nested,
  ResourceScope_Read,
  ResourceType,
} from '@treadinc/horizon-api-spec';

import { UserBasic } from '../useUsers';

export class ResourceBasic {
  constructor(
    private _id: string,
    private _name: string,
    private _type: ResourceType,
  ) {}

  public get id() {
    return this._id;
  }

  public get name() {
    return this._name;
  }

  public get type() {
    return this._type;
  }

  public static parse(proto: Resource_Read_Nested) {
    return new ResourceBasic(proto.id, proto.name, proto.type);
  }
}

export class ResourceScope {
  constructor(
    private _id: string,
    private _user: UserBasic | null,
    private _resource?: ResourceBasic | null,
  ) {}

  public get id() {
    return this._id;
  }

  public get user() {
    return this._user;
  }

  public get resource() {
    return this._resource;
  }

  public static parse(proto: ResourceScope_Read) {
    return new ResourceScope(
      proto.id,
      proto.user ? UserBasic.parse(proto.user) : null,
      proto.resource ? ResourceBasic.parse(proto.resource) : null,
    );
  }
}
