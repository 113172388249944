import Box from '@mui/material/Box';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import type { DriverDay as DriverDayT } from '~hooks/useDriverDays/models';
import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';
import { PaginationLink } from '~services/pagination';
import { rootStore, useStores } from '~store/RootStore';

import { DRIVER_DAY_COLUMNS } from './columnDefinitions';
import { DriverDayDetails } from './DriverDayDetails';
import { DriverDayFilters } from './DriverDayFilters/DriverDayFilters';

const calculateTotalHrs = (
  shiftStart: Date | null,
  shiftEnd: Date | null,
  breakTime: number,
) => {
  if (!shiftStart || !shiftEnd) {
    return 0;
  }

  const shiftDuration = shiftEnd.getTime() - shiftStart.getTime();
  const breakDuration = breakTime * 60 * 1000;

  return (shiftDuration - breakDuration) / (1000 * 60 * 60);
};

type DriverDayRow = {
  id: number;
  driverDayId: string;
  date: Date;
  driver: string;
  truckNo: string | null;
  vendor: string | null;
  shiftStart: Date | null;
  shiftEnd: Date | null;
  break: number;
  totalHrs: number;
  jobCt: number;
  loadCt: number;
  tickets: number;
  materials: string;
  status: {
    approved: boolean;
    approvable: boolean;
    unapprovable: boolean;
    driverDayId: string;
  };
};

const transformDriverDayToDriverDayRow = (
  id: number,
  driverDay: DriverDayT,
): DriverDayRow => ({
  id,
  driverDayId: driverDay.id,
  date: driverDay.driverDayDate,
  driver: driverDay.driver.fullName,
  truckNo: driverDay.equipmentExternalIds[0] ?? null,
  vendor: driverDay.vendorName,
  shiftStart: driverDay.startedShiftAt,
  shiftEnd: driverDay.endedShiftAt,
  break: driverDay.totalBreakMinutes ?? 0,
  totalHrs: driverDay.totalShiftMinutes ? driverDay.totalShiftMinutes / 60 : 0,
  jobCt: driverDay.jobCount,
  loadCt: driverDay.loadsCount,
  tickets: driverDay.ticketsCount,
  materials: driverDay.totalDeliveredQuantities
    .map((deliveredQuantity) => {
      const material = rootStore.companyAssetsStore.materials.find(
        (material) => material.id === deliveredQuantity.material_id,
      );
      return material ? material.name : '';
    })
    .filter((name) => name !== '')
    .join(', '),
  status: {
    approved: driverDay.approved,
    approvable: driverDay.approvable,
    unapprovable: driverDay.unapprovable,
    driverDayId: driverDay.id,
  },
});

export const DriverDay = observer(() => {
  const [searchQuery, setSearchQuery] = useState('');
  const { driverDayStore, userStore } = useStores();
  const {
    fetchDriverDays,
    isLoadingDriverDays,
    subscribeToDriverDayUpdates,
    subscribeToJobUpdates,
    driverDaySubscription,
    jobSubscription,
  } = useDriverDays();

  const hydrate = useCallback(() => {
    fetchDriverDays(undefined, searchQuery);
  }, [fetchDriverDays, searchQuery]);

  useEffect(() => {
    hydrate();
  }, [
    hydrate,
    JSON.stringify(driverDayStore.dateFilters),
    JSON.stringify(driverDayStore.filters),
  ]);

  useEffect(() => {
    if (!driverDaySubscription && userStore.userCompany?.id) {
      subscribeToDriverDayUpdates();
    }
    return () => {
      driverDaySubscription?.unsubscribe();
    };
  }, [driverDaySubscription, userStore.userCompany?.id]);

  useEffect(() => {
    if (!jobSubscription && userStore.userCompany?.id) {
      subscribeToJobUpdates();
    }
    return () => {
      jobSubscription?.unsubscribe();
    };
  }, [jobSubscription, userStore.userCompany?.id]);

  const handlePageChange = useCallback(
    (pagination: PaginationLink) => {
      fetchDriverDays(pagination);
    },
    [fetchDriverDays],
  );

  const columns = useMemo(() => {
    return [
      ...DRIVER_DAY_COLUMNS,
      {
        field: 'actions',
        headerName: `${t('actions.actions')}`,
        width: 96,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        renderHeader: () => (
          <HeaderNavigation
            aria-label="pagination area"
            count={driverDayStore.driverDays.length}
            loading={isLoadingDriverDays}
            pagination={driverDayStore.pagination}
            callback={handlePageChange}
          />
        ),
        renderCell: () => <></>,
      },
    ];
  }, [isLoadingDriverDays, driverDayStore.driverDays, driverDayStore.pagination]);

  return (
    <Box>
      <DriverDayFilters />
      <DataGrid
        id="driver-day-grid"
        filterMode="server"
        rows={driverDayStore.driverDays.map((driverDay, index) =>
          transformDriverDayToDriverDayRow(index, driverDay),
        )}
        columns={columns}
        loading={isLoadingDriverDays}
        disableColumnFilter
        onChangeFilter={(search) => setSearchQuery(search)}
        getDetailPanelContent={(args) => {
          return <DriverDayDetails driverDayId={args.row.driverDayId} />;
        }}
      ></DataGrid>
    </Box>
  );
});
