import {
  getV1DriverDaysDriverDayIdInvoicesPayables,
  getV1DriverDaysDriverDayIdInvoicesReceivables,
  getV1JobsJobIdPayable,
  getV1JobsJobIdReceivable,
  Invoice_Read,
  InvoiceableType,
  InvoiceState,
  patchV1InvoicesId,
  RateType,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { useAddOns } from '~hooks/useAddOns';
import { useInvoiceLineItems } from '~hooks/useInvoiceLineItems';
import { Invoice } from '~hooks/useInvoices/models';
import connection from '~services/connectionModule';
import { PaginationLink, PaginationQuery } from '~services/pagination';
import { realTimeChannels } from '~services/realTimeChannels';
import { useStores } from '~store';
import { InvoiceSearchParamName, InvoiceSearchParams } from '~store/InvoicesStore';

const useInvoices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { invoicesStore } = useStores();
  const { getAllInvoiceLineItems } = useInvoiceLineItems();
  const { getAllInvoiceAddOnCharges } = useAddOns();

  const approveInvoice = async (invoice: Invoice) => {
    const { id, state } = invoice;

    const isApprovable = Boolean(
      id && [InvoiceState.PENDING, InvoiceState.CUSTOMER_PENDING].includes(state),
    );

    if (!isApprovable) {
      return Promise.reject();
    }

    try {
      setIsUpdating(true);

      const slug = state === InvoiceState.PENDING ? 'approve' : 'customer_approve';
      const response = await connection.put<Invoice_Read>(
        `${API_VERSION}/invoices/${id}/${slug}`,
        {},
        {},
        $t('error_messages.invoices.failed_to_approve') as string,
      );
      const updatedInvoice = Invoice.parse(response);

      invoicesStore.updateInvoice(updatedInvoice);

      return updatedInvoice;
    } finally {
      setIsUpdating(false);
    }
  };

  const updateInvoiceRate = async ({
    invoiceId,
    rate_id,
    rate_type,
    rate_value,
  }: {
    invoiceId: string;
    rate_id: string | null;
    rate_type: RateType;
    rate_value: number | null;
  }) => {
    try {
      setIsUpdating(true);
      const response = await patchV1InvoicesId({
        path: { id: invoiceId },
        body: {
          rate_id,
          rate_type,
          rate_value,
        },
      });
      const updatedInvoice = Invoice.parse(response.data.data);
      invoicesStore.updateInvoice(updatedInvoice, true);
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.invoices.failed_to_update') as string,
        [404],
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const getJobInvoices = async (link?: PaginationLink) => {
    try {
      setIsLoading(true);

      const params: PaginationQuery & InvoiceSearchParams = {
        'page[limit]': invoicesStore.pagination.limit,
        ...invoicesStore.searchParams,
        [InvoiceSearchParamName.INVOICEABLE_TYPE]: InvoiceableType.JOB,
      };

      if (link && invoicesStore.pagination[link]) {
        params[`page[${link}]`] = invoicesStore.pagination[link];
      }

      const response = await connection.getPaginated<Invoice_Read>(
        `${API_VERSION}/invoices`,
        { params },
        $t('error_messages.invoices.failed_to_fetch') as string,
      );

      const { data, pagination } = response;
      const invoices = data.map((invoice) => Invoice.parse(invoice));

      invoicesStore.setInvoices(invoices);
      invoicesStore.setPagination(pagination);
      invoicesStore.updatePageNumber(link);
    } finally {
      setIsLoading(false);
    }
  };

  const getDriverDayPayables = async (driverDayId: string) => {
    try {
      setIsLoading(true);
      const response = await getV1DriverDaysDriverDayIdInvoicesPayables({
        path: { 'driver-day-id': driverDayId },
      });
      const invoices = response.data.data.map((invoice) => Invoice.parse(invoice));
      return invoices;
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.invoices.failed_to_fetch') as string,
        [404],
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getDriverDayReceivables = async (driverDayId: string) => {
    try {
      setIsLoading(true);
      const response = await getV1DriverDaysDriverDayIdInvoicesReceivables({
        path: { 'driver-day-id': driverDayId },
      });
      const invoices = response.data.data.map((invoice) => Invoice.parse(invoice));
      return invoices;
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.invoices.failed_to_fetch') as string,
        [404],
      );
    } finally {
      setIsLoading(false);
    }
  };

  const sendInvoice = async (invoice: Invoice) => {
    const { id, state } = invoice;

    const isSendable = Boolean(id && state === InvoiceState.APPROVED);

    if (!isSendable) {
      return Promise.reject();
    }

    try {
      setIsUpdating(true);

      const response = await connection.put<Invoice_Read>(
        `${API_VERSION}/invoices/${id}/send_invoice`,
        {},
        {},
        $t('error_messages.invoices.failed_to_send') as string,
      );
      const updatedInvoice = Invoice.parse(response);

      invoicesStore.updateInvoice(updatedInvoice);

      return updatedInvoice;
    } finally {
      setIsUpdating(false);
    }
  };

  const subscribeToInvoiceRTU = (companyId: string) => {
    const consumer = connection.realTimeConnection;
    const channel = realTimeChannels.InvoiceUpdateChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = consumer?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = consumer?.subscriptions?.create?.(
        { channel, company_id: companyId },
        {
          connected() {},
          disconnected() {},
          received: ({ data }: { data: Invoice_Read }) => {
            const invoice = Invoice.parse(data);

            invoicesStore.updateInvoice(invoice, true);

            if (invoice.id) {
              getAllInvoiceLineItems(invoice.id);
              getAllInvoiceAddOnCharges(invoice.id);
            }
          },
        },
      );
    }

    return subscription;
  };

  const getJobPayable = async (jobId: string) => {
    setIsLoading(true);
    try {
      const response = await getV1JobsJobIdPayable({ path: { 'job-id': jobId } });
      const payable = Invoice.parse(response.data.data);
      return payable;
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.invoices.failed_to_fetch_job_payables') as string,
        [404],
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getJobReceivable = async (jobId: string) => {
    setIsLoading(true);
    try {
      const response = await getV1JobsJobIdReceivable({ path: { 'job-id': jobId } });
      const receivable = Invoice.parse(response.data.data);
      return receivable;
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.invoices.failed_to_fetch_job_receivables') as string,
        [404],
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {
    approveInvoice,
    getJobInvoices,
    getDriverDayPayables,
    getDriverDayReceivables,
    isLoading,
    isUpdating,
    sendInvoice,
    subscribeToInvoiceRTU,
    getJobPayable,
    getJobReceivable,
    updateInvoiceRate,
  };
};

export default useInvoices;
