import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import IconButton from '@mui/material/IconButton';

export const INTERCOM_LAUNCHER_ID = 'intercom_launcher';

const BUTTON_SIZE = '64px';

const IntercomLauncher = () => {
  return (
    <IconButton
      sx={{ width: BUTTON_SIZE, height: BUTTON_SIZE }}
      id={INTERCOM_LAUNCHER_ID}
    >
      <ContactSupportOutlinedIcon />
    </IconButton>
  );
};

export default IntercomLauncher;
