import { LayersList } from '@deck.gl/core';
import { GeoJsonLayer } from '@deck.gl/layers';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Equipment_Read_Nested,
  getV1SitesId,
  LiveSiteChannel_Read,
} from '@treadinc/horizon-api-spec';
import { point } from '@turf/helpers';
import { featureCollection } from '@turf/helpers';
import { t } from 'i18next';
import { flatten, get } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import { TORONTO_OFFICE_COORDINATES } from '~constants/mapConsts';
import { Company, useCompany } from '~hooks/useCompany';
import { Job } from '~hooks/useJob';
import { LiveSiteData } from '~hooks/useLiveMap/models';
import { useLiveMap } from '~hooks/useLiveMap/useLiveMap';
import { useLiveSiteLocations } from '~hooks/useLiveMap/useLiveSiteLocations';
import { useTruckLocations } from '~hooks/useLiveMap/useTruckLocations';
import { NextBillionAssetLocation } from '~hooks/useNextBillionAssetLocationHistories/models';
import { useNextBillionAssetLocationHistories } from '~hooks/useNextBillionAssetLocationHistories/useNextBillionAssetLocationHistories';
import { Order } from '~hooks/useOrders';
import { Site, WayPoint } from '~hooks/useSites/models';
import { BackToLiveBar } from '~pages/LiveMap/BackToLiveBar';
import { TimeIndicatorContextProvider } from '~pages/LiveMap/Timeline/components/hooks/useTimeIndicatorContext';
import { Timeline } from '~pages/LiveMap/Timeline/Timeline';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';
import { getEffectiveUserCompanyId } from '~utils/user/user-utils';
import { hexToRgbaArray } from '~utils/utilFunctions';

import MapConfigurationControl, {
  MapConfigurationOptionKey,
} from '../Controls/MapConfigurationControl';
import {
  GeofenceType,
  HIGHLIGHT_MARKER_RADIUS,
  MarkerType,
  ROUTE_MARKER_RADIUS,
} from './constants';
import { MapV2 } from './MapV2';
import { TruckHoverPopoverContent } from './TruckHoverPopoverContent';
import { TruckOnClickPopoverContent } from './TruckOnClickPopoverContent';

const emDash = '—';

const truncateWithEllipsis = (text: string, maxLength: number): string => {
  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
};

const getEquipmentLabel = (
  equipment: Nullable<Equipment_Read_Nested>,
  truckLocation: NextBillionAssetLocation,
  showVendorName: boolean = false,
) => {
  const truckIdentifier = equipment
    ? equipment.company_share?.external_id ?? equipment.name
    : '';
  const vendorName = truckLocation.vendorName ?? '';
  const fullLabel =
    showVendorName && vendorName
      ? `${truncateWithEllipsis(truckIdentifier, 10)}
    ${truncateWithEllipsis(vendorName, 10)}`
      : truncateWithEllipsis(truckIdentifier, 10);
  return fullLabel;
};

const getSiteById = async (id: string) => {
  try {
    const resp = await getV1SitesId({ path: { id } });
    return Site.parse(resp.data.data);
  } catch (error) {
    // Do nothing. This data is not critical to the map.
  }
};

interface OverlayFeature {
  properties: {
    color: [number, number, number, number]; // RGBA color format
    radius: number; // Point radius in pixels
    lineWidth: number; // Line width in pixels
  };
}

/*
 * Consider this the "opinionated" part of our mapping solution. This component transforms jobs,
 * orders, and local truck data into a more presentation-heavy BaseMap.
 */
const TreadLiveMapV2 = ({
  order,
  job,
  lazyLoadJobPings = false,
  onBackClick,
}: {
  order?: Order;
  job?: Job;
  lazyLoadJobPings?: boolean;
  onBackClick?: () => void;
}) => {
  const theme = useTheme();
  const [truckLocationsById, setTruckLocationsById] = useState<
    Record<string, NextBillionAssetLocation>
  >({});
  const [liveSiteMarkersById, setLiveSiteMarkersById] = useState<
    Record<string, LiveSiteData>
  >({});
  const [siteDetailsBySiteId, setSiteDetailsBySiteId] = useState<Record<
    string,
    Site
  > | null>(null);
  const [jobRoutePings, setJobRoutePings] = useState<NextBillionAssetLocation[] | null>(
    null,
  );
  const [isLoadingRoutePings, setIsLoadingRoutePings] = useState(false);
  const [selectedJobRoutePingInd, setSelectedJobRoutePingInd] = useState<number | null>(
    null,
  );
  const { userStore } = useStores();
  const { getCompanyById } = useCompany();
  const [currentCompany, setCurrentCompany] = useState<Company | null>(null);

  const { getLatestLocations } = useNextBillionAssetLocationHistories();
  const { fetchTreadRoute } = useLiveMap();
  const { subscription, subscribeToLastLocationUpdates } = useTruckLocations();
  const { subscription: liveSiteSubscription, subscribeToLiveSiteUpdates } =
    useLiveSiteLocations();
  const [showVendorName, setShowVendorName] = useState(false);

  const currentCompanyId = getEffectiveUserCompanyId(userStore);
  const getLatestTruckLocations = useCallback(async () => {
    const truckLocations = [];
    let afterLink = null;
    let hasMore = true;

    while (hasMore) {
      const locationResponse = await getLatestLocations({
        job_id: job?.id,
        order_id: order?.id,
        linkType: 'after',
        link: afterLink,
      });

      if (locationResponse) {
        const { data, pagination } = locationResponse;

        truckLocations.push(data);
        if (pagination?.after && data.length !== 0) {
          afterLink = pagination.after;
        } else {
          hasMore = false;
        }
      } else {
        hasMore = false;
      }
    }

    return flatten(truckLocations).flatMap((item) => item);
  }, []);

  const SitePopover = useCallback(
    ({ wayPoint }: { wayPoint: WayPoint }) => {
      return (
        <Box
          sx={{
            p: 1,
            borderRadius: 1,
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gap: 0.5,
            maxWidth: '240px',
          }}
        >
          <Typography noWrap variant="h6" sx={{ gridColumn: 'span 2', fontSize: '14px' }}>
            {wayPoint.site?.name}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {t('live_map.pop_up.site.address')}
          </Typography>
          <Typography variant="body2">
            {wayPoint.siteNested?.address?.thoroughfare ?? emDash}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {t('live_map.pop_up.site.type')}
          </Typography>
          <Typography variant="body2">
            {siteDetailsBySiteId?.[wayPoint.site?.id ?? ''].siteType ?? emDash}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {t('live_map.pop_up.site.geofence_type')}
          </Typography>
          <Typography variant="body2">
            {wayPoint.site?.nextBillionGeofence?.geofenceType
              ? wayPoint.site?.nextBillionGeofence?.geofenceType.replace(
                  /^./,
                  wayPoint.site?.nextBillionGeofence?.geofenceType[0].toUpperCase(),
                )
              : emDash}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {t('live_map.pop_up.site.external_id')}
          </Typography>
          <Typography variant="body2">
            {siteDetailsBySiteId?.[wayPoint.site?.id ?? ''].externalId?.length
              ? siteDetailsBySiteId?.[wayPoint.site?.id ?? ''].externalId
              : emDash}
          </Typography>
        </Box>
      );
    },
    [siteDetailsBySiteId],
  );

  useEffect(() => {
    if (currentCompanyId) {
      getCompanyById({
        id: currentCompanyId,
        callBack: setCurrentCompany,
      });
    }
  }, [currentCompanyId]);

  // Truck locations are primary data we always need. Load and then listen for changes.
  useEffect(() => {
    const loadAndSubscribeToTruckLocations = async () => {
      const data = await getLatestTruckLocations();
      setTruckLocationsById(
        data.reduce(
          (acc, location) => ({
            ...acc,
            [location.equipment?.id ?? location.id]: location,
          }),
          {},
        ),
      );
      if (!subscription) {
        subscribeToLastLocationUpdates({
          onMessageCallBack: (data: NextBillionAssetLocation) => {
            setTruckLocationsById((prev) => ({
              ...prev,
              [data.equipment?.id ?? data.id]: data,
            }));
          },
        });
      }
    };
    loadAndSubscribeToTruckLocations();
    return () => {
      subscription?.unsubscribe?.();
    };
  }, [getLatestTruckLocations, subscription, getEffectiveUserCompanyId(userStore)]);

  useEffect(() => {
    if (!liveSiteSubscription) {
      subscribeToLiveSiteUpdates({
        onMessageCallBack: (data: LiveSiteData) => {
          setLiveSiteMarkersById((prev) => {
            if (
              prev[data.site.id] &&
              data.waypointType !== prev[data.site.id].waypointType
            ) {
              // if the site is already in the map, set the marker type to both_site
              data.waypointType = 'both';
            }
            return {
              ...prev,
              [data.site.id]: data,
            };
          });
        },
      });
    }
    return () => {
      liveSiteSubscription?.unsubscribe?.();
    };
  }, [liveSiteSubscription]);

  // Site details are important for popovers.
  useEffect(() => {
    const loadSiteDetails = async (siteId1: string, siteId2: string) => {
      const [site1, site2] = await Promise.all([
        getSiteById(siteId1),
        getSiteById(siteId2),
      ]);
      if (site1 && site2) {
        setSiteDetailsBySiteId((prev) => ({
          ...(prev ?? {}),
          [site1.id]: site1,
          [site2.id]: site2,
        }));
      }
    };

    const orderSiteIds = [
      get(order, 'waypoints[0].site.id'),
      get(order, 'waypoints[1].site.id'),
    ];
    const jobSiteIds = [
      get(job, 'waypoints[0].site.id'),
      get(job, 'waypoints[1].site.id'),
    ];

    if (order?.id && orderSiteIds.every(Boolean)) {
      loadSiteDetails(orderSiteIds[0]!, orderSiteIds[1]!);
    } else if (job?.id && jobSiteIds.every(Boolean)) {
      loadSiteDetails(jobSiteIds[0]!, jobSiteIds[1]!);
    }
  }, [order?.id, job?.id]);

  // Pings are secondary data we only ask for when a job is present.
  // We also only eagerly load if the lazyLoadJobPings flag is false or empty.
  useEffect(() => {
    if (!job?.id || lazyLoadJobPings) return;
    setIsLoadingRoutePings(true);
    fetchTreadRoute(job.id).then((data) => {
      setJobRoutePings(data);
      setIsLoadingRoutePings(false);
    });
  }, [job?.id, lazyLoadJobPings, setJobRoutePings, setIsLoadingRoutePings]);

  const mapCenter = useMemo(() => {
    return {
      lng: currentCompany?.defaultLon || TORONTO_OFFICE_COORDINATES.lng,
      lat: currentCompany?.defaultLat || TORONTO_OFFICE_COORDINATES.lat,
    };
  }, [currentCompany]);

  const truckAndMovingSiteMarkers = useMemo(
    () =>
      Object.values(truckLocationsById)
        // If order or job are provided, we only surface relevant markers
        .filter((truckLocation) => {
          if (order && truckLocation.orderId !== order.id) {
            return false;
          }
          if (job && truckLocation.jobId !== job.id) {
            return false;
          }
          return true;
        })
        .map((truckLocation) => {
          if (truckLocation.radii?.length) {
            return {
              id: truckLocation.equipment?.id ?? truckLocation.id,
              type: MarkerType.moving_site,
              lat: Number.parseFloat(truckLocation.lat),
              lng: Number.parseFloat(truckLocation.lon),
              radii: truckLocation.radii.map((r) => r.radiusMeters),
            };
          }
          return {
            id: truckLocation.equipment?.id ?? truckLocation.id,
            type: MarkerType.truck,
            label: getEquipmentLabel(
              truckLocation.equipment,
              truckLocation,
              showVendorName,
            ),
            clickPopoverContent: (
              <TruckOnClickPopoverContent truckLocation={truckLocation} />
            ),
            hoverPopoverContent: (
              <TruckHoverPopoverContent truckLocation={truckLocation} />
            ),
            lat: Number.parseFloat(truckLocation.lat),
            lng: Number.parseFloat(truckLocation.lon),
            bearing: truckLocation.bearing
              ? Math.round(Number(truckLocation.bearing))
              : 0,
          };
        }),
    [truckLocationsById, showVendorName],
  );

  const getMarkerType = (waypointType: string) => {
    if (waypointType === 'pickup') {
      return MarkerType.pickup_site;
    } else if (waypointType === 'drop_off') {
      return MarkerType.dropoff_site;
    } else if (waypointType === 'both') {
      return MarkerType.both_site;
    }
    return MarkerType.site;
  };

  const liveSiteMarkers = useMemo(() => {
    return Object.values(liveSiteMarkersById).map((data) => ({
      id: data.site.id,
      type: getMarkerType(data.waypointType),
      lat: data.site?.lat as number,
      lng: data.site?.lon as number,
      // Conditionally add radius if a circle
      ...(data.site?.nextBillionGeofence?.geofenceType === 'circle'
        ? {
            geofenceType: GeofenceType.circle,
            radii: [data.site?.nextBillionGeofence?.circleRadius],
          }
        : {}),
      // Conditionally add coordinates if a polygon
      ...(data.site?.nextBillionGeofence?.geofenceType === 'polygon'
        ? {
            geofenceType: GeofenceType.polygon,
            coordinates: data.site?.nextBillionGeofence?.geojson?.coordinates,
          }
        : {}),
    }));
  }, [liveSiteMarkersById]);

  const orderSiteMarkers = order?.waypoints
    ?.filter((wayPoint) => !!wayPoint && !!wayPoint?.site?.lat && !!wayPoint?.site?.lon)
    .map((wayPoint) => ({
      // Sorry for all the casting. Filter above protects us. Many nullable fields in the origin shape.
      id: wayPoint.id as string,
      type: getMarkerType(wayPoint.type),
      lat: wayPoint.site?.lat as number,
      lng: wayPoint.site?.lon as number,
      // Conditionally add radius if a circle
      ...(wayPoint.site?.nextBillionGeofence?.geofenceType === 'circle'
        ? {
            geofenceType: GeofenceType.circle,
            radii: [wayPoint?.site?.nextBillionGeofence?.circleRadius],
          }
        : {}),
      // Conditionally add coordinates if a polygon
      ...(wayPoint.site?.nextBillionGeofence?.geofenceType === 'polygon'
        ? {
            geofenceType: GeofenceType.polygon,
            coordinates: wayPoint?.site?.nextBillionGeofence?.geojson?.coordinates,
          }
        : {}),
      clickPopoverContent: <SitePopover wayPoint={wayPoint} />,
    }));

  const orderMarkers = useMemo(() => {
    return [...(orderSiteMarkers ?? []), ...truckAndMovingSiteMarkers];
  }, [orderSiteMarkers, truckAndMovingSiteMarkers]);

  const jobSiteMarkers = useMemo(() => {
    return job?.waypoints
      ?.filter((wayPoint) => !!wayPoint && !!wayPoint?.site?.lat && !!wayPoint?.site?.lon)
      .map((wayPoint) => ({
        // Sorry for all the casting. Filter above protects us. Many nullable fields in the origin shape.
        id: wayPoint?.id as string,
        type: getMarkerType(wayPoint.type),
        lat: wayPoint?.site?.lat as number,
        lng: wayPoint?.site?.lon as number,
        // Conditionally add radius if a circle
        ...(wayPoint?.site?.nextBillionGeofence?.geofenceType === 'circle'
          ? {
              geofenceType: GeofenceType.circle,
              radii: [wayPoint?.site?.nextBillionGeofence?.circleRadius],
            }
          : {}),
        // Conditionally add coordinates if a polygon
        ...(wayPoint?.site?.nextBillionGeofence?.geofenceType === 'polygon'
          ? {
              geofenceType: GeofenceType.polygon,
              coordinates: wayPoint?.site?.nextBillionGeofence?.geojson?.coordinates,
            }
          : {}),
        clickPopoverContent: <SitePopover wayPoint={wayPoint} />,
      }));
  }, [job]);

  const jobMarkers = useMemo(() => {
    return [...(jobSiteMarkers ?? []), ...truckAndMovingSiteMarkers];
  }, [jobSiteMarkers, truckAndMovingSiteMarkers]);

  const routePingLayer = useMemo(() => {
    if (!jobRoutePings || jobRoutePings.length === 0) return null;

    const geoJsonPoints = jobRoutePings.map(({ lon, lat }) =>
      point([Number.parseFloat(lon), Number.parseFloat(lat)], {
        radius: ROUTE_MARKER_RADIUS,
        color: hexToRgbaArray('#FFBD80', 255),
        lineWidth: 3,
      }),
    );
    const geojsonData = featureCollection(geoJsonPoints);

    return new GeoJsonLayer({
      id: 'route-layer',
      data: geojsonData,
      getFillColor: (feature: OverlayFeature) => feature.properties.color,
      getPointRadius: (feature: OverlayFeature) => feature.properties.radius,
      stroked: true,
      getLineColor: hexToRgbaArray(theme.brandV2.colors.treadOrangeDark, 255 * 0.85),
      getLineWidth: (feature: OverlayFeature) => feature.properties.lineWidth,
      pointRadiusUnits: 'pixels',
      lineWidthUnits: 'pixels',
    });
  }, [jobRoutePings]);

  const routeHighlightLayer = useMemo(() => {
    if (!jobRoutePings || !selectedJobRoutePingInd) return null;

    const selectedPing = jobRoutePings[selectedJobRoutePingInd];
    const coordinates = [
      Number.parseFloat(selectedPing.lon),
      Number.parseFloat(selectedPing.lat),
    ];

    const highlightPoint = point(coordinates, {
      radius: HIGHLIGHT_MARKER_RADIUS,
      color: hexToRgbaArray('#0044FF', 255),
      lineWidth: 2,
    });
    const highlightPointHalo = point(coordinates, {
      radius: HIGHLIGHT_MARKER_RADIUS * 2,
      color: hexToRgbaArray('#0044FF', 255 * 0.25),
      lineWidth: 0,
    });

    const data = [highlightPointHalo, highlightPoint];

    return new GeoJsonLayer({
      id: 'route-highlight-layer',
      data: data,
      getFillColor: (feature: OverlayFeature) => feature.properties.color,
      getPointRadius: (feature: OverlayFeature) => feature.properties.radius,
      stroked: true,
      getLineColor: [255, 255, 255, 255],
      getLineWidth: (feature: OverlayFeature) => feature.properties.lineWidth,
      pointRadiusUnits: 'pixels',
      lineWidthUnits: 'pixels',
    });
  }, [selectedJobRoutePingInd]);

  const overlayLayers = useMemo(() => {
    if (!routePingLayer) return [];

    const layers = [routePingLayer] as LayersList;
    if (routeHighlightLayer) {
      layers.push(routeHighlightLayer);
    }

    return layers;
  }, [routePingLayer, routeHighlightLayer]);

  const onMapConfigurationOptionsChange = (options: Record<string, boolean>) => {
    setShowVendorName(options[MapConfigurationOptionKey.VENDOR_NAME]);
  };

  return (
    <Box
      sx={{
        flex: 1, // To handle the case where the map is the only child of a flex container
        height: '100%',
        display: 'grid',
        gridTemplateRows: '1fr',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        {(job || order) && onBackClick && (
          <BackToLiveBar
            bottom
            handleBackOnClick={() => {
              setJobRoutePings([]);
              onBackClick();
            }}
            selectedJob={job}
            selectedOrder={order}
          />
        )}
        <MapV2
          center={mapCenter}
          markers={
            (order
              ? orderMarkers
              : job
                ? jobMarkers
                : [...truckAndMovingSiteMarkers, ...liveSiteMarkers]) as ComponentProps<
              typeof MapV2
            >['markers']
          }
          maxZoom={14}
          overlayLayers={overlayLayers}
        >
          <MapConfigurationControl
            position={window.google.maps.ControlPosition.TOP_LEFT}
            onOptionsChange={onMapConfigurationOptionsChange}
          />
        </MapV2>
      </Box>

      <>
        <Box
          m={2}
          sx={{ display: isLoadingRoutePings ? 'flex' : 'none' }}
          alignItems={'center'}
          gap={2}
        >
          <Typography noWrap textOverflow={'visible'} variant="body1">
            {t('live_map.actions.loading_route_history')}
          </Typography>
          <LoadingSpinner isVisible={isLoadingRoutePings} sx={{ width: 'fit-content' }} />
        </Box>
        {!!jobRoutePings && jobRoutePings.length > 0 && (
          <TimeIndicatorContextProvider>
            <Timeline
              pings={jobRoutePings}
              onHover={(arrInd: number) => {
                setSelectedJobRoutePingInd(arrInd);
              }}
            />
          </TimeIndicatorContextProvider>
        )}
      </>
      {job?.id && lazyLoadJobPings && jobRoutePings === null && (
        <LoadingButton
          size="small"
          color="brandV2Yellow"
          loading={isLoadingRoutePings}
          onClick={() => {
            setIsLoadingRoutePings(true);
            fetchTreadRoute(job.id).then((data) => {
              setJobRoutePings(data);
              setIsLoadingRoutePings(false);
            });
          }}
          sx={{ justifySelf: 'start', fontSize: '12px', fontWeight: 'bold' }}
        >
          {t('live_map.actions.view_route_history')}
        </LoadingButton>
      )}
    </Box>
  );
};

export default observer(TreadLiveMapV2);
