import { yupResolver } from '@hookform/resolvers/yup';
import Check from '@mui/icons-material/Check';
import CheckIcon from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ShiftAdjustmentType } from '@treadinc/horizon-api-spec';
import { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import { NewTimeFormField } from '~components/FormFields/NewTimeFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { PseudoLink } from '~components/Helpers/PseudoLink';
import { FeatureFlags } from '~constants/featureFlags';
import { useAddOns } from '~hooks/useAddOns';
import { DriverDay } from '~hooks/useDriverDays/models';
import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';
import { FileAttachment, useFileAttachment } from '~hooks/useFileAttachment';
import useResourceUsageLogs from '~hooks/useResourceUsageLogs/useResourceUsageLogs';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import { dateFormat } from '~utils/dateTime';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

import {
  AddOnChargeDTO,
  ResourceUsageLogDTO,
  schemaHelpers,
  ShiftDetailsSchema,
} from '../../DriverPay/helpers';
import ApprovalActionsMenu from './ApprovalActionsMenu';
import ChargesTable from './ChargesTable';

const emDash = '-';

// We assume hours could be 0.01 and need to round this to an integer
const convertHoursToMinutes = (hours: number): number => {
  return Math.round(hours * 60);
};
const convertMinutesToHours = (minutes: number): number => {
  return minutes / 60;
};
const formatMinutesToHoursString = (minutes: number): string =>
  `${convertMinutesToHours(minutes).toFixed(2)}h`;

const calculateAdjustmentMinutes = (
  startedShiftAt: Date | null,
  endedShiftAt: Date | null,
  totalHrs: number,
) => {
  if (!startedShiftAt || !endedShiftAt) return 0;
  const actualTotalHrs = calculateTotalHrs(startedShiftAt, endedShiftAt);
  const delta = totalHrs - parseFloat(actualTotalHrs.toFixed(2));
  return parseFloat(delta.toFixed(2)) * 60;
};

const calculateTotalHrs = (startedShiftAt: Date, endedShiftAt: Date) => {
  return (endedShiftAt.getTime() - startedShiftAt.getTime()) / 3600000;
};

type FormData = {
  shiftTimeInHours: number;
  externalId: string | null;
  overrideReason: ShiftAdjustmentType | null;
  addOnCharges: AddOnChargeDTO[];
  resourceUsageLogs: ResourceUsageLogDTO[];
  shiftStart: Date | null;
  shiftEnd: Date | null;
};

const maxFileSize = 10;

export function ShiftDetails({
  driverDay,
  firstJobStartAt,
}: {
  driverDay: DriverDay;
  firstJobStartAt: Dayjs;
}) {
  const { addOnsStore, toasterStore, driverDayStore } = useStores();
  const { getAllAddOnChargesByDriverDay } = useAddOns();
  const { getResourceUsageLogsByDriverDay, batchUpdateResourceUsageLogsByDriverDay } =
    useResourceUsageLogs();
  const [isEditing, setIsEditing] = useState(false);
  const {
    updateDriverDay,
    approveDriverDay,
    unapproveDriverDay,
    getDriverDayPhasesTypeahead,
  } = useDriverDays();
  const { batchUpdateAddOnChargesByDriverDay } = useAddOns();
  const {
    createFileAttachmentOnDriverDay,
    updateFileAttachmentById,
    getFileAttachmentByDriverDayId,
    deleteFileAttachmentById,
  } = useFileAttachment();
  const [driverDayTimesheet, setDriverDayTimesheet] = useState<FileAttachment | null>(
    null,
  );
  const [isTimesheetLoading, setIsTimesheetLoading] = useState(false);
  const [isTimesheetDeleting, setIsTimesheetDeleting] = useState(false);
  const modalDialogRef = useRef<ModalDialogHandler>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    getAllAddOnChargesByDriverDay(driverDay.id);
    getResourceUsageLogsByDriverDay(driverDay.id);
    getDriverDayPhasesTypeahead({ driverDayId: driverDay.id });
  }, [driverDay.id]);

  const showEquipmentHours = useFeatureFlag({
    featureFlagKey: FeatureFlags.showEquipmentHoursInChargesTable,
  });

  const addOns = useMemo(() => {
    return addOnsStore.addOnChargesByDriverDayId[driverDay.id] ?? [];
  }, [driverDay.id, addOnsStore.addOnChargesByDriverDayId]);

  const resourceUsageLogs = useMemo(() => {
    const resourceLogs = addOnsStore.resourceUsageLogsByDriverDayId[driverDay.id] ?? [];
    const filteredLogs = resourceLogs.filter((log) => !log.equipment?.id);
    return showEquipmentHours ? resourceLogs : filteredLogs;
  }, [driverDay.id, addOnsStore.resourceUsageLogsByDriverDayId, showEquipmentHours]);

  const phasesOptions = useMemo(() => {
    return driverDayStore.phaseTypeheadsByDriverDayID[driverDay.id] ?? [];
  }, [driverDay.id, driverDayStore.phaseTypeheadsByDriverDayID]);

  const formMethods = useForm({
    resolver: yupResolver(ShiftDetailsSchema),
    defaultValues: {
      shiftTimeInHours: parseFloat(
        convertMinutesToHours(driverDay.totalShiftMinutes ?? 0).toFixed(2),
      ),
      overrideReason: driverDay.shiftAdjustmentType ?? null,
      externalId: driverDay.externalId ?? null,
      addOnCharges: addOns.map((addOnCharge) => {
        return schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge);
      }),
      resourceUsageLogs: resourceUsageLogs.map((resourceUsageLog) => {
        return schemaHelpers.resourceUsageLogToResourceUsageLogDTO(resourceUsageLog);
      }),
      shiftStart: driverDay.startedShiftAt ?? null,
      shiftEnd: driverDay.endedShiftAt ?? null,
    },
    mode: 'all',
    delayError: 500,
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    control,
    watch,
  } = formMethods;

  const watchedShiftStart = watch('shiftStart');
  const watchedShiftEnd = watch('shiftEnd');

  const getTimesheet = () => {
    getFileAttachmentByDriverDayId(driverDay.id).then((data) => {
      const filteredTimeSheet = data?.filter((file) => file.category === 'Timesheet');
      setDriverDayTimesheet(filteredTimeSheet?.[0] ?? null);
      setIsTimesheetLoading(false);
    });
  };

  useEffect(() => {
    setIsTimesheetLoading(true);
    getTimesheet();
  }, [driverDay.id]);

  useEffect(() => {
    if (watchedShiftStart && watchedShiftEnd && isDirty) {
      const newWatchedShiftStart = new Date(watchedShiftStart);
      newWatchedShiftStart.setSeconds(0, 0);
      const newWatchedShiftEnd = new Date(watchedShiftEnd);
      newWatchedShiftEnd.setSeconds(0, 0);
      const totalMinutes = calculateTotalHrs(newWatchedShiftStart, newWatchedShiftEnd);
      setValue('shiftTimeInHours', parseFloat(totalMinutes.toFixed(2)));
    }
  }, [watchedShiftStart, watchedShiftEnd]);

  useEffect(() => {
    setValue(
      'shiftTimeInHours',
      parseFloat(convertMinutesToHours(driverDay.totalShiftMinutes ?? 0).toFixed(2)),
    );
    setValue('overrideReason', driverDay.shiftAdjustmentType ?? null);
  }, [driverDay.totalShiftMinutes, driverDay.shiftAdjustmentType]);

  useEffect(() => {
    setValue(
      'addOnCharges',
      addOns.map((addOnCharge) => {
        return schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge);
      }),
    );
  }, [addOns]);

  useEffect(() => {
    setValue(
      'resourceUsageLogs',
      resourceUsageLogs.map((resourceUsageLog) => {
        return schemaHelpers.resourceUsageLogToResourceUsageLogDTO(resourceUsageLog);
      }),
    );
  }, [resourceUsageLogs]);

  const onSubmit = (data: FormData) => {
    Promise.all([
      batchUpdateResourceUsageLogsByDriverDay(
        driverDay.id,
        data.resourceUsageLogs,
        driverDay.startedShiftAt
          ? driverDay.startedShiftAt.toISOString()
          : firstJobStartAt.toISOString(),
      ),
      batchUpdateAddOnChargesByDriverDay(driverDay.id, data.addOnCharges),
      updateDriverDay(
        driverDay.id,
        calculateAdjustmentMinutes(
          data.shiftStart ? new Date(data.shiftStart) : null,
          data.shiftEnd ? new Date(data.shiftEnd) : null,
          data.shiftTimeInHours,
        ),
        data.externalId,
        data.shiftStart ? new Date(data.shiftStart).toISOString() : null,
        data.shiftEnd ? new Date(data.shiftEnd).toISOString() : null,
        data.overrideReason ?? undefined,
      ),
    ]).then(() => {
      setIsEditing(false);
      toasterStore.push(
        alert(
          t('approvals.driver_pay.settlements.add_ons_updated_successfully'),
          AlertTypes.success,
        ),
      );
      reset();
    });
  };

  const handleApprove = () => {
    approveDriverDay(driverDay.id);
  };

  const handleUnapprove = () => {
    if (!driverDay.unapprovable) return undefined;
    unapproveDriverDay(driverDay.id);
  };

  const ApprovalCTA = () => {
    const approved = driverDay.approved;
    const approvable = driverDay.approvable;

    if (approved) {
      return (
        <ApprovalActionsMenu onMenuItemClick={handleUnapprove} approved={approved} />
      );
    }
    if (approvable) {
      return (
        <Button
          startIcon={<CheckIcon />}
          variant="contained"
          color="success"
          onClick={() => {
            handleApprove();
          }}
          sx={{
            backgroundColor: theme.brandV2.colors.treadGreen,
          }}
          size="small"
          disabled={isEditing}
        >
          {t('actions.approve_shift')}
        </Button>
      );
    }

    return null;
  };

  const resetForm = () => {
    reset({
      shiftTimeInHours: parseFloat(
        convertMinutesToHours(driverDay.totalShiftMinutes ?? 0).toFixed(2),
      ),
      overrideReason: driverDay.shiftAdjustmentType,
      externalId: driverDay.externalId ?? null,
      addOnCharges: addOns.map((addOnCharge) => {
        return schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge);
      }),
      resourceUsageLogs: resourceUsageLogs.map((resourceUsageLog) => {
        return schemaHelpers.resourceUsageLogToResourceUsageLogDTO(resourceUsageLog);
      }),
      shiftStart: driverDay.startedShiftAt ?? null,
      shiftEnd: driverDay.endedShiftAt ?? null,
    });
  };

  const EditingButton = () => {
    if (!driverDay.editable) {
      return null;
    }

    return isEditing ? (
      <Box
        sx={{
          display: 'flex',
          gap: 1.5,
        }}
      >
        <Button
          onClick={() => {
            setIsEditing(false);
            resetForm();
          }}
          variant="outlined"
          color="secondary"
          sx={{
            fontWeight: 600,
            '&.MuiButton-root': {
              borderColor: theme.brandV2.colors.treadGray7,
            },
          }}
          startIcon={<Close />}
        >
          {t('actions.cancel')}
        </Button>

        <Button
          type="submit"
          variant={'contained'}
          color={'brandV2Green'}
          sx={{
            fontWeight: 600,
            '&.MuiButton-root': {
              borderColor: theme.brandV2.colors.treadGray7,
            },
          }}
          startIcon={<Check />}
          disabled={!isValid || !isDirty}
        >
          {t('actions.save_changes')}
        </Button>
      </Box>
    ) : (
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          setIsEditing(!isEditing);
        }}
        sx={{
          fontWeight: 600,
          '&.MuiButton-root': {
            borderColor: theme.brandV2.colors.treadGray7,
          },
        }}
        startIcon={<Edit />}
      >
        {t('actions.edit')}
      </Button>
    );
  };

  const ActionsSection = () => {
    if (isEditing) return <EditingButton />;
    else {
      return (
        <Box
          sx={{
            display: 'flex',
            gap: 1.5,
          }}
        >
          <EditingButton />
          <ApprovalCTA />
        </Box>
      );
    }
  };

  const AddTimeSheetButton = () => {
    return isTimesheetLoading ? (
      <LoadingButton
        loading={true}
        loadingPosition="start"
        startIcon={<></>}
        variant="text"
        color="brandV2OrangeDark"
        sx={{
          height: 'fit-content',
          width: 'fit-content',
          pt: 1,
          fontSize: '0.75rem',
          minWidth: '0px',
        }}
      />
    ) : (
      <Button
        variant="text"
        color="brandV2OrangeDark"
        sx={{
          height: 'fit-content',
          width: 'fit-content',
          p: 0,
          fontSize: '0.75rem',
          minWidth: '0px',
        }}
        onClick={() => fileInputRef && fileInputRef.current?.click()}
      >
        {t('actions.add_sheet')}
      </Button>
    );
  };

  const ViewTimeSheetButton = () => {
    return (
      <PseudoLink
        title={t('actions.view_sheet')}
        action={() => window.open(driverDayTimesheet?.fileUrl)}
        sx={{
          height: 'fit-content',
          width: 'fit-content',
          p: 0,
          fontSize: '0.75rem',
        }}
        underline={false}
        linkColor={theme.brandV2.colors.treadOrangeDark}
      />
    );
  };

  const updateFileAttachment = (id: string) => {
    updateFileAttachmentById(id, {
      category: 'Timesheet',
    })
      .then(() => {
        toasterStore.push(
          alert(
            t('approvals.driver_day.time_sheet_added_successfully'),
            AlertTypes.success,
          ),
        );
        getTimesheet();
      })
      .finally(() => {
        setIsTimesheetLoading(false);
      });
  };

  const handleCreateFileAttachment = async (file: File) => {
    if (!file) return;
    if (file.size / 1024 / 1024 > maxFileSize) {
      alert(
        t('file_upload.max_size', { maxSize: maxFileSize + ' MB' }),
        AlertTypes.error,
      );
      return;
    }

    setIsTimesheetLoading(true);

    const attachment = await createFileAttachmentOnDriverDay(file, driverDay.id);
    if (attachment) {
      updateFileAttachment(attachment.id);
    }
  };

  const handleDeleteFileAttachment = (id: string) => {
    setIsTimesheetDeleting(true);
    deleteFileAttachmentById(id)
      .then(() => {
        getTimesheet();
        modalDialogRef.current?.close();
        toasterStore.push(
          alert(
            t('approvals.driver_day.time_sheet_removed_successfully'),
            AlertTypes.success,
          ),
        );
      })
      .finally(() => {
        setIsTimesheetDeleting(false);
      });
  };

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        id="shift-details-form"
        onSubmit={handleSubmit(onSubmit)}
        aria-label="shift details"
        sx={{
          backgroundColor: 'white',

          borderRadius: 2,
          border: `1px solid ${theme.brandV2.colors.treadGray7}`,
          height: 'fit-content',
          gap: 1.5,
        }}
      >
        <Box
          aria-label="shift summary"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 3,
            p: 1.5,
          }}
        >
          <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
            <Typography
              variant="body2"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
            >
              {t('approvals.driver_day.shift_start_details')}
            </Typography>
            {isEditing ? (
              <NewTimeFormField control={control} errors={errors} name={'shiftStart'} />
            ) : (
              <Typography variant="h6">
                {driverDay?.startedShiftAt
                  ? dateFormat(driverDay.startedShiftAt, 'hh:mm A')
                  : emDash}
              </Typography>
            )}

            <Typography
              variant="caption"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
            >
              {t('approvals.driver_day.first_geofence_entry')} :
              {driverDay?.firstGeofenceEntryAt
                ? dateFormat(driverDay?.firstGeofenceEntryAt, 'hh:mm A')
                : emDash}
            </Typography>

            <Typography
              variant="caption"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
            >
              {t('approvals.driver_day.first_ticket')} :{' '}
              {driverDay?.firstTicketAt
                ? dateFormat(driverDay?.firstTicketAt, 'hh:mm A')
                : emDash}
            </Typography>
          </Box>

          <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
            <Typography
              variant="body2"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
            >
              {t('approvals.driver_day.shift_end_details')}
            </Typography>
            {isEditing ? (
              <NewTimeFormField control={control} errors={errors} name={'shiftEnd'} />
            ) : (
              <Typography variant="h6">
                {driverDay?.endedShiftAt
                  ? dateFormat(driverDay.endedShiftAt, 'hh:mm A')
                  : emDash}
              </Typography>
            )}

            <Typography
              variant="caption"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
            >
              {t('approvals.driver_day.last_geofence_entry')} :{' '}
              {driverDay?.lastGeofenceExitAt
                ? dateFormat(driverDay?.lastGeofenceExitAt, 'hh:mm A')
                : emDash}
            </Typography>

            <Typography
              variant="caption"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
            >
              {t('approvals.driver_day.last_ticket')} :{' '}
              {driverDay?.lastTicketAt
                ? dateFormat(driverDay?.lastTicketAt, 'hh:mm A')
                : emDash}
            </Typography>
          </Box>

          <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
            <Typography
              variant="body2"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
            >
              {t('approvals.driver_day.total_hours')}
            </Typography>
            {isEditing ? (
              <TextFormField
                control={control}
                errors={errors}
                name={'shiftTimeInHours'}
              />
            ) : (
              <Typography variant="h6">
                {driverDay.totalShiftMinutes
                  ? formatMinutesToHoursString(driverDay.totalShiftMinutes)
                  : emDash}
              </Typography>
            )}
            <Typography
              variant="caption"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
            >
              {`${t('approvals.driver_day.breaks')}: ${driverDay?.totalBreakMinutes ? `${driverDay?.totalBreakMinutes}min` : emDash}`}
            </Typography>
          </Box>

          <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
            <Typography
              variant="body2"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
            >
              {t('approvals.driver_day.timesheet_id')}
            </Typography>

            <input
              type="file"
              accept="image/*"
              maxLength={1}
              hidden
              ref={fileInputRef}
              onChange={(e) => {
                if (e.target.files?.length)
                  handleCreateFileAttachment(e.target.files?.[0]);
              }}
              onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                const element = event.target as HTMLInputElement;
                element.value = '';
              }}
            />
            {isEditing ? (
              <>
                <TextFormField name={'externalId'} errors={!errors} control={control} />

                {!driverDayTimesheet ? (
                  <AddTimeSheetButton />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                      flexWrap: 'wrap',
                    }}
                  >
                    <ViewTimeSheetButton />
                    <Typography
                      sx={{
                        color: (theme) => theme.brandV2.colors.treadGray4,
                        fontWeight: 400,
                      }}
                    >
                      |
                    </Typography>
                    <Button
                      variant="text"
                      color="brandV2OrangeDark"
                      sx={{
                        height: 'fit-content',
                        width: 'fit-content',
                        p: 0,
                        fontSize: '0.75rem',
                        minWidth: 'unset',
                      }}
                      onClick={() => modalDialogRef.current?.open()}
                    >
                      {t('actions.remove')}
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Typography variant="h6">{driverDay.externalId ?? emDash}</Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: (theme) => theme.brandV2.colors.treadGray2,
                    fontWeight: 500,
                  }}
                >
                  {driverDay.driverDayId}
                </Typography>

                {driverDayTimesheet ? <ViewTimeSheetButton /> : <AddTimeSheetButton />}
              </>
            )}
          </Box>
          <ActionsSection />
        </Box>
        <ModalDialog
          ref={modalDialogRef}
          title={t('actions.remove_time_sheet')}
          content={t('approvals.driver_day.confirm_removing_time_sheet')}
          confirmButtonText={t('actions.remove_time_sheet')}
          callBack={() => {
            handleDeleteFileAttachment(driverDayTimesheet?.id ?? '');
          }}
          confirmButtonColor={'error'}
          onClose={() => {
            modalDialogRef.current?.close();
          }}
          onCancel={() => {
            modalDialogRef.current?.close();
          }}
          loading={isTimesheetDeleting}
        />
        <ChargesTable
          isEditing={isEditing}
          addOns={addOns}
          resourceUsageLogs={resourceUsageLogs}
          phasesOptions={phasesOptions}
          driver={driverDay.driver}
        />
      </Box>
    </FormProvider>
  );
}
