import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { FormControlProps } from '@mui/material/FormControl';
import { InputBaseProps } from '@mui/material/InputBase';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { SxProps } from '@mui/system';
import { get } from 'lodash';
import React from 'react';
import { Controller } from 'react-hook-form';

import { FormFieldLabel } from '~components/FormFields/FormFieldLabel';

interface FormFieldProps {
  control: any; // Form control
  errors: any; // Object of errors
  name: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  isRequired?: boolean;
  sx?: SxProps<Theme>; // Style
  placeholder?: string;
  InputProps?: Partial<OutlinedInputProps>;
  id?: string;
  inputProps?: InputBaseProps['inputProps'];
  multiline?: boolean;
  rows?: number;
  loading?: boolean;
  isInline?: boolean;
  labelColor?: string;
  margin?: FormControlProps['margin'];
  onFocus?: InputBaseProps['onFocus'];
  onBlur?: InputBaseProps['onBlur'];
  defaultValue?: string | number;
}

const TextFormField = ({
  control,
  errors,
  name,
  label,
  type = 'text',
  disabled = false,
  isRequired,
  sx,
  placeholder,
  id,
  inputProps,
  InputProps: InputPropsProp,
  multiline = false,
  rows,
  loading = false,
  isInline = false,
  labelColor = 'text.primary',
  margin = 'dense',
  onFocus,
  onBlur,
  defaultValue,
}: FormFieldProps) => {
  const hasAsyncError = get(errors, name)?.type === 'manual';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ...rest } }) => (
        <Box
          display={'flex'}
          flexDirection={isInline ? 'row' : 'column'}
          sx={{ ...sx }}
          alignItems={isInline ? 'center' : 'flex-start'}
          width={'100%'}
        >
          {label && (
            <FormFieldLabel
              label={`${label}${isRequired ? ' *' : ''}`}
              labelColor={labelColor}
              sx={{ mr: isInline ? 1 : '' }}
            ></FormFieldLabel>
          )}
          <TextField
            onChange={onChange}
            {...rest}
            onWheel={(e) => e.target instanceof HTMLElement && e?.target?.blur?.()}
            value={value || defaultValue || ''}
            size={'small'}
            rows={rows || 1}
            multiline={multiline}
            fullWidth
            id={id || name}
            margin={margin}
            type={type}
            variant="outlined"
            disabled={disabled}
            placeholder={placeholder}
            inputProps={inputProps}
            error={Boolean(get(errors, name))}
            helperText={hasAsyncError ? get(errors, name)?.message : ''}
            onFocus={onFocus}
            onBlur={onBlur}
            className={isRequired && !disabled ? 'required' : ''}
            InputProps={{
              ...InputPropsProp,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {InputPropsProp?.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        </Box>
      )}
    />
  );
};

export { TextFormField };
