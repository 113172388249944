import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import { AddOnType, RateType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useMemo, useState } from 'react';

import { AddOnCharge, useAddOns } from '~hooks/useAddOns';
import { InvoiceLineItem, useInvoiceLineItems } from '~hooks/useInvoiceLineItems';
import { Invoice } from '~hooks/useInvoices';
import { Job } from '~hooks/useJob';
import * as colsDef from '~pages/Approvals/DriverPay/driversPayDataGridColumnsDefinition';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

import {
  LineItemsTableFooterItem,
  LineItemsTableSkeleton,
} from './components/lineItemsTableComponents';
import {
  AddOnChargeDTO,
  invoiceHelpers,
  NormalizedHourlyLineItem,
  NormalizedLoadLineItem,
  NormalizedTonLineItem,
} from './helpers';
import HourlyRateLineItemsTable from './HourlyRateLineItemsTable';
import LoadRateLineItemsTable from './LoadRateLineItemsTable';
import TonRateLineItemsTable from './TonRateLineItemsTable';

const WAIT_BEFORE_SKELETONS_IN_MS = 1000;

interface InvoiceLineItemsProps {
  invoice: Invoice;
  isEditing?: boolean;
  job: Job;
  utils: colsDef.DriverPayRowDef['row']['utils'];
}

const InvoiceLineItems = observer(
  forwardRef<LineItemsFormHandler, InvoiceLineItemsProps>(function InvoiceLineItems(
    { invoice, isEditing, job, utils },
    ref,
  ) {
    const invoiceId = String(invoice.id);
    const { invoiceLineItemsStore, addOnsStore, userStore } = useStores();
    const { isLoading: isLoadingLineItems, getAllInvoiceLineItems } =
      useInvoiceLineItems();
    const {
      getAllAddOns,
      getAllInvoiceAddOnCharges,
      isLoading: isLoadingAddOns,
    } = useAddOns();
    const [showSkeletons, setShowSkeletons] = useState(false);

    const companyId = userStore.userCompany.id;
    const isLoading = isLoadingLineItems || isLoadingAddOns;

    const lineItems = useMemo(() => {
      return invoiceLineItemsStore.invoiceLineItemsByInvoiceId[invoiceId] ?? [];
    }, [invoiceId, invoiceLineItemsStore.invoiceLineItemsByInvoiceId[invoiceId]]);

    const addOnCharges = useMemo(() => {
      return addOnsStore.addOnChargesByInvoiceId[invoiceId] ?? [];
    }, [invoiceId, addOnsStore.addOnChargesByInvoiceId[invoiceId]]);

    useEffect(() => {
      const lineItemsInStore =
        invoiceLineItemsStore.invoiceLineItemsByInvoiceId[invoiceId];

      if (!lineItemsInStore) {
        getAllInvoiceLineItems(invoiceId);
      }
    }, [invoiceId, invoiceLineItemsStore.invoiceLineItemsByInvoiceId[invoiceId]]);

    useEffect(() => {
      const addOnChargesInStore = addOnsStore.addOnChargesByInvoiceId[invoiceId];

      if (!addOnChargesInStore) {
        getAllInvoiceAddOnCharges(invoiceId);
      }
    }, [invoiceId, addOnsStore.addOnChargesByInvoiceId[invoiceId]]);

    useEffect(() => {
      if (isEditing) {
        getAllAddOns(companyId).then((response) => {
          const selectableAddOns = response.filter((addOn) => {
            return addOn.addOnTypes.includes(AddOnType.INVOICING);
          });

          addOnsStore.setSelectableAddOnsByInvoiceId(invoiceId, selectableAddOns);
        });
      }
    }, [isEditing, companyId, invoiceId]);

    useEffect(() => {
      // Wait some time before showing the loading skeletons, or the UX when loading
      // The page may feel a little funky
      const timeout = setTimeout(() => {
        setShowSkeletons(isLoading);
      }, WAIT_BEFORE_SKELETONS_IN_MS);

      return () => {
        clearTimeout(timeout);
      };
    }, [isLoading]);

    return (
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography>{t('approvals.driver_pay.tabs.driver_pay.line_items')}</Typography>

        {isLoading ? (
          <>{showSkeletons && <LineItemsTableSkeleton />}</>
        ) : (
          <Box display="flex" flexDirection="column">
            <LineItemsTable
              ref={ref}
              addOnCharges={addOnCharges}
              invoice={invoice}
              isEditing={isEditing}
              job={job}
              lineItems={lineItems}
              utils={utils}
            />

            <Box
              display="flex"
              flexDirection="row-reverse"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <LineItemsTableFooter
                invoice={invoice}
                lineItems={lineItems}
                utils={utils}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  }),
);

interface LineItemsTableProps {
  addOnCharges: AddOnCharge[];
  invoice: Invoice;
  isEditing?: boolean;
  job: Job;
  lineItems: InvoiceLineItem[];
  utils: colsDef.DriverPayRowDef['row']['utils'];
}

export type LineItemsFormHandler = {
  onSubmit?: () => Promise<{
    lineItems: Array<
      Partial<NormalizedHourlyLineItem | NormalizedLoadLineItem | NormalizedTonLineItem>
    >;
    addOnCharges: AddOnChargeDTO[];
  }>;
};

const LineItemsTable = forwardRef<LineItemsFormHandler, LineItemsTableProps>(
  function LineItemsTable(
    { addOnCharges, invoice, isEditing, job, lineItems, utils },
    ref,
  ) {
    return (
      <TableContainer>
        <Table
          sx={{
            tableLayout: 'fixed',
            border: `solid 1px ${theme.palette.divider}`,
            borderCollapse: 'separate',
            borderRadius: theme.spacing(0.5, 0.5, 0, 0.5),
            overflow: 'hidden',
          }}
        >
          {invoice.rateType === RateType.RATE_PER_HOUR ? (
            <HourlyRateLineItemsTable
              ref={ref}
              addOnCharges={addOnCharges}
              invoice={invoice}
              isEditing={isEditing}
              job={job}
              lineItems={lineItems}
              utils={utils}
            />
          ) : invoice.rateType === RateType.RATE_PER_LOAD ? (
            <LoadRateLineItemsTable
              ref={ref}
              addOnCharges={addOnCharges}
              invoice={invoice}
              isEditing={isEditing}
              job={job}
              lineItems={lineItems}
              utils={utils}
            />
          ) : (
            <TonRateLineItemsTable
              ref={ref}
              addOnCharges={addOnCharges}
              invoice={invoice}
              isEditing={isEditing}
              job={job}
              lineItems={lineItems}
              utils={utils}
            />
          )}
        </Table>
      </TableContainer>
    );
  },
);

interface LineItemsTableFooterProps {
  invoice: Invoice;
  lineItems: InvoiceLineItem[];
  utils: colsDef.DriverPayRowDef['row']['utils'];
}

const LineItemsTableFooter = observer(
  ({ invoice, lineItems, utils }: LineItemsTableFooterProps) => {
    const invoiceId = String(invoice.id);
    const { invoiceLineItemsStore, addOnsStore } = useStores();

    const [subtotal, addOns, total] = useMemo(() => {
      const subtotal = invoiceLineItemsStore.invoiceSubtotalByInvoiceId[invoiceId];
      const addOns = addOnsStore.addOnChargesTotalByInvoiceId[invoiceId];
      const total = invoiceHelpers.calculateInvoiceTotal(subtotal, addOns);

      return [subtotal, addOns, total];
    }, [
      lineItems,
      invoiceLineItemsStore.invoiceSubtotalByInvoiceId[invoiceId],
      addOnsStore.addOnChargesTotalByInvoiceId[invoiceId],
    ]);

    return (
      <Box
        width="350px"
        bgcolor="grey.50"
        display="flex"
        flexDirection="column"
        borderLeft={`solid 1px ${theme.palette.divider}`}
        borderRight={`solid 1px ${theme.palette.divider}`}
        borderBottom={`solid 1px ${theme.palette.divider}`}
        borderRadius={theme.spacing(0, 0, 0.5, 0.5)}
        py={1.5}
        gap={1.5}
      >
        <LineItemsTableFooterItem
          label={t('common.subtotal')}
          value={utils.currencyFormatter(subtotal)}
        />

        <LineItemsTableFooterItem
          label={t('common.add_ons')}
          value={utils.currencyFormatter(addOns)}
        />

        <Divider />

        <LineItemsTableFooterItem
          label={t('approvals.driver_pay.tabs.driver_pay.total')}
          value={utils.currencyFormatter(total)}
          size="large"
        />
      </Box>
    );
  },
);

export default InvoiceLineItems;
