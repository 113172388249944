import { UnitOfDistance } from '@treadinc/horizon-api-spec';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { WaypointSchema } from '~constants/regexConst';
import { DeparsedRouteEstimate } from '~hooks/useRoute/models';
import useRoute from '~hooks/useRoute/useRoute';
import { useStores } from '~store';
import { convertMeters } from '~utils/utilFunctions';

type RouteEstimateArgs = DeparsedRouteEstimate;

export default function useRouteEstimate() {
  const form = useFormContext();

  const { userStore } = useStores();
  const { getRouteEstimate } = useRoute();

  const shouldUseKilometers = userStore.userCompany.isMeters;

  const watchedDropOffWayPoint = form.watch('dropOffWayPoint') as WaypointSchema | null;
  const watchedPickUpWayPoint = form.watch('pickUpWayPoint') as WaypointSchema | null;

  const routeEstimateArgs: RouteEstimateArgs | null = useMemo(() => {
    const pickupLat = watchedPickUpWayPoint?.site?.lat;
    const pickupLon = watchedPickUpWayPoint?.site?.lon;
    const dropoffLat = watchedDropOffWayPoint?.site?.lat;
    const dropoffLon = watchedDropOffWayPoint?.site?.lon;

    const canEstimate = [pickupLat, pickupLon, dropoffLat, dropoffLon].every((value) => {
      return !_.isNil(value);
    });

    if (!canEstimate) {
      return null;
    }

    return {
      pickupSiteLat: Number(pickupLat),
      pickupSiteLon: Number(pickupLon),
      dropoffSiteLat: Number(dropoffLat),
      dropoffSiteLon: Number(dropoffLon),
    };
  }, [watchedPickUpWayPoint, watchedDropOffWayPoint]);

  const estimateRoute = useCallback(async () => {
    if (!routeEstimateArgs) {
      return;
    }

    const estimate = await getRouteEstimate(routeEstimateArgs);

    if (!estimate) {
      return;
    }

    const cycleDistance = convertMeters(
      estimate.pickupToDropOffDistanceMeters,
      shouldUseKilometers ? UnitOfDistance.KILOMETER : UnitOfDistance.MILE,
    );

    return { cycleDistance, pickupToDropoff: estimate.pickupToDropOffTimeMinutes };
  }, [JSON.stringify(routeEstimateArgs), shouldUseKilometers]);

  return { estimateRoute };
}
