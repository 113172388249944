import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { TextFormField } from '~components/FormFields/TextFormField';
import Copyright from '~pages/Auth/Copyrigh';
import FormWrapperBox from '~pages/Auth/FormWrapperBox';
import LinkToForgotPassword from '~pages/Auth/LinkToForgotPassword';
import LinkToSignIn from '~pages/Auth/LinkToSignIn';
import PageWrapWithBackGround from '~pages/Auth/PageWrapWithBackGround';
import { FormInputProps, inputTypes, ResetSignupProps } from '~pages/Auth/types';
import { signUpResetSchema } from '~pages/Auth/validationSchemas';
import { useStores } from '~store';

// Const getCharacterValidationError = (str: string) => {
//   Return (
//     T('form_validation_errors.passwordValidation', {
//       Field: str,
//     }) || ''
//   );
// };
interface SignUpResetFormProps {
  formTitle: string;
  isReset?: boolean;
  callBackAction: (data: ResetSignupProps) => void;
  isLoading: boolean;
}

const formInputsInitial: FormInputProps[] = [
  // {
  //   Name: inputTypes.email,
  //   Label: t('form_field_labels.email'),
  //   Type: inputTypes.email,
  //   ShowIcon: false,
  //   Id: inputTypes.email,
  // },
  {
    name: inputTypes.password,
    label: t('form_field_labels.password'),
    type: inputTypes.password,
    showIcon: true,
    id: inputTypes.password,
  },
  {
    name: inputTypes.passwordConfirmation,
    label: t('form_field_labels.confirm_password'),
    type: inputTypes.password,
    id: inputTypes.passwordConfirmation,
    showIcon: true,
  },
];
const isRedirectParam = 'native_app';

const SignUpResetForm = ({
  formTitle,
  isReset = false,
  callBackAction,
  isLoading,
}: SignUpResetFormProps) => {
  const { authStore } = useStores();
  const [formInputs, setFormInputs] = useState(formInputsInitial);
  const [searchParams] = useSearchParams();

  const isRedirectToMobileNeeded = searchParams.get(isRedirectParam)!;
  Boolean(isRedirectToMobileNeeded) && authStore.setRedirectToMobileNeeded(true);

  const onSubmit = (data: ResetSignupProps) => {
    callBackAction?.(data);
  };

  const handleTogglePasswordVisibility = (name: string) => {
    const res = formInputs.map((input: FormInputProps) => {
      if (input.name === name) {
        input.type =
          input.type === inputTypes.text ? inputTypes.password : inputTypes.text;
      }
      return input;
    });
    setFormInputs(res);
  };
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<{ email: string; password: string; passwordConfirmation: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(signUpResetSchema),
  });

  return (
    <PageWrapWithBackGround
      backgroundImageUrl={'../../src/assets/images/trucks-image-grey.jpg'}
    >
      <FormWrapperBox title={formTitle}>
        <Box
          width={'100%'}
          component="form"
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          onSubmit={handleSubmit(onSubmit)}
        >
          {formInputs.map((input, index) => (
            <>
              <TextFormField
                key={`form-input-${index}-${input.name}`}
                control={control}
                errors={errors}
                name={input.name}
                label={input.label}
                type={input.type}
                id={input.id}
                inputProps={{ 'data-test-id': input.id }}
                InputProps={{
                  endAdornment: input.showIcon && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          handleTogglePasswordVisibility(input.name);
                        }}
                      >
                        {input.type !== inputTypes.password ? (
                          <VisibilityOff sx={{ width: 18 }} />
                        ) : (
                          <Visibility sx={{ width: 18 }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {input.name === inputTypes.password && errors.password && (
                <Typography variant="body2" color="error">
                  {capitalize(errors.password.message)}
                </Typography>
              )}

              {input.name === inputTypes.passwordConfirmation &&
                errors.passwordConfirmation && (
                  <Typography variant="body2" color="error">
                    {capitalize(errors.passwordConfirmation.message)}
                  </Typography>
                )}
            </>
          ))}

          <LoadingButton
            fullWidth
            disabled={!isDirty || isLoading}
            sx={{ mt: 2 }}
            loading={isLoading}
            size={'small'}
            variant={'contained'}
            color={'primary'}
            type={'submit'}
            data-test-id={'submit-btn'}
          >
            {t('actions.submit')}
          </LoadingButton>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ mt: 2, width: '100%' }}
          >
            <Box display={'flex'}>
              <LinkToSignIn />
            </Box>
            <Box display={'flex'}>{!isReset && <LinkToForgotPassword />}</Box>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </FormWrapperBox>
    </PageWrapWithBackGround>
  );
};
export default SignUpResetForm;
