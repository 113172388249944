import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { NavigationTabs } from '~components/LayoutComponents/NavigationTabs';
import { FeatureFlags } from '~constants/featureFlags';
import { ROUTES } from '~router/routePaths';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

const DEFAULT_NAV_ITEMS = [
  {
    title: 'Company',
    link: 'companies',
  },
  {
    title: 'Users',
    link: 'users',
  },
  {
    title: 'Connections',
    link: 'connections',
  },
  { title: 'Accounts', link: 'accounts' },
];

export const Admin = () => {
  const theme = useTheme();
  const managedCompaniesEnabledFeatureFlagEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.managedCompaniesEnabled,
  });

  const navItems = useMemo(() => {
    if (managedCompaniesEnabledFeatureFlagEnabled) {
      return DEFAULT_NAV_ITEMS.concat([
        {
          title: t('administration.managed_companies.section_title'),
          link: ROUTES.admin.managedCompanies,
        },
      ]);
    }

    return DEFAULT_NAV_ITEMS;
  }, [managedCompaniesEnabledFeatureFlagEnabled]);

  return (
    <>
      <NavigationTabs
        navItems={navItems}
        sx={{ borderBottom: `1px solid ${theme.palette.divider}`, mt: 2 }}
      />

      <Box sx={{ py: 4 }}>
        <Outlet />
      </Box>
    </>
  );
};
