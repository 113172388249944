import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import {
  AccountType,
  FileAttachment_Read,
  ModelError_Item,
  RateOwnerType,
  WaypointType,
} from '@treadinc/horizon-api-spec';
import { t as $t, t } from 'i18next';
import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ControllerProps,
  FieldErrors,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { PhoneCodeFlagInput } from '~components/FormFields/PhoneCodeFlagInput';
import { SingleDateTimeFormField } from '~components/FormFields/SingleDateTimeFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { PseudoLink } from '~components/Helpers/PseudoLink';
import { Contacts } from '~components/Order/Contacts';
import { OrderDetails } from '~components/Order/OrderDetails';
import { PhaseSelection } from '~components/Order/PhaseSelection';
import { SiteSelection } from '~components/Order/SiteSelection/SiteSelection';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { NoteTypes, unitOfMeasureOptions } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { FormStateChangeProps } from '~formsShared';
import { Notes } from '~formsShared/components';
import { Account, useAccount } from '~hooks/useAccount';
import { CompanyBasic } from '~hooks/useCompany';
import { useDepartment } from '~hooks/useDepartment';
import { Material, useMaterials } from '~hooks/useMaterials';
import { Project } from '~hooks/useProjects';
import { RateBasicWithValue, useRates } from '~hooks/useRates';
import { Site, useSites, WayPoint } from '~hooks/useSites';
import { User, useUsers } from '~hooks/useUsers';
import RateDetails, {
  RateDetailsHandler,
} from '~pages/Sales/Orders/NewOrderForm/RateDetails';
import { RateDetailsDTO } from '~pages/Sales/Orders/NewOrderForm/schema';
import { rateToRateBasicWithValue } from '~pages/Sales/Orders/NewOrderForm/utils';
import { RemoveItemButton } from '~pages/Sales/sharedSales/RemoveItemButton';
import { useSiteFormV2 } from '~pages/Settings/Administration/Sites/site-form-v2/hooks';
import { SiteFormV2 } from '~pages/Settings/Administration/Sites/site-form-v2/SiteFormV2';
import { SiteForm } from '~pages/Settings/Administration/Sites/SiteForm';
import { DepartmentInlineForm } from '~pages/Settings/DepartmentManagement/DepartmentInlineForm';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

import FileAttachments, {
  FileAttachmentsHandler,
} from '../../../formsShared/components/FileAttachments';
import { createWaypoint } from '../Orders/NewOrderForm';
import {
  EmptyProjectMaterialTotalItem,
  projectFormSchema,
  ProjectFormSchemaInterface,
  setDefaultProjectValues,
} from './projectFormSchema';

interface AddDefaultSiteParams {
  siteId: string;
  waypointType: WaypointType;
}

type NewOrderFormStateChange = FormStateChangeProps & Partial<AddDefaultSiteParams>;

interface CreateUpdateProjectFormProps {
  defaultProject: Project | null | undefined;
  onFormStateChange: ({
    isValid,
    isDirty,
    siteId,
    waypointType,
  }: NewOrderFormStateChange) => void;
  company?: Nullable<CompanyBasic>;
  errors?: ModelError_Item[];
}

interface NewProjectFormHandler {
  submit: (callback: () => void) => void;
  resetForm: (callback?: () => void) => void;
}

const ProjectForm = forwardRef(function ProjectForm(
  { defaultProject, onFormStateChange, errors: errorsProp }: CreateUpdateProjectFormProps,
  ref: Ref<NewProjectFormHandler>,
) {
  const customerDetailsRef = useRef<RateDetailsHandler>(null);
  const vendorDetailsRef = useRef<RateDetailsHandler>(null);
  const driverDetailsRef = useRef<RateDetailsHandler>(null);
  const siteFormRef = useRef<any>(null);
  const fileAttachmentsRef = useRef<FileAttachmentsHandler>(null);

  const { toasterStore, userStore, companyAssetsStore } = useStores();
  const { createNewSite, isUpdating } = useSites();
  const { createNewSite: createNewSiteV2 } = useSiteFormV2();
  const { getDepartmentByCompanyId } = useDepartment();
  const { createAccount, getAccountsByCompanyIdTypeahead } = useAccount();
  const {
    createMaterialByCompanyId,
    isLoading: isMaterialLoading,
    getMaterialsByCompanyIdTypeahead,
  } = useMaterials();
  const { getAccountRatesUsingOwnerType } = useRates();
  const { getCompanyUsersTypeahead } = useUsers();

  const [showCreateSiteForm, setShowCreateSiteForm] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [showCreateDepartment, setShowCreateDepartment] = useState<boolean>(false);
  const [tmpMaterial, setTmpMaterial] = useState<string>('');
  const [tmpAccountName, setTmpAccountName] = useState<string>('');
  const [showPickUpContact, setShowPickUpContact] = useState<boolean>(false);
  const [showDropOffContact, setShowDropOffContact] = useState<boolean>(false);
  const [pickUpWaypoint, setPickUpWaypoint] = useState<Nullable<WayPoint>>(
    defaultProject?.pickUpWayPoints?.[0],
  );
  const [dropOffWaypoint, setDropOffWaypoint] = useState<Nullable<WayPoint>>(
    defaultProject?.dropOffWayPoints?.[0],
  );
  const [primaryRates, setPrimaryRates] = useState<
    Partial<Record<RateOwnerType, RateBasicWithValue | undefined>>
  >({});

  const isEditingProject = Boolean(defaultProject?.id);
  const departmentOptions = companyAssetsStore.departments;
  const currentCompany = userStore.currentCompanies[0] || userStore.user.company;

  const formMethods = useForm({
    resolver: yupResolver(projectFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: setDefaultProjectValues(defaultProject as Project, currentCompany),
  });

  const isGoogleMapPrimary = useFeatureFlag({
    featureFlagKey: FeatureFlags.googleMapsSiteModification,
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
    getValues,
    setError,
    formState: { errors, isValid, isDirty },
  } = formMethods;

  const selectedPhase = useMemo(() => {
    return defaultProject?.phases?.find((phase) => phase.primary);
  }, []);

  const {
    fields: projectMaterialTotalsFields,
    append: appendMaterialTotal,
    remove: removeMaterialTotal,
    update: updateMaterialTotal,
  } = useFieldArray({
    control,
    name: 'projectMaterialTotals',
  });

  const watchedAccount = watch('account');
  const watchedSelectedCompany = watch('company');
  const prevWatchedSelectedCompany = useRef(watchedSelectedCompany);
  const { account, service } = watch();

  useEffect(() => {
    if (errorsProp) {
      for (const error of errorsProp) {
        const newErrorField = getErrorMapping(error.field, error.message);
        if (newErrorField.field && newErrorField.message) {
          setError(newErrorField.field as keyof ReturnType<typeof setError>, {
            type: 'manual',
            message: newErrorField.message,
          });
        }
      }
    }
  }, [errorsProp]);

  useEffect(() => {
    if (defaultProject?.pickUpWayPoints?.[0]?.contact?.name) {
      setShowPickUpContact(true);
    }
    if (defaultProject?.dropOffWayPoints?.[0]?.contact?.name) {
      setShowDropOffContact(true);
    }
  }, []);

  useEffect(() => {
    if (!isEditingProject) {
      setValue('requester', userStore.user);
    }
  }, []);

  useEffect(() => {
    onFormStateChange({ isDirty, isValid });
  }, [isValid, isDirty]);

  useEffect(() => {
    const selectedCompanyId = watchedSelectedCompany?.id;
    const defaultCompanyId = defaultProject?.company?.id;
    if (selectedCompanyId && selectedCompanyId !== defaultCompanyId) {
      setValue('account', undefined);
      setTmpMaterial('');
      setValue('projectMaterialTotals', [EmptyProjectMaterialTotalItem]);
      setValue('site', {});
    }
  }, [watchedSelectedCompany, defaultProject?.company]);
  prevWatchedSelectedCompany.current = watchedSelectedCompany;

  useEffect(() => {
    if (watchedSelectedCompany?.id) {
      getDepartmentByCompanyId(watchedSelectedCompany?.id);
    }
  }, [watchedSelectedCompany]);

  useImperativeHandle(ref, () => ({
    submit: async (callBack?: (args: ProjectFormSchemaInterface) => void) => {
      const customerRateDetails = await new Promise<RateDetailsDTO | undefined>(
        (resolve) => {
          if (customerDetailsRef.current?.onSubmit) {
            customerDetailsRef.current.onSubmit((data) => {
              resolve(data);
            });
          } else {
            resolve(undefined);
          }
        },
      );

      const vendorRateDetails = await new Promise<RateDetailsDTO | undefined>(
        (resolve) => {
          if (vendorDetailsRef.current?.onSubmit) {
            vendorDetailsRef.current.onSubmit((data) => {
              resolve(data);
            });
          } else {
            resolve(undefined);
          }
        },
      );

      const driverRateDetails = await new Promise<RateDetailsDTO | undefined>(
        (resolve) => {
          if (driverDetailsRef.current?.onSubmit) {
            driverDetailsRef.current.onSubmit((data) => {
              resolve(data);
            });
          } else {
            resolve(undefined);
          }
        },
      );

      if (customerRateDetails && vendorRateDetails && driverRateDetails) {
        handleSubmit((formData) => {
          const initialPickupWaypointId = defaultProject?.pickUpWayPoints?.[0]?.id;
          const shouldRemovePickupWaypoint =
            initialPickupWaypointId && !formData.pickUpWayPoint?.id;

          const initialDropoffWaypointId = defaultProject?.dropOffWayPoints?.[0]?.id;
          const shouldRemoveDropoffWaypoint =
            initialDropoffWaypointId && !formData.dropOffWayPoint?.id;

          callBack?.({
            ...formData,
            customerRate: customerRateDetails.targetRate,
            customerRateType: customerRateDetails.targetRateType,
            customerRateValue: customerRateDetails.targetRateValue,
            vendorRate: vendorRateDetails.targetRate,
            vendorRateType: vendorRateDetails.targetRateType,
            vendorRateValue: vendorRateDetails.targetRateValue,
            driverRate: driverRateDetails.targetRate,
            driverRateType: driverRateDetails.targetRateType,
            driverRateValue: driverRateDetails.targetRateValue,
            ...(shouldRemovePickupWaypoint
              ? { pickUpWayPoint: { id: initialPickupWaypointId, _destroy: true } }
              : {}),
            ...(shouldRemoveDropoffWaypoint
              ? { dropOffWayPoint: { id: initialDropoffWaypointId, _destroy: true } }
              : {}),
          } as ProjectFormSchemaInterface);
        })();
      }
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
    fileAttachmentsOnSubmit: async (projectId: string) => {
      await fileAttachmentsRef.current?.onSubmit(projectId);
    },
    fileAttachmentsOnClose: async () => {
      await fileAttachmentsRef.current?.onCancel();
    },
  }));

  const removeMaterialTotalItem = (index: number) => {
    //New item, not sored in DB yet, just remove it
    if (!getValues('projectMaterialTotals')?.[index].id) {
      removeMaterialTotal(index);
    } else {
      // Previously saved item, mark it as deleted, in order to have their ids in the api call with destroy:1
      updateMaterialTotal(index, {
        ...projectMaterialTotalsFields[index],
        id: getValues('projectMaterialTotals')?.[index]?.id as string,
        isDeleted: true,
      });
    }
  };

  const fetchRates = useCallback(
    (ownerType: RateOwnerType, args?: { query: string; cursor: string }) => {
      if (watchedSelectedCompany?.id) {
        return getAccountRatesUsingOwnerType({
          ownerType,
          includeDefaultRates: true,
          accountId: account?.id,
          serviceId: service?.id,
          query: args?.query,
          cursor: args?.cursor,
          limit: 15,
        }).then((response) => {
          const { basicRates, primaryRate } = response.data.reduce(
            (obj, rate) => {
              obj.basicRates.push(rateToRateBasicWithValue(rate));

              if (rate.isPrimary) {
                obj.primaryRate = rateToRateBasicWithValue(rate);
              }

              return obj;
            },
            { basicRates: [] } as {
              basicRates: RateBasicWithValue[];
              primaryRate?: RateBasicWithValue;
            },
          );

          setPrimaryRates((state) => ({ ...state, [ownerType]: primaryRate }));

          return { ...response, data: basicRates };
        });
      }
    },
    [watchedSelectedCompany?.id, account?.id, service?.id],
  );

  const fetchCustomerRates = useCallback(
    (args?: { query: string; cursor: string }) => {
      return fetchRates(RateOwnerType.CUSTOMER, args);
    },
    [fetchRates],
  );

  const fetchVendorRates = useCallback(
    (args?: { query: string; cursor: string }) => {
      return fetchRates(RateOwnerType.VENDOR, args);
    },
    [fetchRates],
  );

  const fetchDriverRates = useCallback(
    (args?: { query: string; cursor: string }) => {
      return fetchRates(RateOwnerType.DRIVER, args);
    },
    [fetchRates],
  );

  useEffect(() => {
    if (!(RateOwnerType.CUSTOMER in primaryRates)) {
      fetchCustomerRates();
    }

    if (!(RateOwnerType.VENDOR in primaryRates)) {
      fetchVendorRates();
    }

    if (!(RateOwnerType.DRIVER in primaryRates)) {
      fetchDriverRates();
    }
  }, [
    fetchCustomerRates,
    fetchVendorRates,
    fetchDriverRates,
    RateOwnerType.CUSTOMER in primaryRates,
    RateOwnerType.VENDOR in primaryRates,
    RateOwnerType.DRIVER in primaryRates,
  ]);

  const onSuccess = (site: Site | undefined) => {
    if (!site) {
      return;
    }
    setValue('site', site);
    setShowCreateSiteForm(false);
    const name = `${site?.name}`;
    toasterStore.push(
      alert($t('administration.site.site_created', { name }), AlertTypes.success),
    );
  };

  const onSubmitCallback = (data: any) => {
    if (isGoogleMapPrimary) {
      createNewSiteV2(data)
        .then(onSuccess)
        .catch((error) => {
          console.error(error);
        });
    } else {
      createNewSite(data)
        .then(onSuccess)
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const onSubmitForm = () => {
    siteFormRef.current?.submit(onSubmitCallback);
  };

  const closeSiteForm = () => {
    setShowCreateSiteForm(false);
  };

  const onSiteFormStateChange = ({ isDirty }: FormStateChangeProps) => {
    setIsFormDirty(isDirty);
  };

  const onInputAccountName = (event: React.SyntheticEvent, value: string) => {
    setTmpAccountName(value);
  };

  const onInputMaterial = (event: React.SyntheticEvent, value: string) => {
    setTmpMaterial(value);
  };

  const createNewAccount = () => {
    if (!watchedSelectedCompany?.id) {
      toasterStore.push(
        alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
      );
    }

    createAccount({
      account: {
        name: tmpAccountName,
        accountTypes: [AccountType.CUSTOMER],
        connectedCompany: watchedSelectedCompany as CompanyBasic,
      } as unknown as Account,
      callBack: (account) => {
        toasterStore.push(
          alert(
            t('account.account_created', {
              name: account.name,
            }),
            AlertTypes.success,
          ),
        );
        setValue('account', account);
      },
    });
  };

  const createMaterial = (index: number) => {
    if (!currentCompany?.id) {
      toasterStore.push(
        alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
      );
    }

    createMaterialByCompanyId({
      companyId: currentCompany?.id || '',
      material: {
        name: tmpMaterial,
      } as unknown as Material,
    }).then((material: Material) => {
      toasterStore.push(
        alert(
          t('administration.material.material_created', {
            name: material.name,
          }),
          AlertTypes.success,
        ),
      );
      const { projectMaterialTotals } = getValues();
      projectMaterialTotals[index].material = material;

      setValue('projectMaterialTotals', projectMaterialTotals);
    });
  };

  const toggleOnPickUpContact = () => {
    setShowPickUpContact(true);
  };

  const toggleOffPickUpContact = () => {
    setValue('pickUpWayPoint.contact', {
      name: '',
      phone: '',
    });

    trigger('pickUpWayPoint.contact');
    setShowPickUpContact(false);
  };

  const toggleOnDropOffContact = () => {
    setShowDropOffContact(true);
  };

  const toggleOffDropOffContact = () => {
    setValue('dropOffWayPoint.contact', {
      name: '',
      phone: '',
    });
    trigger('dropOffWayPoint.contact');
    setShowDropOffContact(false);
  };

  return (
    <Box component="div" sx={{ mt: 0 }} data-test-id="project-form">
      <FormProvider {...formMethods}>
        <Grid container spacing={2} sx={{ my: 1 }}>
          {/*Left side*/}
          <Grid item xs={6}>
            {/*Project details*/}
            <Card sx={{ p: 2, mb: 2, overflow: 'visible' }}>
              <FormSectionTitle sx={{ mb: 2 }} title={t('order.form.project_details')} />
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <CompanySelectorFormField
                    key={watchedSelectedCompany?.id}
                    name={'company'}
                    disabled={!!defaultProject?.company?.id}
                    errors={errors}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AutocompleteAsyncFormField
                    key={watchedAccount?.id}
                    disabled={Boolean(
                      defaultProject?.projectId || !watchedSelectedCompany?.id,
                    )}
                    control={control}
                    errors={errors}
                    name={'account'}
                    label={`${$t('dispatch.order.customer_account')}`}
                    isRequired={true}
                    getValue={(item) => item.id}
                    getLabel={(item) => item.name || ''}
                    asyncCallback={getAccountsByCompanyIdTypeahead}
                    extraRequestOptions={{
                      companyId:
                        watchedSelectedCompany?.id || defaultProject?.company?.id,
                      accountTypes: [AccountType.CUSTOMER],
                    }}
                    onInput={onInputAccountName}
                    noOptionsText={
                      <Chip
                        size={'small'}
                        variant={'filled'}
                        color={'primary'}
                        onClick={() => createNewAccount()}
                        sx={{ cursor: 'pointer' }}
                        label={t('account.create_account')}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFormField
                    control={control}
                    errors={errors}
                    name="name"
                    label={`${$t('project.form.project_name')}`}
                    isRequired={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteFormField
                    control={control}
                    name="department"
                    groupBy={(item) => item?.typeName}
                    errors={errors}
                    sx={{ mr: 2 }}
                    list={departmentOptions}
                    label={`${$t('project.form.department')}`}
                    getValue={(item) => item.id}
                    getLabel={(item) => item.name || ''}
                    clearable={true}
                    noOptionsText={
                      <Chip
                        size={'small'}
                        variant={'filled'}
                        color={'primary'}
                        onClick={() => {
                          setShowCreateDepartment(true);
                        }}
                        disabled={!watchedSelectedCompany?.id}
                        sx={{ cursor: 'pointer' }}
                        label={$t('administration.department.create')}
                      />
                    }
                  />
                </Grid>
                {/*New department creating form*/}
                <Collapse in={showCreateDepartment} sx={{ width: '100%' }}>
                  <Grid sx={{ ml: 2 }}>
                    <DepartmentInlineForm
                      connectedCompany={watchedSelectedCompany as Nullable<CompanyBasic>}
                      callBackCreated={(department) => {
                        setShowCreateDepartment(false);
                        setValue('department', department);
                        trigger('department');
                        toasterStore.push(
                          alert(
                            t('administration.department.created', {
                              name: department.name,
                            }),
                            AlertTypes.success,
                          ),
                        );
                      }}
                      callBackCloseClicked={() => {
                        setShowCreateDepartment(false);
                      }}
                      sx={{ mb: 2 }}
                      defaultDepartment={null}
                    />
                  </Grid>
                </Collapse>
                <Grid item xs={6}>
                  <SingleDateTimeFormField
                    dateLabel={$t('project.form.effective_at_date') || ''}
                    timeLabel={$t('project.form.effective_at_time') || ''}
                    control={control}
                    errors={errors}
                    name={'effectiveAt'}
                    isRequired={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SingleDateTimeFormField
                    dateLabel={$t('project.form.expires_at_date') || ''}
                    timeLabel={$t('project.form.expires_at_time') || ''}
                    control={control}
                    errors={errors}
                    name={'expiresAt'}
                    isRequired={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFormField
                    control={control}
                    errors={errors}
                    name="externalId"
                    label={`${$t('project.form.project_external_id')}`}
                    isRequired={false}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
                <PhaseSelection
                  phaseOptions={defaultProject?.phases?.map((phase) => ({
                    id: phase.id,
                    name: phase.name,
                    code: phase.code,
                    primary: phase.primary,
                  }))}
                  selectedPhase={
                    selectedPhase
                      ? {
                          id: selectedPhase.id,
                          name: selectedPhase.name,
                          code: selectedPhase.code,
                          primary: selectedPhase.primary,
                        }
                      : null
                  }
                  onPhaseOptionsChange={(options) => {
                    setValue(
                      'phases',
                      options.map((value) => ({
                        id: value.id,
                        name: value.name,
                        code: value.code,
                        primary: value.primary,
                        isDeleted: Boolean(value._destroy),
                      })),
                      { shouldDirty: true },
                    );
                  }}
                  canEdit={true}
                />
              </Grid>
            </Card>

            {/* Contacts */}
            <Contacts defaultData={defaultProject} />

            <Card sx={{ p: 2 }}>
              <Box display="flex" flexDirection="column" gap={3}>
                <RateDetails
                  ref={customerDetailsRef}
                  disableTargetRateControls={!watchedSelectedCompany?.id}
                  fetchRates={fetchCustomerRates}
                  isEditing={isEditingProject}
                  onDirtyStatusChange={() => onFormStateChange({ isDirty: true })}
                  rateCardControlLabel={`${t('order.form.customer_rate_card')}`}
                  targetRate={defaultProject?.customerRate ?? null}
                  targetRateType={defaultProject?.customerRateType ?? null}
                  targetRateValue={defaultProject?.customerRateValue ?? null}
                  title={`${t('order.form.rate_details')}`}
                  matchedAttributes={[
                    { label: t('form_fields.account'), isMatched: Boolean(account?.id) },
                    { label: t('form_fields.service'), isMatched: Boolean(service?.id) },
                  ]}
                />

                <RateDetails
                  ref={vendorDetailsRef}
                  disableTargetRateControls={!watchedSelectedCompany?.id}
                  fetchRates={fetchVendorRates}
                  isEditing={isEditingProject}
                  onDirtyStatusChange={() => onFormStateChange({ isDirty: true })}
                  rateCardControlLabel={`${t('order.form.vendor_rate_card')}`}
                  targetRate={defaultProject?.vendorRate ?? null}
                  targetRateType={defaultProject?.vendorRateType ?? null}
                  targetRateValue={defaultProject?.vendorRateValue ?? null}
                  matchedAttributes={[
                    { label: t('form_fields.account'), isMatched: Boolean(account?.id) },
                    { label: t('form_fields.service'), isMatched: Boolean(service?.id) },
                  ]}
                />

                <RateDetails
                  ref={driverDetailsRef}
                  disableTargetRateControls={!watchedSelectedCompany?.id}
                  fetchRates={fetchDriverRates}
                  isEditing={isEditingProject}
                  onDirtyStatusChange={() => onFormStateChange({ isDirty: true })}
                  rateCardControlLabel={`${t('order.form.internal_driver_rate_card')}`}
                  targetRate={defaultProject?.driverRate ?? null}
                  targetRateType={defaultProject?.driverRateType ?? null}
                  targetRateValue={defaultProject?.driverRateValue ?? null}
                  matchedAttributes={[
                    { label: t('form_fields.account'), isMatched: Boolean(account?.id) },
                    { label: t('form_fields.service'), isMatched: Boolean(service?.id) },
                  ]}
                />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={6}>
            {/*Location details*/}
            <Card sx={{ p: 2, mb: 2 }}>
              <FormSectionTitle
                sx={{ mb: 2 }}
                title={$t('order.form.location_details')}
              />
              <SiteSelection
                allowRemoveDefaultSites
                errors={errors}
                isRequired={false}
                companyId={watchedSelectedCompany?.id}
                projectId={defaultProject?.id}
                pickUpSite={pickUpWaypoint?.site}
                dropOffSite={dropOffWaypoint?.site}
                onDefaultSiteAdded={({ siteId, waypointType }: AddDefaultSiteParams) =>
                  onFormStateChange({ siteId, waypointType, isDirty: true })
                }
                onPickUpSiteChange={(site) => {
                  let newPickUpWaypoint = null;

                  if (site) {
                    newPickUpWaypoint = createWaypoint(
                      site,
                      WaypointType.PICKUP,
                      10,
                      defaultProject?.pickUpWayPoints?.[0]?.id,
                    ) as any;
                  }

                  setValue('pickUpWayPoint', newPickUpWaypoint, { shouldDirty: true });
                  setPickUpWaypoint(newPickUpWaypoint);
                }}
                onDropOffSiteChange={(site) => {
                  let newDropOffWaypoint = null;
                  if (site) {
                    newDropOffWaypoint = createWaypoint(
                      site,
                      WaypointType.DROP_OFF,
                      20,
                      defaultProject?.dropOffWayPoints?.[0]?.id,
                    ) as any;
                  }

                  setValue('dropOffWayPoint', newDropOffWaypoint, { shouldDirty: true });
                  setDropOffWaypoint(newDropOffWaypoint);
                }}
              />
              {!showPickUpContact && (
                <PseudoLink
                  title={`+ ${$t('order.form.add_pick_up_site_contact')}`}
                  action={toggleOnPickUpContact}
                />
              )}
              <Collapse in={showPickUpContact}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextFormField
                      control={control}
                      errors={errors}
                      name="pickUpWayPoint.contact.name"
                      label={`${$t('form_fields.name')}`}
                      disabled={!watchedSelectedCompany?.id}
                      isRequired={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneCodeFlagInput
                      control={control as unknown as ControllerProps['control']}
                      errors={errors as unknown as FieldErrors}
                      name="pickUpWayPoint.contact.phone"
                      label={`${$t('form_fields.phone')}`}
                      disabled={!watchedSelectedCompany?.id}
                      isRequired={false}
                    />
                  </Grid>
                </Grid>
                <PseudoLink
                  title={$t('order.form.remove_pick_up_site_contact')}
                  sx={{ mt: 1 }}
                  action={toggleOffPickUpContact}
                />
              </Collapse>
              {!showDropOffContact && (
                <PseudoLink
                  title={`+ ${$t('order.form.add_drop_off_site_contact')}`}
                  action={toggleOnDropOffContact}
                />
              )}
              <Collapse in={showDropOffContact}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextFormField
                      control={control}
                      errors={errors}
                      name="dropOffWayPoint.contact.name"
                      label={`${$t('form_fields.name')}`}
                      isRequired={false}
                      disabled={!watchedSelectedCompany?.id}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneCodeFlagInput
                      control={control as unknown as ControllerProps['control']}
                      errors={errors as unknown as FieldErrors}
                      name="dropOffWayPoint.contact.phone"
                      label={`${$t('form_fields.phone')}`}
                      isRequired={false}
                      disabled={!watchedSelectedCompany?.id}
                    />
                  </Grid>
                </Grid>
                <PseudoLink
                  title={$t('order.form.remove_drop_off_site_contact')}
                  sx={{ mt: 1 }}
                  action={toggleOffDropOffContact}
                />
              </Collapse>
            </Card>

            {/*Create new site*/}
            <Collapse in={showCreateSiteForm}>
              {showCreateSiteForm && (
                <Card sx={{ p: 2, mb: 2 }}>
                  <FormSectionTitle
                    sx={{ mb: 2 }}
                    title={t('administration.site.add_new')}
                  />
                  {isGoogleMapPrimary ? (
                    <SiteFormV2
                      isInline={true}
                      company={watchedSelectedCompany as CompanyBasic}
                      onFormStateChange={onSiteFormStateChange}
                      ref={siteFormRef}
                      defaultSite={{} as Site}
                    />
                  ) : (
                    <SiteForm
                      isInline={true}
                      company={watchedSelectedCompany as CompanyBasic}
                      onFormStateChange={onSiteFormStateChange}
                      ref={siteFormRef}
                      defaultSite={{} as Site}
                    />
                  )}
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'end'}
                    sx={{ mt: 1 }}
                  >
                    <LoadingButton
                      disabled={!isFormDirty || isUpdating}
                      loading={isUpdating}
                      loadingPosition="start"
                      startIcon={<></>}
                      onClick={onSubmitForm}
                      type="button"
                      variant="contained"
                      color="primary"
                      sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
                    >
                      {$t(`actions.create`)}
                    </LoadingButton>
                    <Button
                      onClick={closeSiteForm}
                      sx={{ ml: 2, px: 2 }}
                      disabled={isUpdating}
                      color="secondary"
                      variant="outlined"
                    >
                      {$t('actions.cancel')}
                    </Button>
                  </Box>
                </Card>
              )}
            </Collapse>

            {/* Order Defaults */}
            <Card sx={{ p: 2, mb: 2 }}>
              <FormSectionTitle sx={{ mb: 0 }} title={t('project.form.order_defaults')} />
              <OrderDetails defaultData={defaultProject} isProjectView />
            </Card>

            <Card sx={{ p: 2, mb: 2 }}>
              <FormSectionTitle sx={{ mb: 0 }} title={t('project.form.project_totals')} />
              {projectMaterialTotalsFields.map((materialTotal, index) => {
                return materialTotal.isDeleted ? null : (
                  <Grid container key={`${index}--${materialTotal.id}`} spacing={2}>
                    <Grid item xs={4}>
                      <AutocompleteAsyncFormField
                        control={control}
                        errors={errors}
                        name={`projectMaterialTotals[${index}].material`}
                        label={`${$t('form_fields.material')}`}
                        isRequired={false}
                        clearable={false}
                        getValue={(item) => item?.id}
                        getLabel={(item) => item.name || ''}
                        asyncCallback={getMaterialsByCompanyIdTypeahead}
                        extraRequestOptions={{
                          companyId:
                            watchedSelectedCompany?.id || defaultProject?.company?.id,
                        }}
                        onInput={onInputMaterial}
                        noOptionsText={
                          tmpMaterial ? (
                            <Chip
                              size={'small'}
                              variant={'filled'}
                              color={'primary'}
                              onClick={() => createMaterial(index)}
                              disabled={isMaterialLoading}
                              sx={{ cursor: 'pointer' }}
                              label={$t('common.add_new_name', {
                                name: tmpMaterial || '',
                              })}
                            />
                          ) : (
                            $t('form_fields.no_options')
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextFormField
                        control={control}
                        errors={errors}
                        name={`projectMaterialTotals[${index}].totalQuantity`}
                        label={`${$t('project.form.total_quantity')}`}
                        isRequired={false}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <AutocompleteFormField
                        control={control}
                        name={`projectMaterialTotals[${index}].unitOfMeasure`}
                        errors={errors}
                        list={unitOfMeasureOptions}
                        label={`${$t('form_fields.unit')}`}
                        isRequired={false}
                        clearable={false}
                        getValue={(item) => item?.id}
                        getLabel={(item) => item.name || ''}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <RemoveItemButton
                        action={() => {
                          removeMaterialTotalItem(index);
                        }}
                        sx={{
                          mt: 4.3,
                          cursor: 'pointer',
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
              <PseudoLink
                title={$t('project.form.add_material')}
                sx={{ display: 'inline-flex' }}
                action={() => appendMaterialTotal(EmptyProjectMaterialTotalItem)}
              />
            </Card>

            <Card sx={{ p: 2, mb: 2 }}>
              <FileAttachments
                fileAttachableId={defaultProject?.id}
                fileAttachableType={FileAttachment_Read.file_attachable_type.PROJECT}
                control={control}
                errors={errors}
                form={formMethods}
                name="fileAttachments"
                ref={fileAttachmentsRef}
              />
            </Card>

            {/* Notes */}
            <Notes defaultData={defaultProject} defaultTab={NoteTypes.PROJECT} />

            {/* Requester */}
            <Card sx={{ p: 2, my: 2 }}>
              <FormSectionTitle title={$t('project.form.requester')} sx={{ mb: 1 }} />
              <AutocompleteAsyncFormField
                control={control}
                errors={errors}
                name={'requester'}
                isRequired={false}
                clearable={true}
                getValue={(user: User) => user.id}
                getLabel={(user: User) => user.fullName ?? ''}
                asyncCallback={getCompanyUsersTypeahead}
                extraRequestOptions={{
                  companyId: watchedSelectedCompany?.id,
                }}
                hideLabel
              />
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  );
});

const getErrorMapping = (fieldName: string, defaultMessage: string) => {
  switch (fieldName) {
    case 'external_id':
      return {
        field: 'externalId',
        message: $t('error_messages.projects.external_id_taken'),
      };
    case 'name':
      return {
        field: 'name',
        message: $t('error_messages.projects.name_taken'),
      };
    default:
      return {
        field: fieldName,
        message: defaultMessage,
      };
  }
};

export { ProjectForm };
