import { t } from 'i18next';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useLogin } from '~hooks/useLogin/useLogin';
import { ResetSignupProps } from '~pages/Auth/types';

import SignUpResetForm from './SignUpResetForm';

const tokenUrl = 'token';
const ResetPassword = () => {
  const { resetPassword, isLoading } = useLogin();
  const [searchParams] = useSearchParams();
  const token = searchParams.get(tokenUrl)!;
  const handleSubmitReset = (data: ResetSignupProps) => {
    resetPassword({
      password_reset_token: token,
      password: data?.password,
    });
  };
  return (
    <>
      <SignUpResetForm
        formTitle={t('page_headings.reset_password')}
        callBackAction={handleSubmitReset}
        isLoading={isLoading}
        isReset={true}
      ></SignUpResetForm>
    </>
  );
};
export default ResetPassword;
