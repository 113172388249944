import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { CommonLoader } from '~components/Loaders/CommonLoader';
import Toasters from '~components/Toasters';
import { PRIMARY_TOKEN } from '~constants/consts';
import { useAccess } from '~hooks/useAccess';
import { useCompany } from '~hooks/useCompany';
import { usePermissions } from '~hooks/usePermissions';
import { useUsers } from '~hooks/useUsers';
import { useStores } from '~store';
import AuthStore from '~store/AuthStore';
import { usePrevious } from '~utils/hooks/usePrevious';

import { getRoutes } from './routeUtils';

const rehydrateUser = async (
  setSplitSDKConfig: React.Dispatch<
    React.SetStateAction<SplitIO.IBrowserSettings | undefined>
  >,
  setIsHydratingUser: React.Dispatch<React.SetStateAction<boolean>>,
  authStore: AuthStore,
  getUser: ReturnType<typeof useUsers>['getUser'],
  getAccess: ReturnType<typeof useAccess>['getAccess'],
  getPermissions: ReturnType<typeof usePermissions>['getPermissions'],
  getUserCompany: ReturnType<typeof useCompany>['getUserCompany'],
  getUserCompanies: ReturnType<typeof useCompany>['getAllUserAvailableCompanies'],
) => {
  setIsHydratingUser(true);
  const user = await getUser();
  if (user) {
    // Handles the refresh case
    if (!authStore.isAuthenticated) {
      authStore.login();
    }

    await Promise.all([getAccess(), getPermissions()]);
    const userCompany = await getUserCompany(String(user.company?.id));
    await getUserCompanies({ id: userCompany.id });

    // SplitIO setup
    const splitSDKKey = import.meta.env.TREAD__SPLITIO_SDK_API_KEY || '';
    // For local testing, feature flags can be set in:
    //    - env/.env.development: if you want all devs to see it
    //    - env/.env.local: if you want to test it locally
    const featureFlags = import.meta.env.TREAD__FEATURE_FLAGS
      ? JSON.parse(import.meta.env.TREAD__FEATURE_FLAGS)
      : {};

    if (splitSDKKey && userCompany.treadId) {
      const splitSDKConfig = {
        core: {
          authorizationKey: splitSDKKey,
          // Key is used to identify the user's company, since the trafficType is set to 'companies'
          key: userCompany.treadId,
          trafficType: 'companies',
        },
        features: featureFlags,
      };

      setSplitSDKConfig(splitSDKConfig);
      setIsHydratingUser(false);
    } else {
      setIsHydratingUser(false);
    }
  } else {
    setIsHydratingUser(false);
  }
};

export const Router = observer(() => {
  const [splitSDKConfig, setSplitSDKConfig] = useState<SplitIO.IBrowserSettings>();
  const [isHydratingUser, setIsHydratingUser] = useState(false);

  const { authStore, userStore, userAdminStore } = useStores();
  const prevImpersonatorUser = usePrevious(userAdminStore.impersonatorUser);

  const { getUser } = useUsers();
  const { getAccess } = useAccess();
  const { getPermissions } = usePermissions();
  const { getUserCompany, getAllUserAvailableCompanies } = useCompany();

  const isAuthenticated = authStore.isAuthenticated;
  const hasToken = Boolean(localStorage.getItem(PRIMARY_TOKEN));

  const isDoneLoadingUser =
    !isHydratingUser && !_.isEmpty(userStore.user) && !_.isEmpty(userStore.userAccess);

  const isReady = hasToken ? isAuthenticated && isDoneLoadingUser : true;

  useEffect(() => {
    if (hasToken && !isHydratingUser) {
      const refreshReason = !authStore.isAuthenticated;
      const loginReason = !userStore.user.id;
      const impersonateReason = userAdminStore.impersonatorUser !== prevImpersonatorUser;
      if (refreshReason || loginReason || impersonateReason) {
        rehydrateUser(
          setSplitSDKConfig,
          setIsHydratingUser,
          authStore,
          getUser,
          getAccess,
          getPermissions,
          getUserCompany,
          getAllUserAvailableCompanies,
        );
      }
    }
  }, [
    hasToken,
    authStore.isAuthenticated,
    userStore.user,
    userAdminStore.impersonatorUser,
    prevImpersonatorUser,
    isHydratingUser,
    setSplitSDKConfig,
    setIsHydratingUser,
  ]);

  if (!isReady) {
    return <CommonLoader />;
  }

  const routes = getRoutes(userStore, userAdminStore);

  return (
    <SplitFactoryProvider config={splitSDKConfig}>
      <Toasters />
      <ToastContainer newestOnTop={true} />
      <RouterProvider router={createBrowserRouter(routes)} />
    </SplitFactoryProvider>
  );
});
