import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { JobState } from '@treadinc/horizon-api-spec';
import { ReactElement, useMemo, useState } from 'react';
import React from 'react';

import { Account } from '~hooks/useAccount';
import { Job } from '~hooks/useJob';
import { User } from '~hooks/useUsers';
import { useStores } from '~store';

import { AssignDriverMenu } from './AssignDriverMenu';
import { AssignedDriverView } from './AssignedDriverView';
import { useJobAssignment } from './hooks/useJobAssignment';

interface AssignedJobToViewProps {
  companyId: string;
  customAssignCTA?: ReactElement;
  customUnassignCTA?: ReactElement;
  driver?: User;
  editable?: boolean;
  isUnassignable?: boolean;
  jobId: string;
  onSuccess?: (job: Job) => void;
  status: JobState;
  sx?: SxProps<Theme>; // Style
  valueToDisplay?: 'driver' | 'vendor';
  vendor?: Account;
  filterDate?: string;
}

interface UpdateJobViewProps {
  customUnassignCTA?: ReactElement;
  driver?: User;
  editable?: boolean;
  onEditClick: () => void;
  status: JobState;
  sx?: SxProps<Theme>; // Style
  valueToDisplay?: 'driver' | 'vendor';
  vendor?: Account;
}

type ShouldShowReadRemoveOnlyViewFnArgs = Pick<
  UpdateJobViewProps,
  'driver' | 'vendor' | 'status' | 'valueToDisplay'
>;
const shouldShowReadRemoveOnlyView = ({
  driver,
  vendor,
  status,
  valueToDisplay,
}: ShouldShowReadRemoveOnlyViewFnArgs) => {
  // Not relying on status as assigned state may go away
  const isJobAssigned = !!vendor || !!driver;

  return isJobAssigned || valueToDisplay === 'driver' || status === JobState.CANCELED;
};

const AssignJobToView = ({
  companyId,
  customAssignCTA,
  customUnassignCTA,
  driver,
  editable = false,
  isUnassignable,
  jobId,
  onSuccess,
  status,
  valueToDisplay,
  vendor,
  filterDate,
}: AssignedJobToViewProps) => {
  const { userStore } = useStores();
  const [_, setShowAssignPopover] = useState<boolean>(false);
  const { handleAssign, handleUnassign } = useJobAssignment(jobId, driver, onSuccess);

  const hasReadRemoveOnlyView = shouldShowReadRemoveOnlyView({
    driver,
    vendor,
    status,
    valueToDisplay,
  });

  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);
  const isEditable = useMemo(
    () => editable && userPermissions.canEditJob,
    [editable, userPermissions.canEditJob],
  );

  return (
    <>
      {!hasReadRemoveOnlyView && (
        <AssignDriverMenu
          companyId={companyId}
          customAssignCTA={customAssignCTA}
          filterDate={filterDate}
          onOpenStateChanged={(open) => {
            setShowAssignPopover(open);
            if (!open) {
              setShowAssignPopover(false);
            }
          }}
          onChange={handleAssign}
        />
      )}

      <AssignedDriverView
        customUnassignCTA={customUnassignCTA}
        driver={driver}
        editable={isEditable}
        isUnassignable={isUnassignable}
        onUnassignClick={handleUnassign}
        status={status}
        valueToDisplay={valueToDisplay}
        vendor={vendor}
      />
    </>
  );
};

export { AssignJobToView };
