interface BaseAlert {
  autoHideDuration: number | null;
  display: boolean;
  message: string;
  onClick?: () => void;
}

interface Success extends BaseAlert {
  kind: 'success';
}

interface Info extends BaseAlert {
  kind: 'info';
}

interface Warn extends BaseAlert {
  kind: 'warn';
}
interface Error extends BaseAlert {
  kind: 'error';
}

export type Alert = Success | Info | Warn | Error;
export const AlertTypes = {
  error: 'error',
  info: 'info',
  success: 'success',
  warn: 'warn',
} as const;
export const alert = (
  message: string | React.ReactNode,
  kind: Alert['kind'],
  autoHideDuration: number | null = 6000,
  onClick?: () => void,
): Alert =>
  ({
    message: message,
    kind,
    display: true,
    autoHideDuration,
    onClick,
  }) as Alert;
