import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import EditOutlined from '@mui/icons-material/EditOutlined';
import Refresh from '@mui/icons-material/Refresh';
import { outlinedInputClasses } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { OrderUnitOfMeasure } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextFormField } from '~components/FormFields/TextFormField';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { NameIdSchemaRequired } from '~constants/regexConst';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { getOrderEstimateBaseUnit } from '~hooks/useOrders/models';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { nullableStringOrNumberIsValidNumber } from '~utils/utilFunctions';

import { findHiredTruckServiceInServicesList } from '../newOrderFormSchema';
import { EstimateCard, FlexRow, IconCTA } from './shared-components';
import useDeliveredQuantitiesEstimate from './useDeliveredQuantitiesEstimate';

const DeliveryEstimates = observer(() => {
  const form = useFormContext();

  const { companyAssetsStore } = useStores();
  const { numberFormatter } = useCompanyCurrency();

  const isEditing = Boolean(form.watch('id'));
  const [isExposingEstimateFields, setIsExposingEstimateFields] = useState(false);

  const {
    estimateDeliveredPerTruck,
    estimateDeliveredQuantity,
    estimateTotalLoads,
    estimateUnitsPerHour,
  } = useDeliveredQuantitiesEstimate();

  const watchedTotalLoads = form.watch('totalLoads') as string | number | null;
  const watchedJobQuantity = form.watch('jobQuantity') as string | number | null;
  const watchedUnitsPerHour = form.watch('unitsPerHour') as string | number | null;
  const watchedUoM = form.watch('unitOfMeasure') as NameIdSchemaRequired | null;
  const watchedEstimatedDeliveredQuantity = form.watch('estimatedDeliveredQuantity') as
    | string
    | number
    | null;

  const service = findHiredTruckServiceInServicesList(companyAssetsStore.services);
  const shouldDisableAllFields = isEditing && !isExposingEstimateFields;

  const orderEstimateBaseUnit = useMemo(() => {
    if (service && watchedUoM?.id) {
      return getOrderEstimateBaseUnit(service, watchedUoM.id as OrderUnitOfMeasure);
    }
  }, [service, watchedUoM?.id]);

  const shouldDisableTotalLoadsField = useMemo(() => {
    const isEstimateBasedInLoads = orderEstimateBaseUnit === 'loads';

    return shouldDisableAllFields || isEstimateBasedInLoads;
  }, [shouldDisableAllFields, orderEstimateBaseUnit]);

  const formattedTotalLoads = useMemo(() => {
    if (nullableStringOrNumberIsValidNumber(watchedTotalLoads)) {
      return numberFormatter(Number(watchedTotalLoads));
    }

    return '-';
  }, [watchedTotalLoads]);

  const formattedEstimatedDeliveredQuantity = useMemo(() => {
    if (nullableStringOrNumberIsValidNumber(watchedEstimatedDeliveredQuantity)) {
      return numberFormatter(Number(watchedEstimatedDeliveredQuantity));
    }

    return '-';
  }, [watchedEstimatedDeliveredQuantity]);

  const formattedJobQuantity = useMemo(() => {
    if (nullableStringOrNumberIsValidNumber(watchedJobQuantity)) {
      return numberFormatter(Number(watchedJobQuantity));
    }

    return '-';
  }, [watchedJobQuantity]);

  const formattedUnitsPerHour = useMemo(() => {
    if (nullableStringOrNumberIsValidNumber(watchedUnitsPerHour)) {
      return numberFormatter(Number(watchedUnitsPerHour));
    }

    return '-';
  }, [watchedUnitsPerHour]);

  const handleRefreshEstimatesClick = useCallback(() => {
    const totalLoads = estimateTotalLoads();
    form.setValue('totalLoads', `${totalLoads ?? ''}`);

    const estimatedDeliveredQuantity = estimateDeliveredQuantity();
    form.setValue('estimatedDeliveredQuantity', `${estimatedDeliveredQuantity ?? ''}`);

    const jobQuantity = estimateDeliveredPerTruck();
    form.setValue('jobQuantity', `${jobQuantity ?? ''}`);

    const unitsPerHour = estimateUnitsPerHour();
    form.setValue('unitsPerHour', `${unitsPerHour ?? ''}`);
  }, [
    estimateDeliveredPerTruck,
    estimateDeliveredQuantity,
    estimateTotalLoads,
    estimateUnitsPerHour,
  ]);

  const handleEditClick = useCallback(() => {
    setIsExposingEstimateFields(true);
  }, []);

  const handleCancelClick = useCallback(() => {
    setIsExposingEstimateFields(false);
    form.resetField('totalLoads');
    form.resetField('jobQuantity');
    form.resetField('unitsPerHour');
  }, []);

  const handleConfirmClick = useCallback(() => {
    setIsExposingEstimateFields(false);
  }, []);

  return (
    <>
      <FlexRow sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <FormSectionTitle
          title={t('dispatch.order.delivery_estimates.title')}
          sx={{ fontSize: '14px' }}
        />

        {isEditing && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {isExposingEstimateFields ? (
              <>
                <IconCTA
                  color={theme.brandV2.colors.treadGreen}
                  icon={Check}
                  onClick={handleConfirmClick}
                  tooltip={t('dispatch.order.delivery_estimates.confirm_values')}
                />

                <IconCTA
                  color={theme.brandV2.colors.treadRed}
                  icon={Close}
                  onClick={handleCancelClick}
                  tooltip={t('dispatch.order.delivery_estimates.reset_values')}
                />
              </>
            ) : (
              <IconCTA
                color={theme.brandV2.colors.treadBlue}
                icon={EditOutlined}
                onClick={handleEditClick}
                tooltip={t('dispatch.order.delivery_estimates.edit_estimates')}
              />
            )}

            <IconCTA
              color={theme.brandV2.colors.treadOrangeDark}
              icon={Refresh}
              onClick={handleRefreshEstimatesClick}
              tooltip={t('dispatch.order.delivery_estimates.edit_estimates')}
            />
          </Box>
        )}
      </FlexRow>

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <EstimateCard title={t('dispatch.order.delivery_estimates.total_loads')}>
            {isExposingEstimateFields ? (
              <TextFormField
                control={form.control}
                disabled={shouldDisableTotalLoadsField}
                errors={form.formState.errors}
                name="totalLoads"
                type="number"
                sx={{ [`& .${outlinedInputClasses.root}`]: { bgcolor: 'white' } }}
              />
            ) : (
              formattedTotalLoads
            )}
          </EstimateCard>
        </Grid>

        <Grid item xs={3}>
          <EstimateCard
            title={t('dispatch.order.delivery_estimates.estimated_delivered_qty')}
          >
            {formattedEstimatedDeliveredQuantity}
          </EstimateCard>
        </Grid>

        <Grid item xs={3}>
          <EstimateCard
            title={t('dispatch.order.delivery_estimates.delivered_per_truck')}
            variant="light"
          >
            {isExposingEstimateFields ? (
              <TextFormField
                control={form.control}
                disabled={shouldDisableAllFields}
                errors={form.formState.errors}
                name="jobQuantity"
                type="number"
                sx={{ [`& .${outlinedInputClasses.root}`]: { bgcolor: 'white' } }}
              />
            ) : (
              formattedJobQuantity
            )}
          </EstimateCard>
        </Grid>

        <Grid item xs={3}>
          <EstimateCard
            title={t('dispatch.order.delivery_estimates.units_per_hour')}
            variant="light"
          >
            {formattedUnitsPerHour}

            {/* Keep this one hidden, as it is for control registering purpose only */}
            <TextFormField
              control={form.control}
              errors={form.formState.errors}
              name="unitsPerHour"
              sx={{ display: 'none' }}
            />
          </EstimateCard>
        </Grid>
      </Grid>
    </>
  );
});

export default DeliveryEstimates;
