import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/system';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { ModelError_Item } from '@treadinc/horizon-api-spec';
import { t as $t, t } from 'i18next';
import { camelCase } from 'lodash';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { CheckBoxFormField } from '~components/FormFields/CheckBoxFormField';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { PseudoLink } from '~components/Helpers/PseudoLink';
import { FeatureFlags } from '~constants/featureFlags';
import { FormStateChangeProps } from '~formsShared';
import { CompanyBasic, useCompany } from '~hooks/useCompany';
import { CompanyShare } from '~hooks/useCompanyShares';
import { Equipment, EquipmentTypeItem, useEquipment } from '~hooks/useEquipment';
import { RemoveItemButton } from '~pages/Sales/sharedSales/RemoveItemButton';
import {
  equipmentFormValidationSchema,
  sharedEquipmentFormValidationSchema,
} from '~pages/Settings/Administration/EquipmentManagement/equipmentFormValidationSchema';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';
import { usePrevious } from '~utils/hooks/usePrevious';

export type EquipmentFormValues = yup.InferType<typeof equipmentFormValidationSchema>;

export interface EquipmentFormHandlers {
  submit: (callBack: (data: EquipmentFormValues) => void) => void;
  resetForm: (callBack?: () => void) => void;
}

interface EquipmentFormProps {
  defaultEquipment: Nullable<Equipment>;
  companyShares: Nullable<Array<CompanyShare>>;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
  onFormDirty?: (isDirty: boolean) => void;
  selectedCompanyId: Nullable<string>;
  errors?: ModelError_Item[];
  isManagedEquipment?: boolean;
}

export const EquipmentHint = styled(Typography)<TypographyProps>(() => {
  return {
    fontSize: '12px',
    mt: 0.5,
    color: '#777',
  };
});

const EquipmentForm = forwardRef(function EquipmentForm(
  {
    defaultEquipment,
    companyShares,
    onFormStateChange,
    selectedCompanyId,
    errors: errorsProp,
    isManagedEquipment = false,
  }: EquipmentFormProps,
  ref: Ref<EquipmentFormHandlers>,
) {
  const { treatments, isReady } = useSplitTreatments({
    names: [FeatureFlags.driverEquipmentEnhancements],
  });
  const driverEquipmentEnhancementsFeatureFlag =
    treatments[FeatureFlags.driverEquipmentEnhancements];
  const isDriverEquipmentEnhancementsEnabled =
    isReady && driverEquipmentEnhancementsFeatureFlag.treatment === 'on';
  const [equipmentTypesOptions, setEquipmentTypesOptions] = useState<EquipmentTypeItem[]>(
    [],
  );
  const [connectedCompanies, setConnectedCompanies] = useState<CompanyBasic[]>([]);
  const { userStore, toasterStore } = useStores();
  const {
    getAllConnectedCompaniesByCompanyId,
    getAllConnectedCompaniesByCompanyIdTypeahead,
  } = useCompany();
  const isSharedEquipment = !!defaultEquipment?.companyShare?.id;
  const selectedCompanyOwnsEquipment =
    defaultEquipment?.company?.id === selectedCompanyId;
  const {
    getEquipmentTypesByCompanyId,
    createEquipmentTypeByCompanyId,
    isLoading: isEquipmentTypeLoading,
  } = useEquipment();

  const defaultValues = {
    id: defaultEquipment?.id || '',
    name: defaultEquipment?.name || '',
    company:
      defaultEquipment?.company ||
      userStore.currentCompanies[0] ||
      userStore.user.company,
    type:
      {
        name: defaultEquipment?.type?.name || '',
        id: defaultEquipment?.type?.id || '',
        system: defaultEquipment?.type?.system || false,
      } || null,

    externalId: defaultEquipment?.externalId || '',
    licenseNumber: defaultEquipment?.licenseNumber || '',
    accountingId: defaultEquipment?.accountingId || '',
    zone: defaultEquipment?.zone || '',
    isExternal: defaultEquipment?.isExternal || false,
    isNotActive: defaultEquipment?.isNotActive || false,
    companySpecificId: defaultEquipment?.companyShare?.externalId || '',
    companyShares: companyShares?.length
      ? companyShares.map((companyShare) => {
          return {
            id: companyShare.id,
            receiverCompany: {
              id: companyShare.receiverCompany.id,
              legalName: companyShare.receiverCompany.legalName,
            },
            dispatchable: {
              id: companyShare.dispatchable ? 'dispatchable' : 'view_only',
              name: companyShare.dispatchable
                ? $t('common.dispatchable')
                : $t('common.view_only'),
            },
            companyShareableId: companyShare.companyShareable.id,
          };
        })
      : [],
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid, isDirty },
    reset,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(
      isSharedEquipment
        ? sharedEquipmentFormValidationSchema
        : equipmentFormValidationSchema,
    ),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  // Set errors from props
  useEffect(() => {
    if (errorsProp) {
      for (const error of errorsProp) {
        const field = camelCase(error.field) as keyof ReturnType<typeof setError>;
        // Replace external ID with Equipment ID
        const message = error.message.replace('external', 'Equipment');

        if (field && message) {
          setError(field, { type: 'manual', message });
        }
      }
    }

    return () => {
      clearErrors();
    };
  }, [errorsProp]);

  const selectedCompany = watch('company');

  // Update default values once async companyShares are loaded
  useEffect(() => {
    if (!isManagedEquipment) {
      reset(defaultValues);
    }
  }, [companyShares, isManagedEquipment]);

  const {
    fields: companySharesFields,
    append: appendCompanyShares,
    remove: removeCompanyShares,
  } = useFieldArray({
    control,
    name: 'companyShares',
  });

  const [tmpEquipmentType, setTmpEquipmentType] = useState<string>('');
  const onInputEquipmentType = (event: React.SyntheticEvent, value: string) => {
    setTmpEquipmentType(value);
  };

  const createEquipmentType = () => {
    if (!userStore.userCompany?.id) {
      toasterStore.push(
        alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
      );
    }

    createEquipmentTypeByCompanyId({
      companyId: selectedCompanyId || userStore.userCompany?.id || '',
      equipmentType: {
        name: tmpEquipmentType,
      } as unknown as EquipmentTypeItem,
    }).then((equipmentType) => {
      setEquipmentTypesOptions((prevState) => [...prevState, equipmentType]);
      setValue('type', equipmentType);
      trigger('type');
      toasterStore.push(
        alert(
          t('administration.equipment.equipment_created', {
            name: equipmentType.name,
          }),
          AlertTypes.success,
        ),
      );
    });
  };

  useImperativeHandle(ref, () => ({
    submit(callBack: (data: EquipmentFormValues) => void) {
      handleSubmit(callBack)();
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
  }));

  const previousCompanyId = usePrevious(selectedCompany?.id);

  useEffect(() => {
    if (
      selectedCompany?.id &&
      !isSharedEquipment &&
      previousCompanyId !== selectedCompany?.id
    ) {
      getEquipmentTypesByCompanyId({
        companyId: selectedCompany?.id,
        callBack: setEquipmentTypesOptions,
      });
    }
  }, [selectedCompany, isSharedEquipment, previousCompanyId]);

  useEffect(() => {
    if (selectedCompanyId || userStore?.userCompany?.id) {
      getAllConnectedCompaniesByCompanyId({
        companyId: selectedCompanyId ?? userStore.userCompany.id,
        callback: setConnectedCompanies,
      });
    }
  }, [userStore?.userCompany?.id, selectedCompanyId]);

  useEffect(() => {
    onFormStateChange({ isValid, isDirty });
  }, [isValid, isDirty]);

  return (
    <form>
      <Box display={'flex'} flexDirection={'column'} sx={{ width: '100%', flexGrow: 1 }}>
        {!isManagedEquipment && (
          <Box sx={{ width: '100%' }}>
            <CompanySelectorFormField
              sx={{ width: '100%', minWidth: '100%' }}
              name={'company'}
              isRequired={true}
              disabled={!!defaultEquipment?.company?.id || isSharedEquipment}
              errors={errors}
              control={control}
            />
          </Box>
        )}
        <Box display={'flex'} sx={{ mt: 2 }}>
          <Box sx={{ width: '50%', mr: 2 }}>
            <TextFormField
              control={control}
              errors={errors}
              name="name"
              label={
                isDriverEquipmentEnhancementsEnabled
                  ? `${$t('form_fields.truck_number_name')}`
                  : `${$t('form_fields.name')}`
              }
              isRequired={true}
              disabled={isSharedEquipment && !selectedCompanyOwnsEquipment}
            />
            {!isDriverEquipmentEnhancementsEnabled && (
              <EquipmentHint variant={'body2'}>
                {$t('administration.equipment.equipment_name_hint')}
              </EquipmentHint>
            )}
          </Box>
          <Box display={'flex'} flexDirection={'column'} sx={{ width: '50%' }}>
            {isDriverEquipmentEnhancementsEnabled ? (
              <AutocompleteFormField
                control={control}
                name="type"
                disabled={
                  !selectedCompany?.id ||
                  (isSharedEquipment && !selectedCompanyOwnsEquipment)
                }
                errors={errors}
                list={equipmentTypesOptions || []}
                label={`${$t('form_fields.equipment_type')}`}
                isRequired={true}
                getValue={(item) => item?.id}
                getLabel={(item) => item.name}
                placeholder={$t('form_fields.select') as string}
                onInput={onInputEquipmentType}
                noOptionsText={
                  tmpEquipmentType ? (
                    <Chip
                      size={'small'}
                      variant={'filled'}
                      color={'primary'}
                      onClick={createEquipmentType}
                      disabled={isEquipmentTypeLoading}
                      sx={{ cursor: 'pointer' }}
                      label={$t('common.add_new_name', {
                        name: tmpEquipmentType || '',
                      })}
                    />
                  ) : (
                    $t('form_fields.no_options')
                  )
                }
              />
            ) : (
              <>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="externalId"
                  label={`${$t('form_fields.equipment_id')}`}
                  isRequired={false}
                  disabled={isSharedEquipment && !selectedCompanyOwnsEquipment}
                />

                <EquipmentHint variant={'body2'}>
                  {$t('administration.equipment.equipment_id_hint')}
                </EquipmentHint>
              </>
            )}
          </Box>
        </Box>
        <Box display={'flex'} sx={{ mt: 2 }}>
          <Box sx={{ width: '50%', mr: 2 }}>
            {isDriverEquipmentEnhancementsEnabled ? (
              <TextFormField
                control={control}
                errors={errors}
                name="externalId"
                label={`${$t('form_fields.equipment_id')}`}
                isRequired={false}
                disabled={isSharedEquipment && !selectedCompanyOwnsEquipment}
              />
            ) : (
              <AutocompleteFormField
                control={control}
                name="type"
                disabled={
                  !selectedCompany?.id ||
                  (isSharedEquipment && !selectedCompanyOwnsEquipment)
                }
                errors={errors}
                list={equipmentTypesOptions || []}
                label={`${$t('form_fields.equipment_type')}`}
                isRequired={true}
                getValue={(item) => item?.id}
                getLabel={(item) => item.name}
                onInput={onInputEquipmentType}
                noOptionsText={
                  tmpEquipmentType ? (
                    <Chip
                      size={'small'}
                      variant={'filled'}
                      color={'primary'}
                      onClick={createEquipmentType}
                      disabled={isEquipmentTypeLoading}
                      sx={{ cursor: 'pointer' }}
                      label={$t('common.add_new_name', {
                        name: tmpEquipmentType || '',
                      })}
                    />
                  ) : (
                    $t('form_fields.no_options')
                  )
                }
              />
            )}
          </Box>
          <Box display={'flex'} sx={{ width: '50%' }}>
            <TextFormField
              control={control}
              errors={errors}
              name="licenseNumber"
              label={`${$t('form_fields.license_number')}`}
              isRequired={false}
              disabled={isSharedEquipment && !selectedCompanyOwnsEquipment}
            />
          </Box>
        </Box>
        <Box display={'flex'} sx={{ mt: 2 }}>
          <Box sx={{ width: '50%', mr: 2 }}>
            <TextFormField
              control={control}
              errors={errors}
              name="accountingId"
              label={`${$t('administration.equipment.accounting_id')}`}
              isRequired={false}
              disabled={isSharedEquipment && !selectedCompanyOwnsEquipment}
            />
          </Box>
          <Box sx={{ width: '50%' }}>
            <TextFormField
              control={control}
              errors={errors}
              name="zone"
              label={`${$t('administration.equipment.zone')}`}
              isRequired={false}
              disabled={isSharedEquipment && !selectedCompanyOwnsEquipment}
            />
          </Box>
        </Box>
        {!isDriverEquipmentEnhancementsEnabled && (
          <Box display={'flex'} sx={{ mt: 2 }}>
            <Box display={'flex'} width={'50%'}>
              <CheckBoxFormField
                control={control}
                errors={errors}
                value={true}
                name="isNotActive"
                isRequired={true}
                label={`${$t('common.inactive')}`}
                disabled={isSharedEquipment && !selectedCompanyOwnsEquipment}
              />
            </Box>
            <Box display={'flex'} width={'50%'} sx={{ mr: 2 }}>
              <CheckBoxFormField
                control={control}
                errors={errors}
                value={false}
                isRequired={true}
                name="isExternal"
                label={`${$t('administration.equipment.ownership_external')}`}
                disabled={isSharedEquipment && !selectedCompanyOwnsEquipment}
              />
            </Box>
          </Box>
        )}

        {!isManagedEquipment && (
          <>
            <Box>
              <Typography variant={'h6'} sx={{ mt: 2 }}>
                {isDriverEquipmentEnhancementsEnabled
                  ? `${$t('administration.equipment.share_with_connected_companies')}`
                  : `${$t('administration.equipment.shared_access_configuration')}`}
              </Typography>
              <Box>
                <EquipmentHint variant={'body2'} mt={0.5}>
                  {isDriverEquipmentEnhancementsEnabled
                    ? $t('administration.equipment.share_with_connected_companies_desc')
                    : $t('administration.equipment.share_equipment_access')}
                </EquipmentHint>
              </Box>
              {isSharedEquipment && !selectedCompanyOwnsEquipment && (
                <>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{ mt: 1, border: '1px solid #ccc', padding: '5px' }}
                  >
                    <EquipmentHint variant={'body2'}>
                      {$t('administration.equipment.shared_from_company')}:{' '}
                      {defaultEquipment?.companyShare?.senderCompany?.legalName}
                    </EquipmentHint>
                    <EquipmentHint variant={'body2'}>
                      {$t('administration.equipment.shared_from_ext_id')}:{' '}
                      {defaultEquipment?.externalId}
                    </EquipmentHint>
                    <EquipmentHint variant={'body2'}>
                      {$t('administration.equipment.shared_from_access')}:{' '}
                      {defaultEquipment?.companyShare?.dispatchable
                        ? $t('common.dispatchable')
                        : $t('common.view_only')}
                    </EquipmentHint>
                  </Box>

                  <Box display={'flex'} mt={1.5} sx={{ alignItems: 'center' }}>
                    <Box display={'flex'} sx={{ width: '50%', mr: 2 }}>
                      <TextFormField
                        control={control}
                        errors={errors}
                        name="companySpecificId"
                        label={`${$t('form_fields.company_specific_id')}`}
                        isRequired={false}
                      />
                    </Box>
                    <EquipmentHint variant={'body2'} sx={{ width: '50%' }}>
                      {$t('administration.equipment.input_company_specific_id')}
                    </EquipmentHint>
                  </Box>
                </>
              )}

              {/* Company Shares */}
              <Box display={'flex'} sx={{ flexDirection: 'column' }}>
                {companySharesFields.map((item, index) => {
                  return (
                    <Box key={item.id} display={'flex'} sx={{ width: '100%', mt: 2 }}>
                      <Box sx={{ width: '50%', mr: 2 }}>
                        <AutocompleteAsyncFormField
                          control={control}
                          errors={errors}
                          name={`companyShares[${index}].receiverCompany`}
                          getValue={(item) => item.id}
                          getLabel={(item) => item.legalName}
                          asyncCallback={getAllConnectedCompaniesByCompanyIdTypeahead}
                          extraRequestOptions={{
                            companyId:
                              selectedCompanyId ?? userStore.userCompany.id ?? '',
                          }}
                          label={`${$t('form_fields.share_to_company')}`}
                          disabled={
                            !!(
                              defaultValues.companyShares.length &&
                              item.receiverCompany.id
                            )
                          } // Disable existing company shares
                        />
                      </Box>
                      <Box sx={{ width: '50%' }}>
                        <AutocompleteFormField
                          control={control}
                          errors={errors}
                          name={`companyShares[${index}].dispatchable`}
                          list={[
                            {
                              id: 'dispatchable',
                              name: $t('common.dispatchable'),
                            },
                            {
                              id: 'view_only',
                              name: $t('common.view_only'),
                            },
                          ]}
                          isRequired={false}
                          getValue={(item) => item.id}
                          getLabel={(item) => item.name}
                          label={`${$t('form_fields.access_level')}`}
                          disabled={
                            isSharedEquipment &&
                            !selectedCompanyOwnsEquipment &&
                            !defaultEquipment?.companyShare?.dispatchable
                          }
                        />
                      </Box>
                      <RemoveItemButton
                        action={() => {
                          removeCompanyShares(index);
                        }}
                        sx={{
                          ml: 1,
                          mt: 2.3,
                          cursor: 'pointer',
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>

              <PseudoLink
                disabled={companySharesFields.length === connectedCompanies.length}
                title={`+ ${$t('administration.equipment.add_shared_company')}`}
                sx={{
                  mt: 2,
                  display: 'inline-flex',
                  pointerEvents:
                    companySharesFields.length === connectedCompanies.length
                      ? 'none'
                      : 'auto',
                }}
                action={() => {
                  companySharesFields.length !== connectedCompanies.length &&
                    appendCompanyShares({
                      id: '',
                      receiverCompany: { id: '', legalName: '' },
                      dispatchable: { id: 'view_only', name: 'View Only' },
                      companyShareableId: defaultEquipment?.id || '',
                    });
                }}
              />
            </Box>
          </>
        )}
      </Box>
    </form>
  );
});
export { EquipmentForm };
