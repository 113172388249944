import { t } from 'i18next';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextFormField } from '~components/FormFields/TextFormField';
import { useStores } from '~store';
import { nullableStringOrNumberIsValidNumber } from '~utils/utilFunctions';

import { FlexColumn, FlexContainer, FlexRow, Hint, Label } from './shared-components';

const formatTime = (minutes: number) => {
  const days = Math.floor(minutes / 60 / 24);
  const hours = Math.floor((minutes / 60) % 24);
  const mins = Math.floor(minutes % 60);

  return `${days > 0 ? `${days}d ` : ''}${hours}h ${mins}m`;
};

export default function Cycle() {
  const form = useFormContext();

  const { userStore } = useStores();

  const shouldUseKilometers = userStore.userCompany.isMeters;

  const watchedDropoffOnsite = form.watch('dropoffOnsite') as string | number | null;
  const watchedPickupOnsite = form.watch('pickupOnsite') as string | number | null;
  const watchedPickupToDropoff = form.watch('pickupToDropoff') as string | number | null;

  const formattedDurations = useMemo(() => {
    const isValidPickupOnsite =
      nullableStringOrNumberIsValidNumber(watchedPickupOnsite) &&
      Number(watchedPickupOnsite) >= 0;

    const isValidDropoffOnsite =
      nullableStringOrNumberIsValidNumber(watchedDropoffOnsite) &&
      Number(watchedDropoffOnsite) >= 0;

    const isValidPickupToDropoff =
      nullableStringOrNumberIsValidNumber(watchedPickupToDropoff) &&
      Number(watchedPickupToDropoff) >= 0;

    return {
      pickupOnsite: isValidPickupOnsite ? formatTime(Number(watchedPickupOnsite)) : null,
      dropoffOnsite: isValidDropoffOnsite
        ? formatTime(Number(watchedDropoffOnsite))
        : null,
      pickupToDropoff: isValidPickupToDropoff
        ? formatTime(Number(watchedPickupToDropoff))
        : null,
    };
  }, [watchedPickupOnsite, watchedDropoffOnsite, watchedPickupToDropoff]);

  return (
    <FlexContainer>
      <FlexRow sx={{ mt: 1 }}>
        <FlexColumn noGap>
          <Label>{t('dispatch.order.cycle.pickup_onsite')}</Label>
          <TextFormField
            control={form.control}
            errors={form.formState.errors}
            name="pickupOnsite"
            type="number"
          />
          <Hint isLarge>{formattedDurations.pickupOnsite}</Hint>
        </FlexColumn>

        <FlexColumn noGap>
          <Label>{t('dispatch.order.cycle.dropoff_onsite')}</Label>
          <TextFormField
            control={form.control}
            errors={form.formState.errors}
            name="dropoffOnsite"
            type="number"
          />
          <Hint isLarge>{formattedDurations.dropoffOnsite}</Hint>
        </FlexColumn>

        <FlexColumn noGap>
          <Label>{t('dispatch.order.cycle.pickup_to_drop')}</Label>
          <TextFormField
            control={form.control}
            errors={form.formState.errors}
            name="pickupToDropoff"
            type="number"
          />
          <Hint isLarge>{formattedDurations.pickupToDropoff}</Hint>
        </FlexColumn>

        <FlexColumn noGap>
          <Label>
            {t('dispatch.order.cycle.cycle_distance', {
              unit: shouldUseKilometers ? t('common.kms') : t('common.mis'),
            })}
          </Label>
          <TextFormField
            control={form.control}
            errors={form.formState.errors}
            name="cycleDistance"
            type="number"
          />
          <Hint>{t('dispatch.order.cycle.cycle_distance_hint')}</Hint>
        </FlexColumn>
      </FlexRow>
    </FlexContainer>
  );
}
