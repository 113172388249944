import styled from '@emotion/styled';
import {
  circularProgressClasses,
  iconButtonClasses,
  svgIconClasses,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/system/Box';
import { createElement, useMemo } from 'react';

import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import theme from '~theme/AppTheme';

export const FlexContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const FlexRow = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

type FlexColumnProps = BoxProps & { noGap?: boolean };

export const FlexColumn = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'noGap',
})<FlexColumnProps>(({ noGap }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  ...(noGap ? {} : { gap: theme.spacing(0.5) }),
}));

type LabelProps = TypographyProps & { isBold?: boolean };

export const Label = styled(Typography, {
  shouldForwardProp: (propName) => propName !== 'isBold',
})<LabelProps>(({ isBold }) => ({
  color: theme.brandV2.colors.treadBlack,
  fontSize: '12px',
  ...(isBold ? { fontWeight: 600 } : {}),
}));

type HintProps = TypographyProps & { isLarge?: boolean };

export const Hint = styled(Typography, {
  shouldForwardProp: (propName) => propName !== 'isLarge',
})<HintProps>(({ isLarge }) => ({
  color: theme.brandV2.colors.treadGray2,
  fontSize: isLarge ? '10px' : '8px',
  letterSpacing: '0.15px',
  minHeight: isLarge ? '15px' : '12px',
}));

type ValueProps = TypographyProps & { isLarge?: boolean };

export const Value = styled(Typography, {
  shouldForwardProp: (propName) => propName !== 'isLarge',
})<ValueProps>(({ isLarge }) => ({
  color: theme.brandV2.colors.treadBlack,
  fontSize: isLarge ? '18px' : '14px',
}));

interface EstimateCardOwnProps {
  title: string;
  variant?: 'regular' | 'light';
}

interface EstimateCardProps extends React.PropsWithChildren<EstimateCardOwnProps> {}

export function EstimateCard({
  children,
  title,
  variant = 'regular',
}: EstimateCardProps) {
  const isStringChildren = typeof children === 'string';

  const propsByVariant = useMemo(() => {
    if (variant === 'light') {
      return { container: { bgcolor: '#FBFDFD' } };
    }

    return {
      container: { bgcolor: theme.brandV2.colors.treadGray8 },
    };
  }, [variant]);

  return (
    <Box
      sx={{
        ...propsByVariant.container,
        border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 0.25,
        height: '100%',
        p: 1,
      }}
    >
      <Typography sx={{ fontSize: '10px', fontWeight: 600 }}>{title}</Typography>

      {isStringChildren ? (
        <Typography sx={{ fontSize: '18px' }}>{children}</Typography>
      ) : (
        children
      )}
    </Box>
  );
}

interface IconCTAProps {
  color: string;
  icon: typeof SvgIcon;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  tooltip: string;
}

export function IconCTA({
  color,
  icon,
  isDisabled,
  isLoading,
  onClick,
  tooltip,
}: IconCTAProps) {
  return (
    <BasicTooltip title={tooltip}>
      <IconButton
        disabled={isDisabled}
        onClick={onClick}
        size="small"
        sx={{
          border: `solid 1px ${color}`,
          p: '3px',
          [`&.${iconButtonClasses.disabled}`]: {
            borderColor: (theme) => theme.brandV2.colors.treadGray5,
            cursor: 'not-allowed',
            pointerEvents: 'auto',
            [`& .${svgIconClasses.root}`]: {
              color: (theme) => theme.brandV2.colors.treadGray5,
            },
          },
        }}
      >
        {isLoading ? (
          <CircularProgress
            size="16px"
            sx={{ [`&.${circularProgressClasses.root}`]: { color } }}
          />
        ) : (
          createElement(icon, { sx: { color, fontSize: '16px' } })
        )}
      </IconButton>
    </BasicTooltip>
  );
}
