import Grid from '@mui/material/Grid';
import { AccountType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { FeatureFlags } from '~constants/featureFlags';
import { useAccount } from '~hooks/useAccount';
import { useDepartment } from '~hooks/useDepartment';
import { ProjectState, useProjects } from '~hooks/useProjects';
import { Phase } from '~hooks/useProjects/models';
import { useStores } from '~store';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

import { PhaseOptions, PhaseSelection } from './PhaseSelection';

interface ProjectDetailsProps {
  companyId?: string | null;
  control: Control<any>;
  errors: FieldErrors<FieldValues>;
  readOnly?: boolean;
  tomlinsonOrderFormProjectFirstFeatureFlagEnabled?: boolean;
}

export const ProjectDetails = ({
  companyId,
  control,
  errors,
  readOnly = false,
  tomlinsonOrderFormProjectFirstFeatureFlagEnabled,
}: ProjectDetailsProps) => {
  const { companyAssetsStore } = useStores();
  const projectPhaseEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.projectPhase,
  });

  const {
    getProjectsByCompanyId,
    getProjectsByCompanyIdTypeahead,
    getProjectPhasesTypeahead,
  } = useProjects();
  const { getAccountsByCompanyIdTypeahead } = useAccount();
  const { getDepartmentByCompanyId } = useDepartment();

  const departmentOptions = companyAssetsStore.departments;
  const watchAccount = useWatch({ control, name: 'account' });
  const watchProject = useWatch({ control, name: 'project' });
  const watchPhases = useWatch({ control, name: 'phases' });
  const watchOrder = useWatch({ control, name: 'id' });
  const [loaded, setLoaded] = useState(false);
  const [localPhaseNameOptions, setLocalPhaseNameOptions] = useState<PhaseOptions[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<PhaseOptions | null>(null);
  const allowCreate = useMemo(() => {
    return !watchProject?.id || watchOrder;
  }, [watchProject, watchOrder]);
  const { setValue } = useFormContext();

  useEffect(() => {
    if (companyId && !readOnly) {
      getProjectsByCompanyId(companyId || '');
      getDepartmentByCompanyId(companyId);
    }
  }, [companyId, readOnly]);

  const fetchAccountsByCompanyId = async (additionalProps = {}) => {
    if (!companyId || readOnly) {
      return [];
    }
    return await getAccountsByCompanyIdTypeahead({
      companyId,
      accountTypes: [AccountType.CUSTOMER],
      ...additionalProps,
    });
  };

  const fetchProjectsByCompanyId = async (additionalProps = {}) => {
    if (!companyId || readOnly) {
      return [];
    }

    return await getProjectsByCompanyIdTypeahead({
      companyId: companyId,
      states: [ProjectState.ACTIVE],
      accountId: watchAccount?.id,
      ...additionalProps,
    });
  };

  useEffect(() => {
    if (!loaded) {
      if (watchPhases) {
        setLocalPhaseNameOptions(
          watchPhases.map((item: Phase) => ({
            id: item.id,
            name: item.name,
            code: item.code,
            primary: item.primary,
          })),
        );

        const primary = watchPhases.find((item: Phase) => item.primary);
        if (primary) {
          setSelectedPhase(primary);
        }
      }
      setLoaded(true);
    }
  }, [JSON.stringify(watchPhases), loaded]);

  useEffect(() => {
    const fetchData = async () => {
      if (watchProject?.id && !watchOrder && loaded) {
        const data = await getProjectPhasesTypeahead({
          projectId: watchProject?.id,
          query: '',
        });
        const items =
          data?.data.map((item) => ({
            id: item.id,
            name: item.name,
            code: item.code,
            primary: item.primary,
          })) || [];

        setLocalPhaseNameOptions(items);
        setSelectedPhase(null);
      }
    };
    fetchData();
  }, [watchProject, loaded, watchOrder]);

  const accountField = (
    <Grid item xs={6}>
      <AutocompleteAsyncFormField
        control={control}
        disabled={!companyId || readOnly}
        errors={errors}
        name={'account'}
        label={`${t('dispatch.order.customer_account')}`}
        isRequired={true}
        getValue={(item) => item.id}
        getLabel={(item) => item.name || ''}
        asyncCallback={fetchAccountsByCompanyId}
        onSelect={(option) => {
          setValue('project', null);
          setLocalPhaseNameOptions([]);
          setSelectedPhase(null);
          return option;
        }}
      />
    </Grid>
  );

  const projectField = (
    <Grid item xs={6}>
      <AutocompleteAsyncFormField
        control={control}
        errors={errors}
        disabled={!companyId || readOnly}
        name={'project'}
        label={`${t('order.form.associated_project_name')}`}
        clearable={true}
        onClear={() => {
          // because AutocompleteAsyncFormField won't clear the value if onClear is set.
          setValue('project', null);
          if (!watchOrder) {
            // only clear phase options on create, not on update.
            setLocalPhaseNameOptions([]);
            setSelectedPhase(null);
          }
        }}
        getValue={(item) => item.id}
        getLabel={(item) => item.name || ''}
        asyncCallback={fetchProjectsByCompanyId}
      />
    </Grid>
  );

  return (
    <>
      {tomlinsonOrderFormProjectFirstFeatureFlagEnabled ? (
        <>
          {projectField}
          {accountField}
        </>
      ) : (
        <>
          {accountField}
          {projectField}
        </>
      )}

      <Grid item xs={6}>
        <TextFormField
          control={control}
          errors={errors}
          disabled={true}
          name="projectExternalId"
          label={`${t('form_fields.project_id')}`}
          isRequired={false}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteFormField
          control={control}
          name="department"
          groupBy={(item) => item?.typeName}
          errors={errors}
          sx={{ mr: 2 }}
          list={departmentOptions}
          label={`${t('project.form.department')}`}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
          clearable={true}
          disabled={readOnly}
        />
      </Grid>
      {projectPhaseEnabled && !readOnly && (
        <PhaseSelection
          phaseOptions={localPhaseNameOptions}
          canEdit={allowCreate}
          selectedPhase={selectedPhase}
          onPhaseOptionsChange={(options) => {
            setValue('phases', options);
          }}
        />
      )}
    </>
  );
};
