import {
  ApproachType,
  AvoidType,
  DistanceMatrixService,
  GeometryType,
  TravelMode,
} from '@nbai/nbmap-gl';
import { useJsApiLoader } from '@react-google-maps/api';
import axios from 'axios';
import { t } from 'i18next';
import { useCallback, useEffect, useRef, useState } from 'react';

import { GOOGLE_MAPS_LOADER_OPTIONS } from '~components/Maps/v2/constants';
import { TORONTO_OFFICE_COORDINATES } from '~constants/mapConsts';
import { AddressItem } from '~hooks/useAddress';
import { LatLng } from '~interfaces/map';
import { useStores } from '~store';

import {
  AddressProvider,
  AddressProviderSuggestion,
  concreteAddressProviderBySupportedAddressProvider,
  SupportedAddressProvider,
} from '../useAddress/models/AddressProvider';

const GOOGLE_MAPS_PLACE_PREDICTION_LOCATION_BIAS_CIRCLE_RADIUS_IN_METERS = 10000;

type NBParams = {
  query: string;
};

interface DistanceParams {
  origins: LatLng[];
  destinations: LatLng[];
  departureTime?: Date;
  mode?: TravelMode;
  avoid?: AvoidType;
  approaches?: ApproachType[];
}

interface AssetLocationParams {
  assetId: string;
  startTime: number;
  endTime: number;
  geometryType: GeometryType;
  pageNumber?: number;
}

interface AssetIdParams {
  userId: string;
}

type UseGeocodeArgs = {
  addressProvider: `${SupportedAddressProvider}`;
};

export const useGeocode = (
  args: UseGeocodeArgs = { addressProvider: 'next_billion' },
) => {
  const token = import.meta.env.TREAD__NEXTBILLION_KEY;
  const baseUrl = 'https://api.nextbillion.io';
  const distanceMatrixService = useRef(new DistanceMatrixService());
  const $http = axios.create();

  const [isLoading, setIsLoading] = useState(false);
  const controller = new AbortController();
  const [startSearchPosition, setStartSearchPosition] = useState<LatLng>(
    TORONTO_OFFICE_COORDINATES,
  );

  const { userStore } = useStores();
  const company = userStore?.userCompany || {};

  const googleMapsApiLoader = useJsApiLoader(GOOGLE_MAPS_LOADER_OPTIONS);

  const createGoogleMapsGeocoderInstance = useCallback(() => {
    return new Promise<google.maps.Geocoder>((resolve, reject) => {
      if (googleMapsApiLoader.isLoaded) {
        return window.google.maps
          .importLibrary('geocoding')
          .then(() => resolve(new google.maps.Geocoder()))
          .catch(() => {
            return reject(
              new Error(`${t('error_messages.geocode.failed_to_load_google_maps_api')}`),
            );
          });
      } else if (googleMapsApiLoader.loadError) {
        reject(googleMapsApiLoader.loadError);
      }
    });
  }, [googleMapsApiLoader.isLoaded, googleMapsApiLoader.loadError]);

  const createGoogleMapsAutocompleteServiceInstance = useCallback(() => {
    return new Promise<google.maps.places.AutocompleteService>((resolve, reject) => {
      if (googleMapsApiLoader.isLoaded) {
        return window.google.maps
          .importLibrary('places')
          .then(() => resolve(new google.maps.places.AutocompleteService()))
          .catch(() => {
            return reject(
              new Error(`${t('error_messages.geocode.failed_to_load_google_maps_api')}`),
            );
          });
      } else if (googleMapsApiLoader.loadError) {
        reject(googleMapsApiLoader.loadError);
      }
    });
  }, [googleMapsApiLoader.isLoaded, googleMapsApiLoader.loadError]);

  const createGoogleMapsPlacesServiceInstance = useCallback(() => {
    return new Promise<google.maps.places.PlacesService>((resolve, reject) => {
      if (googleMapsApiLoader.isLoaded) {
        return window.google.maps
          .importLibrary('places')
          .then(() => {
            return resolve(
              new google.maps.places.PlacesService(window.document.createElement('div')),
            );
          })
          .catch(() => {
            return reject(
              new Error(`${t('error_messages.geocode.failed_to_load_google_maps_api')}`),
            );
          });
      } else if (googleMapsApiLoader.loadError) {
        reject(googleMapsApiLoader.loadError);
      }
    });
  }, [googleMapsApiLoader.isLoaded, googleMapsApiLoader.loadError]);

  const ConcreteAddressProvider =
    concreteAddressProviderBySupportedAddressProvider[args.addressProvider];
  const addressProvider = new AddressProvider(new ConcreteAddressProvider());

  const setDefaultPosition = useCallback(() => {
    if (company?.defaultLat && company?.defaultLon) {
      setStartSearchPosition({
        lat: company.defaultLat,
        lng: company.defaultLon,
      });
    }
  }, [company]);

  useEffect(() => {
    setDefaultPosition();
  }, [setDefaultPosition]);

  async function getAddressItemByGoogleMapsPlaceId(placeId: string) {
    const googleMapsPlacesService = await createGoogleMapsPlacesServiceInstance();

    return await new Promise<AddressItem>((resolve) => {
      return googleMapsPlacesService.getDetails({ placeId }, (result) => {
        if (!result) {
          throw new Error('Failed to find place by place ID.');
        }

        const ConcreteAddressProvider =
          concreteAddressProviderBySupportedAddressProvider[
            SupportedAddressProvider.GOOGLE_MAPS_GEOCODER
          ];
        const addressProvider = new AddressProvider(new ConcreteAddressProvider());

        return resolve(addressProvider.decodeSuggestion(result));
      });
    });
  }

  const getNBPlaces = async (params: NBParams) => {
    const url = `${baseUrl}/multigeocode/search?key=${token}`;

    if (isLoading) {
      setTimeout(() => {
        if (controller) {
          controller.abort();
        }
      }, 1);
    }

    if (!params.query?.length) {
      return;
    }

    setIsLoading(true);

    try {
      let suggestions: AddressProviderSuggestion[] = [];

      if (args.addressProvider === SupportedAddressProvider.GOOGLE_MAPS_GEOCODER) {
        const googleMapsGeocoder = await createGoogleMapsGeocoderInstance();
        const googleResponse = await googleMapsGeocoder.geocode({
          address: params.query,
          fulfillOnZeroResults: true,
        });

        suggestions = googleResponse.results;
      } else if (
        args.addressProvider === SupportedAddressProvider.GOOGLE_MAPS_PLACES_AUTOCOMPLETE
      ) {
        const googleMapsAutocompleteService =
          await createGoogleMapsAutocompleteServiceInstance();

        const googleResponse = await googleMapsAutocompleteService.getPlacePredictions({
          input: params.query,
          locationBias: new google.maps.Circle({
            center: new google.maps.LatLng({ ...startSearchPosition }),
            radius: GOOGLE_MAPS_PLACE_PREDICTION_LOCATION_BIAS_CIRCLE_RADIUS_IN_METERS,
          }),
        });

        suggestions = googleResponse.predictions;
      } else {
        const response = await $http.post<
          unknown,
          { data?: { entities?: AddressProviderSuggestion[] } }
        >(
          url,
          {
            query: params.query,
            at: startSearchPosition,
            limit: 12,
            country: 'CAN,MEX,USA',
          },
          {
            signal: controller.signal,
          },
        );

        suggestions = response.data?.entities || [];
      }

      return suggestions.map((suggestion) => {
        return addressProvider.decodeSuggestion(suggestion);
      });
    } catch (error) {
      const e = error as object;

      if ('code' in e && e.code !== 'ERR_CANCELED') {
        throw error;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const encodeNBPlace = (coordinates: string) => {
    const url = `${baseUrl}/h/revgeocode`;

    return $http
      .get(url, {
        params: {
          key: token,
          at: coordinates,
        },
      })
      .then((resp) => {
        const { items } = resp.data;
        if (items.length) {
          return AddressItem.decodeNBPlace(items[0]);
        }
        return {} as AddressItem;
      });
  };

  const getNBPlaceDetails = (placeId: string) => {
    const url = `${baseUrl}/h/lookup`;

    return $http
      .get(url, {
        params: {
          key: token,
          id: placeId,
        },
      })
      .then((resp) => {
        const { items } = resp.data;
        if (items.length) {
          return AddressItem.decodeNBPlace(items[0]);
        }
        return {} as AddressItem;
      });
  };

  const getDistance = ({
    origins,
    destinations,
    avoid,
    departureTime,
    mode,
    approaches,
  }: DistanceParams) => {
    const options = {
      origins: origins,
      destinations: destinations,
      departureTime: departureTime ?? +new Date(),
      mode: mode || TravelMode.FOUR_WHEELS,
      // Avoid: AvoidType.HIGHWAY,
      approaches: approaches?.length ? approaches : [ApproachType.CURB],
    } as any;

    if (avoid) {
      options.avoid = avoid;
    }

    return distanceMatrixService.current.getDistanceMatrix(options).then((response) => {
      const { status, rows } = response;

      if (status === 'Ok') {
        return rows;
      } else {
        console.error('invalid request');
      }
    });
  };

  /**
   * Retrieves the location data from NextBillion for a specific asset within a given time range.
   * @param assetId - The ID of the asset.
   * @param startTime - The start time of the time range.
   * @param endTime - The end time of the time range.
   * @param geometryType - The type of geometry.
   * @param pageNumber - The page number for pagination.
   */
  const getNBAssetLocation = ({
    assetId,
    startTime,
    endTime,
    geometryType,
    pageNumber,
  }: AssetLocationParams) => {
    const url = `${baseUrl}/skynet/asset/${assetId}/location/list`;
    return $http
      .get(url, {
        params: {
          key: token,
          start_time: startTime,
          end_time: endTime,
          geometry_type: geometryType,
          pn: pageNumber,
          ps: 100,
        },
      })
      .then((resp) => {
        return resp.data;
      });
  };

  /**
   * Retrieves the NB Asset ID based on the provided userId.
   * @param userId - The ID of the user.
   * @returns The asset id for the user, empty string if not found.
   */
  const getNBAssetId = ({ userId }: AssetIdParams): Promise<string> => {
    const url = `${baseUrl}/skynet/asset/list`;
    // Format of the include_all_of_attributes param: key1:value1|key2:value2
    const attributesParams = `user_id:${userId}`;

    return $http
      .get(url, {
        params: {
          key: token,
          include_all_of_attributes: attributesParams,
          ps: 1,
        },
      })
      .then((resp) => {
        return resp.data.data.list[0]?.id || '';
      });
  };

  return {
    encodeLngLat: encodeNBPlace,
    getAddressItemByGoogleMapsPlaceId,
    getAssetId: getNBAssetId,
    getDistance,
    getDriverRoute: getNBAssetLocation,
    getPlaceDetails: getNBPlaceDetails,
    getPlaces: getNBPlaces,
    isLoading,
    updaStartSearchPosition: setStartSearchPosition,
  };
};
